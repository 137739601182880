import { memberCenterTypeEnum } from "../../core/enums/fp-member-center-type.enum";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpMemberCenterSetTemplates } from "./fp-member-center-template";
import { fpSetMemberCenterTemplateListeners } from "./fp-member-center-template-listeners";
import { fpSetCustomerActiveAcessDetailsAsync } from "../../core/utils/fp-set-customer-active-access-details";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";
import { fpRemoveUrlParamenterByName } from "../../core/utils/fp-url-handler";

export async function fpShowMemberCenterFragment(category, userActionToTrigger) {
  try {
    fpSpinnerHandler("show", null);
    const settings = _fp.config.fragments.member_center_fragment.settings;

    // If function to display member center has been triggered, but a customer is not on a correct page do a redirect to the correct one
    if ([memberCenterTypeEnum.Inline, memberCenterTypeEnum.InlinePartial].includes(settings?.type) && settings?.location_url && !document.location.href.includes(settings.location_url)) {
      document.location.href = settings.location_url;
      fpSpinnerHandler("hide", null);
      return;
    }

    // Show authentication for inline member center if a user is not signed in
    const isInlineType = [memberCenterTypeEnum.Inline, memberCenterTypeEnum.InlinePartial].includes(settings?.type);
    if (!_fp.user?.access_token) {
      _fp.config.fragments.authentication_fragment.settings.type = settings.type === memberCenterTypeEnum.InlinePartial ? memberCenterTypeEnum.Inline : settings.type;
      fpShowAuthenticationFragment(authTypeEnum.SignIn);
      fpSpinnerHandler("hide", null);
      return;
    }

    if (_fp.metadata?.member_center_displayed) {
      return;
    }

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    _fp.metadata.member_center_displayed = !isInlineType; // to correctly display member center in modal

    const customerDetails = await fpSetCustomerActiveAcessDetailsAsync(true);
    if (!customerDetails) {
      console.error("FP:: Customer details not set");
      fpSpinnerHandler("hide", null);
      return;
    }

    // Clear possible url params to prevent duplicate journeys
    ["mcc", "mca"].forEach(param => fpRemoveUrlParamenterByName(param));

    fpMemberCenterSetTemplates(settings, customerDetails, category);
    fpSetMemberCenterTemplateListeners(userActionToTrigger);

    fpTrackUserAction("fp_fragment_displayed", null, "member_center", null, null);
    fpSpinnerHandler("hide", null);
  } catch (error) {
    fpSpinnerHandler("hide", null);
    fpErrorHandler("Member Center Fragment", error);
    fpTrackUserAction("fp_error", null, "member_center", null, error);
  }
}
