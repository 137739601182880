import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetCustomerMandatoryFieldsTemplate } from "./fp-set-customer-mandatory-fields-template";
import { fpSetCustomerMandatoryFieldsTemplateListeners } from "./fp-set-customer-mandatory-fields-template-listeners";

export function fpShowCustomerMandatoryFieldsFragment() {
    try {
        _fp.premium_wrapper.innerHTML = fpSetCustomerMandatoryFieldsTemplate();
        fpSetCustomerMandatoryFieldsTemplateListeners();
    } catch (error) {
        fpTrackUserAction("fp_error", null, "customer_mandatory_fields", null, error);
        fpErrorHandler("Customer Mandatory Fields Fragment", error);
    }
}