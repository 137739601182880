import { fpUpdateCardAsync } from "../../../core/requests/cards/fp-card-update";

export async function fpStripeUpdatePaymentMethodAsync(card, paymentMethodResponse) {
    let pm = paymentMethodResponse;
    if (card) {
        pm = await _fp.payment_provider.stripe.createPaymentMethod("card", card);
        if (!pm || !!pm.error || !pm.paymentMethod) {
            const stErr = pm && pm.error ? pm.error : {};
            console.error("FP::", stErr);
            return;
        }
    }

    const currentPaymentMethodId = _fp?.user?.purchase?.payment_method?.id;
    if (!currentPaymentMethodId) {
        console.error("FP:: Payment Method Id Not Populated");
        return;
    }

    let response = await fpUpdateCardAsync(currentPaymentMethodId, pm.paymentMethod.card, pm.paymentMethod.id, false);
    if (response?.error) {
        return;
    }

    if (response?.payment_client_secret) {
        const cardSetupResponse = await _fp.payment_provider.stripe.confirmCardSetup(response.payment_client_secret, { payment_method: pm.paymentMethod.id });
        if (cardSetupResponse?.error) {
            console.error("FP:: Stripe Card Setup Error", cardSetupResponse.error);
            throw cardSetupResponse?.error?.message? cardSetupResponse.error.message : "Stripe Card Setup Error";
        }

        await fpUpdateCardAsync(currentPaymentMethodId, pm.paymentMethod.card, cardSetupResponse.setupIntent.payment_method, false);
    }

    return true;
}