export async function fpLoadScriptsAsync(scriptUrls) {
    function load(scriptUrl) {
        return new Promise(function (resolve) {
            const script = document.createElement("script");
            script.onload = resolve;
            script.src = scriptUrl;
            document.head.appendChild(script);
        });
    }
    
    const promises = [];
    for (const scriptUrl of scriptUrls) {
        promises.push(load(scriptUrl));
    }
    await Promise.all(promises);
}