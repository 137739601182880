import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetPricesTemplate } from "./fp-set-prices-fragment-template";
import { fpSetPricesTemplateListeners } from "./fp-set-prices-fragment-listeners";

export function fpShowPricesFragment() {
    try {
        _fp.premium_wrapper.innerHTML = fpSetPricesTemplate();
        fpSetPricesTemplateListeners();
        fpTrackUserAction("fp_fragment_displayed", null, "paywall", null, null);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "paywall", null, error);
        fpErrorHandler("Paywall", error);
    }
}