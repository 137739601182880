import { fpSendRequest } from "../../utils/fp-http-request";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";

export async function fpRequestResetPasswordAsync(data) {
    try {
        let request = {
            "email": data.email,
            "username": data.email,
            "language": _fp?.config?.client?.language ? _fp.config.client.language : null,
            "browser_session_id": _fp?.metadata?.browser_session_id ? _fp.metadata.browser_session_id : null
        };

        if (_fp.config?.client?.site) {
            request.site = _fp.config.client.site;
        }

        let url = _fp.config.authority + "/" + _fp.config.client.id + "/request_reset_password";
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        throw fpRequestErrorHandler(err);
    }
}