import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpDeleteStorageValue, fpGetStorageValue } from "../../../core/utils/fp-storage-handler";

export function fpAuthVendorSetUserDetails() {
  try {
    const vendorAuthPropName = _fp.config.authentication.storage_property_name;
    if (!vendorAuthPropName) {
      throw new Error("FP: Vendor authentication storage property name not set");
    }

    fpSpinnerHandler("show", null);
    
    _fp.user = _fp.user ? _fp.user : {};
    const user = fpGetStorageValue(vendorAuthPropName);
    if (!user) {
      fpDeleteStorageValue("fp.user");
      
      // // Set Interval to check whether a vendor set an auth cookie after fp.js has been initialized(wait max 1.5 sec)
      // let timer = 0;
      // const intervalId = setInterval(() => {
      //   timer += 500;
      //   const user = fpGetStorageValue(vendorAuthPropName);
      //   if (user || timer >= 2000) {
      //     fpSpinnerHandler("hide", null);
      //     clearInterval(intervalId);
      //     if (user) {
      //       if (_fp?.premium_wrapper) {
      //         _fp.premium_wrapper = "";
      //       }

      //       window.flipPayForcedInit = true;
      //       window.flipPay.init();
      //     }
      //   }
      // }, 500);

      return;
    }

    _fp.user.id = user.id;
    _fp.user.subscriber = user.subscriber;
    _fp.user.id_token = user.id_token;
    _fp.user.access_token = user.access_token;
    _fp.user.access_token_expiration_date = user.expires_at_datetime;
    _fp.user.access_token_expired = new Date(user.expires_at_datetime) < new Date();
    _fp.user.provider = user.provider;

    _fp.user.billing_details = {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email
    };
  } catch (error) {
    fpErrorHandler("Auth Vendor Set User Details", error);
  }
}
