import fpTemplateWelcome from "fpTemplateWelcome";
import fpTemplatePrice from "fpTemplatePrice";
import fpTemplateOrderSummary from "fpTemplateOrderSummary";
import fpTemplateVoluntaryContribution from "fpTemplateVoluntaryContribution";
import fpTemplateGiftRedeem from "fpTemplateGiftRedeem";

import fpTemplateHomeDeliveryAutoComplete from "fpTemplateHomeDeliveryAutoComplete";
import fpTemplateHomeDeliveryAddressForm from "fpTemplateHomeDeliveryAddressForm";

import fpTemplateMemberCenterLayout from "fpTemplateMemberCenterLayout";
import fpTemplateMemberCenterModalWrapper from "fpTemplateMemberCenterModalWrapper";
import fpTemplateMemberCenterProfile from "fpTemplateMemberCenterProfile";
import fpTemplateMemberCenterChangePassword from "fpTemplateMemberCenterChangePassword";
import fpTemplateMemberCenterSupportTicket from "fpTemplateMemberCenterSupportTicket";
import fpTemplateMemberCenterItems from "fpTemplateMemberCenterItems";
import fpTemplateMemberCenterSubscriptions from "fpTemplateMemberCenterSubscriptions";
import fpTemplateMemberCenterSubscriptionActionsWrapper from "fpTemplateMemberCenterSubscriptionActionsWrapper";

import fpTemplateMeteredPaywallRegistrationRequired from "fpTemplateMeteredPaywallRegistrationRequired";
import fpTemplateMeteredPaywallLimitReached from "fpTemplateMeteredPaywallLimitReached";

import fpTemplateAuthInlineWrapper from "fpTemplateAuthInlineWrapper";
import fpTemplateAuthModalWrapper from "fpTemplateAuthModalWrapper";
import fpTemplateAuthSignIn from "fpTemplateAuthSignIn";
import fpTemplateAuthSignUp from "fpTemplateAuthSignUp";
import fpTemplateAuthRequestResetPwd from "fpTemplateAuthRequestResetPwd";
import fpTemplateAuthResetPwd from "fpTemplateAuthResetPwd";

import fpTemplateRegistrationWall from "fpTemplateRegistrationWall";

const fragments = {
  welcome_fragment: { template: fpTemplateWelcome },
  prices_fragment: { template: fpTemplatePrice },
  order_summary_fragment: { template: fpTemplateOrderSummary },
  voluntary_contribution_fragment: { template: fpTemplateVoluntaryContribution },
  gift_redeem_fragment: { template: fpTemplateGiftRedeem },

  home_delivery_fragment: {
    templates: {
      address_autocomplete: fpTemplateHomeDeliveryAutoComplete,
      address_form: fpTemplateHomeDeliveryAddressForm
    },
  },

  member_center_fragment: {
    templates: {
      layout: fpTemplateMemberCenterLayout,
      modal_wrapper: fpTemplateMemberCenterModalWrapper,
      profile: fpTemplateMemberCenterProfile,
      change_password: fpTemplateMemberCenterChangePassword,
      support_ticket: fpTemplateMemberCenterSupportTicket,
      items: fpTemplateMemberCenterItems,
      subscriptions: fpTemplateMemberCenterSubscriptions,
      subscriptions_actions: fpTemplateMemberCenterSubscriptionActionsWrapper,
    },
  },

  authentication_fragment: {
    templates: {
      notification: "",
      inline_wrapper: fpTemplateAuthInlineWrapper,
      modal_wrapper: fpTemplateAuthModalWrapper,
      sign_in: fpTemplateAuthSignIn,
      sign_up: fpTemplateAuthSignUp,
      request_reset_pwd: fpTemplateAuthRequestResetPwd,
      reset_pwd: fpTemplateAuthResetPwd,
    }
  },

  metered_paywall_fragment: {
    templates: {
      registration_required: fpTemplateMeteredPaywallRegistrationRequired,
      limit_reached: fpTemplateMeteredPaywallLimitReached
    }
  },

  registrationwall_fragment: { template: fpTemplateRegistrationWall },

  spinner_fragment: "",
  snackbar_fragment: "",
};

export function fpGetTemplateByName(fragmentName, templateName) {
  if (!fragmentName) {
    return "";
  }

  let template = fragments[fragmentName] ? fragments[fragmentName].template : "";
  if (templateName) {
    template = fragments[fragmentName] && fragments[fragmentName].templates ? fragments[fragmentName].templates[templateName] : "";
  }

  let customTemplate = _fp.config?.fragments[fragmentName]?.template;
  if (templateName && _fp.config?.fragments[fragmentName]?.templates && _fp.config?.fragments[fragmentName]?.templates[templateName]) {
    customTemplate = _fp.config.fragments[fragmentName].templates[templateName];
  }

  return customTemplate ? customTemplate : template;
}
