import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseCancelRenewal(purchaseId, reasonStr) {
  try {
    const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/user_cancel`;
    const request = reasonStr
      ? {
        refund_reason_text: reasonStr,
        language: _fp?.config?.client?.language ? _fp.config.client.language : null
      }
      : null;

    await fpSendRequest("POST", url, JSON.stringify(request));
    return true;
  } catch (err) {
    console.error("FP::", err);
    throw err;
  }
}
