// import { logout } from "fpAuthenticationHandlerAsync";

import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { errorMessageEnum } from "../enums/fp-error-message.enum";
import { journeyTypeEnum } from "../enums/fp-journey-type.enum";
import { fpSpinnerHandler } from "./fp-spinner";

export function fpErrorHandler(type, err, returnMessage) {
    if (err) {
        let error;
        try { error = typeof err === "string" ? JSON.parse(err) : err; } catch (e) { error = err; }

        if (!!error.status && error.status === 401) {
            fpTriggerJourney(journeyTypeEnum.SignOut);
            return;
        }

        if (returnMessage) {
            return fpSetErrorMessage(err);
        }

        fpErrorHandlerDisplayMessage(type, err);
    } else if (typeof type === "string") {
        fpErrorHandlerDisplayMessage(type, null);
    }

    if (type !== "clear") {
        fpSpinnerHandler("hide");
    }
}

function fpErrorHandlerDisplayMessage(type, err) {
    try {
        const msg = type === "clear" ? "" : fpSetErrorMessage(err);
        const errEl = fpErrorGetWrapperEl();
        if (errEl) {
            errEl.style.display = "block";
            errEl.style.textAlign = "center";
            errEl.style.color = "red";
            errEl.innerText = msg;
            errEl.classList.remove("fp-hidden");

            if (errEl.parentNode) {
                errEl.parentNode.style.display = "block";
            }
        }

        if (err?.stack) {
            console.error("FP::", err.stack);
        } else if (msg) {
            console.error("FP::", msg);
        }
    } catch (err) {
        console.error("FP:: Something went wrong", err);
    }
}

export function fpSetErrorMessage(e) {
    try {
        let errorMessage = errorMessageEnum.Generic;
        if (!e) {
            return errorMessage;
        }

        if (e.status === 401) {
            errorMessage = "Not authenticated user";
        } else if (e.error?.code) {
            errorMessage = fpTranslateErrorCode(e.error.code);
        } else if (e.error && typeof e.error === "string") {
            errorMessage = fpTransalateErrorString(e.error);
        } else if (e.code) {
            errorMessage = fpTranslateErrorCode(e.code);
        } else if (typeof e === "string") {
            errorMessage = e;
        }

        return errorMessage;
    } catch (err) {
        return errorMessageEnum.Generic;
    }
}

function fpTranslateErrorCode(code) {
    switch (code) {
        case "invalid_username":
            return errorMessageEnum.InvalidUsername;
        case "invalid_password":
            return errorMessageEnum.InvalidPassword;
        case "limit_exceeded":
            return errorMessageEnum.LimitExceeded;
        case "invalid_username_or_password":
            return errorMessageEnum.InvalidUsernameOrPassword;
        case "price_not_found":
            return errorMessageEnum.PriceNotFound;
        case "customer_not_found":
            return errorMessageEnum.CustomerNotFound;
        case "duplicate":
            return errorMessageEnum.Duplicate;
        case "start_at_must_be_before_finish_at":
            return errorMessageEnum.StartAtMustBeBeforeFinishAt;
        case "overlapping_holiday_exists":
            return errorMessageEnum.OverlappingHolidayExists;
        case "not_found":
            return errorMessageEnum.NotFound;
        case "already_redeemed":
            return errorMessageEnum.AlreadyRedeemed;
        case "social_registration":
            return errorMessageEnum.SocialRegistration;
        case "email_verification_required":
            return errorMessageEnum.EmailVerificationRequired;
        case "recaptcha_validation_error":
            return errorMessageEnum.RecaptchaValidationError;
        default:
            return errorMessageEnum.Generic;
    }
}

function fpTransalateErrorString(e) {
    try {
        let errMsg = errorMessageEnum.Generic;
        let errorObj = JSON.parse(e);
        if (!!errorObj && errorObj.code) {
            return fpTranslateErrorCode(errorObj.code);
        }

        return errMsg;
    } catch (error) {
        return errorMessageEnum.Generic;
    }
}

function fpErrorGetWrapperEl() {
    let errEl = document.getElementById("fp-error-message");
    errEl = errEl ? errEl : document.getElementById("fp-reset-pwd-error-wrraper");
    errEl = errEl ? errEl : document.querySelector("[data-fp-element='error_message']");

    return errEl;
}