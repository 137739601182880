import { memberCenterSubscriptionActionEnum } from "../../../core/enums/fp-member-center-subscription-action.enum";
import { fpGetSubcriptionUpdateOptions } from "./fp-mc-subscription-plan-update-actions";

export function fpMemberCenterSetAvailableSubscriptionActions(wrapperEl, purchase) {
    const settings = _fp.config.fragments.member_center_fragment.settings;
    const noPlanUpdateOptions = fpGetSubcriptionUpdateOptions()?.length;

    const subscriptionActions = [
        {
            type: memberCenterSubscriptionActionEnum.UpdatePaymentMethod,
            isAllowed: purchase.renewable && !purchase.user_cancelled && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.UpdatePaymentMethod),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_update_payment_method']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.UpdateDeliveryAddress,
            isAllowed: purchase.price?.validate_address && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.UpdateDeliveryAddress),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_update_delivery_address']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.UpdateDeliveryHolidays,
            isAllowed: purchase.price?.validate_address && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.UpdateDeliveryHolidays),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_update_delivery_holidays']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.CancelRenewals,
            isAllowed: !!purchase.renewable && purchase.user_cancellable && !purchase.user_cancelled && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.CancelRenewals),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_cancel_renewals']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.Upgrade, // TODO CHECK THE PROPER PURCHASE PROPERTY
            isAllowed: purchase.upgrade_prices?.length > 0 && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.Upgrade),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_upgrade_subscription']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.Downgrade, // TODO CHECK THE PROPER PURCHASE PROPERTY
            isAllowed: purchase.downgrade_prices?.length > 0 && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.Downgrade),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_downgrade_subscription']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.UpdateRenewalAmount,
            isAllowed: !!purchase.renewable && !purchase.user_cancelled && !!settings.actions.find((x) => x === memberCenterSubscriptionActionEnum.UpdateRenewalAmount),
            element: wrapperEl.querySelector("[data-fp-element='mc_action_update_renewal_amount']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.UpdateSubscription,
            isAllowed: noPlanUpdateOptions > 0,
            element: wrapperEl.querySelector("[data-fp-element='mc_action_update_subscription']"),
        },
        {
            type: memberCenterSubscriptionActionEnum.RevertUserCancellation,
            isAllowed: !!purchase?.user_cancellable && !!purchase?.user_cancelled,
            element: wrapperEl.querySelector("[data-fp-element='mc_action_revert_user_cancellation']"),
        }
    ];

    subscriptionActions.push({
        type: null,
        isAllowed: !!subscriptionActions.find(x => x.isAllowed),
        element: wrapperEl.querySelector("[data-fp-element='mc_subscription_actions']"),
    });

    subscriptionActions.forEach((action) => !action.isAllowed ? action.element?.remove() : null);
}