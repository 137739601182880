export function encodeObjectToBase64(obj) {
  const jsonString = JSON.stringify(obj);
  const byteArray = new TextEncoder().encode(jsonString);
  return btoa(String.fromCharCode(...byteArray));
}

export function decodeBase64ToObject(base64String) {
  if (!base64String) { return null; }

  const byteString = atob(base64String);
  const byteArray = new Uint8Array(byteString.split("").map(char => char.charCodeAt(0)));
  const jsonString = new TextDecoder().decode(byteArray);
  return JSON.parse(jsonString);
}
