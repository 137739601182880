import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpSetDeliveryHolidaysAsync(purchaseId, userId, holidaysId, startAt, finishAt) {
    try {
        if (!startAt || !finishAt) {
            return false;
        }

        const requestType = (!!holidaysId && holidaysId !== "none") ? "PATCH" : "POST";
        let requestUrl = `${_fp.config.base_url_transaction}/delivery_holidays`;
        let request = {
            purchase_id: purchaseId,
            user_id: userId,
            start_at: startAt,
            finish_at: finishAt,
            language: _fp?.config?.client?.language ? _fp.config.client.language : null
        };

        if (holidaysId) {
            request = { start_at: startAt, finish_at: finishAt };
            requestUrl += `/${holidaysId}`;
        }

        await fpSendRequest(requestType, requestUrl, JSON.stringify(request));
        return true;
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}