import { fpErrorHandler } from "./fp-error-handler";

export function fpPopulateTemplatePriceDetails(priceHtml, price) {
  try {
    let purchasedPlan = _fp?.user?.purchase?.renewable_price ?? _fp?.user?.purchase?.price;
    let markAsPurchased = price.id === purchasedPlan?.id;

    if (!markAsPurchased && price.placeholder && (price.placeholder_prices ?? []).length > 0) {
      markAsPurchased = !!price.placeholder_prices.find(p => p.id === _fp?.user?.purchase?.price_id);
    }

    priceHtml = priceHtml
      .fpPopulateTemplate("selected", price.id === _fp.metadata?.selected_price?.id ? "selected" : "")
      .fpPopulateTemplate("purchased", markAsPurchased ? "purchased" : "");

    const priceProps = Object.keys(price);
    priceProps.forEach((prop) => {
      priceHtml = priceHtml.fpPopulateTemplate(prop, price[prop]);
    });

    if (!!price.custom_text_fields && price.custom_text_fields.length > 0) {
      price.custom_text_fields.forEach((pc) => {
        priceHtml = priceHtml.fpPopulateTemplate(pc.name, pc.value ? pc.value : "");
      });
    }

    const populatedPriceEl = new DOMParser().parseFromString(priceHtml, "text/html");
    let descriptionsEl = populatedPriceEl.querySelector("[data-fp-element='price-descriptions']");
    if (descriptionsEl) {
      let descriptionsHtml = "";
      let descriptionEl = descriptionsEl.querySelector("[data-fp-element='price-description']");
      if (!!descriptionEl && !!price.descriptions && price.descriptions.length > 0) {
        for (let s = 0; s < price.descriptions.length; s++) {
          descriptionsHtml += descriptionEl.outerHTML
            .fpPopulateTemplate("description", price.descriptions[s].text)
            .fpPopulateTemplate("enabled", (price.descriptions[s].enabled ? "" : "fp-desc-disabled"));
        }
      }

      descriptionsEl.innerHTML = descriptionsHtml;
    }

    return populatedPriceEl.querySelector("body").innerHTML;
  } catch (err) {
    fpErrorHandler("Set Price Details", err);
    return "";
  }
}
