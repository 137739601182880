import { authTypeEnum } from "../../../../core/enums/fp-auth-type.enum";
import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../../../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../../../core/enums/fp-payment-fragment.enum";
import { fpSetNewsletterRegistrations } from "../../../../core/requests/newsletter/fp-newsletter-registrations";
import { fpSetAuthenticationNavigationListeners } from "../../../../core/utils/fp-set-auth-listeners";
import { fpSetBillingDetails } from "../../../../core/utils/fp-set-billing-details";
import { fpSetDeliveryAddress } from "../../../../core/utils/fp-set-delivery-address";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpRemoveUrlParamenterByName } from "../../../../core/utils/fp-url-handler";
import { fpValidatePassword } from "../../../../core/utils/fp-validate-password";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";
import { fpAuthenticationActionAsync } from "../../../../providers/authentication/fp-auth-actions";
import { fpPaymentActionHandlerAsync } from "../../../../providers/payment/fp-payment-action-handler";

export function fpSetCartPaymentListeners() {
    const formEl = document.querySelector("[data-fp-element='payment-form']");
    if (!formEl) {
        console.error("FP::", "Cart payment form element is missing");
    }

    // Define user action listeners
    const goBackBtnEls = document.querySelectorAll("[data-fp-element='back']");
    goBackBtnEls?.forEach(goBackBtnEl => goBackBtnEl.addEventListener("click", () => {
        _fp.metadata.selected_price = null;
        if ((_fp.metadata?.access_prices?.length ?? 0) > 0) {
            _fp.metadata.prices = _fp.metadata.access_prices;
            delete _fp.metadata.access_prices;
        }

        ["price_id", "badge"].forEach((key) => fpRemoveUrlParamenterByName(key));
        fpTriggerJourney(journeyTypeEnum.Cart);
    }, false));

    // Populate customer details if signed in
    const accountEl = formEl.querySelector("[data-fp-element='account_details']");
    const usernameEl = accountEl?.querySelector("input[name='username']");
    if (accountEl && usernameEl && _fp.user?.access_token) {
        usernameEl.value = _fp.metadata?.access_status?.alias ?? "-";
        usernameEl.disabled = true;
        usernameEl.style.opacity = 0.6;
    }

    // Check active offer if available
    const offerEl = document.querySelector(`[data-fp-element="offer"] input[id='${_fp.metadata?.selected_price?.id}']`);
    if (offerEl) {
        offerEl.checked = true;
    }

    // From listners
    formEl.removeEventListener("submit", fpOnCartPaymentFormSubmitAsync, false);
    formEl.removeEventListener("change", fpOnCartPaymentFormChange, false);

    formEl.addEventListener("submit", fpOnCartPaymentFormSubmitAsync, false);
    formEl.addEventListener("change", fpOnCartPaymentFormChange, false);

    // Authentication listeners    
    fpSetAuthenticationNavigationListeners(authTypeEnum.SignIn);
}

async function fpOnCartPaymentFormSubmitAsync(evt) {
    evt.preventDefault();
    fpOnCartError("clear");

    const formData = Object.fromEntries(new FormData(evt.currentTarget)?.entries());

    if (!_fp.user || !_fp.user.access_token) {
        fpSetBillingDetails(evt.currentTarget);
        const response = fpValidatePassword(_fp.user.billing_details);
        if (!response?.valid) {
            fpOnCartError("error", response.error_message);
            return;
        }
    }

    if (_fp.metadata?.selected_price?.validate_address && !_fp?.user?.address) {
        _fp.user = _fp.user ?? {};
        _fp.user.address = _fp.user.address = fpSetDeliveryAddress(formData);
    }

    fpTrackUserAction("fp_user_action", "order_summary_form_submit", null, null, null);
    const response = await fpPaymentActionHandlerAsync(paymentFragmentEnum.OrderSummary, paymentActionEnum.ProcessPayment);
    if (!response?.success) {
        console.error("FP:: Purchase not successful");
        return;
    }

    if (!_fp.user || !_fp.user.access_token) {
        await fpAuthenticationActionAsync(authTypeEnum.SignIn, _fp.user.billing_details);
    }

    // Newsletters Optins
    await fpSetNewslettersOptinsAsync(formData);

    fpTriggerJourney(journeyTypeEnum.PremiumContent);
}

function fpOnCartPaymentFormChange(evt) {
    evt.preventDefault();

    if (evt.srcElement.name === "offer_id") {
        const selectedOfferId = evt.srcElement.value;
        if (selectedOfferId !== _fp.metadata?.selected_price?.id) {
            _fp.metadata.selected_price = _fp.metadata.prices.find(price => price.id === selectedOfferId);
            fpTriggerJourney(journeyTypeEnum.Cart);
            return;
        }
    }
}

function fpOnCartError(type, message) {
    const errEl = document.querySelector("[data-fp-element='cart_error']");
    if (!errEl) {
        return;
    }

    if (type === "clear") {
        errEl.innerHTML = "";
        return;
    }

    errEl.innerHTML = message;
}

async function fpSetNewslettersOptinsAsync(formData) {
    const auhtSettings = _fp.config?.fragments?.authentication_fragment?.settings;
    if (!auhtSettings?.newsletter?.enabled || !formData) {
        return;
    }

    const newsletterRegistrations = Object.keys(formData).reduce((acc, key) => {
        if (key.startsWith("newsletter_")) {
            acc[key.replace("newsletter_", "")] = formData[key];
        }
        return acc;
    }, {});

    await fpSetNewsletterRegistrations(_fp.config.client.root_group_code, newsletterRegistrations);
}