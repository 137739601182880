import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpIsMediaPage } from "../../core/utils/fp-is-media-page";
import { fpIsShopfrontPage } from "../../core/utils/fp-is-shopfront-page";
import { fpSetPremiumElement } from "../../core/utils/fp-set-premium-element";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpShowWelcomeFagment } from "../welcome/fp-welcome-fragment";

export function fpShowPremiumContentFragment() {
  try {
    fpSetPremiumElement(true);
    const welcomeFragmentSettings = _fp.config.fragments.welcome_fragment?.settings;

    let showWelcomeFragment = false;
    if (welcomeFragmentSettings?.enabled) {
      const isShopfrontPage = fpIsShopfrontPage();
      const showWelcomeFragmentOnShopfrontPage = welcomeFragmentSettings?.show_on_shopfront && isShopfrontPage;
      const showWelcomeFragmentOnRegwall = welcomeFragmentSettings.show_on_regwall;

      showWelcomeFragment = showWelcomeFragmentOnShopfrontPage || showWelcomeFragmentOnRegwall;
    }

    if (_fp.metadata.purchase) {
      fpHandlePremiumContentOnPurchase(showWelcomeFragment, welcomeFragmentSettings);
      return;
    }

    // Do not replace content for pages marked as media as this action 
    // can cause unexpected behaviour for existing js functionality on the page   
    const isMediaPage = fpIsMediaPage();
    if (isMediaPage) {
      fpHandlePremiumContentOnMediaPage();
      return;
    }

    fpSpinnerHandler("hide", null);
    if (showWelcomeFragment) {
      fpShowWelcomeFagment();
      return;
    }

    // Do not replace premium content if not neccessary to enable default page loading
    // Content replaced can cause unexpected behaviour for existing js functionality on the page   
    if (_fp.premium_wrapper.innerHTML !== _fp.metadata.content) {
      _fp.premium_wrapper.innerHTML = _fp.metadata.content;
    }
  } catch (error) {
    fpTrackUserAction("fp_error", null, "show_premium_content", null, error);
    fpErrorHandler("Show Premium Content", error);
  }
}

function fpHandlePremiumContentOnPurchase(showWelcomeFragment, welcomeFragmentSettings) {
  const selectedPrice = _fp.metadata?.selected_price;
  const setCookie = !!selectedPrice?.redirect_after_purchase || welcomeFragmentSettings?.set_cookies;
  
  if (setCookie) {
    fpSetStorageValue("fp.subscriber", { subscriber: true, badge: selectedPrice?.badge_text, user: _fp.user?.billing_details }, null);
  }

  if (selectedPrice?.redirect_after_purchase) {
    fpSpinnerHandler("show", null);
    setTimeout(() => {
      window.location.href = selectedPrice?.redirect_after_purchase_url || window.location.reload();
    }, 350);
    return;
  }

  fpSpinnerHandler("hide", null);

  if (showWelcomeFragment) {
    fpShowWelcomeFagment();
    return;
  }

  _fp.premium_wrapper.innerHTML = _fp.metadata.content ?? "";
}

function fpHandlePremiumContentOnMediaPage() {
  // Reload after auth flow finish to ensure a media page is not affected by flip-pay script
  if (_fp.metadata?.authentication_type?.includes("sign")) {
    fpSpinnerHandler("show");
    window.location.reload();
    return;
  }

  fpSpinnerHandler("hide", null);
}