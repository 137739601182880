import { authProviderEnum } from "../../../core/enums/fp-auth-provider.enum";
import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { errorMessageEnum } from "../../../core/enums/fp-error-message.enum";
import { fpUserSignInAsync } from "../../../core/requests/authentication/fp-sign-in";
import { fpUserSignUpAsync } from "../../../core/requests/authentication/fp-sign-up";
import { fpUserVerifyEmailAsync } from "../../../core/requests/authentication/fp-verify-email";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpAuthSetCustomOptions } from "../../../core/utils/fp-set-custom-auth-options";
import { fpGetUrlParameterByName } from "../../../core/utils/fp-url-handler";
import { fpValidatePassword } from "../../../core/utils/fp-validate-password";
import { fpAuthFlipPaySocialsHandler } from "../../../fragments/authentication/flip-pay/handlers/fp-socials-handler";
import { fpInitializeAuthenticationAsync } from "../fp-auth-handler";
import { fpAuthFlipPaySignOut } from "./fp-clear-user-details";

export async function fpAuthFlipPayActionAsync(type, data) {
    try {
        let response = { success: false };
        if (type === authTypeEnum.SignOut) {
            fpAuthFlipPaySignOut();
            return;
        } else if (type === authTypeEnum.SignIn) {
            response = await fpUserSignInAsync(data);
            await fpInitializeAuthenticationAsync(response);
        } else if (type === authTypeEnum.SilentSignUp) {
            response = await fpAuthActionPaymentSilentSignUpAsync(data);
        } else if (type === authTypeEnum.VerifyEmail) {
            const token = fpGetUrlParameterByName("verify_email");
            response = token ? await fpUserVerifyEmailAsync(token) : null;
        } else if ([authProviderEnum.Facebook, authProviderEnum.Google].includes(type)) {
            fpAuthFlipPaySocialsHandler(null, type);
        }

        return response;
    } catch (err) {
        fpErrorHandler("Auth Action", err);
        return null;
    }
}

async function fpAuthActionPaymentSilentSignUpAsync(data) {
    try {
        const formQuery = data?.form_query ? data.form_query : "[data-fp-element='payment-form']";
        const formEl = document.querySelector(formQuery);
        if (!formEl) {
            return { success: false };
        }
        const response = { success: true, error_message: null };
        const formData = Object.fromEntries(new FormData(formEl).entries());

        const isPasswordValid = fpValidatePassword(formData);
        if (!isPasswordValid.valid) {
            throw isPasswordValid.error_message;
        }

        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.authentication_type = formData.type = authTypeEnum.SignUp;

        const authResponse = await fpUserSignUpAsync(formData);
        if (authResponse === "email_verification_required") {
            return { success: false, error_message: errorMessageEnum.VerifyEmail, code: "email_verification_required" };
        }

        fpAuthSetCustomOptions(authTypeEnum.SignUp, formEl, true);
        await fpInitializeAuthenticationAsync(authResponse);

        return response;
    } catch (error) {
        fpErrorHandler("Payment Silent Sign Up", error, false);
        const errMsg = fpErrorHandler("Payment Silent Sign Up", error, true);
        return { success: false, error_message: errMsg, code: error.code ? error.code : "generic" };
    }
}