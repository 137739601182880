import { decodeBase64ToObject, encodeObjectToBase64 } from "./fp-base64";

export function fpSetStorageValue(key, value, expires, encodeToBase64) {
    try {
        value = encodeToBase64 ? encodeObjectToBase64(value) : value;

        let expiryCookieString = "";
        if (expires) {
            let expiryDateStr = null;
            if (typeof expires === "string") {
                expiryDateStr = new Date(expires).toUTCString();
                expiryDateStr = expiryDateStr === "Invalid Date" ? null : expiryDateStr;
            } else if (typeof expires === "object" && expires instanceof Date && !isNaN(expires)) {
                expiryDateStr = expires.toUTCString();
            }

            if (expiryDateStr) {
                expiryCookieString = `; expires=${expiryDateStr}`;
            }
        }

        const domain = fpSetCookieDomain();
        document.cookie = key + "=" + (JSON.stringify(value) || "") + expiryCookieString + "; path=/;domain=" + domain;

        localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
        console.error("FP::", err);
    }
}

export function fpGetStorageValue(key, decodeBase64) {
    try {
        let response = fpGetCookie(key);
        if (!response) {
            response = localStorage.getItem(key);
        }
        if (!response) {
            response = sessionStorage.getItem(key);
        }

        let data;
        try {
            data = JSON.parse(response);
        } catch {
            data = response;
        }

        return decodeBase64 ? decodeBase64ToObject(data) : data;
    } catch (err) {
        console.error("FP::", err);
    }
}

export function fpDeleteStorageValue(key) {
    try {
        if (fpGetCookie(key)) {
            const path = "/";
            const domain = fpSetCookieDomain();
            document.cookie = `${key}=;path=${path};domain=${domain};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
            document.cookie = `${key}=;path=${path};domain=${window.location.hostname};expires=Thu, 01 Jan 1970 00:00:01 GMT`;
        }

        localStorage.removeItem(key);
        sessionStorage.removeItem(key);
    } catch (err) {
        console.error("FP::", err);
    }
}

function fpGetCookie(cname) {
    let name = cname + "=";
    let decodedCookie = null;
    try { decodedCookie = decodeURIComponent(document.cookie); } catch (err) { decodedCookie = document.cookie; }

    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return null;
}

function fpSetCookieDomain() {
    const hostname = window.location.hostname;

    // Handle IP addresses (IPv4 & IPv6) and localhost
    if (hostname.match(/^(\d{1,3}\.){3}\d{1,3}$/) || hostname === "localhost" || hostname.includes(":")) {
        return hostname; // Return as-is (cookies can't be set on IPs)
    }

    const hostNameParts = hostname.split(".");

    // Check if we have a standard domain structure
    if (hostNameParts.length >= 2) {
        const rootDomain = `.${hostNameParts.slice(-2).join(".")}`;

        // Edge case: Prevent setting cookie for public suffixes (e.g., ".co.uk")
        const publicSuffixes = ["co.uk", "gov.in", "com.au", "ac.jp", "edu.in"];
        if (publicSuffixes.includes(rootDomain.substring(1))) {
            return `.${hostNameParts.slice(-3).join(".")}`; // Take last 3 parts
        }

        return rootDomain;
    }

    return hostname; // Fallback for safety
}