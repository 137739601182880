import { fpLoadScriptsAsync } from "../../../core/utils/fp-load-scripts";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpAuthorizeNetInitPaymentMethod } from "./utils/fp-init-payment-method";

/* eslint-disable no-unused-vars */
export async function fpInitializeAuthorizeNetAsync(type, data) {
    data.type = type;
    _fp.payment_providers = _fp.payment_providers ? _fp.payment_providers : {};
    _fp.payment_providers.authorize_net = {
        purchase_intent_id: _fp.metadata?.payment_providers?.[type]?.id,
        credentials: _fp.metadata?.payment_providers?.[type]?.payment_providers?.authorize_net?.authorize_net_credential
    };

    const isTemplateSet = fpAuthorizeNetInitPaymentMethod(type, data);
    if (!isTemplateSet) {
        console.error("FP:: Cannot Set Authorize.Net Payment Fragment");
        return;
    }

    if (typeof Accept === "undefined") {
        await fpLoadScriptsAsync([_fp.config.payment.sdk_src]);
    }

    fpSpinnerHandler("hide");
}