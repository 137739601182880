import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpSetNewsletterRegistrations(parentGroupCode, newsletters) {
  try {
    const url = `${_fp.config.base_url_transaction}/newsletter_registrations`;
    const request = {
      parent_item_group_code: parentGroupCode,
      newsletters: newsletters
    };

    await fpSendRequest("POST", url, JSON.stringify(request));

    return true;
  } catch (err) {
    console.error("FP::", err);
    throw err;
  }
}