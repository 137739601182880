import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { addressAutocompleteProviderEnum } from "../../core/enums/fp-address-autocomplete-provider.enum";
import { fpInitGoogleAutocompleteAsync } from "./google/fp-init-autoaddress";

export async function fpInitializeAddressAutocompleteProviderAsync(settings) {
    try {
        if (settings.provider === addressAutocompleteProviderEnum.Google) {
           await fpInitGoogleAutocompleteAsync(settings);
        }
    } catch (error) {
        fpErrorHandler("Initialize Address Autocomple Provider", error);
    }
}