import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../core/utils/fp-set-template-price-details";
import { fpClearNotUsedAnchors } from "../../core/utils/fp-clear-template";
import { fpGetPriceByIdAsync } from "../../core/requests/prices/fp-get-price";
import { fpGetCustomerAccessStatusAsync } from "../../access/fp-access";

export async function fpSetWelcomeFragmentTemplate() {
    try {
        let templateName = "default";
        if (_fp.metadata?.purchase?.gift) {
            templateName = "voucher_purchased";
        }

        const template = fpGetTemplateByName("welcome_fragment", templateName);
        const templateDOM = new DOMParser().parseFromString(template, "text/html");

        const settings = _fp.config.fragments.welcome_fragment.settings;
        if (settings?.custom_text_fields) {
            await fpPopulateWelcomeFragmentDetailsAsync(templateDOM);
        }

        let welcomeFragment = templateDOM.querySelector("body").innerHTML;
        welcomeFragment = welcomeFragment
            .fpPopulateTemplate("auth_status", (_fp?.user?.access_token ? "signed-in" : "signed-out"))
            .fpPopulateTemplate("purchase_badge", (_fp?.user?.purchase?.badge_text ?? "no-badge-purchase"))
            .fpPopulateTemplate("sku_code", (_fp?.user?.purchase?.sku_code ?? "no-sku-code"))
            .fpPopulateTemplate("duration_unit", (_fp?.user?.purchase?.duration_unit ?? "no-duration"))
            .fpPopulateTemplate("environment", (_fp.config?.base_url_transaction?.includes(".com") ? "fp-env-prod" : "fp-env-test"));

        welcomeFragment = fpClearNotUsedAnchors(welcomeFragment);

        return `${welcomeFragment}`;
    } catch (err) {
        fpErrorHandler("Set Welcome Fragment Template", err);
        return "";
    }
}

async function fpPopulateWelcomeFragmentDetailsAsync(templateDOM) {
    await fpWelcomeFragmentPopulatePriceDetailsAsync(templateDOM);
    // const priceEl = templateDOM.querySelector("[data-fp-element='price']");
    // if (!priceEl) {
    //     return;
    // }

    // let pricesHtml = "";
    // _fp.metadata.prices.forEach((price, ind) => {
    //     pricesHtml += fpPopulateTemplatePriceDetails(priceEl.outerHTML, price, ind);
    // });

    // priceEl.outerHTML = pricesHtml;
}

async function fpWelcomeFragmentPopulatePriceDetailsAsync(templateDOM) {
    const purchaseDetails = _fp?.user?.purchase ?? (_fp.metadata?.access_status?.granted_by_purchase ?? _fp.metadata?.access_status?.granted_by_app_purchase);
    let purchasedPlan = _fp?.user?.purchase?.renewable_price ?? _fp?.user?.purchase?.price ?? _fp?.metadata?.selected_price;

    if (templateDOM.querySelector("[data-fp-element='plan-details']") && !purchasedPlan && _fp.metadata?.access_status?.access) {
        purchasedPlan = purchaseDetails ? await fpGetPriceByIdAsync(purchaseDetails.price_id) : null;
    }

    if (_fp.journey && purchaseDetails && (purchaseDetails?.access_badge && purchaseDetails?.access_badge !== " ") && !purchaseDetails?.access_badge.includes(_fp?.journey?.badge)) {
        _fp.journey.badge = purchaseDetails.access_badge.split(",")[0];
        await fpGetCustomerAccessStatusAsync(true);
    }

    const availablePlans = fpSetAvailablePlansToShow(_fp?.metadata?.prices);
    const elements = [
        { query: "[data-fp-element='price']", type: "outerHTML", prices: availablePlans },
        { query: "[data-fp-element='plan-details']", type: "innerHTML", prices: [purchasedPlan ?? {}] }
    ];

    elements.forEach(({ query, type, prices }) => {
        const els = templateDOM.querySelectorAll(query);
        els.forEach(el => {
            if (!el) { return; }

            const pricesHtml = prices
                .map((price, ind) => fpPopulateTemplatePriceDetails(el[type], price, ind))
                .join("");

            el[type] = pricesHtml;
        });
    });
}

function fpSetAvailablePlansToShow(prices) {
    const availablePlans = prices ?? [];
    const userPurchase = _fp.user?.purchase ?? {};

    // Determine the correct purchase price
    let purchasePrice = userPurchase.price ?? {};
    if (userPurchase.renewable_price && userPurchase.renewable_price.id !== purchasePrice.id) {
        purchasePrice = userPurchase.renewable_price;
    }

    // If no available plans or purchase price is already included, return the plans as-is
    const isDefaultPrice = availablePlans.some(plan => plan.id === purchasePrice.id);
    if (!availablePlans.length || isDefaultPrice) {
        return availablePlans;
    }

    // Replace placeholder price or match with the correct one
    return availablePlans.map(plan => {
        const matchingPlaceholder = plan.placeholder_prices?.find(p => p.id === purchasePrice.id);
        const isMatchingPlan =
            plan.item_group_id === purchasePrice.item_group_id &&
            plan.duration === purchasePrice.duration &&
            plan.duration_unit === purchasePrice.duration_unit;

        return (matchingPlaceholder || isMatchingPlan) ? purchasePrice : plan;
    });
}