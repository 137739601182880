export const paymentErrorEnum = {
    Generic: window.flipPayConfig?.i18n?.errors?.generic ?? "Something went wrong, please try again later",
    GenericCard: window.flipPayConfig?.i18n?.errors?.generic_card ?? "Card details incomplete",
    ExpiredCard: window.flipPayConfig?.i18n?.errors?.expired_card ?? "The card has expired. Check the expiration date or use a different card.",
    CardDeclined: window.flipPayConfig?.i18n?.errors?.card_declined ?? "Your card has been declined.",
    IncompleteExpiry: window.flipPayConfig?.i18n?.errors?.incomplete_expiry ?? "Your card's expiration date is incomplete",
    InvalidExpiryYearPast: window.flipPayConfig?.i18n?.errors?.invalid_expiry_year_past ?? "Your card's expiry year is in the past.",
    IncompleteCvc: window.flipPayConfig?.i18n?.errors?.incomplete_cvc ?? "Your card's security code is incomplete.",
    IncompleteNumber: window.flipPayConfig?.i18n?.errors?.incomplete_number ?? "Your card number is incomplete.",
    InvalidNumber: window.flipPayConfig?.i18n?.errors?.invalid_number ?? "Your card number is invalid.",
    InsufficientFunds: window.flipPayConfig?.i18n?.errors?.insufficient_funds ?? "The customer's account has insufficient funds to cover this payment.",
    PaymentIntentAuthenticationFailure: window.flipPayConfig?.i18n?.errors?.payment_intent_authentication_failure ?? "We are unable to authenticate your payment method.… choose a different payment method and try again.",
    PaypalGeneric: window.flipPayConfig?.i18n?.errors?.payment_paypal ?? "Something went wrong with paypal payment, please try again later",
};
