import { authTypeEnum } from "../enums/fp-auth-type.enum";
import { fpGetCustomerAccessStatusAsync } from "../../access/fp-access";
import { fpShowAuthenticationFragment } from "../../fragments/authentication/fp-authentication-fragment";
import { fpTriggerJourneyByAccessStatusAsync } from "../../journeys/fp-initialize-journey";
import { fpGetCustomerActiveAccessAsync } from "../requests/customer/fp-customer-active-access";
import { fpShowMemberCenterFragment } from "../../fragments/member-center/fp-member-center-fragment";
import { fpAuthenticationActionAsync } from "../../providers/authentication/fp-auth-actions";
import { fpGetServerSideAccessStatusAsync } from "../../access/server-side/fp-server-side-access";

export function fpSetGlobalMethods(onLoadMethod) {
    if (window.flipPay) {
        return;
    }

    window.flipPay = {
        init: onLoadMethod,

        // Authentication actions
        signIn: (provider) => fpInitAuthFlow(authTypeEnum.SignIn, provider),
        signUp: (provider) => fpInitAuthFlow(authTypeEnum.SignUp, provider),
        signOut: () => fpShowAuthenticationFragment(authTypeEnum.SignOut),

        showMemberCenter: () => { fpShowMemberCenterFragment(); },
        getCustomerAcessStatus: () => { return fpGetServerSideAccessStatusAsync(); },
        initPurchaseFlow: async (groupCode, badge, flowWrapperElQuery, customTextFields) => fpInitPurchaseFlowAsync(groupCode, badge, flowWrapperElQuery, customTextFields)
    };
}

function fpInitAuthFlow(type, provider) {
    if (!provider) {
        fpShowAuthenticationFragment(type);
        return;
    }

    fpAuthenticationActionAsync(provider, null);
}

async function fpInitPurchaseFlowAsync(groupCode, badge, flowWrapperElQuery, customTextFields) {
    _fp.journey = { group_code: groupCode, badge: badge };
    _fp.premium_wrapper = document.querySelector(flowWrapperElQuery);

    _fp.config.fragments.authentication_fragment = _fp.config?.fragments?.authentication_fragment ?? {};
    _fp.config.fragments.authentication_fragment.settings = _fp.config.fragments.authentication_fragment?.settings ?? {};
    _fp.config.fragments.authentication_fragment.settings.wrapper_query = { modal: flowWrapperElQuery, inline: flowWrapperElQuery };

    if (!_fp.premium_wrapper) {
        console.error(`Element to render flow not found:: ${flowWrapperElQuery}`);
        return { status: "fail", error: { code: "FP001", message: `Element to render flow not found:: ${flowWrapperElQuery}` } };
    }

    const accessStatus = await fpGetCustomerAccessStatusAsync(true);
    if (!accessStatus) {
        console.error(`No prices found for defined group code:: ${groupCode}`);
        return { status: "fail", error: { code: "FP002", message: `No prices found for defined group code:: ${groupCode}` } };
    }
    // if (!accessStatus || accessStatus.access) {
    //     console.error(`A customer already have an active subscription for defined group code:: ${groupCode}`);
    //     return { status: "fail", error: { code: "FP002", message: `A customer already have an active subscription for defined group code:: ${groupCode}` } };
    // }

    if (_fp?.user?.access_token) {
        const activeAccessResponse = await fpGetCustomerActiveAccessAsync();
        const activePurchase = activeAccessResponse?.purchases?.find(purchase => purchase.id);
        if (activePurchase) {
            _fp.metadata = _fp.metadata ?? {};
            _fp.metadata.subscription_action = { type: "upgrade", original_purchase_id: activePurchase.id };
        }
    }

    if ((customTextFields?.length ?? []) > 0) {
        accessStatus.custom_text_fields = accessStatus.custom_text_fields ?? [];
        customTextFields.forEach(field => {
            let customTextFieldIndex = accessStatus.custom_text_fields.findIndex((x => x.name === field.name));
            if (customTextFieldIndex > -1) {
                accessStatus.custom_text_fields[customTextFieldIndex] = field;
            } else {
                accessStatus.custom_text_fields.push(field);
            }
        });
    }

    // Remove flow type as to trigger flows it cannot be manual
    delete _fp?.config?.flow_type;

    // Pre-select price if a response contians only one offer
    _fp.metadata.selected_price = (accessStatus.prices ?? []).length === 1 ? accessStatus.prices[0] : null;

    try {
        await fpTriggerJourneyByAccessStatusAsync(accessStatus);
        return { status: "success" };
    } catch (err) {
        return { status: "fail", error: { code: "FP0000", message: "Sorry, something went wrong", details: err } };
    }
}