import { paymentActionEnum } from "../../../core/enums/fp-payment-action.enum";
import { fpProcessPaymentWithAuthorizeNetAsync } from "./utils/fp-anet-process-payment";
import { fpUpdateAuthorizeNetPaymentMethodAsync } from "./utils/fp-anet-update-payment-method";

export async function fpAuthorizeNetActionHandlerAsync(actionType, cardToken) {
    let response = true;
    if (actionType === paymentActionEnum.UpdateOtherElementPaymentAmount) {
        // isSuccess = fpStripeUpdateOtherElementPaymentAmount();
    } else if (actionType === paymentActionEnum.UpdatePaymentMethod) {
        response = await fpUpdateAuthorizeNetPaymentMethodAsync(cardToken);
    } else if (actionType === paymentActionEnum.ProcessPayment) {
        response = await fpProcessPaymentWithAuthorizeNetAsync(cardToken);
    }

    return response;
}