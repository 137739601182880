// Helper function to adjust content height dynamically
export function fpMemberCenterAdjustContentHeight() {
    const contentEl = document.querySelector("[data-fp-element='mc_content']");
    if (!contentEl) {
        return;
    }

    contentEl.style.minHeight = "unset";
    const contentElHeight = document.querySelector("#fp-action-template")?.scrollHeight;
    contentEl.style.minHeight = `${contentElHeight ?? 0}px`;
    contentEl.scrollIntoView();
}