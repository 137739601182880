import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { fpPurchaseRedeemGiftCode } from "../../core/requests/purchase/fp-redeem-gift";
import { fpPurchaseLookupGiftCode } from "../../core/requests/purchase/fp-redeem-gift-lookup";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpDeleteStorageValue, fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { fpShowHomeDeliveryFragment } from "../home-delivery/fp-home-delivery-fragment";
import { homeDeliveryTypeEnum } from "../../core/enums/fp-home-delivery-type.enum";

// eslint-disable-next-line no-unused-vars
export function fpSetGiftRedeemTemplateListeners(settings) {
    try {
        const formEl = document.querySelector("[data-fp-element='form_gift_redeem']");
        if (formEl) {
            formEl.removeEventListener("submit", fpOnGiftFormSubmit, false);
            formEl.addEventListener("submit", fpOnGiftFormSubmit, false);
        }

        const editAddressEl = document.querySelector("[data-fp-element='edit_address']");
        if (editAddressEl) {
            editAddressEl.addEventListener("click", () => fpShowHomeDeliveryFragment(homeDeliveryTypeEnum.Form), false);
        }
    } catch (error) {
        console.error("FP::", error);
        throw error;
    }
}

async function fpOnGiftFormSubmit(evt) {
    try {
        evt.preventDefault();

        fpSpinnerHandler("show", null);
        fpErrorHandler("clear", null);

        const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());
        fpTrackUserAction("fp_user_action", null, "gift_redeem", null, formData?.gift_code);

        if (!_fp.user?.access_token) {
            fpTriggerJourney(journeyTypeEnum.SignUp);
            fpSetStorageValue("fp.gift_code", formData.gift_code);
            fpSpinnerHandler("hide", null);
            return;
        }

        const giftCode = formData?.gift_code ? formData.gift_code : _fp?.metadata?.selected_price?.gift_code;
        if (!giftCode) {
            fpErrorHandler("set", "Enter Gift Code");
            fpSpinnerHandler("hide", null);
            return;
        }

        fpDeleteStorageValue("fp.gift_code");

        if (!_fp?.user?.address) {
            const giftCodePriceDetails = await fpPurchaseLookupGiftCode(giftCode);
            if (!giftCodePriceDetails) {
                fpErrorHandler("set", "Gift Code Not Recognized");
                fpSpinnerHandler("hide", null);
                return;
            }

            if (giftCodePriceDetails.validate_address) {
                fpSpinnerHandler("hide", null);
                _fp.metadata.prices = [giftCodePriceDetails];
                _fp.metadata.selected_price = giftCodePriceDetails;
                _fp.metadata.selected_price.gift_code = giftCode;
                fpShowHomeDeliveryFragment();
                return;
            }
        }

        const purchaseResponse = await fpPurchaseRedeemGiftCode(giftCode);
        fpSpinnerHandler("hide", null);
        if (purchaseResponse?.executed) {
            _fp.metadata = _fp.metadata ? _fp.metadata : {};
            _fp.metadata.purchase = purchaseResponse;

            fpTrackUserAction("fp_purchase_resolved", null, "purchase", purchaseResponse.id, purchaseResponse);
            fpTriggerJourney(journeyTypeEnum.PremiumContent);
            return;
        }
    } catch (error) {
        fpErrorHandler("set", error);
        fpSpinnerHandler("hide", null);
    }
}