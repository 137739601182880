import { fpCurrencyToSymbol } from "../../core/utils/fp-currency-to-symbol";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";

export function fpSetVoluntaryContributionTemplate(fragmentConfig, hasSubscription) {
  try {
    const template = fragmentConfig?.template ?? fpGetTemplateByName("voluntary_contribution_fragment");
    const templateDOM = new DOMParser().parseFromString(template, "text/html");

    fpSetVoluntaryContributionTabsElement(templateDOM, fragmentConfig?.settings, hasSubscription);
    fpSetVoluntaryContributionAmountOptions(templateDOM, fragmentConfig?.settings);
    fpSetVolunatryContributionAuthenticationOption(templateDOM, fragmentConfig?.settings);

    let templateHTML = templateDOM.querySelector("body").innerHTML;
    if (_fp?.metadata?.access_status) {
      Object.keys(_fp.metadata.access_status).forEach(prop => {
        templateHTML = templateHTML.fpPopulateTemplate(prop, _fp.metadata.access_status[prop]);
      });
    }

    return templateHTML;
  } catch (error) {
    fpErrorHandler("Set Voluntary Contribution Template", error);
    return "";
  }
}

export function fpUpdateVoluntaryContributionTemplate(settings) {
  const template = fpGetTemplateByName("voluntary_contribution_fragment");
  const templateDOM = new DOMParser().parseFromString(template, "text/html");

  if (_fp.metadata.selected_price.badge_text) {
    const formWrapperDOM = document.querySelector("[data-fp-element='voluntary_contribution_form']");
    formWrapperDOM?.setAttribute("data-fp-attribute", _fp.metadata.selected_price.badge_text);
  }

  const optionsWrapperDOM = templateDOM.querySelector("[data-fp-element='voluntary_contribution_options_main']");
  fpSetVoluntaryContributionAmountOptions(optionsWrapperDOM, settings);

  return optionsWrapperDOM.innerHTML;
}

export function fpSetVoluntaryContributionAmountOptions(templateDOM, settings) {
  const optionsWrapperEl = templateDOM.querySelector("[data-fp-element='voluntary_contribution_options']");
  if (!optionsWrapperEl) {
    console.error("FP::Voluntary Contribution Options Wrapper Element Is Missing -> [data-fp-element='voluntary_contribution_options']");
    return "";
  }

  const optionWrapperEl = templateDOM.querySelector("[data-fp-element='voluntary_contribution_option']");
  if (!optionWrapperEl) {
    console.error("FP:: Voluntary Contribution Option Wrapper Missing -> [data-fp-element='voluntary_contribution_option']");
    return false;
  }

  let optionsHTML = "";
  _fp.metadata.selected_price.voluntary_contribution_amounts.forEach((option, ind) => {
    const isSelected = option === _fp.metadata?.selected_price?.amount ? true : null;
    optionsHTML += fpPopulateVoluntaryContributionAmountDetails(optionWrapperEl.outerHTML, option, ind, isSelected);
  });

  if (!_fp.metadata.selected_price.voluntary_contribution_allow_customer_amount) {
    optionWrapperEl.outerHTML = optionsHTML;
    return;
  }

  if (_fp.metadata.selected_price.voluntary_contribution_allow_customer_amount) {
    optionsHTML += fpPopulateVoluntaryContributionAmountDetails(optionWrapperEl.outerHTML, "other", -1, null);
  }

  optionWrapperEl.outerHTML = optionsHTML;
  fpSetVoluntaryContributionOtherAmountOption(templateDOM, settings);
}

function fpPopulateVoluntaryContributionAmountDetails(templateHTML, option, ind, selected) {
  const isChecked = selected ?? ind === 0;
  templateHTML = templateHTML
    .fpPopulateTemplate("currency", option === "other" ? "" : fpCurrencyToSymbol(_fp.metadata.selected_price.currency))
    .fpPopulateTemplate("amount", option)
    .fpPopulateTemplate("checked", isChecked ? "checked" : "")
    .fpPopulateTemplate("badge_text", _fp.metadata.selected_price.badge_text ? _fp.metadata.selected_price.badge_text : "")
    .fpPopulateTemplate("option_id", `fp-option-${ind}`);

  return templateHTML;
}

// eslint-disable-next-line no-unused-vars
function fpSetVoluntaryContributionOtherAmountOption(templateDOM, settings) {
  const otherOptionEl = templateDOM.querySelector("[data-fp-element='voluntary_contribution_other_option']");
  if (otherOptionEl) {
    otherOptionEl.innerHTML = otherOptionEl.innerHTML
      .fpPopulateTemplate("min_contribution", _fp.metadata.selected_price?.voluntary_contribution_minimum_amount ? _fp.metadata.selected_price?.voluntary_contribution_minimum_amount : 1)
      .fpPopulateTemplate("max_contribution", _fp.metadata.selected_price?.voluntary_contribution_maximum_amount ? _fp.metadata.selected_price?.voluntary_contribution_maximum_amount : 1000)
      .fpPopulateTemplate("currency", fpCurrencyToSymbol(_fp.metadata.selected_price.currency));
  }
}

function fpSetVolunatryContributionAuthenticationOption(templateDOM, settings) {
  const anonymousPaymentAllowed = !!settings?.allow_anonymous;
  const isUserSignedIn = !!_fp.user && !!_fp.user.access_token;

  const authWrapEl = templateDOM.querySelector("[data-fp-element='authentication']");
  const paymentWrapEl = templateDOM.querySelector("[data-fp-element='payment_wrapper']");

  if (!anonymousPaymentAllowed && !isUserSignedIn) {
    paymentWrapEl?.remove();
    return;
  }

  if (isUserSignedIn) {
    authWrapEl?.remove();
  }
}

function fpSetVoluntaryContributionTabsElement(templateDOM, settings, hasSubscription) {
  const tabsWrapperEl = templateDOM.querySelector("[data-fp-element='voluntary_contribution_tabs']");
  const options = settings?.options ?? settings?.tabs?.options;

  if ((!settings?.tabs?.enabled && options?.length === 1) || !options) {
    tabsWrapperEl?.remove();
    return;
  }

  if (hasSubscription) {
    tabsWrapperEl?.classList.add("active-subscription");
  }

  let tabsTemplateHTML = "";
  options.forEach((tab, ind) => {
    const price = _fp.metadata.prices.find((x) => x.badge_text.includes(tab.badge));
    tab.selected = !hasSubscription ? tab.selected : _fp.metadata.prices.find((x) => !x.renewable)?.badge_text.includes(tab.badge);

    let tabTemplateHTML = JSON.parse(JSON.stringify(tabsWrapperEl.innerHTML));
    tabTemplateHTML = tabTemplateHTML
      .fpPopulateTemplate("tab_id", `tab-id-${ind}`)
      .fpPopulateTemplate("tab_label", tab.label)
      .fpPopulateTemplate("tab_value", tab.badge)
      .fpPopulateTemplate("tab_checked", tab.selected ? "checked='checked'" : "")
      .fpPopulateTemplate("badge_text", price.badge_text ? price.badge_text : "")
      .fpPopulateTemplate("renewal_type", price.renewable ? "renewable" : "once");

    tabsTemplateHTML += tabTemplateHTML;
  });

  tabsWrapperEl.innerHTML = tabsTemplateHTML;
}
