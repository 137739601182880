
import { fpClearNotUsedAnchors } from "../../../core/utils/fp-clear-template";
import { fpCurrencyToSymbol } from "../../../core/utils/fp-currency-to-symbol";
import { fpFormatDateString } from "../../../core/utils/fp-format-date-string";
import { fpMemberCenterSetAvailableSubscriptionActions } from "./fp-mc-subscription-actions";

export function fpMemberCenterPopulateSubscriptionDetails(templateDOM) {
    const subscriptions = _fp.user.entitlements?.filter(x => x.entitlement_type === "subscription");
    if (!templateDOM || !subscriptions?.length) {
        console.error("FP:: Subscription details not populated correctly");
        return null;
    }

    let detailsDOM = templateDOM.querySelector("[data-fp-element='mc_user_subscriptions'] [data-fp-element='mc_subscription']");
    detailsDOM = detailsDOM ?? templateDOM;

    let subDetailsHTML = "";
    for (let subscription of subscriptions) {
        subDetailsHTML += fpMemberCenterSetSubscriptionDetailsHTML(detailsDOM.outerHTML, subscription);
        subDetailsHTML = fpClearNotUsedAnchors(subDetailsHTML);
    }

    detailsDOM.outerHTML = subDetailsHTML;
    if (templateDOM.parentElement) {
        const subscriptionsCount = (_fp.user.entitlements ?? []).filter(x => x.entitlement_type === "subscription")?.length ?? 0;
        const subscriptionsLabel = subscriptionsCount > 1 ? "multiple-subs" : "single-sub";
        templateDOM.outerHTML = templateDOM.outerHTML.fpPopulateTemplate("subscriptions_count", subscriptionsLabel);
    }
}

function fpMemberCenterSetSubscriptionDetailsHTML(templateHTML, subscription) {
    const downgradedPlan = subscription.upgrade_prices?.find(x => _fp.user.entitlements?.filter(d => d.entitlement_type === "downgraded")?.some(d => d.price_id === x.id));
    subscription.current_plan = subscription.description;
    if (downgradedPlan) {
        subscription.current_plan = downgradedPlan.name;
        subscription.next_plan = subscription.description;
    }

    const subscriptionStatuses = [
        `fp-subscription-${subscription?.subscription ? "active" : "inactive"}`,
        subscription.user_cancelled && "user-cancelled",
        !subscription.renewable && "non-renewable",
        !!downgradedPlan && "downgraded"
    ].filter(Boolean);

    const templateDOM = (new DOMParser().parseFromString(templateHTML, "text/html"))?.querySelector("[data-fp-element='mc_subscription']");
    subscriptionStatuses.forEach(status => templateDOM.classList.add(status));

    fpMemberCenterSetAvailableSubscriptionDetailsSections(templateDOM, subscription);
    fpMemberCenterSetAvailableSubscriptionActions(templateDOM, subscription);

    let subDetailsHTML = templateDOM.outerHTML;

    // Determine payment method details
    let paymentMethodDetails = subscription?.renewal_payment_method ||
        (subscription?.renewable && subscription?.renewable_price_id && subscription?.renewal_amount ? subscription?.payment_method : null);


    if (paymentMethodDetails?.id) {
        const isPayPalOrRevolut = ["paypal", "revolut_pay"].includes(paymentMethodDetails?.payment_method_type);
        subDetailsHTML = isPayPalOrRevolut
            ? subDetailsHTML
                .fpPopulateTemplate("renewal_card_brand", "Paypal") // TODO:: Add correct brand
                .fpPopulateTemplate("renewal_card_last4", "")
            : Object.entries(paymentMethodDetails).reduce(
                (html, [key, value]) => html.fpPopulateTemplate(`renewal_card_${key}`, value),
                subDetailsHTML
            );
    }

    // Populate subscription details
    Object.entries(subscription).forEach(([key, value]) => {
        subDetailsHTML = subDetailsHTML.fpPopulateTemplate(key, fpMemberCenterSetSubscriptionDetailsTemplateValue(key, value));
    });

    Object.entries(subscription?.contact_info ?? {}).forEach(([key, value]) => {
        subDetailsHTML = subDetailsHTML.fpPopulateTemplate(key, fpMemberCenterSetSubscriptionDetailsTemplateValue(key, value));
    });

    return subDetailsHTML;
}

function fpMemberCenterSetAvailableSubscriptionDetailsSections(templateDOM, subscription) {
    const subSections = {
        subscription_last_day: templateDOM.querySelector("[data-fp-element='mc_subscription_last_day']"),
        renewals: templateDOM.querySelector("[data-fp-element='mc_subscription_renewal_details']"),
        home_delivery: templateDOM.querySelector("[data-fp-element='mc_subscription_delivery_details']"),
        home_delivery_holidays: templateDOM.querySelector("[data-fp-element='mc_subscription_delivery_holidays']"),
        payment_method: templateDOM.querySelector("[data-fp-element='mc_subscription_payment_method']"),
        consumables: templateDOM.querySelector("[data-fp-element='mc_subscription_consumables']"),
        others: templateDOM.querySelector("[data-fp-element='mc_whitelist_corporate_details']")
    };

    // Section can be removed as a subscription exists at this stage
    subSections.others?.remove();

    // Handle renewable and cancellation states
    const sectionToRemove = !subscription.renewable || subscription.user_cancelled ? "renewals" : "subscription_last_day";
    subSections[sectionToRemove]?.remove();

    if (!subscription.renewable) { subSections.payment_method?.remove(); }
    if (!subscription.finish_at) { subSections.subscription_last_day?.remove(); }

    // Handle delivery details
    if (subscription?.price?.validate_address) {
        fpMemberCenterPopulateDeliveryHolidays(subSections.home_delivery_holidays);
    } else {
        subSections.home_delivery?.remove();
        subSections.home_delivery_holidays?.remove();
    }

    // Populate consumables
    fpMemberCenterPopulateConsumables(subSections.consumables);
}

function fpMemberCenterPopulateDeliveryHolidays(templateDOM) {
    if (!templateDOM) {
        return;
    }

    const sections = {
        no_delivery_holidays: templateDOM.querySelector("[data-fp-element='no_delivery_holidays']"),
        delivery_holidays: templateDOM.querySelector("[data-fp-element='delivery_holidays']")
    };

    if (!_fp.user.purchase?.delivery_holidays) {
        sections.delivery_holidays?.remove();
        return;
    }

    sections.no_delivery_holidays?.remove();
    const deliveryHolidayTemplateDOM = sections.delivery_holidays.querySelector("[data-fp-element='delivery_holidays_item']");
    if (!deliveryHolidayTemplateDOM) {
        return;
    }

    let deliveryHolidaysTemplateHTML = "";
    _fp.user.purchase.delivery_holidays.forEach((h, ind) => {
        let templateHTML = deliveryHolidayTemplateDOM.outerHTML;
        templateHTML = templateHTML
            .fpPopulateTemplate("index", (ind + 1))
            .fpPopulateTemplate("id", h.id)
            .fpPopulateTemplate("dh_start_at", fpFormatDateString(h.start_at))
            .fpPopulateTemplate("dh_finish_at", fpFormatDateString(h.finish_at));

        deliveryHolidaysTemplateHTML += templateHTML;
    });

    deliveryHolidayTemplateDOM.innerHTML = deliveryHolidaysTemplateHTML;
}

function fpMemberCenterPopulateConsumables(consumablesWrapperEl) {
    const consumablesTemplateEl = consumablesWrapperEl?.querySelector("[data-fp-element='mc_subscription_consumable']");
    if (!consumablesWrapperEl || !consumablesTemplateEl) {
        return;
    }

    if ((_fp?.user?.consumables ?? []).length > 0) {
        consumablesTemplateEl.remove();
        return;
    }

    const consumablesTemplateHTML = consumablesTemplateEl.outerHTML;
    let consumablesHTML = "";
    _fp.user.consumables?.forEach(consumable => {
        consumablesHTML += consumablesTemplateHTML
            .fpPopulateTemplate("consumable_sku_code", consumable.sku_code)
            .fpPopulateTemplate("consumable_value", (consumable.available_count > 9000 ? "Unlimited" : consumable.available_count));
    });

    consumablesWrapperEl.innerHTML = consumablesHTML;
}

function fpMemberCenterSetSubscriptionDetailsTemplateValue(key, value) {
    if (key.includes("currency")) {
        value = fpCurrencyToSymbol(value);
    } else if (key.includes("_at")) {
        value = fpFormatDateString(value);
    }

    return value;
}