export const errorMessageEnum = {
    InvalidPassword: window.flipPayConfig?.i18n?.errors?.password_validation ?? "Invalid login credentials",
    InvalidUsername: window.flipPayConfig?.i18n?.errors?.password_invalid_username ?? "User not found",
    InvalidUsernameOrPassword: window.flipPayConfig?.i18n?.errors?.password_incorrect_old_password ?? "You entered incorrect old password",
    LimitExceeded: window.flipPayConfig?.i18n?.errors?.limit_exceeded ?? "Number of retries exceeded, please try again later",
    PriceNotFound: window.flipPayConfig?.i18n?.errors?.price_not_found ?? "No prices defined for the selected item group",
    CustomerNotFound: window.flipPayConfig?.i18n?.errors?.customer_not_found ?? "Incorrect email address or password",
    Duplicate: window.flipPayConfig?.i18n?.errors?.duplicate ?? "An account is already registered with your email address",
    StartAtMustBeBeforeFinishAt: window.flipPayConfig?.i18n?.errors?.start_at_value ?? "The start date must be before the end date",
    OverlappingHolidayExists: window.flipPayConfig?.i18n?.errors?.overlapping_holidays ?? "Overlapping holiday dates",
    NotFound: window.flipPayConfig?.i18n?.errors?.not_found ?? "Not found",
    AlreadyRedeemed: window.flipPayConfig?.i18n?.errors?.already_redeemed ?? "Code already redeemed",
    EmailVerificationRequired: window.flipPayConfig?.i18n?.errors?.email_verification ?? "Email verification required",
    Generic: window.flipPayConfig?.i18n?.errors?.generic ?? "Something went wrong, please try again later",
    SocialRegistration: window.flipPayConfig?.i18n?.errors?.social_registration ?? "Incorrect email address, please register",
    NotAuthenticatedUser: window.flipPayConfig?.i18n?.errors?.not_authenticated_user ?? "Not authenticated user",
    MandatoryField: window.flipPayConfig?.i18n?.errors?.mandatory_field ?? "Mandatory field",
    CouponInvalid: window.flipPayConfig?.i18n?.errors?.coupon_invalid ?? "*This promo code is not valid",
    RecaptchaValidationError: window.flipPayConfig?.i18n?.errors?.recaptcha_validation_error ?? "We can't validate your connection due to security reasons"

    // SocialRegistration: "Can't reset password as you used social registration - Google, Facebook",
};
