import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpLoadScriptsAsync } from "../../../core/utils/fp-load-scripts";

export async function fpInitGoogleAutocompleteAsync(settings) {
    try {
        _fp.globals = _fp.globals ? _fp.globals : {};
        _fp.globals.google_autocomplete = fpGoogleMapApiPlacesOnLoad;

        if (typeof google === "undefined" || !google.maps || !google.maps.places) {
            await fpLoadScriptsAsync([`https://maps.googleapis.com/maps/api/js?key=${settings.key}&libraries=places&callback=_fp.globals.google_autocomplete`]);
            return;
        }

        fpGoogleMapApiPlacesOnLoad();
        return;
    } catch (error) {
        delete _fp.globals.google;
        fpErrorHandler("Init Google auto address provider", error);
    }
}

function fpGoogleMapApiPlacesOnLoad() {
    try {
        const autocompleteEl = document.querySelector("[data-fp-element='address_autocomplete']");
        const autocompleteConfig = { fields: ["address_components"], types: ["address"] };

        const settings = _fp.config.fragments?.home_delivery_fragment?.settings?.autocomplete;
        if (settings?.allowed_countries && Array.isArray(settings?.allowed_countries)) {
            autocompleteConfig.componentRestrictions = { country: settings?.allowed_countries };
        }

        _fp.globals.google_autocomplete = new google.maps.places.Autocomplete(autocompleteEl, autocompleteConfig);
        _fp.globals.google_autocomplete.addListener("place_changed", fpGoogleMapApiPlacesOnPlaceChange);
    } catch (error) {
        fpErrorHandler("Google Map Api Places Loaded", error);
    }
}

function fpGoogleMapApiPlacesOnPlaceChange(place) {
    if (!place) {
        place = _fp.globals.google_autocomplete.getPlace();
    }

    if (!place.address_components) {
        return null;
    }

    const address = {
        full: "",
        address_1: setAddressAddress1Prop(place),
        address_2: setAddressAddress2Prop(place),
        address_3: setAddressAddress3Prop(place),
        address_4: setSimpleAddressProp(place, "country", "long_name"),
        address_5: setSimpleAddressProp(place, "subpremise", "long_name"),
        post_code: setAddressPostCode(place),
        country_code: setSimpleAddressProp(place, "country", "short_name")
    };

    if (address.address_1 === address.address_2) {
        address.address_2 = "";
    }

    address.full += address.address_5 ? (address.address_5 + ", ") : "";
    address.full += address.address_1 ? (address.address_1 + ", ") : "";
    address.full += address.address_2 ? (address.address_2 + ", ") : "";
    address.full += address.address_3 ? (address.address_3 + ", ") : "";
    address.full += address.post_code ? (address.post_code + ", ") : "";
    address.full += address.address_4 ? address.address_4 : "";

    _fp.user = _fp.user ? _fp.user : {};
    _fp.user.address = address;
}

function setSimpleAddressProp(place, propertyToCheck, nameType) {
    const component = place.address_components.find(x => x.types.includes(propertyToCheck));
    return !component ? null : component[nameType];
}

function setAddressPostCode(place) {
    const component = place.address_components.find(x => x.types.includes("postal_code"));
    let postCode = component ? component.long_name : null;

    const pcSuffix = place.address_components["postal_code_suffix"];
    if (pcSuffix && postCode) {
        postCode = `${postCode}-${pcSuffix.long_name}`;
    }

    return postCode;
}

function setAddressAddress1Prop(place) {
    let addr1 = null;
    let component = place.address_components.find(x => x.types.includes("street_number"));
    if (component && component.long_name) {
        addr1 = component.long_name;
    }

    ["route", "point_of_interest", "premise", "sublocality_level_1", "neighborhood"].forEach(p => {
        component = place.address_components.find(x => x.types.includes(p));
        if (component && component.long_name) {
            const delimiter = p === "route" ? " " : ", ";
            addr1 = addr1 ? addr1 : "";
            addr1 += `${addr1 ? delimiter : ""}${component.long_name}`;
        }
    });


    return addr1;
}

function setAddressAddress2Prop(place) {
    const componentLocality = place.address_components.find(x => x.types.includes("locality"));
    let address2 = !!componentLocality && componentLocality.long_name ? componentLocality.long_name : null;

    const componentPostalTown = place.address_components.find(x => x.types.includes("postal_town"));
    if (componentPostalTown && componentPostalTown.long_name) {
        address2 = address2 ? `${address2}, ${componentPostalTown.long_name}` : componentPostalTown.long_name;
    }

    if (address2) {
        return address2;
    }

    let component = place.address_components.find(x => x.types.includes("sublocality_level_1"));
    if (component && component.long_name) {
        return component.long_name;
    }

    component = place.address_components.find(x => x.types.includes("neighborhood"));
    if (component && component.long_name) {
        return component.long_name;
    }
}

function setAddressAddress3Prop(place) {
    const componentAAL1 = place.address_components.find(x => x.types.includes("administrative_area_level_1"));
    let address3 = !!componentAAL1 && componentAAL1.long_name ? componentAAL1.long_name : null;

    const componentAAL2 = place.address_components.find(x => x.types.includes("administrative_area_level_2"));
    if (componentAAL2 && componentAAL2.long_name) {
        address3 = address3 ? `${address3}, ${componentAAL2.long_name}` : componentAAL2.long_name;
    }

    return address3;
}