import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetWelcomeFragmentTemplate } from "./fp-set-welcome-fragment-template";

export async function fpShowWelcomeFagment() {
    try {
        _fp.premium_wrapper.innerHTML = await fpSetWelcomeFragmentTemplate();

        const authStatus = _fp.user?.access_token ? "signed_in" : "signed_out";
        fpTrackUserAction("fp_fragment_displayed", null, "welcome", null, null, authStatus);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "welcome", null, error);
        fpErrorHandler("Welcome", error);
    }
}