export const memberCenterSubscriptionActionEnum = {
    UpdatePaymentMethod: "update_payment_method",
    UpdateDeliveryAddress: "update_delivery_address",
    UpdateDeliveryHolidays: "update_delivery_holidays",
    CancelRenewals: "cancel_renewals",
    Upgrade: "upgrade_subscription",
    Downgrade: "downgrade_subscription",
    UpdateRenewalAmount: "update_renewal_amount",

    UpdateSubscription: "update_subscription",
    RevertUserCancellation: "revert_user_cancellation"
};
