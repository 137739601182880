import { fpGetTemplateByName } from "./fp-set-template";

/**
 * @param {*} wrapperQuery element where snackbar should be injected
 * @param {*} message message in the snackbar
 * @param {*} type error, success
 * @returns void
 */
export function fpSnackbarMessageHandler(wrapperQuery, message, type) {
    let snackBarElement = document.getElementById("fp-snackbar");
    if (!snackBarElement) {
        const snackBarHTML = fpGetTemplateByName("snackbar_fragment");
        const tempElement = document.createElement("div");
        tempElement.innerHTML = snackBarHTML;

        if (wrapperQuery) {
            const parentElement = document.querySelector(wrapperQuery);
            parentElement?.appendChild(tempElement);
        } else {
            document.body.appendChild(tempElement);
        }

        snackBarElement = document.getElementById("fp-snackbar");
    }

    if (!snackBarElement) {
        return;
    }

    snackBarElement.className = `fp-show ${type}`;
    snackBarElement.style.backgroundColor = fpSetSnackbarBackground(type);

    const snackBarMessageElement = document.getElementById("fp-snackbar-message");
    if (snackBarMessageElement) {
        let customMessage = message;

        if (type === "success" && !!_fp.config && !!_fp.config.snack_bar_success_message && _fp.config.snack_bar_success_message !== "null") {
            customMessage = _fp.config.snack_bar_success_message;
        }

        if (type === "error" && !!_fp.config && !!_fp.config.snack_bar_error_message && _fp.config.snack_bar_error_message !== "null") {
            customMessage = _fp.config.snack_bar_error_message;
        }

        if (!customMessage) {
            customMessage = "Oops something went wrong, please try again later.";
        }

        snackBarMessageElement.innerHTML = fpFormatSnackbarMessage(customMessage);
    }

    setTimeout(function () {
        snackBarElement.className = snackBarElement.className.replace("fp-show", "");
    }, 4000);
}

function fpSetSnackbarBackground(type) {
    switch (type) {
        case "success":
            return "#28a745";
        case "warning":
            return "#ffc107";
        case "error":
            return "#dc3545";
        case "be_error":
            return "#dc3545";
        case "info":
            return "#007bff";
        default:
            return "#333";
    }
}

function fpFormatSnackbarMessage(message) {
    // Remove all underscores from the message
    const tempMessage = message.replace(/_/g, " ");

    const splitStr = tempMessage.split(" ");
    for (let i = 0; i < splitStr.length; i++) {
        splitStr[i] = splitStr[i].charAt(0) + splitStr[i].substring(1);
    }

    // Directly return the joined string
    return splitStr.join(" ");
}