import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";
import { fpSetCartSelectedItemDetails } from "../../utils/fp-cart-selected-items-details";

export function fpSetCartAccountTemplate() {
    const template = fpGetTemplateByName("cart_fragment", "account");
    const templateDOM = new DOMParser().parseFromString(template, "text/html");

    fpSetCartSelectedItemDetails(templateDOM, false);

    return templateDOM.querySelector("body").innerHTML;
}

