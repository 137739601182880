import { fpTrackUserAction } from "../../../core/utils/fp-tracking";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpAuthenticationActionAsync } from "../../../providers/authentication/fp-auth-actions";
import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";

export function fpMemberCenterSetNavigationActions(e) {
    fpTrackUserAction("fp_user_action", `${e.currentTarget.dataset.fpElement}_selected`, null, null, null);

    if (e.currentTarget.dataset.fpElement === "mc_signout") {
        fpSpinnerHandler("show", null);
        fpAuthenticationActionAsync(authTypeEnum.SignOut);
        return;
    }

    const activeClassName = "active";
    const categoriesNavEls = document.querySelectorAll("[data-fp-element='mc_categories_nav']>*");
    categoriesNavEls.forEach((el) => el.classList.remove(activeClassName));
    e.currentTarget.classList.add(activeClassName);

    const categoriesEls = document.querySelectorAll("[data-fp-element='mc_categories_content']>div");
    categoriesEls.forEach((el) => {
        el.classList[el.dataset.fpElement === e.currentTarget.dataset.fpElement ? "add" : "remove"](activeClassName);
    });
}

