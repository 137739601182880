import { meteredPaywallTypeEnum } from "../../core/enums/fp-metered-paywall-type.enum";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpIsShopfrontPage } from "../../core/utils/fp-is-shopfront-page";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetMeteredPaywallTemplateConditionsNotMet } from "./template/fp-mp-conditions-not-met";
import { fpSetMeteredPaywallTemplateCounter } from "./template/fp-mp-counter";
import { fpSetMeteredPaywallTemplateLastFreeItem } from "./template/fp-mp-last-free-item";
import { fpSetMeteredPaywallTemplateLimitReached } from "./template/fp-mp-limit-reached";
import { fpSetMeteredPaywallTemplateRegistrationRequired } from "./template/fp-mp-registration-required";

export function fpSetMeteredPaywallTemplate() {
    const journeyConfig = fpGetJourneyConfig();
    const settings = journeyConfig?.settings ?? _fp.config.fragments?.metered_paywall_fragment?.settings;

    let templateHtml = "";
    const meteredPaywallType = fpSetMeteredPaywallType(settings);
    fpTrackUserAction("fp_metering", (meteredPaywallType ?? meteredPaywallTypeEnum.ConditionsNotMet), "metering", null, null, null);
    
    switch (meteredPaywallType) {
        case meteredPaywallTypeEnum.UserNotRegistered:  
            templateHtml = fpSetMeteredPaywallTemplateRegistrationRequired();
            break;
        case meteredPaywallTypeEnum.Counter:
            templateHtml = fpSetMeteredPaywallTemplateCounter(settings);
            break;
        case meteredPaywallTypeEnum.LastFreeItem:
            templateHtml = fpSetMeteredPaywallTemplateLastFreeItem();
            break;
        case meteredPaywallTypeEnum.LimitReached:
            templateHtml = fpSetMeteredPaywallTemplateLimitReached();
            break;
        default:
            templateHtml = fpSetMeteredPaywallTemplateConditionsNotMet();
            break;
    }

    const teaser = meteredPaywallType === meteredPaywallTypeEnum.Counter ? "" : fpSetFragmentTeaser("metered_paywall_fragment");
    return { type: meteredPaywallType, html: `${teaser}${templateHtml}` };
}

function fpSetMeteredPaywallType(settings) {
    try {
        const isSignedInCustomer = Boolean(_fp?.user?.access_token);
        const showCounter = fpIsShowCounterFlow();

        const triggeredJourney = _fp?.journey;
        const isRegistrationRequired = (!triggeredJourney && !isSignedInCustomer) || (!isSignedInCustomer && triggeredJourney.settings.registration_required);

        if (isRegistrationRequired) {
            return meteredPaywallTypeEnum.UserNotRegistered;
        } else if (showCounter) {
            return settings.last_free_item_message_enabled && _fp.metadata?.access_status?.metered_paywall_items_used === _fp.metadata?.access_status?.metered_paywall_items_limit
                ? meteredPaywallTypeEnum.LastFreeItem
                : meteredPaywallTypeEnum.Counter;
        } else if (_fp.config?.metering?.enabled || triggeredJourney?.enabled) {
            return meteredPaywallTypeEnum.LimitReached;
        } else {
            return meteredPaywallTypeEnum.ConditionsNotMet;
        }

    } catch (err) {
        console.error("FP:: Set metered paywall type", err);
    }
}

function fpIsShowCounterFlow() {
    const accessStatus = _fp.metadata?.access_status;
    const isClientSide = accessStatus.resolved === "client_side";
    const isServerSide = accessStatus.resolved === "server_side";

    if (fpIsShopfrontPage() || (isClientSide && !accessStatus?.access)) {
        return false;
    }

    if (isClientSide) {
        return true;
    }

    if (isServerSide && accessStatus?.access) {
        return accessStatus.metered_paywall && accessStatus.metered_paywall_items_used <= accessStatus.metered_paywall_items_limit;
    }
}