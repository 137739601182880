
export function fpSetCustomFlow() {
    if (!_fp.config.custom_flow.method) {
        document.dispatchEvent(new Event("fpCustomFlow"));
        return;
    }

    if (document.querySelector("#fp-custom-script")) {
        return;
    }

    const scriptEl = document.createElement("script");
    scriptEl.id = "fp-custom-script";
    scriptEl.onload = () => { };

    scriptEl.innerHTML = _fp.config.custom_flow.method;
    scriptEl.onload();

    document.body.appendChild(scriptEl);
    document.dispatchEvent(new Event("fpCustomFlow"));
}
