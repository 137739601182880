import { journeyTypeEnum } from "../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../core/enums/fp-payment-fragment.enum";
import { fpErrorHandler } from "../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../core/utils/fp-get-journeys-config";
import { fpIsShopfrontPage } from "../core/utils/fp-is-shopfront-page";
import { fpSetSelectedPrice } from "../core/utils/fp-set-selected-price";
import { fpGetUrlParameterByName } from "../core/utils/fp-url-handler";
import { fpPaymentActionHandlerAsync } from "../providers/payment/fp-payment-action-handler";


// eslint-disable-next-line sonarjs/cognitive-complexity
export async function fpSetJourneyTypeAsync(accessStatus) {
    try {
        if (_fp?.config?.flow_type === journeyTypeEnum.Manual) {
            return journeyTypeEnum.Manual;
        }

        const isCustomFlow = fpIsCustomFlow();
        if (isCustomFlow) {
            return journeyTypeEnum.Custom;
        }

        // Metadata
        const triggeredJourney = _fp?.journey;
        const fpJourneyConfig = fpGetJourneyConfig();
        const prices = accessStatus && accessStatus.prices && accessStatus.prices.length >= 1 ? accessStatus.prices : [];
        const selectedPrice = fpSetSelectedPrice(prices);

        // Flows checks
        const isShopfront = fpIsShopfrontPage();

        const isGiftPurchaseFlow = _fp?.premium_wrapper?.dataset?.badge === "gift";
        const isGiftRedeemFlow = fpGetUrlParameterByName("gift_code") || document.location.pathname.includes(_fp.config.redirects?.voucher);

        const isCustomerSignedIn = !!_fp.user && !!_fp.user.access_token;
        const isRegwall = !isCustomerSignedIn && (accessStatus?.registration_only || !!(prices.find(x => x.free && (x.require_registration && !x.require_card))));

        const isCart = !!fpJourneyConfig?.cart;
        const isCouponFirst = !!prices.find(x => x.coupon_first);

        let isMetered = triggeredJourney?.type === journeyTypeEnum.Metered
            || (triggeredJourney?.type !== journeyTypeEnum.Paywall
                && !isShopfront
                && !!accessStatus.metered_paywall
                && !_fp.selected_price);

        if (accessStatus?.access && !accessStatus.metered_paywall && accessStatus?.resolved === "server_side") {
            isMetered = false;
        }

        const pricesPopulated = !!prices?.length > 0;
        const voluntaryContribution = {
            triggered: pricesPopulated && prices.every(x => !!x.voluntary_contribution),
            once_of: pricesPopulated && prices.every(x => !!x.voluntary_contribution) && !prices.every(x => x.renewable)
        };

        const isHomeDeliveryFlow =
            isCustomerSignedIn
            && selectedPrice?.validate_address
            && !isGiftPurchaseFlow
            && !_fp?.user?.address
            && !_fp?.journey?.cart;

        const isUpgradeDowngradeFlow = ["upgrade", "downgrade"].includes(_fp.metadata?.subscription_action?.type);
        if (accessStatus.access
            && (!accessStatus.metered_paywall && !isMetered)
            && !voluntaryContribution.once_of
            && !(isGiftPurchaseFlow || isGiftRedeemFlow)
            && !isUpgradeDowngradeFlow
        ) {
            return journeyTypeEnum.PremiumContent;
        }

        if (isGiftRedeemFlow) {
            return journeyTypeEnum.GiftRedeem;
        }

        if (isCouponFirst) {
            return journeyTypeEnum.CouponCode;
        }

        if (voluntaryContribution.triggered) {
            return journeyTypeEnum.VoluntaryContribution;
        }

        if (isHomeDeliveryFlow) {
            return journeyTypeEnum.HomeDelivery;
        }

        if (!isShopfront && isRegwall) {
            return journeyTypeEnum.Registrationwall;
        }

        if (isMetered) {
            return journeyTypeEnum.Metered;
        }

        if (isCustomerSignedIn && selectedPrice?.free) {
            return await fpSetJourneyForSelectedFreePriceAsync(selectedPrice);
        }

        if (isCart) {
            return journeyTypeEnum.Cart;
        }

        if (isCustomerSignedIn && !!selectedPrice && !selectedPrice.placeholder) {
            return journeyTypeEnum.OrderSummary;
        }

        if (isCustomerSignedIn && !!selectedPrice && selectedPrice.placeholder && isGiftPurchaseFlow) {
            _fp.metadata.selected_placeholder_price = JSON.parse(JSON.stringify(selectedPrice));
            _fp.metadata.prices = selectedPrice.placeholder_prices;
            _fp.metadata.selected_price = _fp.metadata.prices[0];
            return journeyTypeEnum.OrderSummary;
        }

        return journeyTypeEnum.Paywall;
    } catch (error) {
        fpErrorHandler("Journey Type", error);
        return journeyTypeEnum.Paywall;
    }
}

export async function fpSetJourneyForSelectedFreePriceAsync(selectedPrice) {
    if (selectedPrice?.require_card) {
        return journeyTypeEnum.OrderSummary;
    }

    const purchaseResponse = await fpPaymentActionHandlerAsync(paymentFragmentEnum.PricesFragment, paymentActionEnum.ProcessPayment);
    if (purchaseResponse.success) {
        return journeyTypeEnum.PremiumContent;
    }

    if (purchaseResponse?.error_type === "customer_mandatory_fields_missing") {
        return journeyTypeEnum.CustomerMandatoryProperties;
    }

    return journeyTypeEnum.Prices;
}


export function fpIsCustomFlow() {
    // Deprecated to remove
    if (_fp.config.flow_type === "custom" || _fp.config.custom_flow === "true") {
        return true;
    }

    return _fp.config?.custom_flow?.enabled
        && !_fp.config?.custom_flow?.rules?.exclude_pages?.includes(document.location.pathname);
}