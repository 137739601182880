import { journeyTypeEnum } from "../core/enums/fp-journey-type.enum";
import { fpShowPricesFragment } from "../fragments/prices/fp-prices-fragment";
import { fpShowOrderSummaryFragment } from "../fragments/order-summary/fp-order-summary-fragment";
import { fpShowPremiumContentFragment } from "../fragments/premium-content/fp-premium-content";
import { fpShowVoluntaryContributionFragment } from "../fragments/voluntary-contribution/fp-voluntary-contribution-fragment";
import { fpShowHomeDeliveryFragment } from "../fragments/home-delivery/fp-home-delivery-fragment";
import { fpShowAuthenticationFragment } from "../fragments/authentication/fp-authentication-fragment";
import { fpShowMeteredPaywallFragment } from "../fragments/metered-paywall/fp-metered-paywall-fragment";
import { fpShowGiftRedeemFragment } from "../fragments/gift-redeem/fp-gift-redeem-fragment";
import { fpShowRegistrationwallFragment } from "../fragments/registrationwall/fp-registrationwall-fragment";
import { fpShowCustomerMandatoryFieldsFragment } from "../fragments/customer-mandatory-fields/fp-customer-mandatory-fields-fragment";
import { fpSetCustomFlow } from "../core/utils/fp-set-custom-flow";
import { fpShowCartFragment } from "../fragments/cart/fp-cart-fragment";
import { fpShowMarketingMessageFragment } from "../fragments/marketing-message/fp-marketing-message-fragment";

export function fpTriggerJourney(journeyType) {
  _fp.metadata = _fp.metadata ?? {};
  _fp.metadata.journey_type = journeyType;

  switch (journeyType) {
    case journeyTypeEnum.Manual:
      break;
    case journeyTypeEnum.Custom:
      fpSetCustomFlow();
      break;
    case journeyTypeEnum.CouponCode:
      // fpShowUICouponCode();
      break;
    case journeyTypeEnum.GiftRedeem:
      fpShowGiftRedeemFragment();
      break;
    case journeyTypeEnum.Registrationwall:
      fpShowRegistrationwallFragment();
      break;
    case journeyTypeEnum.Metered:
      fpShowMeteredPaywallFragment();
      break;
    case journeyTypeEnum.Free:
      // fpShowUIPremiumContent();
      break;
    case journeyTypeEnum.SignIn:
    case journeyTypeEnum.SignUp:
    case journeyTypeEnum.SignOut:
    case journeyTypeEnum.ResetPassword:
      fpShowAuthenticationFragment(journeyType);
      break;
    case journeyTypeEnum.HomeDelivery:
      fpShowHomeDeliveryFragment();
      break;
    case journeyTypeEnum.VoluntaryContribution:
      fpShowVoluntaryContributionFragment();
      break;
    case journeyTypeEnum.PremiumContent:
      fpShowPremiumContentFragment();
      break;
    case journeyTypeEnum.OrderSummary:
      fpShowOrderSummaryFragment();
      break;
    case journeyTypeEnum.Paywall:
      fpShowPricesFragment();
      break;
    case journeyTypeEnum.CustomerMandatoryProperties:
      fpShowCustomerMandatoryFieldsFragment();
      break;
    case journeyTypeEnum.Cart:
      fpShowCartFragment();
      break;
    case journeyTypeEnum.MarketingMessage:
      fpShowMarketingMessageFragment();
      break;
    default:
      fpShowPricesFragment();
      break;
  }
}
