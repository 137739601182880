import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpUpdateCardAsync(oldCardId, card, paymentMethodId, validated) {
    try {
        if (!card || !oldCardId) {
            return;
        }

        const request = {
            payment_method_id: paymentMethodId,
            old_card_id: oldCardId,
            exp_month: card.exp_month,
            exp_year: card.exp_year,
            last4: card.last4,
            brand: card.brand,
            funding: card.funding,
            country: card.country,
            secret_validated: !!validated
        };

        return await fpSendRequest("POST", `${_fp.config.base_url_transaction}/cards`, JSON.stringify(request));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}