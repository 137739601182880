import { journeyTypeEnum } from "../core/enums/fp-journey-type.enum";
import { fpErrorHandler } from "../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../core/utils/fp-get-journeys-config";
import { fpTrackUserAction } from "../core/utils/fp-tracking";
import { fpGetClientSideAccessStatusAsync } from "./client-side/fp-client-side-access";
import { fpGetServerSideAccessStatusAsync } from "./server-side/fp-server-side-access";

export async function fpGetCustomerAccessStatusAsync(forceServerSideValidation) {
    try {
        fpTrackUserAction("fp_access_requested", null, null, null, null);
        let accessStatus = {};
        const isClientSideValidation = fpIsClientSideValidation();
        if (isClientSideValidation && !forceServerSideValidation) {
            accessStatus = await fpGetClientSideAccessStatusAsync();
            accessStatus.resolved = accessStatus?.country_code ? "server_side" : "client_side";
        } else {
            accessStatus = await fpGetServerSideAccessStatusAsync();
            accessStatus.resolved = "server_side";
        }

        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.access_status = accessStatus;

        fpTrackUserAction("fp_access_resolved", null, null, null, accessStatus);
        return accessStatus;
    } catch (error) {
        fpTrackUserAction("fp_error", "access", null, null, null);
        fpErrorHandler("Customer Access Status", error);
        return null;
    }
}

function fpIsClientSideValidation() {
    const journeyConfig = fpGetJourneyConfig();
    let isClientSideValidation = false;
    if (!journeyConfig?.enabled) {
        return !!_fp.config?.metering?.enabled && !!_fp.config?.metering?.settings?.counter;
    }

    if (journeyConfig.type === journeyTypeEnum.MarketingMessage) {
        return true;
    } else if (journeyConfig.type === journeyTypeEnum.Metered) {
        isClientSideValidation = fpIsClientSideJourney(journeyConfig);
    }

    return isClientSideValidation;
}

function fpIsClientSideJourney(journey) {
    if (journey.settings.metering_type === "client_metering") {
        return true;
    }

    const isSignedInCustomer = Boolean(_fp?.user?.access_token);
    const rulesByMeteringType = {
        hybrid: isSignedInCustomer ? journey.settings.rules.signed_in_customer : journey.settings.rules.signed_out_customer,
        // Add more journey metering types and their rules here
    };

    const validationSide = rulesByMeteringType[journey.settings.metering_type];
    return validationSide === "client_metering";
}