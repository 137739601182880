import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { userAction } from "../../core/enums/fp-user-action.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpSnackbarMessageHandler } from "../../core/utils/fp-snackbar";
import { fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpGetUrlParameterByName } from "../../core/utils/fp-url-handler";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { fpPaymentActionHandlerAsync } from "../../providers/payment/fp-payment-action-handler";
import { fpAuthFlipPaySocialsHandler } from "../authentication/flip-pay/handlers/fp-socials-handler";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";

export function fpSetRegistrationwallTemplateListeners() {
    try {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};

        const priceSelectEls = document.querySelectorAll("[data-fp-element='price_select']");
        priceSelectEls?.forEach(el => el.addEventListener("click", (e) => fpOnRegwallPriceClick(e), false));

        const regFormEl = document.querySelector("[data-fp-element='registration_form']");
        regFormEl?.addEventListener("submit", (e) => fpOnRegistrationFormSubmit(e), false);

        const socialProviders = regFormEl?.querySelectorAll("[data-fp-provider]");
        socialProviders?.forEach((sp) => sp.addEventListener("click", (e) => fpAuthFlipPaySocialsHandler(e, e.currentTarget.dataset.fpProvider)));

        ["[data-fp-element='register']", "[data-fp-element='login']"].forEach(query => {
            const els = document.querySelectorAll(query);
            els?.forEach(el => el.addEventListener("click", () => {
                let actions = { user_action: userAction.SignUp, auth_action: journeyTypeEnum.SignUp };
                if (el.dataset.fpElement === "login") {
                    actions = { user_action: userAction.SignIn, auth_action: journeyTypeEnum.SignIn };
                }

                _fp.metadata.user_action = actions.user_action;
                fpShowAuthenticationFragment(actions.auth_action);
            }));
        });
    } catch (error) {
        fpErrorHandler("Set Registrationwall Fragment Template Listeners", error);
    }
}

function fpOnRegwallPriceClick(event) {
    const priceId = event?.currentTarget?.dataset?.fpPriceId;
    if (!priceId) {
        console.error("FP:: Price Id not set in a template");
        return;
    }

    _fp.metadata.selected_price = _fp.metadata.prices.find(x => x.id === priceId);
    if (!_fp.metadata.selected_price) {
        return;
    }

    fpHandleRegistrationWallAction();
}

function fpOnRegistrationFormSubmit(e) {
    e.preventDefault();
    const form = e.currentTarget;
    if (!form.checkValidity()) {
        form.reportValidity();
        return;
    }

    const formData = Object.fromEntries(new FormData(form).entries());
    if (!formData?.name && formData?.first_name && formData?.last_name) {
        formData.name = `${formData.first_name} ${formData.last_name}`;
    }

    _fp.user = _fp.user ? _fp.user : {};
    _fp.user.billing_details = {
        email: formData?.email,
        name: formData?.name,
        organisation: formData?.organisation
    };

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    _fp.metadata.selected_price = _fp.metadata.prices.find(x => x.id === formData.price_id);

    if (!_fp.user.billing_details.name || !_fp.user.billing_details.email || !_fp.metadata.selected_price) {
        return;
    }

    fpHandleRegistrationWallAction();
}

async function fpHandleRegistrationWallAction() {
    if ((!_fp.config?.journey?.simple_checkout || !_fp?.journey?.simple_checkout) && (!_fp.user || !_fp.user.access_token)) {
        fpSetStorageValue("fp.selected_price_id", _fp.metadata.selected_price.id);
        fpTriggerJourney(journeyTypeEnum.SignUp);
    } else if (_fp.metadata.selected_price.require_card) {
        fpTriggerJourney(journeyTypeEnum.OrderSummary);
    } else {
        fpCreateFreePricePurchaseAsync();
    }
}

async function fpCreateFreePricePurchaseAsync() {
    const registrationwallConfig = _fp.config.fragments?.registrationwall_fragment;
    const customSnackbarMessages = registrationwallConfig?.custom_snackbar_messages;

    try {
        const paymentResponse = await fpPaymentActionHandlerAsync(paymentFragmentEnum.Registrationwall, paymentActionEnum.ProcessPayment);
        if (paymentResponse?.error_type === "customer_mandatory_fields_missing") {
            fpTriggerJourney(journeyTypeEnum.CustomerMandatoryProperties);
            return;
        }

        if (!paymentResponse.success) {
            console.error("FP:: Cannot grant access as purchase not successful");
            return;
        }

        fpRegistrationwallRedirectsHandler();
        fpRegistrationwallSnackbarHandler("success", customSnackbarMessages?.success_message || "Successful purchase for regwall");

        const showPremiumContent = registrationwallConfig?.show_premium_content_after_purchase;
        if (showPremiumContent) {
            fpTriggerJourney(journeyTypeEnum.PremiumContent);
        }
    } catch (err) {
        fpRegistrationwallSnackbarHandler("error", customSnackbarMessages?.error_message || "No successful purchase for regwall");
        throw new Error("No successful purchase for regwall");
    }
}

function fpRegistrationwallRedirectsHandler() {
    const redirectUrl = fpGetUrlParameterByName("redirect_url");
    if (redirectUrl) {
        _fp.metadata.selected_price.redirect_after_purchase = true;
        _fp.metadata.selected_price.redirect_after_purchase_url = redirectUrl;
    }
}

function fpRegistrationwallSnackbarHandler(type, message) {
    const customSnackbarMessagesIsEnabled = _fp.config.fragments?.registrationwall_fragment?.custom_snackbar_messages?.enabled;
    if (!customSnackbarMessagesIsEnabled) {
        return;
    }

    fpSnackbarMessageHandler(null, message, type);
}