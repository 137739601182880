import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseLookupGiftCode(code) {
    try {
        if (!code) {
            return null;
        }

        const url = `${_fp.config.base_url_transaction}/purchases/redeem_lookup`;
        const request = { gift_code: code };

        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
