import { paymentActionEnum } from "../../../core/enums/fp-payment-action.enum";
import { paymentElementEnum } from "../../../core/enums/fp-payment-elements.enum";
import { paymentFragmentEnum } from "../../../core/enums/fp-payment-fragment.enum";
import { paymentMethodEnum } from "../../../core/enums/fp-payment-method";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpSnackbarMessageHandler } from "../../../core/utils/fp-snackbar";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpSripeActionHandlerAsync } from "./fp-stripe-action-handler";
import { fpStripeOnError } from "./fp-stripe-error-handler";
import { fpStripeProcessPaymentAsync } from "./fp-stripe-process-payment";
import { fpStripeValidatePaymentForm } from "../stripe2024/utils/fp-stripe-validate-form";
import { fpTriggerJourney } from "../../../journeys/fp-trigger-journey";
import { journeyTypeEnum } from "../../../core/enums/fp-journey-type.enum";

export async function fpStripeInitElementsAsync(type) {
    try {
        if (_fp.config?.payment?.allow_other_payment_method) {
            await fpStripeInitOtherPaymentElementsAsync(type);
        }

        fpStripeInitCardElement(type);
    } catch (error) {
        fpErrorHandler("Init Stripe Card", error);
    }
}

export async function fpStripeUpdateOtherElementPaymentAmount(type) {
    if (!_fp.config.payment.allow_other_payment_method) {
        return;
    }

    const amountInCents = Math.round(((parseFloat(_fp.metadata?.selected_price.amount)) * 100));
    const elementType = type === paymentFragmentEnum.MemberCenter ? "mc_other_elements" : "other_elements";
    const currency = _fp.metadata?.selected_price.currency ? _fp.metadata?.selected_price.currency?.toLowerCase() : "eur";
    const price = {
        currency: currency,
        amount: amountInCents
    };

    await _fp.payment_provider.elements[elementType].update(price);
    return true;
}

function fpStripeInitCardElement(type) {
    _fp.payment_provider.elements[type] = _fp.payment_provider.stripe.elements();
    if (!_fp.payment_provider.elements[type]) {
        console.error("FP:: Cannot Set Stripe Elements");
        return;
    }

    const cardElements = fpStripeSetCardElements(type);
    cardElements.forEach((ce, ind) => {
        let stripeElement = _fp.payment_provider.elements[type].getElement(ce.type);
        if (stripeElement) {
            stripeElement.destroy();
            stripeElement.unmount(ce.query);
        }

        stripeElement = _fp.payment_provider.elements[type].create(ce.type, ce.configuration);
        if (document.querySelector(ce.query)) {
            stripeElement.mount(ce.query);
        }
        stripeElement.on("change", (evt) => fpStripeOnError(evt, ind), false);
    });
}

async function fpStripeInitOtherPaymentElementsAsync(type) {
    const price = { amount: 0, currency: "eur" };
    if (_fp.metadata?.selected_price) {
        price.currency = _fp.metadata?.selected_price ? _fp.metadata.selected_price.currency?.toLowerCase() : "eur";
        price.amount = (_fp.metadata?.selected_price.amount * 100);
    }

    const otherElQuery = "[data-fp-element='other-payment-options']";
    if (type === paymentFragmentEnum.MemberCenter) {
        fpStripeInitPaymentRequestButton(otherElQuery, price);
    } else {
        fpStripeInitExpressCheckoutElement(otherElQuery, price);
    }
}

async function fpStripeInitPaymentRequestButton(otherElQuery, price) {
    const stripeRequest = {
        country: "IE",
        currency: price.currency,
        total: {
            amount: 0,
            label: _fp.config?.client.site
        }
    };

    const paymentRequest = _fp.payment_provider.stripe.paymentRequest(stripeRequest);
    const otherMethodsAvailable = await paymentRequest.canMakePayment();
    if (!otherMethodsAvailable || (!otherMethodsAvailable.applePay && !otherMethodsAvailable.googlePay)) {
        const otherEl = document.querySelector(otherElQuery);
        otherEl?.classList.add("not-available");
        return;
    }
    const elementType = "mc_other_elements";
    _fp.payment_provider.elements[elementType] = _fp.payment_provider.stripe.elements();

    const otherPaymentEl = _fp.payment_provider.elements[elementType].getElement("paymentRequestButton");
    otherPaymentEl?.destroy();

    const paymentRequestElement = _fp.payment_provider.elements[elementType].create("paymentRequestButton", { paymentRequest: paymentRequest, style: { paymentRequestButton: { theme: "dark" } } });
    paymentRequestElement.mount(otherElQuery);
    paymentRequestElement.on("ready", () => {
        const otherEl = document.querySelector(otherElQuery);
        otherEl?.classList.add("ready");
    });

    paymentRequest.on("paymentmethod", async (res) => {
        try {
            res.complete((!res || res.error ? "fail" : "success"));
            fpSpinnerHandler("show");
            await fpSripeActionHandlerAsync(paymentFragmentEnum.MemberCenter, paymentActionEnum.UpdatePaymentMethod, res);
            window.location.reload();
        } catch (error) {
            fpSnackbarMessageHandler(null, "Oops something went wrong, please try again later", "error");
            fpSpinnerHandler("shide");
        }
    });
}

function fpStripeInitExpressCheckoutElement(otherElQuery, price) {
    const elementType = "other_elements";
    const options = {
        mode: "payment",
        amount: price.amount,
        currency: price.currency,
        setupFutureUsage: "off_session",
        paymentMethodTypes: ["card"],
        paymentMethodCreation: "manual"
    };

    _fp.payment_provider.elements[elementType] = _fp.payment_provider.stripe.elements(options);

    const expressCheckoutElement = _fp.payment_provider.elements[elementType].create("expressCheckout");
    expressCheckoutElement.mount(otherElQuery);

    expressCheckoutElement.on("ready", (event) => {
        const otherEl = document.querySelector(otherElQuery);
        if (otherEl) {
            const isAvailable = event?.availablePaymentMethods?.applePay || event?.availablePaymentMethods?.googlePay;
            otherEl.classList.add("ready");
            otherEl.classList.add(isAvailable ? "available" : "not-available");
        }
    });

    expressCheckoutElement.on("click", (event) => {
        const isValid = fpStripeValidatePaymentForm();
        if (!isValid) {
            return;
        }

        const options = { emailRequired: true, nameRequired: true };
        event.resolve(options);
    });

    expressCheckoutElement.on("confirm", async () => {
        const { error: submitError } = await _fp.payment_provider.elements[elementType].submit();
        if (submitError) {
            console.error("FP ERR");
            return;
        }
        const elements = _fp.payment_provider.elements[elementType];
        const isSuccess = await fpStripeProcessPaymentAsync(paymentMethodEnum.StripeExpressCheckout, elements, null);
        if (isSuccess) {
            fpTriggerJourney(journeyTypeEnum.PremiumContent);
        }
    });
}

function fpStripeSetCardElements(type) {
    const stripeElementConfigStyle = _fp.config?.payment?.card_settings?.style;
    let stripeElementDefaultStyle = {
        base: {
            iconColor: "#ccc",
            color: "#626262",
            backgroundColor: "#ffffff",
            fontFamily: "Questrial, sans-serif",
            fontWeight: 700,
            fontSize: "1rem",
            textTransform: "uppercase",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#eb1c26",
            },
            "::placeholder": {
                color: "#9F9F9F",
            },
        },
        invalid: {
            iconColor: "#eb1c26",
            color: "#eb1c26",
        },
    };

    if (stripeElementConfigStyle) {
        stripeElementDefaultStyle = {
            ...stripeElementDefaultStyle,
            ...stripeElementConfigStyle
        };
    }

    const isMemeberCenter = type === paymentFragmentEnum.MemberCenter;

    let cardElements = [{
        type: "card",
        query: isMemeberCenter ? paymentElementEnum.MemberCenterCard : paymentElementEnum.Card,
        configuration: {
            hidePostalCode: true,
            iconStyle: "solid"
        }
    }];

    if (_fp.config.payment?.card_settings?.type === "split") {
        cardElements = [
            {
                type: "cardNumber",
                query: isMemeberCenter ? paymentElementEnum.MemberCentercardNumber : paymentElementEnum.CardNumber,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: "Card Number"
                }
            },
            {
                type: "cardExpiry",
                query: isMemeberCenter ? paymentElementEnum.MemberCentercardExp : paymentElementEnum.CardExp,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: "MM/YY"
                }
            },
            {
                type: "cardCvc",
                query: isMemeberCenter ? paymentElementEnum.MemberCentercardCvv : paymentElementEnum.CardCvv,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: "CVC"
                }
            }
        ];
    }

    return cardElements;
}