import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseUpdateContributionAmount(purchaseId, amount) {
    try {
        const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/contribution`;
        await fpSendRequest("POST", url, JSON.stringify({ amount: amount, user_id: _fp.user.id }));
        return true;
    } catch (err) {
        console.error("FP::", err);
        return false;
    }
}
