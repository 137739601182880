// Extensions
function fpPopulateTemplate(anchor, value) {
    let pattern = (">>" + anchor + "<<" + "|" + "{{" + anchor + "}}");
    return this.replace(new RegExp(pattern, "g"), value);
}

function fpClearTemplateAnchors() {
    let pattern = (">>.*.<<" + "|" + "{{.*.}}");
    return this.replace(new RegExp(pattern, "g"), "");
}

function fpConvertToJson() {
    let obj = null;
    try {
        obj = JSON.parse(this);
    } catch (err) {
        obj = null;
    }
    return obj;
}

function fpSnakeCaseToPascalCase() {
    return this.replace(/_([a-z])/g, function (match, letter) {
        return letter.toUpperCase();
    }).replace(/^[a-z]/, function (match) {
        return match.toUpperCase();
    });
}

// Set String prototypes
String.prototype.fpPopulateTemplate = fpPopulateTemplate;
String.prototype.fpClearTemplateAnchors = fpClearTemplateAnchors;
String.prototype.fpConvertToJson = fpConvertToJson;
String.prototype.fpSnakeCaseToPascalCase = fpSnakeCaseToPascalCase;

