import { fpGetCustomerMeAsync } from "../requests/customer/fp-customer-me";

export async function fpCustomerValidateMandatoryProperties() {
    const customer = await fpGetCustomerMeAsync();
    if (!customer) {
        throw new Error("Customer not found");
    }

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    _fp.metadata.customer = customer;

    const mandatoryProperties = _fp.config.payment?.required_customer_properties;
    if (!mandatoryProperties || mandatoryProperties.length === 0) {
        return true;
    }

    if (!customer?.properties) {
        return false;
    }

    let isValid = true;
    mandatoryProperties.forEach((prop) => isValid = !isValid ? isValid : !!customer.properties[prop]);
    return isValid;
}