import { errorMessageEnum } from "../../../../core/enums/fp-error-message.enum";
import { paymentErrorEnum } from "../../../../core/enums/fp-payment-error.enum";
import { fpSetErrorMessage } from "../../../../core/utils/fp-error-handler";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpMemberCenterAdjustContentHeight } from "../../../../fragments/member-center/utils/fp-mc-set-height";

export function fpStripeOnError(evt, ind) {
    fpSpinnerHandler("hide");

    ind = ind ? ind : 0;
    _fp.payment_providers.stripe24.saved_errors = _fp.payment_providers.stripe24.saved_errors ? _fp.payment_providers.stripe24.saved_errors : {};

    const errorEl = document.querySelector("[data-fp-element='payment-error']");
    if (!errorEl) {
        console.error("FP:: Payment Error Wrapper Not Found");
        return;
    }

    if (evt.error) {
        // fpStripeHandleFormPaymentInputs(null, "disable");
        let errorMessage = evt.error?.message ? evt.error.message : paymentErrorEnum.Generic;
        if (evt.error?.status === 401) {
            errorMessage = errorMessageEnum.NotAuthenticatedUser;
        } else if (evt.error?.code) {
            const type = evt.error.code.replace(/_([a-z])/g, (g) => g[1].toUpperCase()).replace(/^./, (g) => g[0].toUpperCase());
            errorMessage = paymentErrorEnum[type] ? paymentErrorEnum[type] : paymentErrorEnum.Generic;
        } else if (typeof evt.error === "string") {
            errorMessage = fpStripeSetErrorMessageFromString(evt);
        }

        errorMessage = errorMessage ? errorMessage : paymentErrorEnum.Generic;

        errorEl.setAttribute("style", "display:block; color:red");
        errorEl.innerText = errorMessage;

        _fp.payment_providers.stripe24.saved_errors[ind] = evt.error.message;
    } else {
        // fpStripeHandleFormPaymentInputs(null, "enable");
        _fp.payment_providers.stripe24.saved_errors[ind] = null;

        // Loop over the saved errors and find the first one, if any.
        const nextError = Object.keys(_fp.payment_providers.stripe24.saved_errors)
            .sort()
            .reduce(function (maybeFoundError, key) {
                return maybeFoundError || _fp.payment_providers.stripe24.saved_errors[key];
            }, null);

        if (nextError) {
            // Now that they've fixed the current error, show another one.
            errorEl.innerText = nextError;
        } else {
            // The user fixed the last error; no more errors.
            errorEl.setAttribute("style", "display:none");
        }
    }

    // Adjust MC content hide to show error nicely
    fpMemberCenterAdjustContentHeight();
}

function fpStripeSetErrorMessageFromString(err) {
    try {
        let errorMessage = fpSetErrorMessage(err);

        const obj = JSON.parse(err.error);
        const isVoluntaryContributionFlow = _fp.metadata.prices?.every(x => x.voluntary_contribution);
        if (obj?.code === "duplicate") {
            errorMessage = "You already have an active ";
            errorMessage += isVoluntaryContributionFlow ? "contribution" : "subscription";
            errorMessage += _fp.user?.access_token ? ". To check details, please visit your account settings." : ", please sign in for more information.";
        }

        return errorMessage;
    } catch (error) {
        return paymentErrorEnum.Generic;
    }
}