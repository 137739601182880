import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetCartTemplate } from "./fp-set-cart-fragment-template";
import { fpSetCartTemplateListeners } from "./fp-set-cart-fragment-listeners";
import { fpInitializePaymentMethodAsync } from "../../providers/payment/fp-init-payment-method";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";

export function fpShowCartFragment() {
    try {
        let cartFragmentType = !_fp.metadata?.selected_price ? "items" : "payment";
        // if (!_fp.metadata?.selected_price) {
        //     cartFragmentType = "items";
        // } else if (!_fp.user || !_fp.user.access_token) {
        //     cartFragmentType = "account";
        // } else {
        //     cartFragmentType = "payment";
        // }

        _fp.premium_wrapper.innerHTML = fpSetCartTemplate(cartFragmentType, false);
        fpSetCartTemplateListeners(cartFragmentType);

        const fragmentTypeStatus = `${cartFragmentType}_${_fp.user?.access_token ? "signed_in" : "signed_out"}`;
        fpTrackUserAction("fp_fragment_displayed", null, "cart", null, null, fragmentTypeStatus);

        if (cartFragmentType === "payment") {
            fpInitializePaymentMethodAsync(paymentFragmentEnum.OrderSummary);
        }
    } catch (error) {
        fpTrackUserAction("fp_error", null, "cart", null, error);
        fpErrorHandler("Cart", error);
    }
}