/* eslint-disable sonarjs/cognitive-complexity */
import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { fpMemberCenterAdjustContentHeight } from "../../../../fragments/member-center/utils/fp-mc-set-height";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";
import { fpProcessPaymentWithStripe24Async } from "./fp-stripe-process-purchase";
import { fpUpdateStripe24PaymentMethodAsync } from "./fp-stripe-update-payment-method";
import { fpStripeValidatePaymentForm } from "./fp-stripe-validate-form";

/**
 * @param {*} data TODO: 
 * @returns 
 */
export function fpSetStripeExpressCheckoutElement(type, data) {
    let expressCheckoutOptions = {
        buttonType: {
            applePay: "buy",
            googlePay: "buy",
        },
        layout: {
            maxColumns: 1,
            maxRows: 2,
        },
        paymentMethods: {
            applePay: "always",
            googlePay: "always",
            // revolutPay: "never",
            paypal: "never",
            link: "never",
            amazonPay: "never"
        },
        paymentMethodOrder: ["googlePay", "applePay"]
    };

    const checkoutSettings = _fp.config?.payment?.express_checkout_settings;
    if (checkoutSettings) {
        // Filter out null values from checkoutSettings
        const filteredSettings = Object.fromEntries(
            Object.entries(checkoutSettings).filter(([key, value]) => key && value !== null)
        );

        expressCheckoutOptions = {
            ...expressCheckoutOptions,
            ...filteredSettings
        };
    }

    _fp.payment_providers.stripe24[type].express_checkout_element = data.elements.create("expressCheckout", expressCheckoutOptions);
    _fp.payment_providers.stripe24[type].express_checkout_element.mount("[data-fp-element='other-payment-options'] [data-fp-element='google_apple_pay']");

    _fp.payment_providers.stripe24[type].express_checkout_element.on("ready", ({ availablePaymentMethods }) => {
        const mainWrapEl = document.querySelector("[data-fp-element='other-payment-options']");
        if (!mainWrapEl) { return; }
        mainWrapEl.classList.add("ready");

        const wrapEl = mainWrapEl.querySelector("[data-fp-element='google_apple_pay']");
        if (!wrapEl) { return; }

        availablePaymentMethods = availablePaymentMethods ? availablePaymentMethods : {};
        if (Object.keys(availablePaymentMethods)?.filter(x => !!availablePaymentMethods[x])?.length > 0) {
            mainWrapEl.classList.add("available");
            wrapEl.classList.add("available");
        }

        // Adjust member center height if required
        fpMemberCenterAdjustContentHeight();
    });

    _fp.payment_providers.stripe24[type].express_checkout_element.on("click", (event) => {
        const isValid = fpStripeValidatePaymentForm();
        if (!isValid) {
            return;
        }

        const options = {
            emailRequired: true,
            nameRequired: true,
        };

        const collectAddress = !_fp?.user?.address && (_fp?.metadata?.subscription_action?.type === "upgrade" || !!_fp?.journey?.cart);
        if (!!_fp?.metadata?.selected_price?.validate_address && collectAddress) {
            options.shippingAddressRequired = true;
            options.shippingRates = [{
                id: "1", amount: 0, displayName: "Free of charge"
            }];
        }

        event.resolve(options);
    });

    _fp.payment_providers.stripe24[type].express_checkout_element.on(
        "confirm",
        async (e) => {
            if (type === "setup") {
                await fpUpdateStripe24PaymentMethodAsync(e.expressPaymentType);
                return;
            }

            const collectAddress = !_fp?.user?.address && (_fp?.metadata?.subscription_action?.type === "upgrade" || !!_fp?.journey?.cart);
            if (!!_fp?.metadata?.selected_price?.validate_address && collectAddress) {
                _fp.user = _fp.user ? _fp.user : {};
                _fp.user.address = {
                    user_name: e?.shippingAddress?.name,
                    address_1: `${e?.shippingAddress?.address?.line2 ?? ""} ${e?.shippingAddress?.address?.line1 ?? ""}`,
                    address_2: e?.shippingAddress?.address?.city,
                    address_3: e?.shippingAddress?.address?.state,
                    address_4: e?.shippingAddress?.address?.country,
                    post_code: e?.shippingAddress?.address?.postal_code,
                    country_code: e?.shippingAddress?.address?.country,
                };
            }

            const isSuccess = await fpProcessPaymentWithStripe24Async(
                _fp.payment_providers.stripe24.payment.payment_intent_id,
                _fp.payment_providers.stripe24.payment.payment_client_secret,
                e.expressPaymentType,
                data.elements,
                e.billingDetails
            );

            if (!isSuccess) {
                e.paymentFailed();
                return;
            }

            _fp.metadata?.subscription_action?.type === "upgrade"
                ? window.location.reload()
                : fpTriggerJourney(journeyTypeEnum.PremiumContent);
        }
    );
}