import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";

export function fpAuthorizeNetOnError(type, errorMessage) {
    const errorEl = document.querySelector("[data-fp-element='payment-error']");
    if (!errorEl) {
        console.error("FP:: Payment Error Wrapper Not Found");
        return;
    }

    // Clear error messages
    if (type === "reset") {
        errorEl.innerHTML = "";
        return;
    }

    // Set error messages
    if (Array.isArray(errorMessage)) {
        errorEl.innerHTML = errorMessage?.map(message => `<p>${message.text}</p>`).join("");
    } else if (typeof errorMessage === "object" && errorMessage.status) {
        const message = fpErrorHandler("error", errorMessage, true);
        errorEl.innerHTML = `<p>${message}</p>`;
    }
}