import { fpClearNotUsedAnchors } from "../../core/utils/fp-clear-template";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpSetCartAccountTemplate } from "./views/account/fp-set-cart-account-template";
import { fpSetCartItemsTemplate } from "./views/items/fp-set-cart-items-template";
import { fpSetCartPaymentTemplate } from "./views/payment/fp-set-payment-template";

export function fpSetCartTemplate(type, withoutTeaser) {
    let cartFragment = "";
    if (type === "items") {
        cartFragment = fpSetCartItemsTemplate();
    } else if (type === "account") {
        cartFragment = fpSetCartAccountTemplate();
    } else {
        cartFragment = fpSetCartPaymentTemplate();
    }

    cartFragment = cartFragment.fpPopulateTemplate("auth_status", (_fp.user && _fp.user.access_token) ? "fp-user-logged-in" : "fp-user-logged-out");
    if ((_fp?.metadata?.access_status?.custom_text_fields ?? []).length > 0) {
        for (let ctf of _fp.metadata.access_status.custom_text_fields) {
            cartFragment = cartFragment.fpPopulateTemplate(ctf.name, ctf.value);
        }
    }

    cartFragment = fpClearNotUsedAnchors(cartFragment);

    const teaser = withoutTeaser ? "" : fpSetFragmentTeaser("cart_fragment");
    return `${teaser}${cartFragment}`;
}
