import { fpClearNotUsedAnchors } from "../../core/utils/fp-clear-template";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../core/utils/fp-set-template-price-details";
import { fpSetStorageValue } from "../../core/utils/fp-storage-handler";

export function fpSetRegistrationwallTemplate(withoutTeaser) {
    try {
        const template = fpGetTemplateByName("registrationwall_fragment");
        const templateDOM = new DOMParser().parseFromString(template, "text/html");

        fpPopulateRegwallFragmentActionsDetails(templateDOM);

        let regwallFragment = templateDOM.querySelector("body").innerHTML;
        regwallFragment = fpClearNotUsedAnchors(regwallFragment);

        const teaser = withoutTeaser ? "" : fpSetFragmentTeaser("registrationwall_fragment");
        return `${teaser}${regwallFragment}`;
    } catch (error) {
        fpErrorHandler("Set Registrationwall Fragment Template", error);
        return "";
    }
}

function fpPopulateRegwallFragmentActionsDetails(templateDOM) {
    const settings = _fp.config?.fragments?.registrationwall_fragment?.settings;
    const priceEl = templateDOM.querySelector("[data-fp-element='price']");
    if (settings?.registration_only) {
        priceEl?.remove();
        return;
    }

    if (!priceEl) {
        return;
    }

    // Set first price as selected by default. (Used for social auth registration with free purchase)
    fpSetStorageValue("fp.selected_price_id", _fp.metadata.prices[0]?.id);

    let pricesHtml = "";
    _fp.metadata.prices.forEach((price, ind) => {
        pricesHtml += fpPopulateTemplatePriceDetails(priceEl.outerHTML, price, ind);
    });

    priceEl.outerHTML = pricesHtml;
}
