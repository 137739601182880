import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpPaymentActionHandlerAsync } from "../../providers/payment/fp-payment-action-handler";
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { fpShowVoluntaryContributionFragment, fpVoluntaryContributionGetConfig } from "./fp-voluntary-contribution-fragment";
import { fpSetDynamicLabelEl } from "../../core/utils/fp-dynamic-input-thumbnail";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";

export function fpSetVoluntaryContributionTemplateListeners(formEl) {
  try {
    formEl = formEl ? formEl : document.querySelector("form[data-fp-element='voluntary_contribution_form']");
    if (!formEl) {
      console.error("FP:: Missing form element form[data-fp-element='voluntary_contribution_form']");
      return;
    }

    fpVoluntaryContributionSetOnAmountChangeListeners(formEl);

    formEl.removeEventListener("submit", fpOnVoluntaryContributionFormSubmit, false);
    formEl.addEventListener("submit", fpOnVoluntaryContributionFormSubmit, false);
    if (_fp.metadata.selected_price.badge_text) {
      formEl.setAttribute("data-fp-attribute", _fp.metadata.selected_price.badge_text);
    }

    const authEls = [formEl.querySelector("[data-fp-element='authentication_sign_up']"), formEl.querySelector("[data-fp-element='authentication_sign_in']")];
    authEls?.forEach((authEl) => {
      authEl?.removeEventListener("click", fpOnVoluntaryContributionAuthClick, false);
      authEl?.addEventListener("click", fpOnVoluntaryContributionAuthClick, false);
    });

    const tabsEls = document.querySelectorAll("[data-fp-element='voluntary_contribution_tabs'] input");
    tabsEls?.forEach((psEl) => {
      psEl.removeEventListener("click", fpOnTabClick, false);
      psEl.addEventListener("click", fpOnTabClick, false);
    });
  } catch (error) {
    fpErrorHandler("Set Voluntary Contribution Template Listeners", error);
  }
}

export function fpVoluntaryContributionSetOnAmountChangeListeners(formEl) {
  const otherAmountEl = document.querySelector("[data-fp-element='voluntary_contribution_other_amount']");
  if (otherAmountEl) {
    ["keyup", "blur"].forEach((action) => {
      otherAmountEl.removeEventListener(action, fpOnVoluntaryContributionOtherAmountOnChange, false);
      otherAmountEl.addEventListener(action, fpOnVoluntaryContributionOtherAmountOnChange, false);
    });
  }

  const optionsEls = formEl.querySelectorAll("input[name='amount']");
  optionsEls.forEach((option) => {
    option.removeEventListener("click", fpOnVoluntaryContributionOptionClick, false);
    option.addEventListener("click", fpOnVoluntaryContributionOptionClick, false);
  });
}

function fpOnVoluntaryContributionOptionClick(evt) {
  const otherOptionWrapperEl = document.querySelector("[data-fp-element='voluntary_contribution_other_option']");
  if (otherOptionWrapperEl) {
    otherOptionWrapperEl.classList[evt.currentTarget.value === "other" ? "add" : "remove"]("active");
    const otherOptInputEl = otherOptionWrapperEl.querySelector("input");
    if (otherOptInputEl) {
      const minContribution = _fp.metadata?.selected_price?.voluntary_contribution_minimum_amount ? _fp.metadata.selected_price.voluntary_contribution_minimum_amount : 1;
      const contributionOptions = _fp.metadata?.selected_price?.voluntary_contribution_amounts ? _fp.metadata.selected_price.voluntary_contribution_amounts : [minContribution];

      _fp.metadata.selected_price.amount = contributionOptions[0];

      otherOptInputEl.min = minContribution;
      otherOptInputEl.value = evt.currentTarget.value === "other" ? _fp.metadata.selected_price.amount : evt.currentTarget.value;
      otherOptInputEl.setCustomValidity("");

      const dynamicLabelEls = document.querySelectorAll("[data-fp-element='dynamic_label']");
      dynamicLabelEls?.forEach((el) => fpSetDynamicLabelEl(el));
    }
  }

  _fp.metadata.selected_price.amount = evt.currentTarget.value === "other" ? _fp.metadata.selected_price.amount : evt.currentTarget.value;
  fpTrackUserAction("fp_user_action", "price_selected", null, null, _fp.metadata.selected_price);
  const isMemberCenterAction = !!document.querySelector("[data-fp-element='subscription_actions_form']");
  if (!isMemberCenterAction) {
    fpPaymentActionHandlerAsync(paymentFragmentEnum.VoluntaryContribution, paymentActionEnum.UpdateOtherElementPaymentAmount);
  }
}

function fpOnVoluntaryContributionOtherAmountOnChange(evt) {
  const paymentEl = document.querySelector("[data-fp-element='payment_wrapper']");
  if (!paymentEl) {
    console.error("FP:: Payment wrapper not defined");
    return;
  }

  const errEls = [
    { type: "max", el: document.querySelector("[data-fp-element='voluntary_contribution_error_max_amount']"), active: evt.currentTarget.value > _fp.metadata.selected_price.voluntary_contribution_maximum_amount },
    { type: "min", el: document.querySelector("[data-fp-element='voluntary_contribution_error_min_amount']"), active: evt.currentTarget.value < _fp.metadata.selected_price.voluntary_contribution_minimum_amount },
  ];

  paymentEl.style.pointerEvents = "none";
  errEls.forEach((err) => (err.el ? (err.el.style.display = err.active ? "block" : "none") : null));

  if (evt.type === "keyup") {
    return;
  }

  if (errEls.every((x) => !x.active) && _fp.metadata.selected_price !== evt.currentTarget.value) {
    _fp.metadata.selected_price.amount = evt.currentTarget.value;
    fpTrackUserAction("fp_user_action", "price_selected", null, null, _fp.metadata.selected_price);

    const isMemberCenterAction = !!document.querySelector("[data-fp-element='subscription_actions_form']");
    if (!isMemberCenterAction) {
      fpPaymentActionHandlerAsync(paymentFragmentEnum.VoluntaryContribution, paymentActionEnum.UpdateOtherElementPaymentAmount);
    }
  }

  paymentEl.style.pointerEvents = "auto";
  const dynamicThumbnailEls = document.querySelectorAll("[data-fp-element='dynamic_label']");
  dynamicThumbnailEls?.forEach((el) => fpSetDynamicLabelEl(el));

  evt.currentTarget.setCustomValidity(evt.currentTarget.value === "" ? "Mandatory field" : "");
}

async function fpOnVoluntaryContributionFormSubmit(evt) {
  evt.preventDefault();
  fpTrackUserAction("fp_user_action", "voluntary_contribution_form_submit", null, null, null);
  fpVoluntaryContributionGetFormData(evt.currentTarget);

  const isFormValid = fpVoluntaryContributionFormValidation(evt);
  if (isFormValid) {
    const response = await fpPaymentActionHandlerAsync(paymentFragmentEnum.VoluntaryContribution, paymentActionEnum.ProcessPayment);
    if (response?.success) {
      fpTriggerJourney(journeyTypeEnum.PremiumContent);
    }
  }
}

function fpOnVoluntaryContributionAuthClick(evt) {
  evt.preventDefault();
  fpTrackUserAction("fp_user_action", "voluntary_contribution_authenticate", null, null, null);

  const journeyType = evt.currentTarget.dataset?.fpElement.includes("sign_in") ? journeyTypeEnum.SignIn : journeyTypeEnum.SignUp;
  fpShowAuthenticationFragment(journeyType);
}

function fpVoluntaryContributionFormValidation(evt) {
  const formEl = evt.currentTarget;
  for (const errormsg of formEl.querySelectorAll(".fp-vc-control-error")) {
    errormsg.style.display = "none";
  }

  if (!formEl.checkValidity()) {
    const requiredFormElements = Array.from(formEl.elements).filter((x) => x.required);
    requiredFormElements.forEach((el) => {
      if (!el.checkValidity()) {
        el.nextElementSibling.textContent = el.validationMessage;
        el.nextElementSibling.style.display = "block";
      }
    });

    return false;
  }

  if (_fp.user?.access_token) {
    return true;
  }

  return !!_fp.metadata.selected_price.allow_anonymous && !!_fp.user.billing_details.email && !!_fp.user.billing_details.name;
}

function fpOnTabClick(evt) {
  const fragmentConfig = fpVoluntaryContributionGetConfig();
  const options = fragmentConfig?.settings?.options ?? fragmentConfig?.settings?.tabs?.options;

  options.forEach((option) => (option.selected = evt.currentTarget.value === option.badge));
  fpShowVoluntaryContributionFragment(true);
}

export function fpVoluntaryContributionGetFormData(formEl) {
  const formData = Object.fromEntries(new FormData(formEl).entries());
  _fp.user = _fp.user ? _fp.user : {};
  _fp.user.billing_details = {
    email: formData.email,
    first_name: formData.first_name,
    last_name: formData.last_name,
    name: `${formData.first_name} ${formData.last_name}`,
  };
}
