export function fpSetDynamicLabelEl(labelEl) {
    if (!labelEl) {
        return;
    }

    const inputEl = labelEl.querySelector("input");
    const textEls = labelEl.querySelectorAll("[data-fp-element='dynamic_label_text']");

    if (!inputEl || !textEls) {
        return;
    }

    labelEl.style.position = "relative";
    textEls.forEach((el) => {
        const width = getTextWidth(inputEl.value, window.getComputedStyle(inputEl, null).getPropertyValue("font"));
        el.style.position = "absolute";
        el.style.left = width + "px";
        if (width <= 0) {
            el.classList.add("empty");
        } else {
            el.classList.remove("empty");
        }
    });
}

function getTextWidth(text, font) {
    // re-use canvas object for better performance
    let canvas = getTextWidth.canvas || (getTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width.toFixed(0);
}
