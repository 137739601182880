export const paymentElementEnum = {
    Card: "#fp-card",
    CardNumber: "#fp-card-number",
    CardCvv: "#fp-card-cvv",
    CardExp: "#fp-card-exp",

    MemberCenterCard: "#fp-mc-card",
    MemberCentercardNumber: "#fp-mc-card-number",
    MemberCentercardCvv: "#fp-mc-card-cvv",
    MemberCentercardExp: "#fp-mc-card-exp",
};
