import { memberCenterSubscriptionActionEnum } from "../../../../core/enums/fp-member-center-subscription-action.enum";
import { paymentActionEnum } from "../../../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../../../core/enums/fp-payment-fragment.enum";
import { fpPurchaseChangeRenewalPriceAsync } from "../../../../core/requests/purchase/fp-change-renewal-price";
import { fpPurchaseDowngradeAsync } from "../../../../core/requests/purchase/fp-downgrade";
import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpSetDeliveryAddress } from "../../../../core/utils/fp-set-delivery-address";
import { fpSetElementInnerHTMLByQuery } from "../../../../core/utils/fp-set-element-inner-html";
import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../../../core/utils/fp-set-template-price-details";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpInitializePaymentMethodAsync } from "../../../../providers/payment/fp-init-payment-method";
import { fpPaymentActionHandlerAsync, fpProcessPurchaseAsync } from "../../../../providers/payment/fp-payment-action-handler";
import { fpMemberCenterAdjustContentHeight } from "../../utils/fp-mc-set-height";
import { fpGetSubcriptionUpdateOptions } from "../../utils/fp-mc-subscription-plan-update-actions";

export async function fpMemberCenterUpdateSubscriptionHandler(templateDOM, actionType, submitBtnDataSet, formData) {
    if (actionType === "show_options") {
        fpMemberCenterShowAvailableOptions(templateDOM);
        return false;
    } else if (actionType === "confirm_action") {
        fpMemberCenterShowConfirmActionForm(templateDOM, submitBtnDataSet.priceId);
        return false;
    } else if (actionType === "process_action") {
        return await fpMemberCenterProcessActionAsync(formData);
    }
}

function fpMemberCenterShowAvailableOptions(templateDOM) {
    const optionsWrapperEl = templateDOM.querySelector("[data-fp-element='subscription_update_options']");
    const optionEl = templateDOM.querySelector("[data-fp-element='subscription_update_option']");
    if (!optionsWrapperEl || !optionEl) {
        return;
    }

    const confirmationEl = templateDOM.querySelector("[data-fp-element='subscription_update_confirmation']");
    confirmationEl?.remove();

    const options = fpGetSubcriptionUpdateOptions();
    if (options.length === 0) {
        return;
    }

    const optionsHTML = fpMemberCenterSetAvailableOptions(optionEl, options);

    optionsWrapperEl.innerHTML = optionsHTML;
}

async function fpMemberCenterShowConfirmActionForm(templateDOM, priceId) {
    fpSpinnerHandler("hide");

    // Get template if not provided
    if (!templateDOM) {
        let templateHTML = fpGetTemplateByName("member_center_fragment", "subscriptions_actions")
            ?.fpPopulateTemplate("type", memberCenterSubscriptionActionEnum.UpdateSubscription);

        templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");
    }

    // Retrieve selected price metadata
    const options = fpGetSubcriptionUpdateOptions(true);
    _fp.metadata = _fp.metadata ?? {};
    _fp.metadata.selected_price = options?.find(x => x.id === priceId);
    _fp.metadata.member_center = {
        action_type: _fp.metadata?.selected_price?.action_type,
        action_confirmed: true
    };

    _fp.metadata.subscription_action = {
        type: _fp.metadata?.selected_price?.action_type,
        original_purchase_id: _fp.user?.purchase?.id
    };

    // Remove existing options
    document.querySelector("[data-fp-element='subscription_update_options']")?.remove();

    // Get elements
    const paymentWrapperEl = templateDOM.querySelector("[data-fp-element='subscription_update_confirmation']");
    const mainWrapEl = document.querySelector("[data-fp-element='action_update_subscription']");
    mainWrapEl?.classList?.add(_fp.metadata?.selected_price?.action_type);

    // Remove delivery details if not required
    const deliveryAddrEl = paymentWrapperEl.querySelector("[data-fp-element='delivery_details']");
    if (deliveryAddrEl && !_fp?.metadata?.selected_price?.validate_address) {
        deliveryAddrEl.remove();
    }

    // Populate template with price & purchase details
    let templateHTML = fpPopulateTemplatePriceDetails(paymentWrapperEl.outerHTML, _fp.metadata.selected_price);
    Object.entries(_fp.user?.purchase || {}).forEach(([key, value]) => {
        if (typeof value === "string") {
            templateHTML = templateHTML.fpPopulateTemplate(key, value);
        } else if (typeof value === "object") {
            Object.entries(value).forEach(([prop, propValue]) => {
                templateHTML = templateHTML.fpPopulateTemplate(prop, propValue);
            });
        }
    });

    templateHTML = templateHTML.fpClearTemplateAnchors();
    mainWrapEl.innerHTML = templateHTML;

    // Store reference to the subscription actions wrapper
    _fp.premium_wrapper = document.querySelector("[data-fp-element='subscription_actions']");

    // Handle immediate charge case
    // Legacy fallback as BE should return correct configuration
    let isImmediateCharge = _fp.metadata?.selected_price.apply_upgrade_deduction ?? !!_fp.config?.fragments?.member_center_fragment?.settings?.plan_update?.immediate_charge;
    if (isImmediateCharge && _fp.metadata?.selected_price?.action_type === "upgrade") {
        await fpMemberCenterHandleImmediateChargeAsync();
    } else {
        _fp.premium_wrapper?.querySelector("[data-fp-element='immediate_charge']")?.remove();
    }

    fpMemberCenterAdjustContentHeight();

    const paymentToggleEl = document.querySelector("[data-fp-element='immediate_charge'] input[type='checkbox']");
    paymentToggleEl?.addEventListener("change", fpMemberCenterAdjustContentHeight, false);
}

async function fpMemberCenterProcessActionAsync(formData) {
    const actionType = _fp.metadata?.selected_price?.action_type;
    if (!actionType) {
        return;
    }

    delete _fp.metadata.member_center.action_type;
    delete _fp.metadata.member_center.action_confirmed;

    if (_fp.metadata?.selected_price?.validate_address) {
        _fp.user = _fp.user ?? {};
        _fp.user.address = _fp.user.address = fpSetDeliveryAddress(formData);
    }

    try {
        fpTrackUserAction("fp_user_action", actionType, null, null, null, "requested");

        let response = null;
        if (actionType === "change_renewal_price") {
            response = await fpPurchaseChangeRenewalPriceAsync(_fp.user?.purchase?.id, _fp.metadata?.selected_price.id);
        } else if (actionType === "upgrade") {
            let isImmediateCharge = _fp.metadata.selected_price.apply_upgrade_deduction;
            if (!isImmediateCharge) { // Legacy as BE should return correct configuration
                isImmediateCharge = !!_fp.config?.fragments?.member_center_fragment?.settings?.plan_update?.immediate_charge;
            }

            const useCardElement = formData?.new_card === undefined || formData?.new_card === "true";
            const purchaseData = { ...(isImmediateCharge && { card_id: _fp.user?.purchase?.renewal_payment_method?.id }) };

            response = (isImmediateCharge && useCardElement)
                ? await fpPaymentActionHandlerAsync(paymentFragmentEnum.MemberCenter, paymentActionEnum.ProcessPayment)
                : await fpProcessPurchaseAsync(false, "create_purchase", purchaseData);
        } else if (actionType === "downgrade") {
            response = await fpPurchaseDowngradeAsync(_fp.user?.purchase?.id, _fp.metadata?.selected_price?.id);
        }

        if (!response?.success && response !== true) {
            return false;
        }

        fpTrackUserAction("fp_user_action", actionType, "price", _fp.metadata?.selected_price?.id, _fp.metadata?.selected_price, "processed");

        return true;
    } catch (err) {
        const actionType = _fp.metadata?.selected_price?.action_type ?? "subscription_action";
        fpTrackUserAction("fp_error", actionType, null, null, null);
        fpErrorHandler("show", err);

        return false;
    }
}

function fpMemberCenterSetAvailableOptions(optionEl, options) {
    let optionsHTML = "";

    const settings = _fp.config?.fragments?.member_center_fragment?.settings;
    if (!settings?.available_plans_table_config) {
        optionsHTML = options
            .map(price => fpPopulateTemplatePriceDetails(optionEl.outerHTML, price))
            .join("");
        return optionsHTML;
    }

    optionsHTML = options
        .map(option => fpPopulateGroupedAvailableSubUpdateOptions(optionEl.outerHTML, option))
        .join("");

    return optionsHTML;
}

function fpPopulateGroupedAvailableSubUpdateOptions(template, details) {
    const config = _fp.config.fragments.member_center_fragment.settings.available_plans_table_config;

    let templateHtml = template.fpPopulateTemplate("name", details[config.group_by]);

    const templateEl = new DOMParser().parseFromString(templateHtml, "text/html");
    const optEl = templateEl.querySelector("[data-fp-element='subscription_update_price_option']");

    let pricesHtml = "";
    config.columns.forEach(column => {
        const key = column[Object.keys(column)[0]];
        if (details[key]) {
            pricesHtml += fpPopulateTemplatePriceDetails(optEl.outerHTML, details[key]);
        }
    });

    optEl.outerHTML = pricesHtml;
    return templateEl.querySelector("body")?.innerHTML;
}

async function fpMemberCenterHandleImmediateChargeAsync() {
    const btnsEls = document.querySelectorAll("[data-fp-element='subscription_update_confirmation'] button");

    btnsEls?.forEach(bEl => bEl.disabled = true);
    await fpInitializePaymentMethodAsync(null, _fp.user?.purchase?.id, "upgrade");

    let charge = _fp.metadata?.payment_providers?.payment?.amount ?? 0;
    charge = typeof charge === "number" ? charge.toFixed(2) : "";
    fpSetElementInnerHTMLByQuery("[data-fp-element='upgrade_amount']", charge);

    btnsEls?.forEach(bEl => bEl.disabled = false);
}