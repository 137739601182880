import { fpCreatePaymentIntentAsync, fpCreateSetupIntentAsync } from "../requests/purchase/fp-payment-intents";

export async function fpGetPaymentProvidersAsync(type, purchaseId, actionType) {
    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    if (!_fp.metadata.payment_providers) {
        _fp.metadata.payment_providers = {};
    }

    const defaultProvider = _fp.config.payment.provider ?? "stripe";
    let paymentProviders = { [defaultProvider]: {} };

    if (defaultProvider === "stripe") {
        _fp.metadata.payment_providers[type] = paymentProviders;
        return paymentProviders;
    }

    // Payment & Setup(update payment method)
    try {
        let res = null;
        if (type === "payment") {
            res = await fpCreatePaymentIntentAsync(purchaseId, actionType);
            _fp.metadata.payment_providers.payment = res;
        } else if (type === "setup") {
            res = await fpCreateSetupIntentAsync(purchaseId);
            _fp.metadata.payment_providers.setup = res;
        }

        paymentProviders = res?.payment_providers;
    } catch (error) {
        paymentProviders = { [defaultProvider]: {} };
    }

    return paymentProviders;
}