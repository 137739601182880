import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpSetCustomerProperties(request) {
    try {
        if (!_fp.user || !_fp.user.access_token) {
            return Promise.resolve(null);
        }

        const url = `${_fp.config.base_url_transaction}/customers/me/properties`;
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
