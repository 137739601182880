import { paymentFragmentEnum } from "../../../core/enums/fp-payment-fragment.enum";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpLoadScriptsAsync } from "../../../core/utils/fp-load-scripts";
import { fpStripeInitElementsAsync } from "./fp-stripe-elements";

export async function fpInitializeStripeAsync(type) {
    try {
        const isTemplateSet = fpStripeSetPaymentFragmentHTML(type);
        if (!isTemplateSet) {
            console.error("FP:: Cannot Set Stripe Payment Fragment");
            return;
        }

        if (typeof Stripe === "undefined") {
            await fpLoadScriptsAsync(["https://js.stripe.com/v3/"]);
        }

        _fp.payment_provider = {
            stripe: _fp.payment_provider?.stripe ? _fp.payment_provider.stripe : Stripe(_fp.config.payment.key),
            elements: {}
        };

        await fpStripeInitElementsAsync(type);
    } catch (error) {
        fpErrorHandler("Initialize Stripe", error);
    }
}

function fpStripeSetPaymentFragmentHTML(type) {
    const paymentEl = document.querySelector("[data-fp-element='payment_wrapper']");
    if (!paymentEl) {
        console.error("FP:: Payment Fragment Missing -> [data-fp-element='payment-wrapper']");
        return false;
    }

    const otherPaymentOptions = paymentEl.querySelector("[data-fp-element='other-payment-options']");
    if (otherPaymentOptions && !_fp.config?.payment?.allow_other_payment_method) {
        otherPaymentOptions.remove();
    }

    const cardPrefix = type === paymentFragmentEnum.MemberCenter ? "mc-" : "";
    const cardWrapperEl = paymentEl.querySelector("[data-fp-element='card-payment']");
    if (cardWrapperEl) {
        if (_fp.config?.payment?.card_settings?.type !== "compact") {
            cardWrapperEl.innerHTML = `
            <div class='fp-card'>
                <div id='fp-${cardPrefix}card-number'></div>
                <div class='fp-${cardPrefix}card-details'>
                    <div id='fp-${cardPrefix}card-exp'></div>
                    <div id='fp-${cardPrefix}card-cvv'></div>
                </div>
            </div>`;
        } else {
            cardWrapperEl.innerHTML = `<div id='fp-${cardPrefix}card'></div>`;
        }
    }

    return true;
}