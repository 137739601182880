import { errorMessageEnum } from "../../../core/enums/fp-error-message.enum";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";

export function fpStripeOnError(evt, ind) {
    fpSpinnerHandler("hide");

    ind = ind ? ind : 0;
    _fp.payment_provider.saved_errors = _fp.payment_provider.saved_errors ? _fp.payment_provider.saved_errors : {};

    const errorEl = document.querySelector("[data-fp-element='payment-error']");
    if (!errorEl) {
        console.error("FP:: Payment Error Wrapper Not Found");
        return;
    }

    if (evt.error) {
        // fpStripeHandleFormPaymentInputs(null, "disable");
        let errorMessage = evt.error?.message ? evt.error.message : errorMessageEnum.Generic;
        if (evt.error?.status === 401) {
            errorMessage = "Not authenticated user";
        } else if (evt.error?.code === "limit_exceeded") {
            errorMessage = "You reached retries limit, please try again later.";
        } else if (typeof evt.error === "string") {
            errorMessage = fpStripeSetErrorMessageFromString(evt.error);
        }

        errorMessage = errorMessage ? errorMessage : errorMessageEnum.Generic;
        
        errorEl.setAttribute("style", "display:block; color:red");
        errorEl.innerText = errorMessage;

        _fp.payment_provider.saved_errors[ind] = evt.error.message;
    } else {
        // fpStripeHandleFormPaymentInputs(null, "enable");
        _fp.payment_provider.saved_errors[ind] = null;

        // Loop over the saved errors and find the first one, if any.
        const nextError = Object.keys(_fp.payment_provider.saved_errors)
            .sort()
            .reduce(function (maybeFoundError, key) {
                return maybeFoundError || _fp.payment_provider.saved_errors[key];
            }, null);

        if (nextError) {
            // Now that they've fixed the current error, show another one.
            errorEl.innerText = nextError;
        } else {
            // The user fixed the last error; no more errors.
            errorEl.setAttribute("style", "display:none");
        }
    }
}

function fpStripeSetErrorMessageFromString(value) {
    try {
        const obj = JSON.parse(value);
        const isVoluntaryContributionFlow = _fp.metadata.prices?.every(x => x.voluntary_contribution);
        if (obj?.code === "duplicate") {
            let errorMessage = "You already have an active ";
            errorMessage += isVoluntaryContributionFlow ? "contribution" : "subscription";
            errorMessage += _fp.user?.access_token? ". To check details, please visit your account settings.": ", please sign in for more information.";
            return errorMessage;
        }
    } catch (error) {
        return errorMessageEnum.Generic;
    }
}