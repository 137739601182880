import { fpSendRequest } from "../../utils/fp-http-request";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";

export async function fpUserConfirmPinCodeAsync(data) {
    const request = {
        "pin": data.pin_code ?? null,
        "pin_token": _fp?.metadata?.auth_token ?? null,
        "site": _fp.config?.client?.site ?? null
    };

    const url = `${_fp.config?.authority}/${_fp.config?.client?.id}/verify_email_address_pin`;

    try {
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (error) {
        throw fpRequestErrorHandler(error);
    }
}