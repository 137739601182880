export const journeyTypeEnum = {
    SignIn: "sign_in",
    SignUp: "sign_up",
    SignOut: "sign_out",
    ResetPassword: "reset_pwd",
    Custom: "custom",
    OrderSummary: "order_summary",
    CouponCode: "coupon_code",
    HomeDelivery: "home_delivery",
    Prices: "prices",
    Registrationwall: "registrationwall",
    Free: "free",
    Paywall: "paywall",
    Metered: "metered",
    PremiumContent: "premium_content",
    VoluntaryContribution: "voluntary_contribution",
    GiftRedeem: "gift_redeem",
    CustomerMandatoryProperties: "customer_mandatory_properties",
    Cart: "cart",
    Manual: "manual",
    MarketingMessage: "marketing_message",
    Dynamic: "dynamic",
};
