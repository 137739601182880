import { paymentProviderEnum } from "../../core/enums/fp-payment-provider.enum";
import { fpGetPaymentProvidersAsync } from "../../core/utils/fp-get-payment-providers";
import { fpInitializeAuthorizeNetAsync } from "./authorize.net/fp-authorize-init";
import { fpInitializeStripeAsync } from "./stripe/fp-stripe-init";
import { fpInitializeStripe2024Async } from "./stripe2024/fp-init";

export async function fpInitUpdatePaymentMethodAsync(purchaseId, fragmentType) {
    if (!purchaseId) {
        console.error("FP:: Purchase id not provided", fragmentType);
        return;
    }

    const paymentProviders = await fpGetPaymentProvidersAsync("setup", purchaseId);
    if ((Object.keys(paymentProviders)?.length ?? 0) === 0) {
        return;
    }
    if (paymentProviders[paymentProviderEnum.Stripe2024]) {
        fpInitializeStripe2024Async("setup", paymentProviders[paymentProviderEnum.Stripe2024]);
    } else if (paymentProviders[paymentProviderEnum.Stripe]) {
        fpInitializeStripeAsync(fragmentType);
    } else if (paymentProviders[paymentProviderEnum.AuthorizeNet]) {
        fpInitializeAuthorizeNetAsync("setup", paymentProviders[paymentProviderEnum.AuthorizeNet]);
    }
}