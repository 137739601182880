import { fpGetTemplateByName } from "../../core/utils/fp-set-template";

export function fpSetGiftRedeemTemplate(type) {
    try {
        const templateType = type ? "redeem_gift_details" : "redeem_gift";
        const template = fpGetTemplateByName("gift_redeem_fragment", templateType);
        const templateDOM = new DOMParser().parseFromString(template, "text/html");

        if (type) {
            fpSetGiftPriceDetails(templateDOM);
        }

        let templateHTML = templateDOM.querySelector("body").innerHTML;
        templateHTML = templateHTML
            .fpPopulateTemplate("gift_code", _fp.metadata?.gift?.code ? _fp.metadata.gift.code : "");

        return templateHTML;
    } catch (error) {
        console.error("FP::", error);
        throw error;
    }
}

function fpSetGiftPriceDetails(templateDOM) {
    try {
        const printHomeDeliveryEl = templateDOM.querySelector("[data-fp-element='home-delivery']");
        if (!printHomeDeliveryEl || !_fp.user.address) {
            return;
        }

        const addressFormated = _fp.user.address.full.replace(",", "<br/>");
        printHomeDeliveryEl.outerHTML = printHomeDeliveryEl.outerHTML
            .fpPopulateTemplate("user_name", _fp.user.address.user_name)
            .fpPopulateTemplate("delivery_address", addressFormated);
    } catch (error) {
        console.error("FP", error);
        throw error;
    }
}