import { errorMessageEnum } from "../../../../core/enums/fp-error-message.enum";
import { fpGetJourneyConfig } from "../../../../core/utils/fp-get-journeys-config";
import { fpSnackbarMessageHandler } from "../../../../core/utils/fp-snackbar";

export function fpStripeValidatePaymentForm() {
    const fpJourneyConfig = fpGetJourneyConfig();
    if (!fpJourneyConfig) {
        return true;
    }

    // If simple checkout enabled(in config) and a user is not signed up, payment form needs to be validated whether all
    // mandatory fields are populated. As we will need to sign up customer later(simple checkout shouldn't be enabled if social auth is allowed)
    if (fpJourneyConfig.simple_checkout && (!_fp.user || !_fp.user.access_token) && !fpValidatePaymentForm()) {
        return false;
    }

    const isAnonPurchase = _fp?.metadata?.selected_price?.allow_anonymous && (!_fp.user || !_fp.user.access_token);
    if (!isAnonPurchase && !fpJourneyConfig?.payment?.force_form_validation) {
        return true;
    }

    const paymentFormEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='voluntary_contribution_form']");
    const isFormValid = fpValidatePaymentForm(!!fpJourneyConfig?.payment?.force_form_validation);
    if (!isFormValid || !paymentFormEl) {
        console.error("FP:: Invalid/Missing payment form");
        return false;
    }

    return true;
}

function fpValidatePaymentForm(forceValidation) {
    const paymentFormEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='voluntary_contribution_form']");
    if (!paymentFormEl) {
        console.error("FP:: payment form element not found");
        return false;
    }

    const errElQuery = ".fp-control-error";
    const errorMsgs = paymentFormEl.querySelectorAll(errElQuery);
    errorMsgs.forEach(el => el.innerHTML = "");

    // Determine if form validation is required
    const formData = Object.fromEntries(new FormData(paymentFormEl)?.entries()) ?? {};
    const requiresValidation = !!Object.keys(formData)?.find(x => x === "password") || forceValidation;

    if (!requiresValidation) { return true; }

    const isFormValid = paymentFormEl.checkValidity();
    if (isFormValid) { return true; }

    // Filter invalid elements to exclude hidden ones
    const invalidEls = [...paymentFormEl.querySelectorAll(":invalid")].filter(el => el.offsetParent !== null);

    if (invalidEls.length === 0) { return true; }

    fpSnackbarMessageHandler(null, "Mandatory Fields Not Populated", "error");

    invalidEls.forEach(el => {
        // el.reportValidity();
        let errEl = el.closest(errElQuery) || el.parentElement?.querySelector(errElQuery);
        if (errEl) { errEl.innerHTML = errorMessageEnum.MandatoryField; }
    });

    return false;
}