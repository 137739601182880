export async function fpStripeConfirmCardActionAsync(clientSecret, elements, type) {
    const stripeCardEl = elements?.getElement("card")
        ? elements.getElement("card")
        : elements.getElement("cardNumber");

    if (!stripeCardEl) {
        return { error: { message: "No Stripe card element injected" } };
    }

    if (type === "setup") {
        return await _fp.payment_providers.stripe24.stripe.confirmCardSetup(clientSecret, { payment_method: { card: stripeCardEl } });
    }

    return await _fp.payment_providers.stripe24.stripe.confirmCardPayment(clientSecret, { payment_method: { card: stripeCardEl } });
}
