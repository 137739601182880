export async function fpStripeConfirmActionAsync(clientSecret, elements, paymentMethodType, actionType) {
    const redirectUrl = new URL(window.location.href);
    redirectUrl.searchParams.set("payment_method_type", paymentMethodType);
    if (actionType === "payment") {
        redirectUrl.searchParams.set("purchase_id", _fp.metadata?.purchase?.id ?? null);
        redirectUrl.searchParams.set("price_id", _fp.metadata?.selected_price?.id ?? null);
        redirectUrl.searchParams.set("anonymous", !!_fp.metadata?.selected_price?.allow_anonymous);
    } else if (actionType === "setup") {
        const setupIntentId = _fp?.metadata?.payment_providers?.setup?.id ?? _fp.metadata?.payment_providers?.payment?.id;
        redirectUrl.searchParams.set("payment_provider", "stripe_24");
        redirectUrl.searchParams.set("setup_intent_id", setupIntentId);
    }

    let stripeAction = "confirmPayment";
    if (actionType === "setup") {
        stripeAction = "confirmSetup";
        await elements.submit();
    }

    return await _fp.payment_providers.stripe24.stripe[stripeAction]({
        elements,
        clientSecret,
        confirmParams: {
            return_url: redirectUrl.toString(),
        },
        redirect: "if_required",
    });
}