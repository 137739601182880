import { fpClearNotUsedAnchors } from "../../../core/utils/fp-clear-template";

export function fpMemberCenterPopulateProfile(templateHTML, customerDetails) {
  Object.keys(customerDetails.customer).forEach((key) => {
    templateHTML = templateHTML.fpPopulateTemplate(
      key,
      customerDetails.customer[key]
    );
  });

  return fpClearNotUsedAnchors(templateHTML);
}
