import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPatchCustomerDetailsAsync(type, request) {
    try {
        if (!_fp.user || !_fp.user.access_token) {
            return Promise.resolve(null);
        }

        return await fpSendRequest("PATCH", `${_fp.config.base_url_transaction}/customers/me/${type}`, JSON.stringify(request));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
