import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpScrollToElement } from "../../core/utils/fp-scroll-to-element";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpInitializePaymentMethodAsync } from "../../providers/payment/fp-init-payment-method";
import { fpSetOrderSummaryTemplate } from "./fp-set-order-summary-template";
import { fpSetOrderSummaryTemplateListeners } from "./fp-set-order-summary-template-listeners";

export function fpShowOrderSummaryFragment() {
    try {
        fpScrollToElement(_fp.premium_wrapper);

        const isCardPurchase = !!_fp.metadata?.selected_price?.amount > 0 || !!_fp.metadata?.selected_price?.require_card;
        _fp.premium_wrapper.innerHTML = fpSetOrderSummaryTemplate(isCardPurchase);
        fpSetOrderSummaryTemplateListeners();
        if (isCardPurchase) {
            fpInitializePaymentMethodAsync(paymentFragmentEnum.OrderSummary);
        }


        fpTrackUserAction("fp_fragment_displayed", null, "order_summary", null, null);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "order_summary", null, error);
        fpErrorHandler("Order Summary Fragment", error);
    }
}