import { fpErrorHandler } from "./fp-error-handler";
import { fpDeleteStorageValue, fpGetStorageValue } from "./fp-storage-handler";
import { fpGetUrlParameterByName } from "./fp-url-handler";

export function fpSetSelectedPrice(prices) {
    try {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};

        if (_fp.metadata.selected_price) {
            return fpFindSelectedPriceInAvailablePrices(prices, _fp.metadata.selected_price.id);
        }

        const storagePropName = "fp.selected_price_id";
        let selectedPriceId = fpGetStorageValue(storagePropName);
        fpDeleteStorageValue(storagePropName);

        if (!selectedPriceId) {
            selectedPriceId = fpGetUrlParameterByName("price_id") ? fpGetUrlParameterByName("price_id") : null;
        }

        prices = prices ? prices : [];
        _fp.metadata.prices = prices;

        let selectedPrice = selectedPriceId ? prices.find(x => x.id === selectedPriceId) : null;
        if (!!_fp.user && !!_fp.user.access_token) {
            _fp.metadata.selected_placeholder_price = !!selectedPrice && !!selectedPrice.placeholder ? prices.find(x => x.id === selectedPriceId) : null;
            if (!!selectedPrice && !!selectedPrice.placeholder && !!selectedPrice.placeholder_prices && selectedPrice.placeholder_prices.length > 0) {
                _fp.metadata.prices = selectedPrice.placeholder_prices;
            }
        }

        _fp.metadata.selected_price = selectedPrice;
        return selectedPrice;
    } catch (error) {
        fpErrorHandler("Set Selected Price", error);
        delete _fp.metadata.selected_price;
        return null;
    }
}

export function fpFindSelectedPriceInAvailablePrices(prices, selectedPriceId) {
    const availablePrices = prices ?? _fp.metadata.prices;

    // Attempt to find the selected price directly
    let selectedPrice = availablePrices?.find(price => price.id === selectedPriceId);

    // If not found, look for it in placeholder prices
    if (!selectedPrice) {
        for (const price of availablePrices) {
            const placeholderPrices = price.placeholder_prices ?? [];
            selectedPrice = placeholderPrices.find(p => p.id === selectedPriceId);
            if (selectedPrice) { break; }
        }
    }

    return selectedPrice;
}