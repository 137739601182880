import { fpErrorHandler } from "../../../core/utils/fp-error-handler";

export function fpMemberCenterPopulateItems(templateHTML, customerDetails) {
    try {
        const templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");
        const elements = {
            no_items: templateDOM.querySelector("[data-fp-element='mc_no_items']"),
            items_list: templateDOM.querySelector("[data-fp-element='mc_items_list']")
        };

        let items = customerDetails.purchases && customerDetails.purchases.length > 0
            ? customerDetails.purchases.filter(x => !!x.code)
            : null;


        if (!items) {
            elements.items_list?.remove();
            return templateDOM.querySelector("body").innerHTML;
        }
        
        elements.no_items?.remove();

        const tableEl = elements.items_list.querySelector("[data-fp-element='mc_items_list_table']");
        const tableHeadColumns = [];
        tableEl.querySelectorAll("[data-fp-attribute]")?.forEach(
            el => el.dataset.fpAttribute
                ? tableHeadColumns.push(el.dataset.fpAttribute)
                : null);

        let tableBodyRowsHtml = "";
        items.forEach((item, ind) => {
            let bodyCols = "";
            tableHeadColumns.forEach(headColumn => {
                bodyCols += `
                <td>
                    ${headColumn === "index"
                        ? (ind + 1)
                        : headColumn === "link"
                            ? `<a href="${item[headColumn]}">Click Here</a>`
                            : item[headColumn]
                    }
                </td>`;
            });

            tableBodyRowsHtml += `<tr>${bodyCols}</tr>`;
        });

        const tableBodyEl = tableEl.querySelector("[data-fp-element='mc_items_list_table_body']");
        tableBodyEl.innerHTML = tableBodyRowsHtml;

        return templateDOM.querySelector("body").innerHTML;
    } catch (error) {
        fpErrorHandler("Member Center Populate Items", error);
        return "";
    }
}