export function fpSetDeliveryAddress(formData) {
    const { address, address_2, city, state, country, postal_code, zip_code, country_code } = formData || {};
    const { first_name, last_name } = _fp.user?.billing_details || {};

    const deliveryAddress = {
        address: null,
        address_1: address,
        address_2: city,
        address_3: state,
        address_4: country,
        address_5: address_2,
        post_code: postal_code || zip_code,
        country_code: country_code,
    };

    if (address_2) {
        deliveryAddress.address_1 = `${deliveryAddress.address_1} ${address_2}`;
    }

    deliveryAddress.address = Object.values(deliveryAddress).join(" ");
    deliveryAddress.phone_number = formData?.phone_number ?? "not set";

    if (first_name || last_name) {
        deliveryAddress.user_name = `${first_name ?? ""} ${last_name ?? ""}`;
    } else if (_fp?.user?.billing_details?.name) {
        deliveryAddress.user_name = _fp.user.billing_details.name;
    }
    
    return deliveryAddress;
}