import { fpSetStripeExpressCheckoutElement } from "./fp-stripe-express-checkout-element";
import { fpSetStripePaymentElement } from "./fp-stripe-payment-element";

export function fpSetStripeOtherPaymentElements(type, data) {
    const otherPaymentElements = {
        express_checkout: ["google_pay", "apple_pay"].find(x => data.payment_method_type.includes(x)),
        payment: ["paypal", "revolut_pay"].find(x => data.payment_method_type.includes(x)),
    };

    if (!otherPaymentElements.express_checkout && !otherPaymentElements.payment) {
        return;
    }

    const isSet = fpSetStripeOtherPaymentsHTML(otherPaymentElements);
    if (!isSet) {
        return;
    }

    // Apple/Google pay
    fpSetStripeExpressCheckoutElement(type, data);

    // Paypal, Revoult
    fpSetStripePaymentElement(type, data);
}

function fpSetStripeOtherPaymentsHTML(data) {
    const el = document.querySelector("[data-fp-element='other-payment-options'");
    if (!el) {
        return false;
    }

    let html = `
    <style>
        [data-fp-element='stripe_payment_form'],
        [data-fp-element='google_apple_pay']:not(.available),
        [data-fp-element='payment_element']:not(.available) {display: none;}
    </style>
    `;

    html += data.express_checkout ? "<div data-fp-element='google_apple_pay'></div>" : "";
    if (data.payment) {
        html += `
        <div data-fp-element='payment_element'>
            <div data-fp-element='paypal'></div>
            <div data-fp-element='revolut_pay'></div>
            <div data-fp-element='stripe_payment_form' type='form'></div>
        </div>
        `;
    }

    el.innerHTML = html;
    return true;
}