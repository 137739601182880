import { fpGetJourneyConfig } from "../../utils/fp-get-journeys-config";
import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpGetCustomerActiveAccessAsync() {
    try {
        // Check if the user details are stored in session storage
        // let userDetails = sessionStorage.getItem("fp.user");
        // userDetails = userDetails ? JSON.parse(userDetails) : null;

        // if (!refreshDetails
        //     && userDetails
        //     && _fp.user.access_token === userDetails.access_token
        //     && _fp.user.access_token_expired === userDetails.access_token_expired) {
        //     _fp.user = userDetails;
        //     return Promise.resolve({ already_set: true });
        // }

        // Call BE to get all of the user details
        if (!_fp?.user?.access_token) {
            // Return empty promise as the default option
            return Promise.resolve(null);
        }
        const fpJourneyConfig = fpGetJourneyConfig();
        let groupCodeParam = `?item_group_code=${fpJourneyConfig?.group_code}`;
        if (_fp.config.client.root_group_code) {
            groupCodeParam = `?parent_item_group_code=${_fp.config.client.root_group_code}`;
        }

        return await fpSendRequest("GET", (`${_fp.config.base_url_transaction}/customers/me/active_access${groupCodeParam}`));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
