import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetRegistrationwallTemplateListeners } from "./fp-set-registrationwall-fragment-listeners";
import { fpSetRegistrationwallTemplate } from "./fp-set-registrationwall-fragment-template";

export function fpShowRegistrationwallFragment() {
    try {
        const journey = fpGetJourneyConfig();
        const query = journey?.trigger?.query ?? _fp.premium_wrapper_selector;
        if (!query) {
            console.error("FP:: missing query for regwalled elements");
            return;
        }

        const regwalledElements = document.querySelectorAll(query);
        regwalledElements?.forEach(el => el.innerHTML = fpSetRegistrationwallTemplate());
        fpSetRegistrationwallTemplateListeners();

        fpTrackUserAction("fp_fragment_displayed", null, "registrationwall", null, null);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "registrationwall", null, error);
        fpErrorHandler("Registrationwall Fragment", error);
    }
}