/* eslint-disable no-unused-vars */
import { fpSetCartAccountListeners } from "./views/account/fp-set-cart-account-listeners";
import { fpSetCartItemsListeners } from "./views/items/fp-set-cart-items-listeners";
import { fpSetCartPaymentListeners } from "./views/payment/fp-cart-payment-listeners";

export function fpSetCartTemplateListeners(type) {
    if (type === "items") {
        fpSetCartItemsListeners();
    } else if (type === "account") {
        fpSetCartAccountListeners();
    } else if (type === "payment") {
        fpSetCartPaymentListeners();
    }
}