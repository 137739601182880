
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpSetStripeCardElement } from "./utils/fp-stripe-card-element";
import { fpSetStripeOtherPaymentElements } from "./utils/fp-stripe-set-other-payment-methods";

export function fpStripe24InitPaymentElements(type, data) {
    if (!data) { return; }

    const clientSecret = type === "setup" ? data?.setup_client_secret : data?.payment_client_secret;
    if (!clientSecret) { return; }

    const stripeProvider = _fp.payment_providers.stripe24;
    const stripeInstance = stripeProvider.stripe.elements({ clientSecret });
    stripeProvider[type] = { ...data, elements: stripeInstance };

    const formSelector = type === "setup" ? "[data-fp-form-type='update_payment_method']" : "form";
    const formEl = type === "setup"
        ? document.querySelector(formSelector)
        : (_fp.premium_wrapper?.nodeName === "FORM"
            ? _fp.premium_wrapper
            : _fp.premium_wrapper.querySelector(formSelector));

    // Debit, credit cards    
    fpSetStripeCardElement(stripeProvider[type], formEl);

    // Google/Apple pay, Paypal, Revolut...
    fpSetStripeOtherPaymentElements(type, stripeProvider[type]);

    fpSpinnerHandler("hide");
}