import { journeyTypeEnum } from "../enums/fp-journey-type.enum";
import { fpShowAuthenticationFragment } from "../../fragments/authentication/fp-authentication-fragment";
import { fpNavigationOnClick } from "../../fragments/navigation/fp-navigation-fragment";

export function fpSetGlobalEventListeners() {
    document.addEventListener("fp.user_action", (event) => {
        if (!event?.detail?.type) {
            return;
        }

        if (["sign_in", "sign_up", "sign_out"].includes(event.detail.type)) {
            const actionType = event.detail.type === "sign_in" && !!_fp?.user?.access_token ? "member_center" : event.detail.type;
            fpNavigationOnClick(actionType);
        }
    });


    document.addEventListener("fp.authenticate_user", () => fpShowAuthenticationFragment(journeyTypeEnum.SignUp));
}