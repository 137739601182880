import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { errorMessageEnum } from "../../../core/enums/fp-error-message.enum";
import { fpShowAuthenticationFragment } from "../../authentication/fp-authentication-fragment";

export function fpFormHandleErrorMessage(errorResponse) {
    let errorMessage = "";
    if (errorResponse?.code === "email_verification_required") {
        errorMessage = errorMessageEnum.EmailVerificationRequired;
        fpShowAuthenticationFragment(authTypeEnum.VerifyEmail, null);
    } else if (errorResponse?.code === "duplicate") {
        errorMessage = errorMessageEnum.Duplicate;
    }

    const errEl = document.querySelector("[data-fp-element='form-error-message']");
    if (errEl) {
        errEl.innerHTML = errorMessage;
    }
}

