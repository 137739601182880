import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { homeDeliveryTypeEnum } from "../../core/enums/fp-home-delivery-type.enum";
import { fpInitializeAddressAutocompleteProviderAsync } from "../../providers/address-autocomplete/fp-initialize-address-autocomplete";
import { fpShowHomeDeliveryFragment } from "./fp-home-delivery-fragment";
import { fpShowOrderSummaryFragment } from "../order-summary/fp-order-summary-fragment";
import { fpSortByInteger } from "../../core/utils/fp-sort-by-integer";
import { fpShowGiftRedeemFragment } from "../gift-redeem/fp-gift-redeem-fragment";

export async function fpSetHomeDeliveryTemplateListeners(templateName) {
    try {
        const templateSettings = _fp.config.fragments?.home_delivery_fragment?.settings;
        if (templateSettings?.autocomplete?.enabled && templateName !== homeDeliveryTypeEnum.Form) {
            const isInitialized = await fpHomeDeliveryInitAddressAutocompleteAsync(templateSettings);
            if (!isInitialized) {
                return "";
            }
        }

        if (_fp.user?.address && templateName === homeDeliveryTypeEnum.Form) {
            Object.keys(_fp.user.address).forEach(key => {
                const inputEl = document.querySelector(`[data-fp-element='home_delivery_form'] input[name='${key}'`);
                if (inputEl) {
                    inputEl.value = _fp.user.address[key];
                }
            });
        }

        const formEl = document.querySelector("[data-fp-element='home_delivery_form']");
        if (!formEl) {
            console.error("FP:: Missing form element form[data-fp-element='home_delivery_form']");
            return;
        }

        formEl.removeEventListener("submit", fpHomeDeliveryFormOnSubmit, false);
        formEl.addEventListener("submit", fpHomeDeliveryFormOnSubmit, false);

        const userActionEl = document.querySelector("[data-fp-element='user_action']");
        userActionEl?.addEventListener("click", (evt) => fpShowHomeDeliveryFragment(evt.currentTarget.dataset.fpAttribute), false);
    } catch (error) {
        fpErrorHandler("Set Home Delivery Template Listeners", error);
    }
}

export async function fpHomeDeliveryInitAddressAutocompleteAsync(templateSettings) {
    const autocompleteInputEl = document.querySelector("[data-fp-element='address_autocomplete']");
    if (!autocompleteInputEl) {
        console.error("FP:: Missing form element form[data-fp-element='address_autocomplete']");
        return false;
    }

    if (!templateSettings?.autocomplete?.provider || !templateSettings?.autocomplete?.key) {
        console.error("FP:: Missing autocomplete mandatory details in configuration");
        return false;
    }

    await fpInitializeAddressAutocompleteProviderAsync(templateSettings.autocomplete);
    return true;
}

function fpHomeDeliveryFormOnSubmit(evt) {
    evt.preventDefault();

    if (evt.currentTarget.dataset.fpAttribute === homeDeliveryTypeEnum.Autocomplete) {
        fpShowHomeDeliveryFragment(homeDeliveryTypeEnum.Form);
    } else {
        _fp.user.address = _fp.user.address ? _fp.user.address : {};
        const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());
        Object.keys(formData).forEach((key) => _fp.user.address[key] = formData[key]);

        fpHomeDeliverySetFullAddress(_fp.user.address);
        fpHomeDeliverySetAvailablePlans();

        if (_fp.premium_wrapper?.dataset?.gift === "redeem") {
            fpShowGiftRedeemFragment("home_delivery");
            return;
        }

        fpShowOrderSummaryFragment();
    }
}

export function fpHomeDeliverySetFullAddress(address) {
    address.full = "";
    address.full += address.address_5 ? (address.address_5 + ", ") : "";
    address.full += address.address_1 ? (address.address_1 + ", ") : "";
    address.full += address.address_2 ? (address.address_2 + ", ") : "";
    address.full += address.address_3 ? (address.address_3 + ", ") : "";
    address.full += address.post_code ? (address.post_code + ", ") : "";
    address.full += address.address_4 ? address.address_4 : "";
}

function fpHomeDeliverySetAvailablePlans() {
    if (!_fp.metadata.selected_price.placeholder && !_fp.metadata.selected_placeholder_price) {
        return;
    }

    if (!_fp.metadata.selected_placeholder_price) {
        _fp.metadata.selected_placeholder_price = JSON.parse(JSON.stringify(_fp.metadata.selected_price));
    }

    _fp.metadata.prices = _fp.metadata.selected_placeholder_price.placeholder_prices;

    const settings = _fp.config.fragments.home_delivery_fragment.settings;
    if (settings?.special_offers?.enabled && settings?.special_offers?.offers?.length > 0 && _fp.user?.address?.post_code) {
        const offersBadges = settings?.special_offers?.offers?.map(x => x.badge);
        const offer = settings?.special_offers?.offers.find(x => x.post_codes.includes(_fp.user?.address?.post_code));
        _fp.metadata.prices = !offer
            ? _fp.metadata.prices.filter(x => !offersBadges.find(ob => x.badge_text.includes(ob)))
            : _fp.metadata.prices.filter(x => x.badge_text.includes(offer.badge));
    }

    _fp.metadata.prices = _fp.metadata.prices.sort(fpSortByInteger("position", "asc"));
    _fp.metadata.selected_price = _fp.metadata.prices[0];
}