import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseUpdateDeliveryAddress(purchaseId, contactDetails) {
    try {
        const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/contact_info`;
        await fpSendRequest("PUT", url, JSON.stringify(contactDetails));
        return true;
    } catch (err) {
        console.error("FP::", err);
        return false;
    }
}
