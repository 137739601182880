import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseChangeRenewalPriceAsync(purchaseId, priceId) {
  try {
    const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/change_renewal_price`;
    await fpSendRequest("POST", url, JSON.stringify({ renewable_price_id: priceId }));
    return true;
  } catch (err) {
    console.error("FP::", err);
    throw err;
  }
}
