import { fpErrorHandler } from "../utils/fp-error-handler";
import { fpSendRequest } from "../utils/fp-http-request";

export async function fpAccessRequestAsync(request) {
    try {
        return await fpSendRequest("POST", `${_fp.config.base_url_transaction}/access`, JSON.stringify(request), false, "application/json;");
    } catch (error) {
        fpErrorHandler("BE Access Request", error);
        throw error;
    }
}