import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpDeleteStorageValue } from "../../../core/utils/fp-storage-handler";
import { fpDispatchCommonAuthCustomEvent } from "../fp-auth-handler";

export function fpAuthFlipPaySignOut() {
    try {
        // Clear the global variable
        const keysToExclude = ["config", "premium_wrapper"];
        const keys = Object.keys(_fp);
        keys.forEach(key => keysToExclude.includes(key) ? null : delete _fp[key]);

        // Clear a browser storage
        const storePropsToRemove = ["fp.user", "fp.subscriber", "fp_username", "fp.browser_session_id", "fp.selected_price", "fp.selected_price_id", "fp.browser_session_id"];
        storePropsToRemove.forEach(prop => fpDeleteStorageValue(prop));

        if (!_fp?.config?.authentication?.disable_signout_reload) {
            window.location.reload();
            return;
        }

        fpDispatchCommonAuthCustomEvent(authTypeEnum.SignOut);
    } catch (error) {
        fpErrorHandler("Flip-Pay Sign Out", error);
    }
}
