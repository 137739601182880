
import { fpInitializeConfigurationAsync } from "./configuration/fp-initialize-configuration";
import { fpInitializeAuthenticationAsync } from "./providers/authentication/fp-auth-handler";
import { fpInitializeCustomerJourney } from "./journeys/fp-initialize-journey";
import { fpSetGlobalEventListeners } from "./core/utils/fp-global-event-listeners";
import { fpErrorHandler } from "./core/utils/fp-error-handler";
import { fpSetGlobalMethods } from "./core/utils/fp-global-methods";

(function () {
    window._fp = !window._fp ? {} : window._fp;

    async function flipPayOnLoad() {
        try {
            await fpInitializeConfigurationAsync();
            await fpInitializeAuthenticationAsync();

            fpSetGlobalMethods(flipPayOnLoad);
            fpSetGlobalEventListeners();

            fpInitializeCustomerJourney();
        } catch (err) {
            fpErrorHandler("On Load", err);
        }
    }

    // Define when the script should start with execution
    if (document.readyState === "loading") {
        document.addEventListener("DOMContentLoaded", flipPayOnLoad);
    } else {
        flipPayOnLoad();
    }
})();