import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpAuthFlipPaySignOut } from "../../../providers/authentication/flip-pay/fp-clear-user-details";
import { fpInjectAuthFlipPayFragment, fpSetAuthModalVisibility } from "./fragments/fp-auth-fragment-wrapper";
import { fpAuthFlipPayDirect } from "./fragments/fp-auth-direct";
import { userAction } from "../../../core/enums/fp-user-action.enum";

export function fpAuthFlipPaySelectJourney(type) {
    try {
        fpInjectAuthFlipPayFragment();

        switch (type) {
            case authTypeEnum.SignIn:
            case authTypeEnum.SignUp:
            case authTypeEnum.RequestResetPassword:
            case authTypeEnum.ResetPassword:
            case authTypeEnum.VerifyEmail:
                fpAuthFlipPayDirect(type);
                break;
            case authTypeEnum.SignOut:
                [userAction.AuthFragmentClick].includes(_fp?.metadata?.user_action) ? delete _fp?.metadata?.user_action : null;
                fpAuthFlipPaySignOut();
                break;
            case authTypeEnum.AuthClose:
                [userAction.AuthFragmentClick].includes(_fp?.metadata?.user_action) ? delete _fp?.metadata?.user_action : null;                
                fpSetAuthModalVisibility("hide");
                break;
            default:
                break;
        }
    } catch (error) {
        fpErrorHandler("FlipPay Auth Handler", error);
    }
}

