export function fpClearNotUsedAnchors(template) {
    let anchorsToReplace = template.match(/(?:>>)(.*?)(?:<<)|(?:{{)(.*?)(?:}})/gs);
    if (!!anchorsToReplace && anchorsToReplace.length > 0) {
        anchorsToReplace.forEach(anchor => {
            let anchorName = anchor.replaceAll(">", "").replaceAll("<", "").replaceAll("{", "").replaceAll("}", "");
            let anchorValue = "";
            template = template.replaceAll(`>>${anchorName}<<`, anchorValue).replaceAll(`{{${anchorName}}}`, anchorValue);
        });
    }

    return template;
}