import { fpErrorHandler } from "../core/utils/fp-error-handler";
import { fpSetPremiumElement } from "../core/utils/fp-set-premium-element";
import { fpGetCustomerAccessStatusAsync } from "../access/fp-access";
import { fpShowMemberCenterFragment } from "../fragments/member-center/fp-member-center-fragment";
import { fpSetJourneyTypeAsync } from "./fp-set-journey-type";
import { fpTriggerJourney } from "./fp-trigger-journey";
import { fpSetBrowserSessionId } from "../core/utils/fp-set-session-id";
import { fpRemoveUrlParamenterByName } from "../core/utils/fp-url-handler";
import { journeyTypeEnum } from "../core/enums/fp-journey-type.enum";
import { memberCenterTypeEnum } from "../core/enums/fp-member-center-type.enum";
import { fpAuthenticationActionAsync } from "../providers/authentication/fp-auth-actions";
import { fpInitializeAuthenticationAsync } from "../providers/authentication/fp-auth-handler";
import { fpSpinnerHandler } from "../core/utils/fp-spinner";
import { fpExecutePurchaseAsync } from "../core/requests/purchase/fp-execute-purchase";
import { fpTrackUserAction } from "../core/utils/fp-tracking";
import { fpConfirmSetupIntentAsync } from "../core/requests/purchase/fp-payment-intents";
import { fpSnackbarMessageHandler } from "../core/utils/fp-snackbar";
import { fpGetJourneyConfig } from "../core/utils/fp-get-journeys-config";
import { fpIsMemberCenterPage } from "../core/utils/fp-is-member-center-page";

export async function fpInitializeCustomerJourney() {
  try {
    fpSetBrowserSessionId();

    const finishOtherFlow = await fpIsOtherFlowToBeFinishedAsync();
    if (finishOtherFlow) {
      fpSpinnerHandler("hide", null);
      return;
    }

    fpSetPremiumElement();
    const journeyConfig = fpGetJourneyConfig();
    if (!_fp.premium_wrapper || _fp?.config?.flow_type === journeyTypeEnum.Manual) {
      fpSpinnerHandler("hide", null);
      return;
    }

    if (journeyConfig?.type === journeyTypeEnum.MarketingMessage) {
      fpSpinnerHandler("hide", null);
      fpTriggerJourney(journeyTypeEnum.MarketingMessage);
      return;
    }

    if (journeyConfig?.type === journeyTypeEnum.Dynamic) {
      const setJourneyDynamicType = new Function(`return ${journeyConfig.type_definition}`)();
      setJourneyDynamicType();
    }

    const accessStatus = await fpGetCustomerAccessStatusAsync();
    if (!accessStatus) {
      fpSpinnerHandler("hide", null);
      return;
    }

    fpSpinnerHandler("hide", null);
    fpTriggerJourneyByAccessStatusAsync(accessStatus);
  } catch (error) {
    fpSpinnerHandler("hide", null);
    fpErrorHandler("Initialize Customer Journey", error);
  }
}

export async function fpTriggerJourneyByAccessStatusAsync(accessStatus) {
  const journeyType = await fpSetJourneyTypeAsync(accessStatus);
  fpTriggerJourney(journeyType);
}

export async function fpIsOtherFlowToBeFinishedAsync() {
  const urlParams = Object.fromEntries(new URLSearchParams(location.search));

  if (urlParams.payment_intent && urlParams.payment_intent_client_secret && urlParams.purchase_id) {
    return fpFinishPurchaseFlowAsync(urlParams);
  }

  if (urlParams.setup_intent && urlParams.setup_intent_client_secret) {
    return fpFinishUpdatePaymentMethodFlowAsync(urlParams);
  }

  if (!!urlParams.reset_password && !_fp.user?.access_token) {
    fpTriggerJourney(journeyTypeEnum.ResetPassword);
    return true;
  }

  if (urlParams.verify_email) {
    fpAuthVerifyEmailAsync();
    return true;
  }

  if (fpIsMemberCenterPage()) {
    fpShowMemberCenterFragment(urlParams.mcc, urlParams.mca ?? null);
    return true;
  }

  return false;
}

export async function fpAuthVerifyEmailAsync() {
  fpSpinnerHandler("show", null);
  const response = await fpAuthenticationActionAsync("verify_email");
  fpRemoveUrlParamenterByName("verify_email");
  fpInitializeAuthenticationAsync(response);
  fpInitializeCustomerJourney();
  fpSpinnerHandler("hide", null);
}

export async function fpFinishPurchaseFlowAsync(urlParams) {
  ["anonymous", "payment_intent", "payment_intent_client_secret", "payment_method_type", "price_id", "purchase_id", "redirect_status"].forEach((param) => fpRemoveUrlParamenterByName(param));

  fpSetPremiumElement();

  if (urlParams.redirect_status === "failed") {
    fpSnackbarMessageHandler(null, "Action not successful, please try again.", "error");
    return false;
  }

  fpSpinnerHandler("show");
  if (urlParams.anonymous === "true" && (!_fp?.user?.access_token)) {
    await fpGetCustomerAccessStatusAsync();
    _fp.metadata.selected_price = _fp.metadata.prices.find(x => x.id === urlParams.price_id);
  }

  const executeResponse = await fpExecutePurchaseAsync(urlParams.purchase_id, { payment_intent_id: urlParams.payment_intent });
  _fp.metadata.purchase = executeResponse;

  fpTrackUserAction("fp_purchase_resolved", null, "purchase", executeResponse.id, executeResponse);
  fpTriggerJourney(journeyTypeEnum.PremiumContent);
  return true;
}

export async function fpFinishUpdatePaymentMethodFlowAsync(urlParams) {
  ["payment_provider", "setup_intent_id", "setup_intent", "setup_intent_client_secret", "payment_method_type", "price_id", "purchase_id", "redirect_status"].forEach((param) => fpRemoveUrlParamenterByName(param));

  if (urlParams.redirect_status === "failed") {
    fpSnackbarMessageHandler(null, "Action not successful, please try again.", "error");

    const memberCenterSettings = _fp.config.fragments?.member_center_fragment?.settings;
    if (memberCenterSettings?.type === memberCenterTypeEnum.Inline && window.location.pathname === memberCenterSettings?.location_url) {
      fpShowMemberCenterFragment();
      return true;
    }

    return false;
  }

  fpSpinnerHandler("show");

  const response = await fpConfirmSetupIntentAsync(urlParams.setup_intent_id, urlParams.setup_intent, urlParams.payment_provider, urlParams.payment_method_type);
  if (!response || response.error) {
    // TODO: error handler
  }

  window.location.reload();
  return true;
}