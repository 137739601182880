import { authErrorEnum } from "../enums/fp-auth-error.enum";

export function fpValidatePassword(formData) {
    try {
        const anonAllowed = !!_fp.metadata?.selected_price?.allow_anonymous;
        const password = formData.password ? formData.password : formData.new_password;
        const confirPasswordRequired = Object.keys(formData)?.find(key => ["password_confirm", "confirm_password"].includes(key));
        const confirmPassword = formData.password_confirm ? formData.password_confirm : formData.confirm_password;

        if (!password) { return { valid: anonAllowed }; }

        // Password and confirm pasword needs to be the same
        // ----------------------------------------------------------
        if (confirPasswordRequired && password !== confirmPassword) {
            return { valid: false, error_message: authErrorEnum.PasswordConfirmPasswordNoMatch };
        }

        // Password length needs to be at least 8 char long
        // ----------------------------------------------------------
        if (password.length < 8) {
            return { valid: false, error_message: authErrorEnum.PasswordMinLength };
        }

        // Password needs to contain number or symbol
        // ----------------------------------------------------------
        // if (!RegExp(/^(?=.*[!@#$%^&*])/u).test(password) &&
        //     !RegExp(/^(?=.*[0-9])/u).test(password)) {
        //     erroMessages.push("Password must contain numbers and symbols");
        //     isValid = false;
        // }

        return { valid: true, error_message: null };
    } catch (err) {
        throw new Error(err);
    }
}