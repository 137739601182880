import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpGetCustomerMeAsync() {
    try {
        return await fpSendRequest("GET", (`${_fp.config.base_url_transaction}/customers/me`));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
