import { fpCreatePurchaseAsync } from "../../../../core/requests/purchase/fp-create-purchase";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpAuthorizeNetOnError } from "./fp-anet-error-handler";

export async function fpProcessPaymentWithAuthorizeNetAsync(cardToken) {
    try {
        const request = {
            purchase_intent_id: _fp.payment_providers?.authorize_net?.purchase_intent_id ?? null,
            payment_token: cardToken,
            payment_method_type: "card",
        };

        _fp.metadata.purchase = await fpCreatePurchaseAsync(request);
        fpTrackUserAction("fp_purchase_resolved", null, "purchase", _fp.metadata.purchase.id, _fp.metadata.purchase);
        fpSpinnerHandler("hide", null);

        return { success: true };
    } catch (err) {
        fpSpinnerHandler("hide", null);
        fpTrackUserAction("fp_error", null, "purchase", null, "Generic error");
        fpAuthorizeNetOnError("set", err);
        return { success: true, err: err };
    }
}