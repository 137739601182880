import { fpConfirmSetupIntentAsync } from "../../../../core/requests/purchase/fp-payment-intents";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";

export async function fpUpdateAuthorizeNetPaymentMethodAsync(cardToken) {
    try {
        fpSpinnerHandler("show");
        const data = _fp.payment_providers.authorize_net;
        if (!data) {
            return { success: false, err: { message: "Anet data not found" } };
        }

        const response = await fpConfirmSetupIntentAsync(_fp.metadata?.payment_providers?.setup?.id, cardToken, "authorize_net", "card");
        fpTrackUserAction("fp_update_payment_method_resolved", null, "update_payment_method", _fp.metadata.purchase.id, _fp.metadata.purchase);

        if (!response || response.error) {
            fpSpinnerHandler("hide");
            return { success: false, err: response.error };
        }
        
        fpSpinnerHandler("hide");
        return { success: true };
    } catch (err) {        
        fpSpinnerHandler("hide");
        fpTrackUserAction("fp_error", null, "fp_update_payment_method_error", null, "Generic error");
        return { success: false, err: err };
    }
}