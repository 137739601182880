import { fpAuthFlipPaySelectJourney } from "./fp-auth-journey";
import { fpAuthFlipPaySocialsHandler } from "./handlers/fp-socials-handler";
import { fpAuthFlipPayFormHandler } from "./handlers/fp-form-handler";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../../core/utils/fp-tracking";
import { fpAuthSetCustomOptions } from "../../../core/utils/fp-set-custom-auth-options";
import { fpInitMultiselect } from "../../../core/utils/fp-multiselect";
import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { userAction } from "../../../core/enums/fp-user-action.enum";
import { fpSetPinCodeListener } from "../../../core/utils/fp-set-pin-code-listener";

export function fpAuthFlipPaySetListeners() {
  try {
    const authWrapEl = document.querySelector("#fp-auth-content");
    if (!authWrapEl) {
      console.error("FP:: Missing Auth Content Wrapper");
      return;
    }

    // Define on clear action
    const clearEls = document.querySelectorAll("[data-fp-element='clear']");
    clearEls?.forEach(el => el.addEventListener("click", fpClearAuthWrapperElement, false));

    const authTypesEls = authWrapEl.querySelectorAll("[data-fp-attribute]");
    if (authTypesEls) {
      authTypesEls.forEach((at) => at.addEventListener("click", (e) => fpAuthFlipPayHandleUserAction("show_username_password_authentication", e), false));
    }

    const socialProviders = authWrapEl.querySelectorAll("[data-fp-provider]");
    if (socialProviders) {
      socialProviders.forEach((sp) => sp.addEventListener("click", (e) => fpAuthFlipPayHandleUserAction("show_social_authentication", e)));
    }

    const formEl = authWrapEl.querySelector("form");
    if (formEl) {
      formEl.addEventListener("submit", (e) => fpAuthFlipPayHandleUserAction("submit_auth_form", e), false);
    }

    const notifcationCloseEl = document.querySelector("[data-fp-element='auth_notification_close']");
    if (notifcationCloseEl) {
      notifcationCloseEl.addEventListener("click", (e) => fpAuthFlipPayCloseNotification(e), false);
    }

    const multiSelectEls = document.querySelectorAll("[data-fp-element='multiselect']");
    if (multiSelectEls) {
      multiSelectEls.forEach(el => fpInitMultiselect(el));
    }

    const pinCodeEl = document.querySelector("[data-fp-element='validation_pin_code']");
    if (pinCodeEl) {
      fpSetPinCodeListener(pinCodeEl);
    }

  } catch (error) {
    fpErrorHandler("Set Flip-Pay Auth Listener", error);
  }
}

function fpAuthFlipPayHandleUserAction(type, evt) {
  evt.preventDefault();
  fpTrackUserAction("fp_user_action", type, "authentication", null, null);
  fpAuthSetCustomOptions(type, evt.currentTarget);

  _fp.metadata.user_action = userAction.AuthFragmentClick;

  if (type === "show_username_password_authentication") {
    _fp.metadata.journey = evt.currentTarget.dataset.fpAttribute;
    _fp.metadata.auth_notification_displayed = _fp.metadata.journey === authTypeEnum.AuthClose ? null : _fp.metadata.auth_notification_displayed;

    fpAuthFlipPaySelectJourney(evt.currentTarget.dataset.fpAttribute);
  } else if (type === "show_social_authentication") {
    fpAuthFlipPaySocialsHandler(evt, evt.currentTarget.dataset.fpProvider);
  } else if (type === "submit_auth_form") {
    fpAuthFlipPayFormHandler(evt, evt.currentTarget);
  }
}

function fpAuthFlipPayCloseNotification(evt) {
  evt.preventDefault();

  const authNotificationWraperEl = document.querySelector("[data-fp-element='fp_auth_notification'");
  if (authNotificationWraperEl) {
    authNotificationWraperEl.remove();
  }
}

function fpClearAuthWrapperElement() {
  const authWrapperEl = document.querySelector("#fp-auth-main");
  authWrapperEl?.remove();
}