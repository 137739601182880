import { authTypeEnum } from "../enums/fp-auth-type.enum";
import { fpSetStorageValue } from "./fp-storage-handler";

export function fpAuthSetCustomOptions(type, currentEl, skipTypeCheck) {
    const formEl = ["show_username_password_authentication", "show_social_authentication"].includes(type) ? currentEl.closest("form") : currentEl;
    const formData = formEl ? Object.fromEntries(new FormData(formEl).entries()) : null;
    if (!skipTypeCheck && formData?.type !== authTypeEnum.SignUp) {
        return;
    }

    const formKeys = Object.keys(formData);
    const customOptionKey = "custom_option_";
    if (formKeys?.find(x => x.includes(customOptionKey))) {
        formData.custom_options = [];
        formKeys.forEach(key => {
            if (key.includes(customOptionKey)) {
                const newKey = key.replace(customOptionKey, "");
                formData.custom_options.push({ option: newKey, value: formData[key] });
                delete formData[key];
            }
        });
    }

    if (formData?.custom_options?.length > 0) {
        fpSetStorageValue("fp.auth.custom_options", formData.custom_options);
    }

    return formData;
}