import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpDeleteStorageValue, fpGetStorageValue, fpSetStorageValue } from "../../../core/utils/fp-storage-handler";
import { fpGetUrlParameterByName, fpRemoveUrlParamenterByName } from "../../../core/utils/fp-url-handler";
import { fpShowAuthenticationFragment } from "../../../fragments/authentication/fp-authentication-fragment";

export async function fpAuthFlipPaySetUserDetails(data) {
    try {
        if (data) {
            fpAuthFlipPayCompleteUserAuthentication(data);
            return;
        }

        await fpAuthFlipPayPopulateUserDetails();
    } catch (error) {
        fpErrorHandler("Auth FlipPay Set User Details", error);
    }
}

function fpAuthFlipPayCompleteUserAuthentication(data) {
    const storagePropName = "fp.user";
    if (!data) {
        fpDeleteStorageValue(storagePropName);
        return;
    }

    const user = {
        provider: data.provider ? data.provider : "web-direct"
    };

    Object.keys(data).forEach(key => user[key] = data[key]);
    if (user.expires_in) {
        user.expires_at_datetime = new Date((new Date().setSeconds(user.expires_in)));
        user.access_token_expiration_date_expired = user.expires_at_datetime < new Date();
    }

    _fp.user = _fp.user ? _fp.user : {};
    Object.keys(user).forEach(key => _fp.user[key] = user[key]);

    if (_fp.user.auth_type === authTypeEnum.SignUp && _fp.user.provider === "web-direct") {
        _fp.user.registered = true;
    }

    fpSetStorageValue(storagePropName, user);
}

async function fpAuthFlipPayPopulateUserDetails() {
    const storagePropName = "fp.user";
    let user = fpGetStorageValue(storagePropName);
    if (!user) {
        user = fpCheckUrlAuthParameters();
    }

    if (!user) {
        fpDeleteStorageValue(storagePropName);
        return;
    }

    // Redirect details for socials auth. Clear storage to be sure
    fpDeleteStorageValue("fp.redirect_details");

    // Check if it's auth flow i.e. a user is finishing social auth. 
    const isSocialAuthFlow = ["google", "facebook", "apple"].includes(user?.provider?.toLowerCase());
    if (!_fp?.metadata?.auth_flow && isSocialAuthFlow) {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.auth_flow = true;
    }

    if (!user.expires_at_datetime) {
        fpSetTokenExpirationDate(user);
    }

    // Store user details to cookie
    if (_fp.config.auth_settings?.provider) {
        user.provider = _fp.config.auth_settings.provider;
    }

    // Set global variable
    _fp.user = _fp.user ? _fp.user : {};
    _fp.user.id = user.id;
    _fp.user.user_id = user.user_id;
    _fp.user.subscriber = user.subscriber;
    _fp.user.registered = user.registered ?? false;
    _fp.user.id_token = user.id_token;
    _fp.user.access_token = user.access_token;
    _fp.user.access_token_expiration_date = user.expires_at_datetime;
    _fp.user.access_token_expired = new Date(user.expires_at_datetime) < new Date();
    _fp.user.provider = user.provider;

    // Set cookie variable
    fpSetStorageValue(storagePropName, user, user.expires_at_datetime);

    // Continue in the flow
    if (!!_fp.user && (!_fp.user.access_token_expiration_date || (_fp.user.expired || _fp.user.access_token_expired))) {
        fpShowAuthenticationFragment(authTypeEnum.SignOut);
        return;
    }

    return null;
}

function fpCheckUrlAuthParameters() {
    const authDetails = {};
    const authParams = ["access_token", "expires_in", "token_type"];
    authParams.forEach(param => {
        const value = fpGetUrlParameterByName(param);
        if (!value) {
            return;
        }

        authDetails[param] = value;
        fpRemoveUrlParamenterByName(param);
    });

    return (authDetails?.access_token && authDetails?.expires_in) ? authDetails : null;
}

function fpSetTokenExpirationDate(user) {
    if (user.expires_at) {
        user.expires_at_datetime = new Date(new Date().setSeconds(user.expires_at));
    } else if (user.expires_in) {
        user.expires_at_datetime = new Date(new Date().setSeconds(user.expires_in, true));
    } else if (!!user.expires_at_datetime && typeof user.expires_at_datetime === "string") {
        user.expires_at_datetime = new Date(user.expires_at_datetime);
    } else if (user.access_token_expiration_date) {
        user.expires_at_datetime = user.access_token_expiration_date;
    }
}