import { fpGetJourneyConfig } from "../../utils/fp-get-journeys-config";
import { fpSendRequest } from "../../utils/fp-http-request";
import { fpSpinnerHandler } from "../../utils/fp-spinner";


/**
 * @returns Payment intent object
 */
export async function fpCreatePaymentIntentAsync(purchaseId, actionType) {
    const fpJourneyConfig = fpGetJourneyConfig();

    try {
        fpSpinnerHandler("show", null);

        const request = {
            price_id: _fp.metadata?.selected_price?.id,
            payment_provider: _fp.config?.payment?.provider ?? "not_set",
            item_group_code: fpJourneyConfig?.group_code,
            item_group_id: _fp.metadata?.selected_price?.item_group_id ?? null,
            item_id: null,
            client_id: _fp.config.client.id,
            amount: parseFloat(_fp.metadata?.selected_price?.amount ?? 0),
            upgrade: actionType === "upgrade",
            ...(purchaseId && { original_purchase_id: purchaseId }),
        };


        return await fpSendRequest("POST", `${_fp.config.base_url_transaction}/purchase_intents`, JSON.stringify(request));
    } catch (err) {
        fpSpinnerHandler("hide", null);
        throw err;
    }
}

export async function fpUpdatePaymentIntentAsync(purchaseIntentId) {
    const fpJourneyConfig = fpGetJourneyConfig();

    try {
        fpSpinnerHandler("show", null);

        const request = {
            id: purchaseIntentId,
            client_id: _fp.config.client.id,
            price_id: _fp.metadata?.selected_price?.id,
            item_id: null,
            item_group_code: fpJourneyConfig?.group_code,
            payment_provider: _fp.config?.payment?.provider ?? "not_set",
            amount: parseFloat(_fp.metadata?.selected_price?.amount ?? 0)
        };

        const response = await fpSendRequest("POST", `${_fp.config.base_url_transaction}/purchase_intents/${purchaseIntentId}`, JSON.stringify(request));

        fpSpinnerHandler("hide", null);
        return response;
    } catch (err) {
        fpSpinnerHandler("hide", null);
        throw err;
    }
}

/**
 * @returns Setup intent object
 */
export async function fpCreateSetupIntentAsync(purchaseId) {
    try {
        const request = { purchase_id: purchaseId };
        return await fpSendRequest("POST", `${_fp.config.base_url_transaction}/payment_method_intents`, JSON.stringify(request));
    } catch (err) {
        fpSpinnerHandler("hide", null);
        throw err;
    }
}

export async function fpConfirmSetupIntentAsync(purchaseIntentId, itentId, paymentProvider, paymentMethodType) {
    try {
        const request = {
            setup_intent_id: itentId,
            payment_provider: paymentProvider,
            payment_method_type: paymentMethodType ?? "unknown"
        };

        return await fpSendRequest("POST", `${_fp.config.base_url_transaction}/payment_method_intents/${purchaseIntentId}/confirmed`, JSON.stringify(request));
    } catch (err) {
        fpSpinnerHandler("hide", null);
        throw err;
    }
}

