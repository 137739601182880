import { fpErrorHandler } from "./fp-error-handler";
import { fpSendRequest } from "./fp-http-request";

export function fpTrackUserAction(eventType, actionType, objectType, objectId, object, actionStatus) {
    try {
        if (!_fp.config) {
            return;
        }

        if (!!_fp.config?.tracking?.enabled && _fp.config.tracking?.types?.length > 0) {
            const request = fpSetTrackingRequestObject(eventType, actionType, objectType, objectId, object, actionStatus);
            // Custom event
            if (_fp.config.tracking.types.includes("event")) {
                document.body.dispatchEvent(new CustomEvent("fp_user_event", { bubbles: false, detail: request }));
            }

            // API
            if (_fp.config.tracking.types.includes("api")) {
                fpDispatchAPIEvent(eventType, request);
            }

            // GA
            if (_fp.config.tracking.types.includes("ga")) {
                fpDispatchGAEvent(eventType, objectType, object);
            }
        } else if (eventType === "purchase_failed") {
            const request = fpSetTrackingRequestObject(eventType, actionType, objectType, objectId, object, actionStatus);
            fpDispatchAPIEvent(eventType, request);
        }
    } catch (err) {
        fpErrorHandler("Track User Action", err);
    }
}

function fpSetTrackingRequestObject(eventType, actionType, objectType, objectId, object, actionStatus) {
    // const request = {
    //     event_type: (eventType ? eventType : "unknown"),
    //     vendor_id: _fp.config.client.id
    // };

    // if (_fp.metadata?.browser_session_id) {
    //     request.browser_session_id = _fp.metadata.browser_session_id;
    // }

    // if (actionType) {
    //     request.action_type = actionType;
    // }

    // if (objectType) {
    //     request.object_type = objectType;
    // }
    // if (objectId) {
    //     request.object_id = objectId;
    // }
    // if (object) {
    //     request.object = object;
    // }

    return {
        event_type: eventType || "unknown",
        vendor_id: _fp.config.client.id,
        ...(_fp.metadata?.browser_session_id && { browser_session_id: _fp.metadata.browser_session_id }),
        ...(actionType && { action_type: actionType }),
        ...(actionStatus && { action_status: actionStatus }),
        ...(objectType && { object_type: objectType }),
        ...(objectId && { object_id: objectId }),
        ...(object && { object: object }),
        ...(object?.message && { message: object.message })
    };
}

function fpDispatchAPIEvent(eventType, request) {
    const archiveEvents = _fp?.config?.tracking?.archive_events ?? ["purchase_failed"];
    const trackEvent = !!_fp?.config?.base_url_transaction && archiveEvents.includes(eventType);

    if (!trackEvent || eventType?.includes("fp_")) { // Event types with fp_ are not tracked by BE
        return;
    }

    if (eventType !== "purchase_failed") {
        delete request?.object_type;
        delete request?.object;
    }

    const url = `${_fp.config.base_url_transaction}/user_events`;
    fpSendRequest("POST", url, JSON.stringify(request)).then(function (response) {
        if (response) { return; }
    }).catch(function (err) {
        fpErrorHandler("trackUserAction", err);
    });
}

function fpDispatchGAEvent(eventType, objectType, object) {
    if (typeof gtag === "undefined") {
        return;
    }

    const type = eventType === "purchased" ? "purchase" : eventType;
    let params = {
        "ui_type": objectType,
        "user_registered": !!_fp && !!_fp.user && !!_fp.user.access_token
    };

    // Remove client id from the object as it's overriding the default configuration of gtag
    object = object ? object : {};
    delete object.client_id;
    delete object.clientId;

    if (type === "purchase") {
        params = object;
        params.ui_type = objectType;
        params.user_registered = !!_fp && !!_fp.user && !!_fp.user.access_token;
    }

    gtag("event", type, params);
}