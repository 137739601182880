import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../../../../core/enums/fp-payment-action.enum";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";
import { fpAuthorizeNetActionHandlerAsync } from "../fp-authorize-action-handler";
import { fpAuthorizeNetOnError } from "./fp-anet-error-handler";

export function fpAuthorizeNetInitPaymentMethod(type, data) {
    const paymentEl = document.querySelector("[data-fp-element='payment_wrapper']");
    if (!paymentEl) {
        console.error("FP:: Payment Fragment Missing -> [data-fp-element='payment-wrapper']");
        return false;
    }

    return fpAuthorizeNetSetCardFragmentHTML(type, data, paymentEl);
}

function fpAuthorizeNetSetCardFragmentHTML(type, data, paymentEl) {
    const authorizeNetWrapperEl = paymentEl.querySelector("[data-fp-element='authorizenet']");
    if (!authorizeNetWrapperEl) {
        return false;
    }

    authorizeNetWrapperEl.innerHTML = `
        <input type="hidden" name="dataValue" id="dataValue" />
        <input type="hidden" name="dataDescriptor" id="dataDescriptor" />
        <input type="hidden" name="expMonth" id="expMonth" />
        <input type="hidden" name="expYear" id="expYear" />

        <div style="display: flex; flex-direction: column; gap:8px;">
            <input style="flex:1; max-width: unset; margin: 0;" type="text" name="cardNumber" id="cardNumber" placeholder="Card Number" maxlength="19" oninput="formatCardNumber(this)"/> 
            <div style="display: flex; gap:10px;">
                <input style="flex:1;" type="text" id="cardExpiry" maxlength="5" placeholder="MM/YY" oninput="formatExpiry(this)">
                <input style="flex:1;" type="text" name="cardCode" id="cardCode" placeholder="CVV" maxlength="4"/> 
            </div>
        </div>

        <div data-fp-element='payment-error'></div>
        <button type="button" onclick="fpSendPaymentDataToAnet('${type}')">${type === "setup" ? "Update Payment Method" : "Complete Payment"}</button>
        `;

    window.formatExpiry = function (input) {
        let value = input.value.replace(/\D/g, ""); // Remove non-digits
        if (value.length === 0) {
            input.value = ""; // If input is empty, clear the field
            return;
        }

        // Handle deletion of "/" or general backspacing
        if (value.length <= 2) {
            input.value = value; // Only month entered or clearing the input
        } else if (value.length > 2) {
            input.value = value.substring(0, 2) + "/" + value.substring(2, 4); // Format as MM/YY
        }

        const formEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='subscription_actions_form']");
        ["#expMonth", "#expYear"].forEach((query, ind) => {
            const el = formEl?.querySelector(query);
            if (el) {
                el.value = input.value.includes("/") ? input.value.split("/")[ind] : "";
            }
        });
    };

    window.formatCardNumber = function (input) {
        let value = input.value.replace(/\D/g, ""); // Remove non-digit characters
        let formattedValue = "";

        // Add space after every 4 digits
        for (let i = 0; i < value.length; i += 4) {
            formattedValue += value.substring(i, i + 4) + " ";
        }

        input.value = formattedValue.trim(); // Trim any trailing space
    };

    window.fpSendPaymentDataToAnet = function (type) {
        fpSpinnerHandler("display");
        fpAuthorizeNetOnError("reset");

        const authData = {
            clientKey: _fp.payment_providers?.authorize_net?.credentials?.client_key,
            apiLoginID: _fp.payment_providers?.authorize_net?.credentials?.api_login_id
        };

        // authData.clientKey = "6GSp54hYZjcaJCVnmKDh526E4p6sULf64XTGZt3prp64fv9P7MBm6cZzy8B594wD";
        // authData.apiLoginID = "3ZyYn98c";

        const formEl = document.querySelector("[data-fp-element='payment-form'], [data-fp-element='subscription_actions_form']");
        const formData = Object.fromEntries(new FormData(formEl)?.entries());
        const cardData = {
            cardNumber: formData?.cardNumber?.trim()?.replaceAll(" ", ""),
            month: formData?.expMonth?.trim(),
            year: formData?.expYear?.trim(),
            cardCode: formData?.cardCode?.trim(),
        };

        const secureData = {
            authData: authData,
            cardData: cardData,
        };

        Accept.dispatchData(secureData, (response) => fpAnetResponseHandler(type, response));
    };

    return true;
}

async function fpAnetResponseHandler(type, response) {
    fpAuthorizeNetOnError("reset");
    if (!response || (response?.messages?.resultCode ?? "error").toLowerCase() === "error") {
        fpAuthorizeNetOnError("error", response?.messages?.message);
        fpSpinnerHandler("hide");
        return;
    }

    if (!response?.opaqueData?.dataValue) {
        console.error("FP:: No opaque data in the response");
        fpSpinnerHandler("hide");
        return;
    }

    const actionType = type === "setup" ? paymentActionEnum.UpdatePaymentMethod : paymentActionEnum.ProcessPayment;
    const res = await fpAuthorizeNetActionHandlerAsync(actionType, response.opaqueData.dataValue);
    fpSpinnerHandler("hide");

    if (!res.success) {
        console.error("FP:: User action not successful");
        fpAuthorizeNetOnError("error", "Action not successful, please try again later.");
        return;
    }

    if (type === "setup") {
        window.location.reload();
        return;
    }

    fpTriggerJourney(journeyTypeEnum.PremiumContent);
}