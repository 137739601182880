
export function fpRequestErrorHandler(err) {
    try {
        const errStatus = err?.status ? err.status : 500;
        const e = typeof err === "object" ? err : fpParseErrorObj(err.error);
        e.error = !!e.error && typeof e.error === "string" ? fpParseErrorObj(err.error) : null;

        const errorCode = e?.error?.code ? e.error.code : e?.code;

        let errorMessage = err?.message ? err.message : "Something went wrong, please try again later.";
        if (errStatus === 401) {
            errorMessage = "Not authenticated user";
        } else if (errorCode) {
            switch (errorCode) {
                case "invalid_password":
                    errorMessage = "Password is too weak.";
                    break;
                case "limit_exceeded":
                    errorMessage = "Number of retries exceeded, please try again later.";
                    break;
                case "price_not_found":
                    errorMessage = "No prices defined for the selected item group";
                    break;
                case "duplicate":
                    errorMessage = "An account is already registered with your email address";
                    break;
                case "invalid_username_or_password":
                    errorMessage = "You entered incorrect old password";
                    break;
                case "processing_error":
                    errorMessage = e.error.message ? e.error.message : "Processing Error";
                    break;
                default:
                    break;
            }
        }

        return {
            status: errStatus,
            code: e && e.error && e.error.code ? e.error.code : null,
            message: errorMessage
        };
    } catch (error) {
        return {
            status: 500,
            code: null,
            message: err && err.message ? err.message : "Something went wrong, please try again later."
        };
    }
}

function fpParseErrorObj(obj) {
    try {
        return JSON.parse(obj);
    } catch (error) {
        return null;
    }
}