import { fpErrorHandler } from "./fp-error-handler";

export function fpGetUrlParameterByName(paramName) {
    try {
        let url = decodeURIComponent(window.location.href);
        url = url.replace(/&amp;/g, "&");
        if (typeof url === "string") {
            let params = url.split("?");
            if (params.length === 1) {
                return undefined;
            }

            let eachParamsArr = params[1].split("&");
            let obj = {};
            if (eachParamsArr && eachParamsArr.length) {
                for (let ind = 0; ind < eachParamsArr.length; ind++) {
                    let param = eachParamsArr[ind];
                    let keyValuePair = param.split("=");
                    let key = keyValuePair[0];
                    let value = keyValuePair[1];
                    obj[key] = value;
                }
            }

            return obj[paramName];
        }
    } catch (error) {
        fpErrorHandler("Get Url Parameter", error);
        return null;
    }
}

export function fpRemoveUrlParamenterByName(parameter) {
    let url = document.location.href;
    const urlparts = url.split("?");
    if (urlparts.length >= 2) {
        const prefix = encodeURIComponent(parameter) + "=";
        const pars = urlparts[1].split(/[&;]/g);

        for (let i = pars.length; i-- > 0;) {
            if (pars[i].lastIndexOf(prefix, 0) !== -1) {
                pars.splice(i, 1);
            }
        }

        url = urlparts[0] + (pars.length > 0 ? "?" + pars.join("&") : "");
    }

    history.replaceState(null, "", url);
}