import { memberCenterTypeEnum } from "../enums/fp-member-center-type.enum";

export function fpIsMemberCenterPage() {
    const urlParams = Object.fromEntries(new URLSearchParams(location.search));
    const memberCenterSettings = _fp.config.fragments?.member_center_fragment?.settings;

    const isInlineMemberCenterType = [memberCenterTypeEnum.Inline, memberCenterTypeEnum.InlinePartial].includes(memberCenterSettings?.type);
    let isInlineMemberCenterPage = document.location.pathname === memberCenterSettings?.location_url;
    if (!isInlineMemberCenterPage && (memberCenterSettings?.locations_urls ?? []).length > 0) {
        isInlineMemberCenterPage = !!memberCenterSettings.locations_urls.find(url => url === document.location.pathname);
    }

    const isMemberCenterAction = !!urlParams.mcc;
    return !!isMemberCenterAction || (isInlineMemberCenterType && isInlineMemberCenterPage);
}