import { fpStripeOnError } from "./fp-stripe-error-handler";

/**
 * @param {*} data.type - payment or setup i.e. intent type
 * @returns 
 */
export function fpSetStripeCardElement(data, formEl) {
    const generateElement = ["card"].find(x => data.payment_method_type.includes(x));
    if (!generateElement || !formEl) {
        return;
    }

    const carElementSet = fpStripeSetCardElementFragmentHTML(data.type);
    if (!carElementSet) {
        return;
    }

    const cardElements = fpStripeSetCardElements(data.type);
    cardElements.forEach((element, ind) => {
        let stripeElement = data.elements.getElement(element.type);
        if (stripeElement) {
            stripeElement.destroy();
            stripeElement.unmount(element.query);
        }
        stripeElement = data.elements.create(element.type, element.configuration);
        if (document.querySelector(element.query)) {
            stripeElement.mount(element.query);
        }

        stripeElement.on("change", (evt) => fpStripeOnError(evt, ind), false);
    });
}

function fpStripeSetCardElementFragmentHTML(type) {
    const paymentEl = document.querySelector("[data-fp-element='payment_wrapper']");
    if (!paymentEl) {
        console.error("FP:: Payment Element Missing -> [data-fp-element='payment-wrapper']");
        return false;
    }

    const otherPaymentOptions = paymentEl.querySelector("[data-fp-element='other-payment-options']");
    if (otherPaymentOptions && !_fp.config?.payment?.allow_other_payment_method) {
        otherPaymentOptions.remove();
    }

    const cardWrapperEl = paymentEl.querySelector("[data-fp-element='card-payment']");
    if (cardWrapperEl) {
        if (_fp.config?.payment?.card_settings?.type !== "compact") {
            cardWrapperEl.innerHTML = `
            <div class='fp-card'>
                <div id='fp-${type}-card-number'></div>
                <div class='fp-${type}-card-details'>
                    <div id='fp-${type}-card-exp'></div>
                    <div id='fp-${type}-card-cvv'></div>
                </div>
            </div>`;
        } else {
            cardWrapperEl.innerHTML = `<div id='fp-${type}-card'></div>`;
        }
    }

    return true;
}

export function fpStripeSetCardElements(type) {
    const stripeElementConfigStyle = _fp.config?.payment?.card_settings?.style;
    let stripeElementDefaultStyle = {
        base: {
            iconColor: "#ccc",
            color: "#626262",
            backgroundColor: "#ffffff",
            fontFamily: "Questrial, sans-serif",
            fontWeight: 700,
            fontSize: "15px",
            textTransform: "uppercase",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": {
                color: "#626262",
            },
            "::placeholder": {
                color: "#9F9F9F",
            },
        },
        invalid: {
            iconColor: "#eb1c26",
            color: "#eb1c26",
        },
    };

    if (stripeElementConfigStyle) {
        stripeElementDefaultStyle = {
            ...stripeElementDefaultStyle,
            ...stripeElementConfigStyle
        };
    }

    let cardElements = [{
        type: "card",
        query: `#fp-${type}-card`,
        configuration: {
            hidePostalCode: true,
            iconStyle: "solid"
        }
    }];

    if (_fp.config.payment?.card_settings?.type === "split") {
        cardElements = [
            {
                type: "cardNumber",
                query: `#fp-${type}-card-number`,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: _fp.config.payment?.card_settings?.placeholder?.card_number ?? "Card Number"
                }
            },
            {
                type: "cardExpiry",
                query: `#fp-${type}-card-exp`,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: _fp.config.payment?.card_settings?.placeholder?.card_exp_date ?? "MM/YY"
                }
            },
            {
                type: "cardCvc",
                query: `#fp-${type}-card-cvv`,
                configuration: {
                    style: stripeElementDefaultStyle,
                    placeholder: _fp.config.payment?.card_settings?.placeholder?.card_cvc ?? "CVC"
                }
            }
        ];
    }

    return cardElements;
}
