/* eslint-disable sonarjs/no-duplicate-string */
export function fpInitMultiselect(mainEl) {
    const selectId = mainEl?.id;
    if (!selectId) {
        return;
    }

    const selectOptionsWrapQuery = "[data-fp-element='multiselect_options']";
    const selectedLabelQuery = "[data-fp-element='multiselect_label']";

    fpMultiSelectCheckboxStatusChange(mainEl);
    const labelEl = mainEl.querySelector(selectedLabelQuery);
    if (labelEl) {
        labelEl.addEventListener("click", (e) => {
            e.preventDefault();
            fpMultiselectToggleCheckboxArea(mainEl, false);
        }, false);
    }

    const options = document.querySelectorAll(`${selectOptionsWrapQuery} > label`);
    options.forEach(opt => opt.addEventListener("change", () => {
        fpMultiSelectCheckboxStatusChange(mainEl);
    }, false));

    document.addEventListener("click", (evt) => {
        const flyoutElement = document.getElementById(selectId);
        let targetElement = evt.target;

        do {
            if (targetElement === flyoutElement) {
                return;
            }
            targetElement = targetElement.parentNode;
        } while (targetElement);

        fpMultiselectToggleCheckboxArea(mainEl, true);
    });
}

function fpMultiSelectCheckboxStatusChange(wrappeEl) {
    const multiselect = wrappeEl.querySelector("[data-fp-element='multiselect_label']");
    const multiselectOption = multiselect.getElementsByTagName("option")[0];

    const values = [];
    const checkboxes = wrappeEl.querySelector("[data-fp-element='multiselect_options']");
    const checkedCheckboxes = checkboxes.querySelectorAll("input[type=checkbox]:checked");

    for (const item of checkedCheckboxes) {
        const checkboxValue = item.getAttribute("value");
        values.push(checkboxValue);
    }

    const dropdownValueEl = wrappeEl.querySelector("[data-fp-element='multiselect_value']");
    const dropdownPlaceholderEl = wrappeEl.querySelector("[data-fp-element='multiselect_placeholder']");
    let dropdownValue = values?.length > 0 ? values : dropdownPlaceholderEl?.value;
    if (values.length > 0) {
        dropdownValue = values.join(", ");

    }

    if (dropdownValueEl) {
        dropdownValueEl.value = dropdownValue;
    }

    multiselectOption.innerText = dropdownValue ? dropdownValue : multiselectOption.innerText;
}

function fpMultiselectToggleCheckboxArea(wrapperEl, onlyHide = false) {
    const checkboxes = wrapperEl.querySelector("[data-fp-element='multiselect_options']");
    const displayValue = checkboxes.style.display;
    if (displayValue !== "block") {
        if (onlyHide === false) {
            checkboxes.style.display = "block";
        }
    } else {
        checkboxes.style.display = "none";
    }
}