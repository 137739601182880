import { paymentProviderEnum } from "../../core/enums/fp-payment-provider.enum";
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { fpSripeActionHandlerAsync } from "./stripe/fp-stripe-action-handler";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpCustomerValidateMandatoryProperties } from "../../core/utils/fp-customer-validate-mandatory-props";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpCreatePurchaseAsync } from "../../core/requests/purchase/fp-create-purchase";
import { fpSripe24ActionHandlerAsync } from "./stripe2024/fp-action-handler";
import { fpAuthorizeNetActionHandlerAsync } from "./authorize.net/fp-authorize-action-handler";

export async function fpPaymentActionHandlerAsync(fragmentType, actionType) {
  try {
    fpSpinnerHandler("show");

    let isSuccess = false;
    const isFreePurchase = !_fp.metadata?.selected_price?.require_card && (_fp.metadata?.selected_price?.free || _fp.metadata?.selected_price?.amount === 0);

    if (actionType === paymentActionEnum.ProcessPayment && isFreePurchase) { // Check conditions if a customer wants to process a free purchase
      return await fpProcessFreePricePurchaseAsync();
    } else if (_fp.config.payment.provider === paymentProviderEnum.Stripe) {
      isSuccess = await fpSripeActionHandlerAsync(fragmentType, actionType, null);
    } else if (_fp.config.payment.provider === paymentProviderEnum.Stripe2024) {
      isSuccess = await fpSripe24ActionHandlerAsync(actionType);
    } else if (_fp.config.payment.provider === paymentProviderEnum.AuthorizeNet) {
      isSuccess = await fpAuthorizeNetActionHandlerAsync(actionType);
    }

    // Spinner doesn't needs to be removed as it's handled by Premium Content fragment when condition below is true
    if (!isSuccess || (actionType !== paymentActionEnum.ProcessPayment)) {
      fpSpinnerHandler("hide");
    }

    return { success: isSuccess };
  } catch (error) {
    fpSpinnerHandler("hide");
    fpErrorHandler("Payment Action Handler", error);
    return { success: false, error_type: "payment_action_handler", error: error };
  }
}

export async function fpProcessPurchaseAsync(setAsNewPurchase, type, data) {
  const purchaseResponse = await fpCreatePurchaseAsync((data ?? null));
  if (!purchaseResponse || purchaseResponse.error || !purchaseResponse.executed) {
    return { success: false, error_type: type };
  }

  purchaseResponse.isNewPurchase = setAsNewPurchase;
  _fp.metadata.purchase = purchaseResponse;

  fpTrackUserAction("fp_purchase_resolved", null, "purchase", purchaseResponse.id, purchaseResponse);
  fpSpinnerHandler("hide");
  return { success: true };
}


async function fpProcessFreePricePurchaseAsync() {
  const formValidity = await fpPaymentValidateCustomerMandatoryPropertiesAsync();
  if (!formValidity.success) {
    return formValidity;
  }

  return await fpProcessPurchaseAsync(true, "free_price_create_purchase");
}

async function fpPaymentValidateCustomerMandatoryPropertiesAsync() {
  const customerRequiredProperites = _fp.config.payment?.required_customer_properties;
  if (!customerRequiredProperites || customerRequiredProperites?.length === 0) {
    return { success: true };
  }

  const isValid = await fpCustomerValidateMandatoryProperties();
  if (isValid) {
    return { success: true };
  }

  fpSpinnerHandler("hide");
  return { success: false, error_type: "customer_mandatory_fields_missing" };
}
