import { fpMemberCenterSubscriptionActionsHandler } from "./listeners/fp-mc-subscription-actions-handler";
import { fpMemberCenterFormHandler } from "./listeners/fp-mc-form-handler";
import { fpMemberCenterSetNavigationActions } from "./listeners/fp-mc-navigation";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { fpSetAuthenticationNavigationListeners } from "../../core/utils/fp-set-auth-listeners";

export function fpSetMemberCenterTemplateListeners(userActionToTrigger) {
  // Handle Close Action If Member Center Type Is Modal
  const closeButtonsEls = document.querySelectorAll("[data-fp-element='modal_close']");
  closeButtonsEls?.forEach((el) => el.addEventListener("click", () => fpMemberCenterModalClose(), false));

  // Handle Switch Between Member Center Categories 
  const navButtonsEls = document.querySelectorAll("[data-fp-element='mc_categories_nav']>*");
  navButtonsEls?.forEach((el) => el.addEventListener("click", (e) => fpMemberCenterSetNavigationActions(e), false));

  // Handle Subscription Actons
  const subscriptionActionsButtonsEls = document.querySelectorAll("[data-fp-element*='mc_action_'");
  subscriptionActionsButtonsEls?.forEach((el) => el.addEventListener("click", (e) => fpMemberCenterSubscriptionActionsHandler(e), false));

  // Handle Form Submit Actions
  let formsEls = document.querySelectorAll("[data-fp-element='mc_content'] form");
  formsEls = formsEls?.length > 0 ? formsEls : document.querySelectorAll("form[data-fp-element^='mc_']");
  formsEls?.forEach((el) => el.addEventListener("submit", (e) => fpMemberCenterFormHandler(e), false));

  // Handle Sign Out action if element is not part of categories
  fpSetAuthenticationNavigationListeners(authTypeEnum.SignOut);

  if (userActionToTrigger) {
    const actionToTriggerEl = document.querySelector(`[data-fp-element='mc_action_${userActionToTrigger}`);
    actionToTriggerEl?.click();
  }
}

function fpMemberCenterModalClose() {
  fpTrackUserAction("fp_user_action", "member_center_modal_close", null, null, null);
  delete _fp?.metadata?.member_center_displayed;
  const modalWrapperEl = document.querySelector("[data-fp-element='mc_modal_wrapper']");
  modalWrapperEl?.remove();
}