import { authProviderEnum } from "../../core/enums/fp-auth-provider.enum";
import { fpAuthFlipPayActionAsync } from "./flip-pay/fp-auth-action";

export async function fpAuthenticationActionAsync(type, data) {
    if (!_fp.config?.authentication) {
        console.error("FP:: Auth Settings Not Provided");
        return;
    }

    let response = null;
    if (_fp.config.authentication.provider === authProviderEnum.FlipPay) {
        response = await fpAuthFlipPayActionAsync(type, data);
    }

    return response;
}