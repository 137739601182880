import { recaptchaProviderEnum } from "../../core/enums/fp-recaptcha-provider.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpRecaptchaGoogleGenerateToken } from "./google/fp-recaptcha-google";

export async function fpGenerateRecaptchaTokenAsync(actionType) {
    try {
        const settings = _fp.config?.payment?.recaptcha;
        let recaptchaToken = null;
        if (settings?.type === recaptchaProviderEnum.Google) {
            recaptchaToken = await fpRecaptchaGoogleGenerateToken(actionType);
        }

        return recaptchaToken;
    } catch (error) {
        fpErrorHandler("Generate recaptcha token", error);
    }
}