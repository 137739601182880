import { fpIsShopfrontPage } from "./fp-is-shopfront-page";

export function fpSetPremiumElement(premiumWrapperOnly) {
    if (_fp.config?.journeys && _fp.config?.journeys.length > 0) {
        fpSelectJourneyFlowByConfig(_fp.config?.journeys);
        return;
    }

    if (_fp.config?.redirects?.welcome_page && document.location.href.includes(_fp.config?.redirects?.welcome_page)) {
        _fp.premium_wrapper = null;
        return;
    }

    if (_fp.premium_wrapper) {
        return;
    }

    if (!_fp.premium_wrapper && !!_fp.config.premium_content.id) {
        _fp.premium_wrapper_selector = `#${_fp.config.premium_content.id}`;
        _fp.premium_wrapper = document.getElementById(_fp.config.premium_content.id);
    }

    if (!_fp.premium_wrapper && !!_fp.config.premium_content.classname) {
        const els = document.getElementsByClassName(_fp.config.premium_content.classname);
        _fp.premium_wrapper_selector = `.${_fp.config.premium_content.classname}`;
        _fp.premium_wrapper = els ? els[0] : null;
    }

    if (!_fp.premium_wrapper && !!_fp.config.premium_content.shopfront_query && fpIsShopfrontPage()) {
        _fp.premium_wrapper_selector = `${_fp.config.premium_content.shopfront_query}`;
        _fp.premium_wrapper = document.querySelector(_fp.config.premium_content.shopfront_query);
    }

    if (!_fp.premium_wrapper && !!_fp.config.premium_content.query) {
        _fp.premium_wrapper_selector = `${_fp.config.premium_content.query}`;
        _fp.premium_wrapper = document.querySelector(_fp.config.premium_content.query);
    }

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    if (_fp.premium_wrapper && !premiumWrapperOnly) {
        _fp.metadata.content = _fp.premium_wrapper.innerHTML;
    } else {
        delete _fp.premium_wrapper_selector;
    }
}

function fpSelectJourneyFlowByConfig(journeys) {
    journeys.forEach(journey => {
        if (!journey.trigger) {
            return;
        }

        let premiumWrapperEl = null;

        if (journey.trigger.id) {
            premiumWrapperEl = document.getElementById(journey.trigger.id);
        }

        if (!premiumWrapperEl && journey.trigger.classname) {
            premiumWrapperEl = document.querySelector(`.${journey.trigger.classname}`);
        }

        if (!premiumWrapperEl && journey.trigger.query) {
            premiumWrapperEl = document.querySelector(`${journey.trigger.query}`);
        }

        if (!premiumWrapperEl) {
            return;
        }

        _fp.premium_wrapper = premiumWrapperEl;
        _fp.journey = journey;
    });

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    if (_fp.premium_wrapper && !_fp.metadata.content) {
        _fp.metadata.content = _fp.premium_wrapper.innerHTML;
    }
}