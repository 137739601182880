import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";

export function fpSetHomeDeliveryTemplate(templateName) {
  try {
    const templateSettings = _fp.config.fragments?.home_delivery_fragment?.settings;
    templateName = templateName ? templateName : templateSettings?.autocomplete?.enabled ? "address_autocomplete" : "address_form";

    const template = fpGetTemplateByName("home_delivery_fragment", templateName);
    const templateDOM = new DOMParser().parseFromString(template, "text/html");
    const templateHTML = templateDOM.querySelector("body").innerHTML;

    const teaser = fpSetFragmentTeaser("home_delivery_fragment");
    return `${teaser}${templateHTML}`;
  } catch (error) {
    fpErrorHandler("Set Home Delivery Fragment Template", error);
    throw error;
  }
}
