import { userAction } from "../../core/enums/fp-user-action.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpGetUTMParameters } from "../../core/utils/fp-get-utm-parameters";
import { fpIsShopfrontPage } from "../../core/utils/fp-is-shopfront-page";
import { fpDeleteStorageValue, fpGetStorageValue, fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpGetUrlParameterByName } from "../../core/utils/fp-url-handler";

export async function fpSetAccessRequestDetailsAsync() {
    try {
        const fpJourneyConfig = fpGetJourneyConfig();
        const cannonicalEl = document.querySelector("link[rel='canonical']");
        const cannonicalUrl = cannonicalEl ? cannonicalEl.href : null;

        const request = {
            referrer: document.referrer ? encodeURIComponent(document.referrer) : null,
            href: cannonicalUrl ? encodeURIComponent(cannonicalUrl) : encodeURIComponent(window.location.href),
            browser_session_id: _fp.metadata?.browser_session_id,
            country_code: _fp.metadata?.access_status?.country_code ? _fp.metadata.access_status.country_code : null,
            group_code: fpSetRequestGroupCode(),
            code: fpSetRequestCode(),
            coupon_code: fpSetRequestCouponCode(),
            badge: fpSetRequestBadge(),
            auto_purchase: fpSetRequestAutoPurchaseFlag(),
            selected_price_id: _fp?.metadata?.selected_price?.id ?? null,
        };

        if (fpJourneyConfig?.detect_ad_blocker) {
            request.ad_block_detected = await fpSetRequestAdBlockStatusAsync();
        }

        // Set Client Id when a customer is signed out
        if (!_fp.user || !_fp.user.access_token || !!_fp.user.access_token_expired) {
            request.client_id = _fp.config.client.id;
        }

        // Set AMP properties
        const readerId = fpGetUrlParameterByName("reader_id");
        if (readerId) {
            request.reader_id = readerId;
            request.amp_site = false;
        }

        // Set UTM Parameters
        const utmParameters = fpGetUTMParameters();
        if (Object.keys(utmParameters).length > 0) {
            Object.keys(utmParameters).forEach((param) => request[param] = encodeURIComponent(utmParameters[param]));
        }

        // Clean undefined/null properties from request 
        Object.keys(request).forEach(key => (request[key] === null || request[key] === undefined) ? delete request[key] : null);

        return request;
    } catch (error) {
        fpErrorHandler("Set Access Request", error);
        return null;
    }
}

function fpSetRequestGroupCode() {
    const fpJourneyConfig = fpGetJourneyConfig();
    let groupCode = fpJourneyConfig?.group_code || null;
    const journeySettings = fpJourneyConfig?.group_code_rules;
    if (journeySettings?.url_param?.enabled) {
        const urlParams = Object.fromEntries(new URLSearchParams(window.location.search).entries());

        const { param, identifier } = journeySettings.url_param;
        const paramValue = urlParams[param];
        if (paramValue) {
            groupCode = identifier ? paramValue.includes(identifier) ? paramValue : groupCode : paramValue;
        }
    }

    if (journeySettings?.path_name?.enabled) {
        const pathName = document.location.pathname;
        const paths = journeySettings.path_name.paths;

        const matchedPath = paths.find(pathConfig => pathConfig.path === pathName);
        if (matchedPath) {
            groupCode = matchedPath.group_code;
        }
    }

    if (journeySettings?.data_attribute?.enabled && _fp.premium_wrapper?.dataset?.groupCode) { // Premium wrapper elemement need to contain data-group-code
        groupCode = _fp.premium_wrapper.dataset.groupCode.trim();
    }

    const pageConfig = fpGetApplicationJsonConfig(journeySettings?.application_ld_json);
    if (journeySettings?.application_ld_json?.enabled && pageConfig) {
        const sections = pageConfig[journeySettings.application_ld_json.parameter]?.map(x => {
            let str = x.toLowerCase();
            str = str.replaceAll(" &amp; ", "_").replaceAll(/&amp;/g, "_");
            return str;
        });

        if (sections?.length > 0) {
            groupCode = sections[0];
            // const allIgs = sections.join(", ");
        }
    }

    const urlGroupCode = fpGetUrlParameterByName("group_code");
    if (urlGroupCode) {
        groupCode = urlGroupCode;
    }

    return groupCode;
}

export function fpSetRequestCode() {
    const fpJourneyConfig = fpGetJourneyConfig();
    let code = fpJourneyConfig.code;
    if (!code) {
        code = _fp?.metadata?.journey_type === "voluntary_contribution" ? new Date().toISOString() : encodeURIComponent(document.location.pathname);
    }

    let articleIdEl = document.querySelector("[data-premium-article-id]");
    articleIdEl = articleIdEl ? articleIdEl : document.querySelector("[id^='post']");
    if (articleIdEl) {
        if (articleIdEl.dataset && articleIdEl.dataset.premiumArticleId) {
            code = articleIdEl.dataset.premiumArticleId;
        } else if (articleIdEl.id && articleIdEl.id.split("-").length === 2) {
            code = articleIdEl.id.split("-")[1];
        }
    }

    return code ? code : null;
}

function fpSetRequestBadge() {
    const fpJourneyConfig = fpGetJourneyConfig();
    let badge = fpJourneyConfig?.badge;
    badge = badge ? badge : (fpIsShopfrontPage() ? "shopfront" : null);

    const urlBadge = fpGetUrlParameterByName("badge");
    if (urlBadge) {
        _fp.config.badge = badge = urlBadge;
        _fp?.journey && (_fp.journey.badge = badge);
    }

    const storageBadge = fpGetStorageValue("fp.badge");
    if (storageBadge) {
        badge = storageBadge;
        fpDeleteStorageValue("fp.badge");
    }

    const badgeRules = fpJourneyConfig?.badge_rules;
    const badgeDataAttribute = _fp.premium_wrapper?.dataset?.badge;

    if (_fp?.metadata?.user_action === userAction.PricesTabClick || _fp?.metadata?.user_action === userAction.AuthFragmentClick) {
        delete _fp?.metadata?.user_action;
        if (badgeRules?.check_data_attribute && badgeDataAttribute) {
            badge = _fp?.metadata?.last_tab_clicked_badge ? _fp?.metadata?.last_tab_clicked_badge : badgeDataAttribute;
        }
        _fp.config.badge = badge;
        return badge;
    }

    if (badgeRules?.check_data_attribute) { // Premium wrapper element need to contain data-badge
        badge = badgeDataAttribute && badgeDataAttribute !== "" ? badgeDataAttribute : badge;
    } else if (badgeRules?.check_page_path_name && badgeRules?.path_rules?.length > 0) {
        const rule = badgeRules.path_rules.find(x => document.location.pathname.includes(x.path_name));
        badge = rule?.badge ? rule.badge : badge;
    }

    _fp.config.badge = badge;
    return badge;
}

function fpSetRequestCouponCode() {
    const storageProp = "fp.coupon";
    let coupon = fpGetStorageValue(storageProp);
    fpDeleteStorageValue(storageProp);

    if (!coupon) {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        let cc = fpGetUrlParameterByName("coupon_code");
        if (cc) {
            _fp.metadata.coupon = { code: cc };
            if (!_fp.user || (!_fp.user.access_token || !!_fp.user.access_token_expired)) {
                // removeParamByName("coupon_code", document.location.href, true);
                fpSetStorageValue(storageProp, _fp.metadata.coupon);
            }
        }
    }

    return _fp.metadata.coupon && _fp.metadata.coupon.code ? _fp.metadata.coupon.code : null;
}

async function fpSetRequestAdBlockStatusAsync() {
    let adBlockEnabled = false;
    const googleAdUrl = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    try {
        await fetch(new Request(googleAdUrl)).catch(() => adBlockEnabled = true);
    } catch (e) {
        return true;
    }
    return adBlockEnabled;
}

function fpSetRequestAutoPurchaseFlag() {
    const fpMeteringConfig = _fp?.journey || _fp.config?.metering;
    const meteringAutoPurchase = fpMeteringConfig?.enabled && fpMeteringConfig?.settings?.auto_purchase;
    const regwallAutoPurchase = _fp.config?.fragments?.registrationwall_fragment?.auto_purchase;
    return (meteringAutoPurchase || regwallAutoPurchase) && !fpIsShopfrontPage();
}

function fpGetApplicationJsonConfig(settings) {
    if (!settings || !settings.parameter) {
        return null;
    }

    const pageConfigsEls = [...document.querySelectorAll("script[type='application/ld+json']")];
    if (!pageConfigsEls || pageConfigsEls.length === 0) {
        return null;
    }

    return pageConfigsEls.map(x => x.innerHTML.fpConvertToJson())?.find(config => config[settings.parameter]);
}