import { fpGetTemplateByName } from "../../../core/utils/fp-set-template";
import { fpSetPricesTemplate } from "../../prices/fp-set-prices-fragment-template";

export function fpSetMeteredPaywallTemplateLimitReached() {
    const templateHTML = fpGetTemplateByName("metered_paywall_fragment", "limit_reached");
    const templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");

    const pricesWrapperEl = templateDOM.querySelector("[data-fp-element='prices']");
    if (pricesWrapperEl) {
        pricesWrapperEl.innerHTML = fpSetPricesTemplate(true);
    }

    let template = templateDOM.querySelector("body").innerHTML;
    template = template.fpPopulateTemplate("authentication_status", (_fp?.user?.access_token ? "signed-in" : "signed-out"));

    return template;
}