import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { authProviderEnum } from "../../core/enums/fp-auth-provider.enum";
import { fpAuthFlipPaySelectJourney } from "./flip-pay/fp-auth-journey";

export function fpShowAuthenticationFragment(journeyType) {
  try {
    if (_fp.config.authentication.provider === authProviderEnum.FlipPay) {
      fpAuthFlipPaySelectJourney(journeyType);
    } else if (_fp.config.authentication.provider === authProviderEnum.Auth0) {
      console.log("Auth0");
    } else if (_fp.config.authentication.provider === authProviderEnum.Vendor) {
      console.log("Vendor");
    }

    fpTrackUserAction("fp_fragment_displayed", null, "authentication", null, null);
  } catch (error) {
    fpTrackUserAction("fp_error", "authentication", null, error);
    fpErrorHandler("Show Auth Fragment", error);
  }
}
