import { durationEnum } from "../../core/enums/fp-duration.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpIsShopfrontPage } from "../../core/utils/fp-is-shopfront-page";
import { fpGetStorageValue, fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpGetServerSideAccessStatusAsync } from "../server-side/fp-server-side-access";

export async function fpGetClientSideAccessStatusAsync() {
  let accessStatus = { access: false };

  const settings = (_fp?.journey || _fp.config?.metering)?.settings;
  
  const hasAccessByMeter = fpHasAccessByBrowserStorageMetering(settings);
  const isShopfront = fpIsShopfrontPage();

  if (isShopfront) {
    return await fpGetServerSideAccessStatusAsync();
  }

  if (hasAccessByMeter) {
    accessStatus.access = true;
  } else if (settings?.redirect_url) {
    console.log("FP:: SHOW REDIRECT FRAGMENT");
  } else if (settings?.registration_only) {
    accessStatus.access = !!_fp.user?.access_token;
    accessStatus.registration_only = true;
  } else {
    accessStatus = await fpGetServerSideAccessStatusAsync();
  }

  return accessStatus;
}

function fpHasAccessByBrowserStorageMetering(settings) {
  try {
    const isShopfront = fpIsShopfrontPage();
    if (isShopfront) {
      return false;
    }

    const articleId = window.location.pathname;
    const storageMeteringPropName = "fp.metering";
    let storageMetering = fpGetStorageValue(storageMeteringPropName);
    try {
      storageMetering = JSON.parse(atob(storageMetering));
    } catch (err) {
      storageMetering = fpSetDefaultStorageMeteringObject(settings);
    }

    if (storageMetering && !!Array.isArray(storageMetering.articles) && storageMetering.articles.find((x) => x === articleId)) {
      return true;
    }

    storageMetering = !storageMetering ? fpSetDefaultStorageMeteringObject(settings) : storageMetering;
    storageMetering.articles.push(articleId);

    // Check exp date
    const isCounterExpired = !!storageMetering.exp_date && new Date(storageMetering.exp_date) < new Date();
    if (isCounterExpired) {
      storageMetering = fpSetDefaultStorageMeteringObject(settings);
    }

    if (storageMetering.articles.length > (settings.counter ? settings.counter : 0)) {
      return false;
    }

    const storageValue = btoa(JSON.stringify(storageMetering));
    fpSetStorageValue(storageMeteringPropName, storageValue, storageMetering.exp_date);

    return true;
  } catch (err) {
    fpErrorHandler("Storage Metering", err);
    return true;
  }
}

function fpSetDefaultStorageMeteringObject(settings) {
  let expDate = new Date();
  if (settings?.counter_expiration === durationEnum.Day) {
    expDate.setDate(expDate.getDate() + 1);
  } else if (settings?.counter_expiration === durationEnum.Year) {
    expDate.setMonth(expDate.getMonth() + 12);
  } else {
    expDate.setMonth(expDate.getMonth() + 1);
  }

  return { exp_date: expDate, articles: [] };
}
