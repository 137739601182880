import { fpClearNotUsedAnchors } from "../../core/utils/fp-clear-template";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../core/utils/fp-set-template-price-details";

export function fpSetOrderSummaryTemplate(isCardPurchase) {
  try {
    const template = fpGetTemplateByName("order_summary_fragment");
    const templateDOM = new DOMParser().parseFromString(template, "text/html");

    fpSetCustomerSignUpDetails(templateDOM);
    fpSetOrderSummaryPaymentDetails(templateDOM, isCardPurchase);
    fpSetOrderSummaryHomeDeliveryDetails(templateDOM);
    fpSetOrderSummaryPlaceholderDetails(templateDOM);
    fpSetOrderSummaryPriceDetails(templateDOM);
    fpSetOrderSummaryCouponCodeDetails(templateDOM);

    let orderSummaryFragment = templateDOM.querySelector("body").innerHTML;
    if (_fp?.journey?.badge) {
      orderSummaryFragment = orderSummaryFragment.fpPopulateTemplate("badge", _fp?.journey?.badge);
    }
    
    orderSummaryFragment = fpClearNotUsedAnchors(orderSummaryFragment);

    const teaser = fpSetFragmentTeaser("order_summary_fragment");
    return `${teaser}${orderSummaryFragment}`;
  } catch (error) {
    fpErrorHandler("Set Order Summary Fragment Template", error);
    return "";
  }
}

function fpSetCustomerSignUpDetails(templateDOM) {
  const fpJourneyConfig = fpGetJourneyConfig();

  const customerSignUpDetailsEl = templateDOM.querySelector("[data-fp-element='customer-sign-up-details'");
  if (!customerSignUpDetailsEl) {
    return;
  }

  if ((!fpJourneyConfig?.simple_checkout) || (_fp?.user?.access_token)) {
    customerSignUpDetailsEl.remove();
  }
}

function fpSetOrderSummaryPlaceholderDetails(templateDOM) {
  const placeholderEl = templateDOM.querySelector("[data-fp-element='order-sumary-placeholder-details'");
  if (!placeholderEl) {
    return;
  }

  if (!_fp.metadata.selected_placeholder_price) {
    placeholderEl.remove();
    return;
  }

  placeholderEl.outerHTML = fpPopulateTemplatePriceDetails(placeholderEl.outerHTML, _fp.metadata.selected_placeholder_price, 1);
}

function fpSetOrderSummaryPriceDetails(templateDOM) {
  const pricesWrapperEl = templateDOM.querySelector("[data-fp-element='order-summary-items']");
  if (!pricesWrapperEl) {
    return;
  }

  const priceWrapperEl = pricesWrapperEl.querySelector("[data-fp-element='order-summary-item']");
  if (!priceWrapperEl) {
    return;
  }

  let pricesHtml = "";
  let prices = _fp.metadata.prices;
  if (_fp.config.fragments.order_summary_fragment?.settings?.display_selected_price_only) {
    prices = prices.filter(x => x.id === _fp.metadata.selected_price.id);
  }

  prices.forEach((price, ind) => {
    pricesHtml += fpPopulateTemplatePriceDetails(priceWrapperEl.outerHTML, price, ind);
  });

  pricesWrapperEl.innerHTML = pricesHtml;
}

function fpSetOrderSummaryHomeDeliveryDetails(templateDOM) {
  const printHomeDeliveryEl = templateDOM.querySelector("[data-fp-element='home-delivery']");
  if (!printHomeDeliveryEl) {
    return;
  }

  if (!_fp.metadata?.selected_price?.validate_address || !_fp.user.address) {
    printHomeDeliveryEl.remove();
    return;
  }

  const addressFormated = _fp.user.address.full.replace(",", "<br/>");
  printHomeDeliveryEl.outerHTML = printHomeDeliveryEl.outerHTML
    .fpPopulateTemplate("user_name", _fp.user.address.user_name)
    .fpPopulateTemplate("delivery_address", addressFormated);
}

function fpSetOrderSummaryCouponCodeDetails(templateDOM) {
  const couponCodeWrapperEl = templateDOM.querySelector("[data-fp-element='coupon-code']");
  if (!couponCodeWrapperEl) {
    return;
  }

  const couponElsTypes = {
    coupon_applied: couponCodeWrapperEl.querySelector("[data-fp-element='coupon-code-applied']"),
    coupon_apply: couponCodeWrapperEl.querySelector("[data-fp-element='coupon-code-apply']"),
  };

  if (_fp.metadata.access_status?.coupon_applied) {
    couponElsTypes?.coupon_apply?.remove();
  } else {
    couponElsTypes?.coupon_applied?.remove();
  }

  let templateHTML = couponCodeWrapperEl.innerHTML;
  if (_fp?.metadata?.selected_price) {
    templateHTML = fpPopulateTemplatePriceDetails(templateHTML, _fp.metadata.selected_price, 1);
  }

  const coupon = _fp.metadata.selected_price.coupon;
  templateHTML = templateHTML.fpPopulateTemplate("discount", coupon?.discount ?? 0);
  couponCodeWrapperEl.innerHTML = templateHTML;
}

function fpSetOrderSummaryPaymentDetails(templateDOM, isCardPurchase) {
  const freePurchaseEl = templateDOM.querySelector("[data-fp-element='free_purchase']");
  if (isCardPurchase) {
    freePurchaseEl?.remove();
    return;
  }

  const paymentWrapperEl = templateDOM.querySelector("[data-fp-element='payment_wrapper']");
  paymentWrapperEl?.remove();
}
