import { authTypeEnum } from "../../../../core/enums/fp-auth-type.enum";
import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpUserSignInAsync } from "../../../../core/requests/authentication/fp-sign-in";
import { fpUserSignUpAsync } from "../../../../core/requests/authentication/fp-sign-up";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpSetAuthModalVisibility } from "../fragments/fp-auth-fragment-wrapper";
import { fpInitializeCustomerJourney } from "../../../../journeys/fp-initialize-journey";
import { fpRequestResetPasswordAsync } from "../../../../core/requests/authentication/fp-request-reset-password";
import { fpResetPasswordAsync } from "../../../../core/requests/authentication/fp-reset-password";
import { fpRemoveUrlParamenterByName } from "../../../../core/utils/fp-url-handler";
import { fpInitializeAuthenticationAsync } from "../../../../providers/authentication/fp-auth-handler";
import { fpValidatePassword } from "../../../../core/utils/fp-validate-password";
import { memberCenterTypeEnum } from "../../../../core/enums/fp-member-center-type.enum";
import { fpShowAuthenticationFragment } from "../../fp-authentication-fragment";
import { fpDeleteStorageValue, fpGetStorageValue } from "../../../../core/utils/fp-storage-handler";
import { fpUserConfirmPinCodeAsync } from "../../../../core/requests/authentication/fp-confirm-pincode";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";

export async function fpAuthFlipPayFormHandler(event, formEl) {
    try {
        if (!formEl) {
            return;
        }

        event.preventDefault();
        fpAuthFormErrorHandler(formEl, "clear", null);
        fpSpinnerHandler("show", null);

        const formData = Object.fromEntries(new FormData(formEl).entries());
        let isPasswordValid = [authTypeEnum.SignUp, authTypeEnum.ResetPassword].includes(formData.type) ? fpValidatePassword(formData) : { valid: true };
        if (!isPasswordValid?.valid) {
            fpAuthFormErrorHandler(formEl, "set", isPasswordValid.error_message);
            return;
        }

        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.auth_details = formData;

        fpTrackUserAction("fp_authentication", `${formData.type}_requested`, "authentication", null, null);

        let response = null;
        if (formData.type === authTypeEnum.SignIn) {
            response = await fpUserSignInAsync(formData);
        } else if (formData.type === authTypeEnum.RequestResetPassword) {
            response = await fpRequestResetPasswordAsync(formData);
            _fp.metadata = { ..._fp.metadata, auth_token: response?.pin_token ?? null };
            
            fpAuthWaitOnConfirmAction(formData.type);
            return;
        } else if (formData.type === authTypeEnum.ResetPassword) {
            fpRemoveUrlParamenterByName("reset_password");
            response = await fpResetPasswordAsync(formData);
        } else if (formData.type === authTypeEnum.SignUp) {
            formData.custom_options = fpGetStorageValue("fp.auth.custom_options");
            fpDeleteStorageValue("fp.auth.custom_options");

            response = await fpUserSignUpAsync(formData);
            if (response === "email_verification_required") {
                fpSpinnerHandler("hide", null);
                fpShowAuthenticationFragment(authTypeEnum.VerifyEmail);
                return response;
            }
        } else if (formData.type === "pin_code") {
            response = await fpUserConfirmPinCodeAsync(formData);
        }

        response = response ? response : {};
        response.auth_type = formData.type;

        const fragmentSettings = _fp.config?.fragments?.authentication_fragment?.settings;
        if (fragmentSettings?.type !== memberCenterTypeEnum.Inline) {
            fpSetAuthModalVisibility("hide");
        }

        await fpInitializeAuthenticationAsync(response);

        fpTrackUserAction("fp_authentication", `${formData.type}_completed`, "authentication", null, response, null);

        const authSettings = _fp.config?.authentication;
        if (authSettings?.reload_page_after_sign_in && [authTypeEnum.SignIn, authTypeEnum.SignUp].includes(response.auth_type)) {
            window.location.reload();
            return;
        }

        fpInitializeCustomerJourney();
        fpSpinnerHandler("hide", null);
    } catch (error) {
        const errorMessage = fpErrorHandler("Auth FlipPay Form Handler", error, true);
        fpSpinnerHandler("hide", null);
        fpAuthFormErrorHandler(formEl, "set", errorMessage);
    }
}

function fpAuthFormErrorHandler(formEl, type, errorMessage) {
    if (type !== "clear") {
        fpSpinnerHandler("hide", null);
    }

    const errEl = formEl.querySelector("[data-fp-element='form_error']");
    if (errEl) {
        errEl.innerHTML = type === "clear" ? "" : `${errorMessage}`;
    }
}

function fpAuthWaitOnConfirmAction(type) {
    fpSpinnerHandler("hide", null);
    if (authTypeEnum.RequestResetPassword === type) {
        const elements = {
            request: document.querySelector("[data-fp-element='reset_password_requested']"),
            confirm: document.querySelector("[data-fp-element='reset_password_confirm']")
        };

        if (elements.request) {
            elements.request.style.display = "none";
        }

        if (elements.confirm) {
            elements.confirm.style.display = "block";
        }
    }
}