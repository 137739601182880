import apiConfig from "fpApiConfig";
import defaultConfig from "fpDefaultConfig";
import { fpErrorHandler } from "../core/utils/fp-error-handler";

export async function fpInitializeConfigurationAsync() {
    try {        
        if (window.flipPayConfig) {
            fpCheckConfigOverrides(defaultConfig, flipPayConfig);
        }

        if (window.flipPayConfigOverride) {
            fpCheckConfigOverrides(defaultConfig, window.flipPayConfigOverride);
        }

        _fp.config = Object.assign(apiConfig, defaultConfig);           
    } catch (error) {
        fpErrorHandler("Configuration", error);
    }
}

function fpCheckConfigOverrides(defaultConfig, customConfig) {
    if (!customConfig) {
        return;
    }

    Object.keys(customConfig).forEach(key => {
        const isSimpleType = ["string", "number", "boolean"].includes(typeof customConfig[key]);
        const isSimpleArray = Array.isArray(customConfig[key]) && ["string", "number"].includes(typeof customConfig[key][0]);
        if ((isSimpleType || isSimpleArray) || customConfig[key] === null || !defaultConfig[key]) {
            defaultConfig[key] = customConfig[key];
        } else if (customConfig[key] && typeof customConfig[key] === "object") {
            fpCheckConfigOverrides(defaultConfig[key], customConfig[key]);
        }
    });
}