/* eslint-disable no-unused-vars */
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { fpGetEntitlementsAsync } from "../../core/requests/entitlements/fp-get-entitlements";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpInitializePaymentMethodAsync } from "../../providers/payment/fp-init-payment-method";
import { fpPaymentActionHandlerAsync } from "../../providers/payment/fp-payment-action-handler";
import { fpSetVoluntaryContributionTemplate, fpUpdateVoluntaryContributionTemplate } from "./fp-set-voluntary-contribution-template";
import { fpSetVoluntaryContributionTemplateListeners, fpVoluntaryContributionGetFormData } from "./fp-set-voluntary-contribution-template-listeners";

export async function fpShowVoluntaryContributionFragment(refreshOptionsOnly) {
  try {
    const fragmentConfig = fpVoluntaryContributionGetConfig();
    fragmentConfig.has_susbscription = fragmentConfig.has_susbscription ?? await fpVoluntaryContributionCheckSubscriptionStatusAsync(fragmentConfig?.settings);

    fpContributionSetSelectedPrice(fragmentConfig.settings, fragmentConfig.has_susbscription);

    if (refreshOptionsOnly) {
      fpContributionRefreshDetails(fragmentConfig.settings);
      return;
    }


    fpVoluntaryContributionSetTemplate(fragmentConfig, fragmentConfig.has_susbscription);
    fpSetVoluntaryContributionBillingDetails();

    fpTrackUserAction("fp_fragment_displayed", null, "voluntary_contribution", null, null);
    if ((_fp.user && _fp.user.access_token) || !!fragmentConfig.settings.allow_anonymous) {
      fpInitializePaymentMethodAsync(paymentFragmentEnum.VoluntaryContribution);
    }

    fpSetVoluntaryContributionTemplateListeners();
  } catch (error) {
    fpTrackUserAction("fp_error", null, "voluntary_contribution", null, error);
    fpErrorHandler("Voluntary Contribution", error);
  }
}

export function fpVoluntaryContributionGetConfig() {
  const journeyConfig = fpGetJourneyConfig();
  return journeyConfig?.fragments?.voluntary_contribution_fragment ?? _fp.config.fragments?.voluntary_contribution_fragment;
}

async function fpVoluntaryContributionSetTemplate(fragmentConfig, hasSubscription) {
  const voluntaryContributionElement = document.querySelector(".fp-va-wrapper");
  if (voluntaryContributionElement) {
    return;
  }

  const templateDOM = document.createElement("div");
  templateDOM.innerHTML = fpSetVoluntaryContributionTemplate(fragmentConfig, hasSubscription);

  // Clean content if re init has been called from auth flow -> flipPayForcedInit
  _fp.premium_wrapper.innerHTML = window.flipPayForcedInit ? "" : _fp.premium_wrapper.innerHTML;
  if (fragmentConfig?.settings?.position === "top" && _fp.premium_wrapper.firstChild) {
    _fp.premium_wrapper.insertBefore(templateDOM, _fp.premium_wrapper.firstChild);
  } else {
    _fp.premium_wrapper.appendChild(templateDOM);
  }
}

function fpVoluntaryContributionUpdateTemplate(settings) {
  const optionsWrapperDOM = document.querySelector("[data-fp-element='voluntary_contribution_options_main']");
  optionsWrapperDOM.innerHTML = fpUpdateVoluntaryContributionTemplate(settings);
}

function fpSetVoluntaryContributionBillingDetails() {
  const formEl = document.querySelector("[data-fp-element='voluntary_contribution_form']");
  const formElements = formEl?.elements ? Array.from(formEl.elements) : null;
  if (!formElements || !_fp.user?.billing_details) {
    return;
  }

  if (_fp.user?.access_token) {
    formEl.classList.add("fp-user-signed-in");
  }

  Object.keys(_fp.user.billing_details).forEach((key) => {
    const el = formElements.find((x) => x.name === key);
    if (el && el.nodeName === "INPUT") {
      el.value = _fp.user.billing_details[key];
      el.disabled = _fp.user?.access_token;
    }
  });
}

function fpContributionSetSelectedPrice(settings, hasSubscription) {
  _fp.metadata.selected_price = _fp.metadata.prices[0];

  const contributionRenewalTypes = settings?.options ?? settings?.tabs?.options;
  const defaultOption = (settings?.tabs?.enabled || contributionRenewalTypes?.length > 1) && contributionRenewalTypes.find((x) => x.selected);
  if (defaultOption) {
    _fp.metadata.selected_price = hasSubscription
      ? _fp.metadata.prices.find((x) => !x.renewable)
      : _fp.metadata.prices.find((x) => x.badge_text.includes(defaultOption.badge));
  }

  _fp.metadata.selected_price.amount = _fp.metadata.selected_price.voluntary_contribution_amounts.includes(_fp.metadata.selected_price.amount)
    ? _fp.metadata.selected_price.amount
    : _fp.metadata.selected_price.voluntary_contribution_amounts[0];
}

function fpContributionRefreshDetails(settings) {
  fpVoluntaryContributionUpdateTemplate(settings);
  fpPaymentActionHandlerAsync(paymentFragmentEnum.VoluntaryContribution, paymentActionEnum.UpdateOtherElementPaymentAmount);
  fpTrackUserAction("fp_user_action", "volunatry_contribution_option_change", "price", _fp.metadata.selected_price.id, null);

  const formEl = document.querySelector("[data-fp-element='voluntary_contribution_form'");
  if (formEl) {
    fpVoluntaryContributionGetFormData(formEl);
  }

  fpSetVoluntaryContributionTemplateListeners();
}

async function fpVoluntaryContributionCheckSubscriptionStatusAsync(settings) {
  let hasSubscription = !!_fp.metadata.access_status?.subscription;
  if (hasSubscription && (_fp?.user?.access_token && (settings?.check_by_entitlements && _fp.journey.group_code))) {
    const res = await fpGetEntitlementsAsync(_fp.journey.group_code);
    hasSubscription = !!res?.access;
  }

  return hasSubscription;
}