import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";
import { fpSetAuthModalVisibility } from "./fp-auth-fragment-wrapper";
import { fpAuthFlipPaySetListeners } from "../fp-auth-listeners";
import { fpGetStorageValue, fpSetStorageValue } from "../../../../core/utils/fp-storage-handler";
import { fpGetUrlParameterByName } from "../../../../core/utils/fp-url-handler";
import { authTypeEnum } from "../../../../core/enums/fp-auth-type.enum";
import { userAction } from "../../../../core/enums/fp-user-action.enum";

// Fragmnet to display User authentication options -> Sign In & Sign Up
export function fpAuthFlipPayDirect(type) {
  try {
    const settings = _fp.config.fragments?.authentication_fragment?.settings;
    const authWrapEl = document.querySelector("#fp-auth-content"); // Id needs to be set in the template
    if (!authWrapEl) {
      fpErrorHandler("Auth Fragment Error", "Authentication wrapper element not set");
      return;
    }

    const templateDOM = fpSetAuthFlipPayUserTemplate(type);
    const templateBodyDOM = templateDOM.querySelector("body");

    // Some of the actions do not require notification message to be displayed
    const skipNotification = [userAction.RegwallSignUp, userAction.RegwallSignIn, userAction.AuthFragmentClick].includes(_fp?.metadata?.user_action);
    skipNotification ? delete _fp?.metadata?.user_action : null;

    // Remove settings?.display_notification check when all vendors migrated to settings?.notification?.enabled
    if (!skipNotification && (settings?.display_notification || settings?.notification?.enabled)) {     
      fpAuthSetNotificationMessage(templateBodyDOM, type, settings);
    }

    let templateHTML = templateBodyDOM.innerHTML;
    templateHTML = templateHTML.fpPopulateTemplate("reset_password_token", fpGetUrlParameterByName("reset_password"));

    authWrapEl.innerHTML = templateHTML;

    if (settings?.type !== "inline") {
      fpSetAuthModalVisibility("show");
    }

    fpAuthFlipPaySetListeners();
  } catch (error) {
    fpErrorHandler("FlipPay Auth Sign In", error);
  }
}

function fpSetAuthFlipPayUserTemplate(type) {
  const template = fpGetTemplateByName("authentication_fragment", type);
  const templateDOM = new DOMParser().parseFromString(template, "text/html");

  const usernamePasswordWrapperEl = templateDOM.querySelector("[data-fp-element='auth_direct'");
  if (usernamePasswordWrapperEl && _fp.config.authentication.disable_username_password) {
    usernamePasswordWrapperEl.remove();
  }

  const isSocialsAuthEnabled = _fp.config.authentication.socials && _fp.config.authentication.socials.enabled;
  const socialsWrapperEl = templateDOM.querySelector("[data-fp-element='auth_social'");
  if (socialsWrapperEl) {
    if (!isSocialsAuthEnabled) {
      socialsWrapperEl.remove();
    } else {
      const socialTypesEls = socialsWrapperEl.querySelectorAll("[data-fp-provider]");
      socialTypesEls.forEach((el) => (_fp.config.authentication.socials.types && _fp.config.authentication.socials.types.includes(el.dataset.fpProvider.toLowerCase()) ? null : el.remove()));
    }
  }

  return templateDOM;
}

function fpAuthSetNotificationMessage(templateBodyDOM, type, settings) {
  if (_fp.metadata.journey === "auth_close") {
    _fp.metadata.auth_notification_displayed = false;
    return;
  }

  if (_fp.metadata.auth_notification_displayed) {
    return;
  }

  _fp.metadata.auth_notification_displayed = true;
  const notificationCookie = fpGetStorageValue("fp.auth_notification_displayed");

  const maxNoDisplays = settings?.notification?.counter ? settings.notification.counter : 1;
  let noDisplayedNotifications = notificationCookie?.counter ? notificationCookie.counter : 0;

  if ((noDisplayedNotifications >= maxNoDisplays) || type === authTypeEnum.ResetPassword || type === authTypeEnum.VerifyEmail) {
    return;
  }

  const notificationEl = document.createElement("div");
  notificationEl.classList = "fp-auth-notification-wrapper";
  notificationEl.setAttribute("data-fp-element", "fp_auth_notification");
  notificationEl.innerHTML = fpGetTemplateByName("authentication_fragment", "notification");
  templateBodyDOM.appendChild(notificationEl);

  fpSetStorageValue("fp.auth_notification_displayed", { counter: ++noDisplayedNotifications });
}