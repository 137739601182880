import { memberCenterCategoryEnum } from "../../../core/enums/fp-member-center-category.enum";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpSnackbarMessageHandler } from "../../../core/utils/fp-snackbar";
import { fpValidatePassword } from "../../../core/utils/fp-validate-password";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpPatchCustomerDetailsAsync } from "../../../core/requests/customer/fp-customer-patch-details";
import { fpCustomerChangePasswordAsync } from "../../../core/requests/customer/fp-customer-change-password";
import { fpTrackUserAction } from "../../../core/utils/fp-tracking";
import { fpCreateCustomerSupportTicket } from "../../../core/requests/customer/fp-customer-support-ticket";
import { fpShowMemberCenterFragment } from "../fp-member-center-fragment";
import { memberCenterMessageEnum } from "../../../core/enums/fp-member-center-message-enum";

export async function fpMemberCenterFormHandler(evt) {
  try {
    evt.preventDefault();

    const formType = evt.currentTarget.dataset.fpElement;
    const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());

    fpSpinnerHandler("show", null);
    fpTrackUserAction("fp_user_action", `${formType}_submit`, null, null, null);
    fpFormErrorElementHandler(evt, "clear", null);

    let response = false;
    if (formType.includes(memberCenterCategoryEnum.Profile)) {
      await fpPatchCustomerDetailsAsync("name", formData);
    } else if (formType.includes(memberCenterCategoryEnum.SupportTicket)) {
      response = await fpCreateCustomerSupportTicket(formData);
    } else if (formType.includes(memberCenterCategoryEnum.ChangePassword)) {
      const validation = fpValidatePassword(formData);
      if (!validation.valid) {
        throw validation;
      }

      response = await fpCustomerChangePasswordAsync(formData);
    }

    fpSnackbarMessageHandler(null, memberCenterMessageEnum.ChangesHaveBeenSaved, "success");
    fpSpinnerHandler("hide", null);

    if (response) {
      document.querySelector("[data-fp-element='mc_modal_wrapper']")?.remove();
      _fp.metadata.member_center_displayed = false;
      fpShowMemberCenterFragment();
    }
  } catch (error) {
    fpSpinnerHandler("hide", null);
    fpFormErrorElementHandler(evt, "show", error);
    fpTrackUserAction("fp_error", null, "mc_form_submit", null, error);
  }
}

function fpFormErrorElementHandler(evt, type, error) {
  const wrapperEl = evt.currentTarget ? evt.currentTarget : evt.srcElement;
  if (!wrapperEl) {
    return;
  }

  let errorEl = wrapperEl.querySelector("[data-fp-element='mc_form_error']");
  if (type === "clear" && errorEl) {
    errorEl.innerHTML = "";
    return;
  }

  if (!errorEl) {
    const newErrorEl = document.createElement("div");
    newErrorEl.style = "color:red;text-align:left;";
    newErrorEl.setAttribute("data-fp-element", "mc_form_error");
    wrapperEl.appendChild(newErrorEl);
    errorEl = wrapperEl.querySelector("[data-fp-element='mc_form_error']");
  }
  errorEl.innerHTML = "";

  let errorMessages = [];
  if (error?.error_messages) {
    errorMessages = error.error_messages;
  } else if (error?.error_message && typeof error?.error_message === "string") {
    errorMessages.push(fpErrorHandler(null, error?.error_message, true));
  } else if (error?.error && typeof error.error === "string") {
    errorMessages.push(fpErrorHandler(null, error, true));
  }

  errorMessages.forEach((msg) => (errorEl.innerHTML += `<p style="margin:0;color:red;">${msg}</p>`));
}
