import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpLoadScriptsAsync } from "../../../core/utils/fp-load-scripts";
import { fpTrackUserAction } from "../../../core/utils/fp-tracking";

export async function fpRecaptchaGoogleGenerateToken(actionType) {
    const recaptchaToken = _fp.config?.payment?.recaptcha?.token;
    if (!recaptchaToken) {
        fpErrorHandler("Recaptcha token not provided", null);
        return null;
    }

    if (typeof grecaptcha === "undefined") {
        await fpLoadScriptsAsync([`https://www.google.com/recaptcha/api.js?render=${recaptchaToken}`]);
    }

    if (typeof grecaptcha === "undefined") {
        return null;
    }

    let recaptchaTokenPromiseResolve;
    const recaptchaTokenPromise = new Promise((resolve) => recaptchaTokenPromiseResolve = resolve);

    grecaptcha.ready(async function () {
        try {
            const token = await grecaptcha.execute(recaptchaToken, { action: actionType });
            recaptchaTokenPromiseResolve(token);
        } catch (error) {
            // fpErrorHandler("Google Recaptcha", error);
            fpTrackUserAction("fp_error", null, "recaptcha_token", null, error);
            recaptchaTokenPromiseResolve(null);
        }
    });

    return await recaptchaTokenPromise;
}