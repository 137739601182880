import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetHomeDeliveryTemplateListeners } from "./fp-set-home-delivery-listeners";
import { fpSetHomeDeliveryTemplate } from "./fp-set-home-delivery-template";

export function fpShowHomeDeliveryFragment(templateName) {
     try {
          _fp.premium_wrapper.innerHTML = fpSetHomeDeliveryTemplate(templateName);
          fpSetHomeDeliveryTemplateListeners(templateName);
          fpTrackUserAction("fp_fragment_displayed", null, "home_delivery", null, null);
     } catch (error) {
          fpTrackUserAction("fp_error", null, "home_deliveryl", null, error);
          fpErrorHandler("Home Delivery", error);
     }
}