import { fpSendRequest } from "../../utils/fp-http-request";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";

export async function fpResetPasswordAsync(data) {
    try {
        let request = {
            "token": data.token,
            "password": data.password,
            "language": _fp?.config?.client?.language ? _fp.config.client.language : null
        };

        if (_fp.config?.client?.site) {
            request.site = _fp.config?.client?.site;
        }

        let url = _fp.config.authority + "/" + _fp.config.client.id + "/reset_password";
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        throw fpRequestErrorHandler(err);
    }
}