import { fpSendRequest } from "../../utils/fp-http-request";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";

export async function fpUserVerifyEmailAsync(token) {
    try {
        const request = {
            token: token,
            site: _fp?.config?.client?.site ?? null
        };

        const url = _fp.config.authority + "/" + _fp.config.client.id + "/verify_email_address";
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        throw fpRequestErrorHandler(err);
    }
}