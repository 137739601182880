import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseRevertUserCancellation(purchaseId) {
    try {
        const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/revert_user_cancellation`;
        await fpSendRequest("POST", url, null);
        return true;
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
