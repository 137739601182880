import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../../../core/utils/fp-set-template-price-details";

export function fpSetCartItemsTemplate() {
    const template = fpGetTemplateByName("cart_fragment", "items");
    const templateDOM = new DOMParser().parseFromString(template, "text/html");

    fpSetPricesFragmentTabsElement(templateDOM);
    fpSetCartPricesOptions(templateDOM);

    let templateHTML = templateDOM.querySelector("body").innerHTML;
    templateHTML = templateHTML
        .fpPopulateTemplate("badge", _fp?.journey?.badge ?? "none")
        .fpPopulateTemplate("country_code", _fp?.metadata?.access_status?.country_code ?? "none");

    return templateHTML;
}

function fpSetPricesFragmentTabsElement(templateDOM) {
    const settings = _fp.config.fragments?.cart_fragment?.settings;
    const pricesTabsEl = templateDOM.querySelector("[data-fp-element='prices_tabs']");
    if (!pricesTabsEl || !settings?.tabs?.length) {
        pricesTabsEl?.remove();
        return;
    }

    let tabsTemplateHTML = "";
    settings.tabs.forEach((tab, ind) => {
        let tabTemplateHTML = JSON.parse(JSON.stringify(pricesTabsEl.innerHTML));
        tabTemplateHTML = tabTemplateHTML
            .fpPopulateTemplate("tab_id", `tab-id-${ind}`)
            .fpPopulateTemplate("tab_label", tab.label)
            .fpPopulateTemplate("tab_value", tab.badge)
            .fpPopulateTemplate("tab_checked", ind === 0 ? "checked='checked'" : "");

        tabsTemplateHTML += tabTemplateHTML;
    });

    pricesTabsEl.innerHTML = tabsTemplateHTML;
    return tabsTemplateHTML;
}

function fpSetCartPricesOptions(templateDOM) {
    const priceEl = templateDOM.querySelector("[data-fp-element='price']");
    if (!priceEl) {
        console.error("Prices Fragment template is missing mandatory attribute -> [data-fp-element='price']");
        throw new Error("Missing Mandatory DOM Element");
    }

    let pricesHtml = "";
    _fp.metadata.prices.forEach((price, ind) => {
        pricesHtml += fpPopulateTemplatePriceDetails(priceEl.outerHTML, price, ind);
    });

    priceEl.outerHTML = pricesHtml;
}

