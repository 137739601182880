import { fpGetTemplateByName } from "../../../core/utils/fp-set-template";
import { fpSetPricesTemplate } from "../../prices/fp-set-prices-fragment-template";

export function fpSetMeteredPaywallTemplateRegistrationRequired() {
    const layoutTemplate = fpGetTemplateByName("metered_paywall_fragment", "registration_required");
    const layoutDOM = new DOMParser().parseFromString(layoutTemplate, "text/html");

    const pricesWrapperEl = layoutDOM.querySelector("[data-fp-element='prices']");
    if (pricesWrapperEl) {
        pricesWrapperEl.innerHTML = fpSetPricesTemplate(true);
    }

    return layoutDOM.querySelector("body").innerHTML;
}