import { paymentActionEnum } from "../../../core/enums/fp-payment-action.enum";
import { fpUpdatePaymentIntentAsync } from "../../../core/requests/purchase/fp-payment-intents";
import { fpProcessPaymentWithStripe24Async } from "./utils/fp-stripe-process-purchase";
import { fpUpdateStripe24PaymentMethodAsync } from "./utils/fp-stripe-update-payment-method";

export async function fpSripe24ActionHandlerAsync(actionType) {
    let isSuccess = true;
    if (actionType === paymentActionEnum.UpdateOtherElementPaymentAmount) {
        isSuccess = fpStripeUpdateOtherElementPaymentAmount();
    } else if (actionType === paymentActionEnum.UpdatePaymentMethod) {
        isSuccess = await fpUpdateStripe24PaymentMethodAsync("card");
    } else if (actionType === paymentActionEnum.ProcessPayment) {
        isSuccess = await fpProcessPaymentWithStripe24Async(
            _fp.payment_providers.stripe24.payment.payment_intent_id,
            _fp.payment_providers.stripe24.payment.payment_client_secret,
            "card",
            _fp?.payment_providers.stripe24?.payment?.elements,
            null
        );
    }

    return isSuccess;
}

export async function fpStripeUpdateOtherElementPaymentAmount() {
    if (!_fp.config.payment.allow_other_payment_method) { return; }

    _fp.config.payment.providers = await fpUpdatePaymentIntentAsync(_fp.metadata.payment_providers.payment.id);

    // Update Stripe elements
    const res = await _fp.payment_providers.stripe24.payment.elements.fetchUpdates();

    return Object.keys(res)?.length === 0;
}
