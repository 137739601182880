import { authTypeEnum } from "../../../core/enums/fp-auth-type.enum";
import { paymentMethodEnum } from "../../../core/enums/fp-payment-method";
import { fpCreatePurchaseAsync } from "../../../core/requests/purchase/fp-create-purchase";
import { fpExecutePurchaseAsync } from "../../../core/requests/purchase/fp-execute-purchase";
import { fpGetJourneyConfig } from "../../../core/utils/fp-get-journeys-config";
import { fpSnackbarMessageHandler } from "../../../core/utils/fp-snackbar";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../core/utils/fp-tracking";
import { fpAuthenticationActionAsync } from "../../authentication/fp-auth-actions";
import { fpStripeOnError } from "./fp-stripe-error-handler";

// eslint-disable-next-line sonarjs/cognitive-complexity
export async function fpStripeProcessPaymentAsync(type, elements, paymentMethodId) {
    try {
        let stripePaymentResponse;
        if (type === paymentMethodEnum.StripeCard) {
            stripePaymentResponse = await _fp.payment_provider.stripe.createPaymentMethod("card", elements);
        } else if (type === paymentMethodEnum.StripeExpressCheckout) {
            stripePaymentResponse = await _fp.payment_provider.stripe.createPaymentMethod({ elements });
        }

        if (!stripePaymentResponse || stripePaymentResponse.error) {
            fpStripeOnError(stripePaymentResponse);
            return false;
        }

        const paymentMethod = stripePaymentResponse.paymentMethod ? stripePaymentResponse.paymentMethod : stripePaymentResponse;
        const purchaseRequest = fpStripeSetPurchaseRequestObject(paymentMethod);
        // else if (type === paymentMethodEnum.StripeSubscriptions) {
        //     // response = await fpStripeConfirmCardPayment(card, purchase.payment_client_secret, paymentMethodId);
        // } else if ([paymentMethodEnum.StripeApplePay, paymentMethodEnum.StripeGooglePay].includes(type)) {
        //     delete purchaseRequest.card;
        //     purchaseRequest.payment_method_id = paymentMethodId;
        // }


        // If simple checkout enabled(by config) a user needs to be signed up before a purchase can be created       
        // Simple Checkout should be enabled only for the simplest flows without any auth validations or social auth 
        const fpJourneyConfig = fpGetJourneyConfig();

        if (fpJourneyConfig?.simple_checkout && (!_fp.user || !_fp.user.access_token)) {
            const response = await fpAuthenticationActionAsync(authTypeEnum.SilentSignUp);
            if (!response?.success) {
                console.error("FP:: User not signed up properly");
                fpSnackbarMessageHandler(null, response.error_message, "error");
                return false;
            }
        }

        fpTrackUserAction("fp_purchase_request", null, "purchase", null, null);
        const purchaseResponse = await fpCreatePurchaseAsync(purchaseRequest);
        if (!purchaseResponse || purchaseResponse.error) {
            throw purchaseResponse?.error;
        }

        if (purchaseResponse.executed) {
            purchaseResponse.isNewPurchase = true;
            _fp.metadata.purchase = purchaseResponse;
            fpTrackUserAction("fp_purchase_resolved", null, "purchase", purchaseResponse.id, purchaseResponse);
            return true;
        }

        let stripeResponse = null;
        const isFreePurchase = purchaseResponse.free || purchaseResponse.amount === 0;
        const isOtherPaymentMethod = [paymentMethodEnum.StripeApplePay, paymentMethodEnum.StripeGooglePay, paymentMethodEnum.StripeSubscriptions].includes(type);
        if (isFreePurchase && isOtherPaymentMethod) {
            stripeResponse = await _fp.payment_provider.stripe.confirmCardSetup(purchaseResponse.payment_client_secret, { payment_method: paymentMethodId });
        } else if (isOtherPaymentMethod) {
            fpTrackUserAction("fp_purchase_action_required", null, type, purchaseResponse.id, null);
            stripeResponse = await _fp.payment_provider.stripe.confirmCardPayment(purchaseResponse.payment_client_secret, { payment_method: paymentMethodId }, { handleActions: false });
            if (["requires_action", "requires_source_action"].includes(stripeResponse?.paymentIntent?.status)) {
                fpTrackUserAction("fp_purchase_action_required", null, "payment_method_validation", purchaseResponse.id, null);
                stripeResponse = await _fp.payment_provider.stripe.confirmCardPayment(purchaseResponse.payment_client_secret);
            }
        } else {
            fpTrackUserAction("fp_purchase_action_required", null, type, purchaseResponse.id, null);
            fpTrackUserAction("fp_purchase_action_required", null, "payment_method_validation", purchaseResponse.id, null);
            stripeResponse = await _fp.payment_provider.stripe.handleCardAction(purchaseResponse.payment_client_secret);
        }

        if (!stripeResponse || (stripeResponse.error || (!stripeResponse.setupIntent && !stripeResponse.paymentIntent))) {
            throw stripeResponse;
        }

        const executeRequest = { paymentIntentId: stripeResponse?.paymentIntent?.id, setupIntentId: stripeResponse?.setupIntent?.id };
        const executeResponse = await fpExecutePurchaseAsync(purchaseResponse.id, executeRequest);
        if (!executeResponse?.executed) {
            return false;
        }

        _fp.metadata.purchase = executeResponse;
        fpTrackUserAction("fp_purchase_resolved", null, "purchase", executeResponse.id, executeResponse);
        return true;
    } catch (error) {
        fpStripeOnError(error);
        fpSpinnerHandler("hide", null);
        fpTrackUserAction("fp_error", null, "purchase", null, "Generic error");
        return false;
    }
}

function fpStripeSetPurchaseRequestObject(paymentMethod) {
    if (paymentMethod.billing_details) {
        ["address", "phone"].forEach((k) => delete paymentMethod.billing_details[k]);
    }

    let purchaseRequest = {
        card: {
            payment_method_id: paymentMethod?.id,
            exp_month: paymentMethod?.card.exp_month,
            exp_year: paymentMethod?.card.exp_year,
            last4: paymentMethod?.card.last4
        },
        billing_details: paymentMethod.billing_details
    };

    if (_fp.user.access_token && _fp.user.billing_details) {
        purchaseRequest.billing_details = _fp.user.billing_details;
    } else if (!_fp.user.billing_details) {
        _fp.user.billing_details = purchaseRequest.billing_details;
    }

    return purchaseRequest;
}