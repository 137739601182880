import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { paymentProviderEnum } from "../../core/enums/fp-payment-provider.enum";
import { fpInitializeStripeAsync } from "./stripe/fp-stripe-init";
import { fpInitializeStripe2024Async } from "./stripe2024/fp-init";
import { fpGetPaymentProvidersAsync } from "../../core/utils/fp-get-payment-providers";
import { fpInitializeAuthorizeNetAsync } from "./authorize.net/fp-authorize-init";

export async function fpInitializePaymentMethodAsync(type, purchaseId, actionType) {
    try {
        const paymentProviders = await fpGetPaymentProvidersAsync("payment", purchaseId, actionType);
        if (!paymentProviders || (Object.keys(paymentProviders)?.length ?? 0) === 0) {
            return;
        }

        if (paymentProviders[paymentProviderEnum.Stripe]) {
            fpInitializeStripeAsync(type);
        } else if (paymentProviders[paymentProviderEnum.Stripe2024]) {
            fpInitializeStripe2024Async("payment", paymentProviders[paymentProviderEnum.Stripe2024]);
        } else if (paymentProviders[paymentProviderEnum.AuthorizeNet]) {
            fpInitializeAuthorizeNetAsync("payment", paymentProviders[paymentProviderEnum.AuthorizeNet]);
        }
    } catch (error) {
        fpErrorHandler("Initialize Payment Provider", error);
    }
}
