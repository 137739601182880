import { fpErrorHandler } from "./fp-error-handler";
import { fpGetJourneyConfig } from "./fp-get-journeys-config";
import { fpIsShopfrontPage } from "./fp-is-shopfront-page";

export function fpSetFragmentTeaser() {
  try {
    const journeyConfig = fpGetJourneyConfig();
    const teaserSettings = journeyConfig?.teaser ? journeyConfig.teaser : _fp.config?.teaser;

    const isTeaserDisabled = teaserSettings && !teaserSettings?.enabled;
    if (!_fp.metadata?.content || isTeaserDisabled || fpIsShopfrontPage()) {
      return "";
    }

    let teaserHtml = "";
    const teaserQueries = (teaserSettings?.queries) ?? null;
    const teaserNoChildNodes = (teaserSettings?.no_child_nodes) ?? null;
    if (teaserQueries) {
      teaserHtml = fpSetTeaserByQueries(teaserQueries);
    } else if (teaserNoChildNodes && _fp.premium_wrapper) {
      teaserHtml = fpSetTeaserByChildNodes(teaserNoChildNodes);
    }

    if (teaserSettings?.enabled_fadeout && _fp.config.fragments?.teaser_fade_out?.template) {
      teaserHtml += _fp.config.fragments.teaser_fade_out.template;
    }

    return teaserHtml;
  } catch (error) {
    fpErrorHandler("Set Fragment Teaser", error);
    return "";
  }
}

function fpSetTeaserByQueries(teaserQueries) {
  let teaserHtml = "";
  const contentDom = new DOMParser().parseFromString(_fp.metadata.content, "text/html");
  teaserQueries.forEach((query) => {
    const el = contentDom.querySelector(query);
    if (el) {
      teaserHtml += el.outerHTML;
    }
  });

  return teaserHtml;
}

function fpSetTeaserByChildNodes(teaserNoChildNodes) {
  let teaserHtml = "";
  const allNodes = _fp.premium_wrapper.childNodes;
  for (let ind = 0; ind < teaserNoChildNodes; ind++) {
    const node = allNodes[ind];
    let el = node;
    if (node.nodeType === Node.TEXT_NODE) {
      const spanEl = document.createElement("span");
      spanEl.innerHTML = node.nodeValue.trim();
      el = spanEl;
    }

    teaserHtml += el.outerHTML;
  }

  return teaserHtml;
}