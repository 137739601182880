import { paymentActionEnum } from "../../../core/enums/fp-payment-action.enum";
import { paymentMethodEnum } from "../../../core/enums/fp-payment-method";
import { fpStripeUpdateOtherElementPaymentAmount } from "./fp-stripe-elements";
import { fpStripeProcessPaymentAsync } from "./fp-stripe-process-payment";
import { fpStripeUpdatePaymentMethodAsync } from "./fp-stripe-update-payment-method";

// import { fpInitializePaymentMethodAsync } from "../fp-init-payment-method"; 

export async function fpSripeActionHandlerAsync(fragmentType, actionType, paymentMethod) {
    let isSuccess = true;
    if (actionType === paymentActionEnum.ProcessPayment) {
        const elements = fpStripeSetCardObject(fragmentType, null);
        isSuccess = await fpStripeProcessPaymentAsync(paymentMethodEnum.StripeCard, elements);
    } else if (actionType === paymentActionEnum.UpdatePaymentMethod) {
        // await fpInitializePaymentMethodAsync(fragmentType); --> 24/07/24 - Temp update card fix

        const card = fpStripeSetCardObject(fragmentType, paymentMethod);
        isSuccess = await fpStripeUpdatePaymentMethodAsync(card, paymentMethod);
    } else if (actionType === paymentActionEnum.UpdateOtherElementPaymentAmount) {
        isSuccess = fpStripeUpdateOtherElementPaymentAmount(fragmentType);
    }

    return isSuccess;
}

function fpStripeSetCardObject(fragmentType, paymentMethod) {
    if (paymentMethod) {
        return null;
    }
    
    const elements = _fp?.payment_provider?.elements[fragmentType];
    if (!elements) {
        return null;
    }
    
    const stripeCardEl = elements?.getElement("card")
        ? elements.getElement("card")
        : elements.getElement("cardNumber");

    return JSON.parse(JSON.stringify(stripeCardEl));
}