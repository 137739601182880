import { fpErrorHandler } from "./fp-error-handler";

export function fpIsMediaPage() {
    try {
        let isMediaPage = false;
        if (_fp.config.redirects?.media_pages?.length > 0) {
            isMediaPage = _fp.config.redirects?.media_pages.indexOf(document.location.href) > -1;
            if (!isMediaPage) {
                isMediaPage = !!_fp.config.redirects?.media_pages.find(x => document.location.href.includes(x));
            }
        }
        return isMediaPage;
    } catch (err) {
        fpErrorHandler("Shopfront check", err);
        return false;
    }
}