import { fpGetCustomerAccessStatusAsync } from "../../access/fp-access";
import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { errorMessageEnum } from "../../core/enums/fp-error-message.enum";
import { homeDeliveryTypeEnum } from "../../core/enums/fp-home-delivery-type.enum";
import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { fpClearFlowWrapperElement } from "../../core/utils/fp-clear-flow-wrapper";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpFindSelectedPriceInAvailablePrices } from "../../core/utils/fp-set-selected-price";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpTriggerJourneyByAccessStatusAsync } from "../../journeys/fp-initialize-journey";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { fpPaymentActionHandlerAsync } from "../../providers/payment/fp-payment-action-handler";
import { fpShowHomeDeliveryFragment } from "../home-delivery/fp-home-delivery-fragment";
import { fpFindNavigationElements, fpGetAvailableNavigationTypes, fpNavigationOnClick } from "../navigation/fp-navigation-fragment";
import { fpShowPricesFragment } from "../prices/fp-prices-fragment";

export function fpSetOrderSummaryTemplateListeners() {
  try {
    // Mark selected price as checked, as it cannot be done in the template population method
    const selectedPlanEl = document.querySelector(`[data-fp-element='fp-price-${_fp.metadata?.selected_price?.id}']`);
    if (selectedPlanEl) {
      selectedPlanEl.checked = true;
    }

    // Set customer custom actions listeners
    const clearEls = document.querySelectorAll("[data-fp-element='clear']");
    clearEls?.forEach(el => el.addEventListener("click", fpClearFlowWrapperElement, false));

    const goBackBtnEls = document.querySelectorAll("[data-fp-element='back']");
    goBackBtnEls?.forEach(goBackBtnEl => goBackBtnEl.addEventListener("click", fpShowPricesFragment, false));

    // Set form listeners
    const formEl = document.querySelector("[data-fp-element='payment-form']");
    if (!formEl) {
      console.error("FP::", "Payment form element not found");
      return;
    }

    formEl.addEventListener("change", (e) => fpOnOrderSummaryFormChange(e), false);
    formEl.addEventListener("submit", (e) => fpOnOrderSummaryFormSubmit(e), false);

    const editAddressEl = document.querySelector("[data-fp-element='edit_address']");
    editAddressEl?.addEventListener("click", () => fpShowHomeDeliveryFragment(homeDeliveryTypeEnum.Form), false);

    // Set toggle listeners
    if (_fp.config.fragments.order_summary_fragment?.settings?.item_descriptions?.toggle_enabled) {
      const orderSummaryItemsEls = document.querySelectorAll("[data-fp-element='order-summary-item']");
      orderSummaryItemsEls?.forEach((item) => {
        const toggleItemDescEl = item.querySelector("[data-fp-element='toggle-item-description']");
        toggleItemDescEl?.addEventListener("click", () => fpToggleItemDescription(item), false);
      });
    }

    // Set coupon code listeners
    fpSetOrderSummaryCouponCodeListeners();

    // Set authentication listeners
    const navigationElements = fpGetAvailableNavigationTypes();
    if (_fp.config.navigation.enabled && navigationElements?.find(x => x.type === authTypeEnum.SignIn)) {
      const navItem = navigationElements.find(x => x.type === authTypeEnum.SignIn);
      const signInEls = fpFindNavigationElements(navItem?.selectors);
      signInEls?.forEach(el => el.addEventListener("click", () => fpNavigationOnClick(authTypeEnum.SignIn, authTypeEnum.SignIn), false));
    }

  } catch (error) {
    fpErrorHandler("Order Summary Template Listeners", error);
  }
}

function fpOnOrderSummaryFormChange(evt) {
  evt.preventDefault();

  fpClearSummaryFormErrors(evt?.currentTarget);

  const elType = evt?.srcElement?.type;
  if (["checkbox", "date"].includes(elType) || (evt?.srcElement?.type === "radio" && evt?.srcElement?.name === "fp_payment_method")) { // Do not continue as this type is used only for card/other payment element switcher
    return;
  }

  const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());
  _fp.metadata.selected_price = formData.price_id
    ? _fp.metadata.prices.find((x) => x.id === formData.price_id)
    : _fp.metadata.selected_price;

  fpTrackUserAction("fp_user_action", "order_summary_price_selected", null, _fp.metadata.selected_price.id, _fp.metadata.selected_price);

  const isGift = _fp.metadata?.selected_price?.gift || (_fp.metadata?.selected_price?.placeholder && !!_fp.metadata?.selected_price?.placeholder_prices?.find(x => x.gift));
  if (!isGift && _fp.metadata?.selected_price?.validate_address && !_fp.user?.address) {
    fpShowHomeDeliveryFragment();
    return;
  } else if (isGift && _fp.metadata?.selected_price.placeholder_prices?.length > 1) {
    _fp.metadata.selected_placeholder_price = JSON.parse(JSON.stringify(_fp.metadata?.selected_price));
    _fp.metadata.prices = _fp.metadata?.selected_price.placeholder_prices;
    _fp.metadata.selected_price = _fp.metadata.prices[0];

    fpTriggerJourney(journeyTypeEnum.OrderSummary);
    return;
  }

  fpPaymentActionHandlerAsync(paymentFragmentEnum.OrderSummary, paymentActionEnum.UpdateOtherElementPaymentAmount);
}

async function fpOnOrderSummaryFormSubmit(evt) {
  evt.preventDefault();
  fpClearSummaryFormErrors(evt?.currentTarget);
  fpTrackUserAction("fp_user_action", "order_summary_form_submit", null, null, null);
  const response = await fpPaymentActionHandlerAsync(paymentFragmentEnum.OrderSummary, paymentActionEnum.ProcessPayment);
  if (response?.success) {
    fpTriggerJourney(journeyTypeEnum.PremiumContent);
  }
}

function fpToggleItemDescription(item) {
  const itemDescriptionEl = item.querySelector("[data-fp-element='price-descriptions']");
  if (itemDescriptionEl) {
    const itemDescDisplayed = itemDescriptionEl.style.display === "block";
    itemDescriptionEl.style.display = itemDescDisplayed ? "none" : "block";
  }
}

function fpSetOrderSummaryCouponCodeListeners() {
  // Set coupon code submit listeners
  const couponCodeBtnsEls = document.querySelectorAll("[data-fp-element='coupon-submit']");
  couponCodeBtnsEls?.forEach(el => el.addEventListener("click", () => fpOnCouponCodeSubmitAsync(), false));

  // Set coupon code elements values
  if (_fp.metadata?.coupon?.code) {
    const couponCodeInputsEls = document.querySelectorAll("[data-fp-element='coupon-input']");
    couponCodeInputsEls.forEach(inputEl => inputEl.value = _fp.metadata.coupon.code);
  }

  // Set error if necessary
  const showError = !!_fp?.metadata?.coupon?.code && !(_fp?.metadata?.prices ?? []).find(x => x.coupon_applied);
  if (showError) {
    const errEls = document.querySelectorAll("[data-fp-element='coupon-error']");
    errEls.forEach(el => el.innerHTML = errorMessageEnum.CouponInvalid);
  }
}

async function fpOnCouponCodeSubmitAsync() {
  const couponCodeValue = document.querySelector("[data-fp-element='coupon-input']")?.value;
  if (!couponCodeValue) {
    return;
  }

  _fp.metadata = _fp.metadata ?? {};
  _fp.metadata.coupon = { code: couponCodeValue };
  const accessStatus = await fpGetCustomerAccessStatusAsync(true);
  if (!accessStatus || !accessStatus.prices?.length) {
    return;
  }

  _fp.metadata.selected_price = fpFindSelectedPriceInAvailablePrices(null, _fp.metadata.selected_price.id);
  fpTriggerJourneyByAccessStatusAsync(accessStatus);
}

function fpClearSummaryFormErrors(formEl) {
  const errEls = formEl?.querySelectorAll(".fp-control-error");
  errEls?.forEach(el => el.innerHTML = "");
}
