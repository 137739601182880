/* eslint-disable no-unused-vars */
import { homeDeliveryTypeEnum } from "../../../core/enums/fp-home-delivery-type.enum";
import { memberCenterSubscriptionActionEnum } from "../../../core/enums/fp-member-center-subscription-action.enum";
import { paymentActionEnum } from "../../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../../core/enums/fp-payment-fragment.enum";
import { memberCenterMessageEnum } from "../../../core/enums/fp-member-center-message-enum";
import { fpGetPriceByIdAsync } from "../../../core/requests/prices/fp-get-price";
import { fpPurchaseCancelRenewal } from "../../../core/requests/purchase/fp-cancel-renewals";
import { fpSetDeliveryHolidaysAsync } from "../../../core/requests/purchase/fp-delivery-holidays";
import { fpPurchaseUpdateContributionAmount } from "../../../core/requests/purchase/fp-update-contribution-amount";
import { fpPurchaseUpdateDeliveryAddress } from "../../../core/requests/purchase/fp-update-delivery-address";
import { fpCurrencyToSymbol } from "../../../core/utils/fp-currency-to-symbol";
import { fpDispatchCustomEvent } from "../../../core/utils/fp-custom-event";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpFormatDateString } from "../../../core/utils/fp-format-date-string";

import { fpGetTemplateByName } from "../../../core/utils/fp-set-template";
import { fpSpinnerHandler } from "../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../core/utils/fp-tracking";
import { fpInitUpdatePaymentMethodAsync } from "../../../providers/payment/fp-init-update-payment-method";
import { fpPaymentActionHandlerAsync } from "../../../providers/payment/fp-payment-action-handler";
import { fpHomeDeliveryInitAddressAutocompleteAsync, fpHomeDeliverySetFullAddress } from "../../home-delivery/fp-set-home-delivery-listeners";
import { fpSetHomeDeliveryTemplate } from "../../home-delivery/fp-set-home-delivery-template";
import { fpSetVoluntaryContributionAmountOptions } from "../../voluntary-contribution/fp-set-voluntary-contribution-template";
import { fpVoluntaryContributionSetOnAmountChangeListeners } from "../../voluntary-contribution/fp-set-voluntary-contribution-template-listeners";
import { fpShowMemberCenterFragment } from "../fp-member-center-fragment";
import { fpSnackbarMessageHandler } from "../../../core/utils/fp-snackbar";

import { fpMemberCenterSetBackAction } from "./actions/fp-mc-back-action";
import { fpMemberCenterUpdateSubscriptionHandler } from "./actions/fp-mc-update-subscription-action";
import { fpPurchaseRevertUserCancellation } from "../../../core/requests/purchase/fp-revert-user-cancellation";

export async function fpMemberCenterSubscriptionActionsHandler(evt) {
  const type = evt.currentTarget.dataset.fpElement.split("mc_action_")[1];
  const wrapEl = evt.currentTarget.parentElement;
  if (!wrapEl) {
    return;
  }

  if (type === memberCenterSubscriptionActionEnum.UpdateRenewalAmount && _fp?.user?.purchase?.price_id) {
    _fp.metadata.selected_price = await fpGetPriceByIdAsync(_fp.user.purchase.price_id);
  }

  if (type !== memberCenterSubscriptionActionEnum.UpdateSubscription) {
    fpTrackUserAction("fp_user_action", type, null, null, null, "requested"); // An event fired in fpMemberCenterUpdateSubscriptionHandler
  }

  fpMemberCenterUpdateActionWrapperTemplate(wrapEl, type);
  fpMemberCenterSetActionTemplateListeners(type);

  if (type === memberCenterSubscriptionActionEnum.UpdatePaymentMethod) {
    fpInitUpdatePaymentMethodAsync(_fp?.user?.purchase?.id, paymentFragmentEnum.MemberCenter);
  } else if (type === memberCenterSubscriptionActionEnum.UpdateDeliveryAddress) {
    await fpMemberCenterSetUpdateDeliveryAddressListenersAsync();
  } else if (type === memberCenterSubscriptionActionEnum.UpdateDeliveryHolidays) {
    fpMemberCenterSetDeliveryHolidaysDetails(evt);
  }
}

function fpMemberCenterUpdateActionWrapperTemplate(wrapEl, type) {
  const childEl = fpMemberCenterSetActionTemplate(type);
  childEl.id = "fp-action-template";

  wrapEl.appendChild(childEl);
  wrapEl.classList.add(`fp-mc-sub-action-${type}`);

  const contentEl = document.querySelector("[data-fp-element='mc_content']");
  if (!contentEl) {
    return;
  }
  const contentElHeight = document.querySelector("#fp-action-template")?.scrollHeight;
  contentEl.style.minHeight = `${contentElHeight ?? 0}px`;
}

function fpMemberCenterSetActionTemplateListeners(type) {
  fpMemberCenterSetBackAction();

  const wrapperEl = document.querySelector(`[data-fp-form-type='${type}']`);
  if (type === memberCenterSubscriptionActionEnum.UpdateRenewalAmount) {
    fpVoluntaryContributionSetOnAmountChangeListeners(wrapperEl);
  }

  wrapperEl.addEventListener(
    "submit",
    async (evt) => {
      try {
        evt.preventDefault();

        fpSpinnerHandler("show");

        const actionType = evt.currentTarget.dataset.fpFormType;
        evt.currentTarget.classList.add(actionType);

        const formData = Object.fromEntries(new FormData(wrapperEl).entries());

        let isSuccess = false;
        if (actionType === memberCenterSubscriptionActionEnum.UpdatePaymentMethod) {
          const actionResponse = await fpPaymentActionHandlerAsync(paymentFragmentEnum.MemberCenter, paymentActionEnum.UpdatePaymentMethod);
          isSuccess = actionResponse.success;
        } else if (actionType === memberCenterSubscriptionActionEnum.CancelRenewals) {
          isSuccess = await fpPurchaseCancelRenewal(_fp.user.purchase.id, null);
        } else if (actionType === memberCenterSubscriptionActionEnum.UpdateRenewalAmount) {
          const amount = formData?.amount === "other" ? document.querySelector("#other-amount")?.value : formData?.amount;
          isSuccess = await fpPurchaseUpdateContributionAmount(_fp.user.purchase.id, amount);
        } else if (actionType === memberCenterSubscriptionActionEnum.UpdateDeliveryAddress) {
          isSuccess = await fpMemberCenterValidateUpdateDeliveryAddress(formData);
        } else if (actionType === memberCenterSubscriptionActionEnum.UpdateDeliveryHolidays) {
          isSuccess = await fpSetDeliveryHolidaysAsync(_fp.user.purchase.id, _fp.user.purchase.user_id, formData?.holidays_id, formData?.start_at, formData?.finish_at);
        } else if (actionType === memberCenterSubscriptionActionEnum.UpdateSubscription) {
          const action = (!!_fp.metadata?.member_center?.action_type && _fp.metadata?.member_center?.action_confirmed) ? "process_action" : "confirm_action";
          isSuccess = await fpMemberCenterUpdateSubscriptionHandler(null, action, evt?.submitter?.dataset, formData);
        } else if (actionType === memberCenterSubscriptionActionEnum.RevertUserCancellation) {
          isSuccess = await fpPurchaseRevertUserCancellation(_fp.user.purchase.id);
        }

        if (!isSuccess) {
          fpSpinnerHandler("hide");
          return;
        }

        if (actionType !== memberCenterSubscriptionActionEnum.UpdateSubscription) {
          fpTrackUserAction("fp_user_action", actionType, null, null, null, "processed"); // An event fired in fpMemberCenterUpdateSubscriptionHandler
        }

        delete _fp?.metadata?.member_center_displayed;
        document.querySelector("[data-fp-element='mc_content']")?.remove();
        document.querySelector("[data-fp-element='mc_modal_wrapper']")?.remove();

        fpDispatchCustomEvent(document.body, "fpMemberCenterAction", { type: actionType, badge: _fp.user?.purchase?.badge_text });

        fpShowMemberCenterSnackbar(actionType);
        fpShowMemberCenterFragment();
        fpSpinnerHandler("hide");
      } catch (err) {
        console.error("FP MC", err);
        const formErrEl = document.querySelector("[data-fp-element='subscription_actions_form'] [data-fp-element='form_error']");
        if (formErrEl) {
          const errMsg = fpErrorHandler("Member Center User Action", err, true);
          formErrEl.innerHTML = errMsg;
        }

        fpSpinnerHandler("hide");
      }
    },
    false
  );
}

function fpMemberCenterSetActionTemplate(type) {
  let templateHTML = fpGetTemplateByName("member_center_fragment", "subscriptions_actions");
  templateHTML = templateHTML.fpPopulateTemplate("type", type);

  const templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");
  if (type === memberCenterSubscriptionActionEnum.UpdateRenewalAmount) {
    fpSetVoluntaryContributionAmountOptions(templateDOM, _fp.config.fragments?.voluntary_contribution_fragment?.settings);
  } else if (type === memberCenterSubscriptionActionEnum.UpdateDeliveryAddress) {
    fpMemberCenterSetUpdateDeliveryAddressTemplate(templateDOM, null);
  } else if (type === memberCenterSubscriptionActionEnum.UpdateSubscription) {
    fpMemberCenterUpdateSubscriptionHandler(templateDOM, "show_options", null);
  }

  const actionEls = templateDOM.querySelectorAll("[data-fp-element*='action_']");
  actionEls.forEach(el => el.dataset.fpElement.includes(type) ? null : el.remove());

  const actionTemplateEl = document.createElement("div");
  actionTemplateEl.setAttribute("data-fp-element", "mc-action-details");
  actionTemplateEl.style = "position:absolute;left:0;right:0;top:0;bottom:0;";
  actionTemplateEl.innerHTML = fpTemplatePopulatePurchaseDetails(templateDOM);

  return actionTemplateEl;
}

function fpTemplatePopulatePurchaseDetails(templateDOM) {
  let templateHTML = templateDOM.querySelector("body").innerHTML;
  Object.keys(_fp.user.purchase).forEach(key => {
    let value = _fp.user.purchase[key];
    if (key.includes("currency")) {
      value = fpCurrencyToSymbol(value);
    } else if (key.includes("_at")) {
      value = fpFormatDateString(value);
    }

    templateHTML = templateHTML.fpPopulateTemplate(key, value);
  });

  return templateHTML;
}

async function fpMemberCenterSetUpdateDeliveryAddressListenersAsync() {
  const settings = _fp.config.fragments?.home_delivery_fragment?.settings;
  if (settings?.autocomplete?.enabled) {
    const isInitialized = fpHomeDeliveryInitAddressAutocompleteAsync(settings);
    if (!isInitialized) {
      return "";
    }
  }

  const manualEl = document.querySelector("[data-fp-attribute='address_form']");
  if (manualEl) {
    manualEl.addEventListener("click", () => fpMemberCenterSetUpdateDeliveryAddressTemplate(document, homeDeliveryTypeEnum.Form), false);
  }
}

function fpMemberCenterSetUpdateDeliveryAddressTemplate(templateDOM, templateName) {
  _fp.metadata.update_delivery_form_displayed = !!templateName;

  const hdWrapEl = templateDOM.querySelector("[data-fp-element='action_update_delivery_address']");
  hdWrapEl.innerHTML = fpSetHomeDeliveryTemplate(templateName);
  if (!templateName) {
    return;
  }

  Object.keys(_fp.user.address).forEach(key => {
    const inputEl = document.querySelector(`[data-fp-element='action_update_delivery_address'] input[name='${key}'`);
    if (inputEl) { inputEl.value = _fp.user.address[key]; }
  });

  if (_fp.user.purchase?.contact_info?.name) {
    const inputEl = document.querySelector("[data-fp-element='action_update_delivery_address'] input[name='user_name']");
    if (inputEl) { inputEl.value = _fp.user.purchase.contact_info.name; }
  }
}

async function fpMemberCenterValidateUpdateDeliveryAddress(formData) {
  if (!_fp.metadata.update_delivery_form_displayed) {
    fpMemberCenterSetUpdateDeliveryAddressTemplate(document, homeDeliveryTypeEnum.Form);
    return false;
  }

  _fp.user.address = _fp.user.address ? _fp.user.address : {};
  Object.keys(formData).forEach((key) => _fp.user.address[key] = formData[key]);

  fpHomeDeliverySetFullAddress(_fp.user.address);

  const request = {
    address: _fp.user.address.full,
    address1: _fp.user.address.address_1,
    address2: _fp.user.address.address_2,
    address3: _fp.user.address.address_3,
    address4: _fp.user.address.address_4,
    address5: _fp.user.address.address_5,
    country_code: _fp.user.address.country_code,
    post_code: _fp.user.address.post_code,
    name: _fp.user.address.user_name,
    phone_number: _fp.user.address.phone_number,
  };

  return await fpPurchaseUpdateDeliveryAddress(_fp.user.purchase.id, request);
}

function fpMemberCenterSetDeliveryHolidaysDetails(evt) {
  const deliveryHolidaysId = evt.currentTarget.dataset.fpAttribute;
  if (!deliveryHolidaysId) {
    return;
  }

  const deliveryHolidays = _fp.user?.purchase?.delivery_holidays.find(x => x.id === deliveryHolidaysId);
  if (!deliveryHolidays) {
    return;
  }

  const wrapEl = document.querySelector("[data-fp-element='action_update_delivery_holidays']");
  if (!wrapEl) {
    return;
  }

  [
    { query: "input[name='holidays_id']", value: deliveryHolidays.id, type: "id" },
    { query: "input[name='start_at']", value: deliveryHolidays.start_at, type: "date" },
    { query: "input[name='finish_at']", value: deliveryHolidays.finish_at, type: "date" },
  ].forEach(x => {
    const el = wrapEl.querySelector(x.query);
    if (!el) {
      return;
    }

    if (x.type === "id") {
      el.value = x.value;
      return;
    }

    const date = new Date(x.value);
    const formattedDate = date.toISOString().substring(0, 10);
    el.value = formattedDate;

  });
}

function fpShowMemberCenterSnackbar(type) {
  const notificationType = type.fpSnakeCaseToPascalCase();
  let notificationMessage = memberCenterMessageEnum[notificationType] ?? memberCenterMessageEnum.ChangesHaveBeenSaved;
  const notificationSettings = _fp.config.fragments?.member_center_fragment?.settings?.notifications;
  if (notificationSettings) {
    let notification = notificationSettings?.messages?.find(x => x.type === type) ?? notificationSettings?.messages?.find(x => x.type === "default");
    notificationMessage = notification?.message ? notification.message : notificationMessage;
  }

  fpSnackbarMessageHandler(null, notificationMessage, "success");
}