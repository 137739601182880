import { authTypeEnum } from "../enums/fp-auth-type.enum";
import { fpFindNavigationElements, fpGetAvailableNavigationTypes, fpNavigationOnClick } from "../../fragments/navigation/fp-navigation-fragment";

export function fpSetAuthenticationNavigationListeners(type) {
    const authType = type ?? authTypeEnum.SignIn;
    const navigationElements = fpGetAvailableNavigationTypes();
    const navItem = navigationElements.find(x => x.type === authType);

    const authEls = fpFindNavigationElements(navItem?.selectors);
    if (authEls) {
        authEls?.forEach(el => {
            el.addEventListener("click", (e) => fpNavigationOnClick(e, authType), false);
        });
    }
}
