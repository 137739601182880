import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseRedeemGiftCode(code) {
    try {
        if (!code) {
            return null;
        }

        const url = `${_fp.config.base_url_transaction}/purchases/redeem`;
        const request = {
            gift_code: code,
            language: _fp?.config?.client?.language ? _fp.config.client.language : null
        };
        
        if (_fp.user?.address) {
            request.name = _fp.user.address.user_name ? _fp.user.address.user_name : request.name;
            request.address_country_code = _fp.user.address.country_code ? _fp.user.address.country_code : null;

            let addr = _fp.user.address;
            let addrFields = ["phone_number", "delivery_notes", "address_id", "full", "address_1", "address_2", "address_3", "address_4", "address_5", "post_code", "address_latitude", "address_longitude"];
            for (let af = 0; af < addrFields.length; af++) {
                if (addr[addrFields[af]]) {
                    let addrKey = addrFields[af] === "full" ? "address" : addrFields[af];
                    request[addrKey] = addr[addrFields[af]];
                }
            }
        }

        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}
