import { memberCenterTypeEnum } from "../../../../core/enums/fp-member-center-type.enum";
import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";

export function fpInjectAuthFlipPayFragment() {
    try {
        const settings = _fp.config.fragments?.authentication_fragment?.settings;
        if (settings?.type === memberCenterTypeEnum.Inline) {
            fpInjectAuthenticationInlineFragment();
        } else {
            fpInjectAuthenticationModalFragment();
        }
    } catch (err) {
        fpErrorHandler("Inject FlipPay auth fragment", err);
    }
}

function fpInjectAuthenticationInlineFragment() {
    const inlineWrapperTemplate = fpGetTemplateByName("authentication_fragment", "inline_wrapper");
    const wrapperEl = document.querySelector(_fp.config?.fragments?.authentication_fragment?.settings?.wrapper_query?.inline);
    if (!wrapperEl) {
        return;
    }

    wrapperEl.innerHTML = inlineWrapperTemplate;
}

function fpInjectAuthenticationModalFragment() {
    const modalEl = document.getElementById("fp-auth-main");
    if (modalEl) {
        return;
    }

    let modalWrapper = fpGetTemplateByName("authentication_fragment", "modal_wrapper");
    const authWrapperEl = document.querySelector("body");
    if (!authWrapperEl || !modalWrapper) {
        return;
    }

    let fpModal = authWrapperEl.querySelector("#fp-modal");
    if (!fpModal) {
        let defualtColorScheme = `
        <style>
            :root {
                --modal_background_color: #ffffff;
                --modal_border_color: #ffffff;
                --button_color: #ffffff;
                --button_background_color: #121212;
                --separator_color: #666666;
            }
        </style>
        `;

        let elemetType = !!_fp.config.auth_settings && !!_fp.config.auth_settings.fragment_type && _fp.config.auth_settings.fragment_type === "inline" ? "inline" : "modal";
        let customColorScheme = _fp.config.custom_color_scheme ? _fp.config.custom_color_scheme : defualtColorScheme;
        modalWrapper = modalWrapper.fpPopulateTemplate("custom_color_scheme", customColorScheme);
        modalWrapper = elemetType === "inline" ? "<div id=\"fp-modal\" style=\"display: none;\" ></div>" : modalWrapper;

        let frag = document.createDocumentFragment();
        let temp = document.createElement("div");
        temp.innerHTML = modalWrapper;
        while (temp.firstChild) {
            frag.appendChild(temp.firstChild);
        }

        authWrapperEl.appendChild(frag);
    }
}

export function fpSetAuthModalVisibility(type) {
    const modalEl = document.getElementById("fp-modal");
    if (!modalEl) {
        return;
    }

    modalEl.setAttribute("aria-hidden", type !== "show");
    modalEl.style.display = type === "show" ? " block" : "none";

    const clearEls = document.querySelectorAll("[data-fp-element='clear']");
    if (clearEls?.length > 0 && type !== "show" && modalEl.parentElement) {
        modalEl.parentElement.remove();
    }
}