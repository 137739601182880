export function fpScrollToElement(fpElementToScrollTo) {
    try {
        if (!fpElementToScrollTo || !(fpElementToScrollTo instanceof HTMLElement)) {
            throw new Error("Element to scroll to is not a valid HTMLElement");
        }

        fpElementToScrollTo.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest"
        });
    } catch (error) {
        console.error("FP Scroll to Element", error);
    }
}