export function fpMemberCenterSetBackAction() {
    const backBtnEl = document.querySelector("#mc-action-cancel, [data-fp-element='mc_upgrade_back']");
    backBtnEl?.addEventListener("click", () => {
        document.querySelector("div[data-fp-element='mc-action-details']")?.remove();

        // Clear class added to identify the member center action
        const classPrefix = "fp-mc-sub-action-";
        const activeEls = document.querySelectorAll(`[class*="${classPrefix}"]`);
        activeEls?.forEach(activeEl => {
            [...activeEl.classList].forEach(className => {
                if (className.startsWith(classPrefix)) {
                    activeEl.classList.remove(className);
                }
            });
        });

        // Clear min height number as it can break a layout
        const contentEl = document.querySelector("[data-fp-element='mc_content']");
        if (contentEl) {
            contentEl.style.minHeight = "unset";
        }

        delete _fp.metadata?.member_center?.action_type;
        delete _fp.metadata?.member_center?.action_confirmed;
        
    }, false);
}