import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpCustomerChangePasswordAsync(request) {
    try {
        if (!_fp.user || !_fp.user.access_token || !_fp.user.id) {
            return Promise.resolve(null);
        }

        if (!!_fp.config.client && !!_fp.config.client.site) {
            request.site = _fp.config.client.site;
        }

        await fpSendRequest("POST", `${_fp.config.original_authority}/users/${_fp.user.id}/change_password`, JSON.stringify(request));
        return { success: true };
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}