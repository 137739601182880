export function fpGetSubcriptionUpdateOptions(noGrouping) {
    const actions = [
        { prices: _fp.user?.purchase?.upgrade_prices, action_type: "upgrade" },
        { prices: _fp.user?.purchase?.downgrade_prices, action_type: "downgrade" },
        { prices: _fp.user?.purchase?.change_renewal_prices, action_type: "change_renewal_price" },
    ];

    const options = actions.reduce((acc, { prices, action_type }) => {
        if (prices) {
            acc.push(...prices.map(price => ({ ...price, action_type: action_type })));
        }
        return acc;
    }, []);

    const settings = _fp.config?.fragments?.member_center_fragment?.settings;
    const currentPlanPrice = fpSetCurrentPlanPrice();
    if (currentPlanPrice && !settings?.plan_update?.hide_active_plan) {
        options.push({ ...currentPlanPrice, action_type: "current_plan" });
    }

    if (noGrouping || !settings?.available_plans_table_config) {
        return options;
    }

    return fpGroupSubcriptionUpdateOptions(options);
}

function fpGroupSubcriptionUpdateOptions(array) {
    const config = _fp.config.fragments.member_center_fragment.settings.available_plans_table_config;

    const grouped = array.reduce((result, item) => {
        const key = item[config.group_by];
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {});

    return fpGroupByConfigGroupName(grouped, config);
}

function fpGroupByConfigGroupName(grouped, config) {
    return Object.keys(grouped).map(name => {
        const result = { name };
        config.columns.forEach(col => {
            const key = Object.keys(col)[0];
            if (key) {
                result[col[key]] = grouped[name].find(item => item[key] === col[key]);
            }
        });
        return result;
    });
}

function fpSetCurrentPlanPrice() {
    let currentPlanPrice = _fp?.user?.purchase?.price;
    const nextPlanPrice = _fp?.user?.purchase?.renewable_price;

    if (currentPlanPrice && (nextPlanPrice && currentPlanPrice?.id !== nextPlanPrice?.id)) {
        currentPlanPrice = nextPlanPrice;
    }

    return currentPlanPrice;
}