import { fpErrorHandler } from "./fp-error-handler";
import { fpGetStorageValue, fpSetStorageValue } from "./fp-storage-handler";

export function fpSetBrowserSessionId() {
    try {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        if (_fp.metadata.browser_session_id) {
            return;
        }

        const storagePropName = _fp.config?.client?.session_id_storage_name ? _fp.config.client.session_id_storage_name : "fp.browser_session_id";
        _fp.metadata.browser_session_id = fpGetStorageValue(storagePropName);
        if (storagePropName !== "fp.browser_session_id" || _fp.metadata.browser_session_id ) {
            return;
        }

        _fp.metadata.browser_session_id = fpGenerateSessionId();
        fpSetStorageValue(storagePropName, _fp.metadata.browser_session_id, null);
    } catch (error) {
        fpErrorHandler("Set Session Id", error);
    }
}

function fpGenerateSessionId() {
    let lut = [];
    for (let i = 0; i < 256; i++) {
        lut[i] = (i < 16 ? "0" : "") + (i).toString(16);
    }

    const dvals = new Uint32Array(4);
    const crypto = window.crypto || window.msCrypto;
    crypto.getRandomValues(dvals);
    const d0 = dvals[0];
    const d1 = dvals[1];
    const d2 = dvals[2];
    const d3 = dvals[3];
    return lut[d0 & 0xff] + lut[d0 >> 8 & 0xff] + lut[d0 >> 16 & 0xff] + lut[d0 >> 24 & 0xff] + "-" +
        lut[d1 & 0xff] + lut[d1 >> 8 & 0xff] + "-" + lut[d1 >> 16 & 0x0f | 0x40] + lut[d1 >> 24 & 0xff] + "-" +
        lut[d2 & 0x3f | 0x80] + lut[d2 >> 8 & 0xff] + "-" + lut[d2 >> 16 & 0xff] + lut[d2 >> 24 & 0xff] + lut[d3 & 0xff] + lut[d3 >> 8 & 0xff] + lut[d3 >> 16 & 0xff] + lut[d3 >> 24 & 0xff];
}