import { memberCenterCategoryEnum } from "../../core/enums/fp-member-center-category.enum";
import { memberCenterTypeEnum } from "../../core/enums/fp-member-center-type.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpMemberCenterPopulateItems } from "./template/fp-member-center-populate-items";
import { fpMemberCenterPopulateProfile } from "./template/fp-member-center-populate-profile";
import { fpMemberCenterPopulateSubscriptions } from "./template/fp-member-center-populate-subscriptions";

export function fpMemberCenterSetTemplates(settings, customerDetails, category) {
  try {
    // Populate member center section with details
    const categories = fpMemberCenterPopulateCategoriesTemplates(settings, customerDetails);

    // Member center where vendor/publisher defines sections and its injection rules
    if (settings.type === memberCenterTypeEnum.InlinePartial) {
      settings.sections?.forEach(section => {
        const sectionEl = document.querySelector(section.query);
        if (sectionEl && categories[section.type]) {
          sectionEl.innerHTML = categories[section.type];
        }
      });

      return;
    }

    // Member center layout generated by flip-pay
    const layoutTemplate = fpGetTemplateByName("member_center_fragment", "layout");
    const layoutDOM = new DOMParser().parseFromString(layoutTemplate, "text/html");

    const layoutSections = layoutDOM.querySelectorAll("li[data-fp-element]");
    layoutSections?.forEach(section => {
      const sectionCategory = section.dataset?.fpElement?.replace("mc_", "");
      const displaySection = !!Object.keys(categories).find(categoryKey => categoryKey === `${sectionCategory}`);
      if (!displaySection || (sectionCategory === "change_password" && _fp.user.provider !== "web-direct")) {
        section.remove();
      }
    });

    const activeTabEl = layoutDOM.querySelector("[data-fp-element='mc_categories_nav'] [data-fp-element]");
    let activeCategory = activeTabEl?.dataset?.fpElement ? activeTabEl.dataset.fpElement : memberCenterCategoryEnum.Profile;
    if (category) {
      activeCategory = category;
    }

    settings.categories?.forEach(category => {
      const elements = layoutDOM.querySelectorAll(`[data-fp-element='mc_${category}']`);
      elements?.forEach((el) => el.classList[activeCategory.includes(category) ? "add" : "remove"]("active"));

      const categoryWrapperEl = Array.from(elements).find((x) => x.nodeName === "DIV");
      if (!categoryWrapperEl) {
        return;
      }
      categoryWrapperEl.innerHTML = categories[category];
    });

    // Add aclose button to member center in a modal
    if (settings.type === memberCenterTypeEnum.Modal) {
      fpMemberCenterAppendCloseButton(layoutDOM);
    }

    const wrapperEl = fpMemberCenterSetWrapperEl(settings);
    const contentDOM = wrapperEl?.querySelector("#fp-mc-temp");
    contentDOM.outerHTML = layoutDOM?.querySelector("body").innerHTML;
  } catch (err) {
    fpSpinnerHandler("hide", null);
    fpErrorHandler("Set Member Center Template", err);
  }
}

export function fpMemberCenterPopulateCategoriesTemplates(settings, customerDetails) {
  const memberCenterSections = {};
  settings.categories?.forEach(category => {
    const categoryTemplateHTML = fpGetTemplateByName("member_center_fragment", category);

    let categoryHTML = "";
    switch (category) {
      case memberCenterCategoryEnum.Profile:
        categoryHTML = fpMemberCenterPopulateProfile(categoryTemplateHTML, customerDetails);
        break;
      case memberCenterCategoryEnum.Subscriptions:
        categoryHTML = fpMemberCenterPopulateSubscriptions(categoryTemplateHTML, customerDetails);
        break;
      case memberCenterCategoryEnum.Items:
        categoryHTML = fpMemberCenterPopulateItems(categoryTemplateHTML, customerDetails);
        break;
      case memberCenterCategoryEnum.SupportTicket:
        categoryHTML = categoryTemplateHTML; // no anchors to populate
        break;
      case memberCenterCategoryEnum.ChangePassword:
        categoryHTML = categoryTemplateHTML; // no anchors to populate
        break;
      default:
        categoryHTML = "";
        break;
    }

    memberCenterSections[category] = categoryHTML;
  });

  return memberCenterSections;
}

function fpMemberCenterSetWrapperEl(settings) {
  let mcWrapperEl = _fp.premium_wrapper;
  if (settings.type === memberCenterTypeEnum.Modal) {
    const modalWrapperQuery = "[data-fp-element='mc_modal_wrapper']";
    mcWrapperEl = document.querySelector(modalWrapperQuery);
    if (!mcWrapperEl) {
      const divEl = document.createElement("div");
      divEl.setAttribute("data-fp-element", "mc_modal_wrapper");
      document.body.appendChild(divEl);
      mcWrapperEl = document.querySelector(modalWrapperQuery);
      mcWrapperEl.outerHTML = fpGetTemplateByName("member_center_fragment", "modal_wrapper");
      mcWrapperEl = document.querySelector(modalWrapperQuery);
    }
  } else if (settings.type === memberCenterTypeEnum.Inline) {
    const query = settings?.query ? settings?.query : "[data-fp-element='mc_inline_wrapper']";
    mcWrapperEl = document.querySelector(query);
    mcWrapperEl?.innerHTML ? mcWrapperEl.innerHTML = "" : null; // Reset inner content as authentication or other elements can be injected
  }

  if (!mcWrapperEl) {
    return false;
  }

  const divEl = document.createElement("div");
  divEl.id = "fp-mc-temp";
  mcWrapperEl.appendChild(divEl);
  mcWrapperEl.style.display = "block";

  return mcWrapperEl;
}

function fpMemberCenterAppendCloseButton(layoutDOM) {
  const closeEl = document.createElement("div");
  closeEl.setAttribute("data-fp-element", "modal_close");
  closeEl.style = "position: absolute;top:5px;right:10px;cursor:pointer;";
  closeEl.innerHTML = "X";

  const contentEl = layoutDOM.querySelector("[data-fp-element='mc_content']");
  contentEl?.appendChild(closeEl);
}

// export function fpSetMemberCenterTemplate(settings, customerDetails) {
//   try {
//     const layoutTemplate = fpGetTemplateByName("member_center_fragment", "layout");
//     const layoutDOM = new DOMParser().parseFromString(layoutTemplate, "text/html");

//     if (settings.type === memberCenterTypeEnum.Modal) {
//       fpMemberCenterAppendCloseButton(layoutDOM);
//     }

//     const firstTabEl = layoutDOM.querySelector("[data-fp-element='mc_categories_nav'] [data-fp-element]");
//     const firstCategory = firstTabEl?.dataset?.fpElement ? firstTabEl.dataset.fpElement : memberCenterCategoryEnum.Profile;

//     const categories = fpMemberCenterPopulateCategories(settings, layoutDOM);
//     categories.forEach((category) => {
//       if (!category.isEnabled) {
//         category.elements.forEach((el) => el.remove());
//         return;
//       }

//       category.elements.forEach((el) => el.classList[firstCategory.includes(category.type) ? "add" : "remove"]("active"));

//       const categoryWrapperEl = Array.from(category.elements).find((x) => x.nodeName === "DIV");
//       if (!categoryWrapperEl) {
//         return;
//       }

//       let categoryTemplateHTML = fpGetTemplateByName("member_center_fragment", category.type);
//       if (category.type === memberCenterCategoryEnum.Profile) {
//         Object.keys(customerDetails.customer).forEach((key) => (categoryTemplateHTML = categoryTemplateHTML.fpPopulateTemplate(key, customerDetails.customer[key])));
//       } else if (category.type === memberCenterCategoryEnum.Items) {
//         categoryTemplateHTML = fpMemberCenterPopulateItems(categoryTemplateHTML, customerDetails);
//       } else if (category.type === memberCenterCategoryEnum.Subscriptions) {
//         categoryTemplateHTML = fpMemberCenterPopulateSubscriptions(categoryTemplateHTML, customerDetails);
//       }

//       categoryWrapperEl.innerHTML = categoryTemplateHTML;
//     });

//     return layoutDOM.querySelector("body").innerHTML;
//   } catch (error) {
//     fpErrorHandler("Set Member Center Template", error);
//     return "";
//   }
// }

// export function fpMemberCenterPopulateCategories(settings, layoutDOM) {
//   settings.categories = settings.categories ? settings.categories : [];
//   return [
//     {
//       type: memberCenterCategoryEnum.Profile,
//       isEnabled: settings.categories.includes(memberCenterCategoryEnum.Profile),
//       elements: layoutDOM.querySelectorAll("[data-fp-element='mc_profile']"),
//     },
//     {
//       type: memberCenterCategoryEnum.Subscriptions,
//       isEnabled: settings.categories.includes(memberCenterCategoryEnum.Subscriptions),
//       elements: layoutDOM.querySelectorAll("[data-fp-element='mc_subscriptions']"),
//     },
//     {
//       type: memberCenterCategoryEnum.Items,
//       isEnabled: settings.categories.includes(memberCenterCategoryEnum.Items),
//       elements: layoutDOM.querySelectorAll("[data-fp-element='mc_items']"),
//     },
//     {
//       type: memberCenterCategoryEnum.ChangePassword,
//       isEnabled: settings.categories.includes(memberCenterCategoryEnum.ChangePassword) && _fp.user.provider === "web-direct",
//       elements: layoutDOM.querySelectorAll("[data-fp-element='mc_change_password']"),
//     },
//     {
//       type: memberCenterCategoryEnum.SupportTicket,
//       isEnabled: settings.categories.includes(memberCenterCategoryEnum.SupportTicket),
//       elements: layoutDOM.querySelectorAll("[data-fp-element='mc_support_ticket']"),
//     },
//   ];
// }

