import { fpGetTemplateByName } from "../../core/utils/fp-set-template";

export function fpSetCustomerMandatoryFieldsTemplate() {
    try {
        const template = fpGetTemplateByName("customer_mandatory_fields_fragment");
        const templateDOM = new DOMParser().parseFromString(template, "text/html");

        return templateDOM.querySelector("body").innerHTML;
    } catch (error) {
        console.error("FP::", error);
        throw error;
    }
}