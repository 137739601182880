import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpSetMeteredPaywallTemplate } from "./fp-set-metered-paywall-template";
import { fpSetMeteredPaywallTemplateListeners } from "./fp-set-metered-paywall-template-listeners";

export function fpShowMeteredPaywallFragment() {
    try {
        const meteredPaywall = fpSetMeteredPaywallTemplate();
        _fp.premium_wrapper.innerHTML = meteredPaywall.html;
        fpSetMeteredPaywallTemplateListeners(meteredPaywall.type);

        fpTrackUserAction("fp_fragment_displayed", null, meteredPaywall.type, null, null);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "metered_paywall", null, error);
        fpErrorHandler("Metered Paywall Fragment", error);
    }
}