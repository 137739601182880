import { authProviderEnum } from "../../core/enums/fp-auth-provider.enum";
import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpAuthenticationActionAsync } from "../../providers/authentication/fp-auth-actions";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";
import { fpShowMemberCenterFragment } from "../member-center/fp-member-center-fragment";

export const fpNavigationItemsDisplayedByAuthStatus = {
    signed_in: [authTypeEnum.SignOut, authTypeEnum.MemberCenter],
    signed_out: [authTypeEnum.SignIn, authTypeEnum.SignUp, authProviderEnum.Google, authProviderEnum.Facebook]
};

export function fpShowNavigationFragment() {
    try {
        const elementsToBeDisplayed = _fp?.user?.access_token
            ? fpNavigationItemsDisplayedByAuthStatus["signed_in"]
            : fpNavigationItemsDisplayedByAuthStatus["signed_out"];

        // Subscribe button in the navigation element -> hide if we know a customer is a subscriber
        const subscriberCookie = fpGetStorageValue("fp.subscriber");
        const isSubscriber = !!_fp?.user?.subscriber || !!subscriberCookie?.subscriber;
        if (!isSubscriber) {
            elementsToBeDisplayed.push("navigation_subscribe_redirect");
        }

        let navTypes = fpGetAvailableNavigationTypes();
        navTypes.forEach(item => {
            const navigationElements = fpFindNavigationElements(item.selectors);
            navigationElements?.forEach(element => {
                if (!elementsToBeDisplayed.find(x => x === item.type)) {
                    element.style.display = "none";
                    return;
                }

                element.style.display = item?.style?.display || "inline-block";
                element.removeEventListener("click", (evt) => fpNavigationOnClick(evt, item.type), false);
                element.addEventListener("click", (evt) => fpNavigationOnClick(evt, item.type), false);
            });
        });

        // Handle cases where the navigation elements may appear dynamically after the page loads.
        if (_fp?.config?.navigation?.enable_observer) {
            fpInitNavigationObserver(elementsToBeDisplayed);
        }
    } catch (error) {
        fpErrorHandler("Navigation Fragment", error);
    }
}

function fpInitNavigationObserver(elementsToBeDisplayed) {
    const observer = new MutationObserver(() => {
        let navTypes = fpGetAvailableNavigationTypes();

        navTypes.forEach(item => {
            const navigationElements = fpFindNavigationElements(item.selectors);
            navigationElements?.forEach(element => {
                if (!elementsToBeDisplayed.find(x => x === item.type)) {
                    element.style.display = "none";
                    return;
                }

                element.style.display = item?.style?.display || "inline-block";
                element.removeEventListener("click", (evt) => fpNavigationOnClick(evt, item.type), false);
                element.addEventListener("click", (evt) => fpNavigationOnClick(evt, item.type), false);
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });

    const timeToDisconnectObserver = 10000;

    setTimeout(() => {
        observer.disconnect();
    }, timeToDisconnectObserver);
}

export function fpNavigationOnClick(evt, actionType) {
    evt?.preventDefault();

    fpTrackUserAction("fp_user_action", `show_${actionType}`, null, null, null);
    if (actionType?.includes("member_center")) {
        fpShowMemberCenterFragment();
        return;
    } else if (actionType === "navigation_subscribe_redirect" && _fp?.config?.redirects?.shopfront_main) {
        window.location.href = _fp?.config?.redirects?.shopfront_main;
        return;
    } else if ([authProviderEnum.Google, authProviderEnum.Facebook].includes(actionType)) {
        fpAuthenticationActionAsync(actionType, null);
        return;
    }

    _fp.metadata.journey = actionType;
    fpShowAuthenticationFragment(actionType);
}

export function fpFindNavigationElements(queries) {
    let elements = null;
    if (!!queries && queries.length > 0) {
        elements = [];
        for (let q = 0; q < queries.length; q++) {
            let els = document.querySelectorAll(queries[q]);
            if (!!els && els.length > 0) {
                els.forEach(element => {
                    element.style.cursor = "pointer";
                    elements.push(element);
                });
            }
        }
    }

    return elements;
}

// Queries with href param are used to handle wordpress menus
export const fpNavigationElementsDefault = [
    {
        type: authTypeEnum.SignIn,
        selectors: [".fp-sign-in", "#fp-sign-in", "[href='#fp-sign-in#']"],
    },
    {
        type: authTypeEnum.SignUp,
        selectors: [".fp-sign-up", "#fp-sign-up", "[href='#fp-sign-up#']"],
    },
    {
        type: authTypeEnum.SignOut,
        selectors: [".fp-sign-out", "#fp-sign-out", "[href='#fp-sign-out#']", "[data-fp-element='mc_signout']"],
    },
    {
        type: authTypeEnum.MemberCenter,
        selectors: [".fp-member-center", "#fp-member-center", "[href='#fp-member-center#']"],
    },
    {
        type: "navigation_subscribe_redirect",
        selectors: [".fp-btn-subscribe", "#fp-btn-subscribe"],
    },
    {
        type: authProviderEnum.Google,
        selectors: [".fp-auth-google"],
    },
    {
        type: authProviderEnum.Facebook,
        selectors: [".fp-auth-facebook"],
    },
];

export function fpGetAvailableNavigationTypes() {
    const navItems = fpNavigationElementsDefault;
    navItems.forEach(item => {
        const override = _fp.config?.navigation?.items?.find(o => o.type === item.type);
        if (!override) {
            return;
        }

        Object.assign(item, {
            selectors: override.selectors ?? item.selectors,
            style: override.style ?? item.style
        });

    });

    return navItems;
}
