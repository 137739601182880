export function fpSetBillingDetails(formEl) {
    if (!formEl) {
        console.error("FP:: Form element missing");
    }

    const formData = Object.fromEntries(new FormData(formEl).entries());
    let name = formData?.name;
    if (!name && (formData?.first_name || formData?.last_name)) {
        name = `${formData?.first_name ?? ""} ${formData?.last_name ?? ""}`;
    }

    _fp.user = _fp.user ?? {};
    _fp.user.billing_details = {
        username: formData.username,
        email: formData.username,
        name: name,
        password: formData.password,
    };
}