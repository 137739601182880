import { fpSendRequest } from "../../utils/fp-http-request";
import { fpGetUrlParameterByName } from "../../utils/fp-url-handler";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";
import { fpTrackUserAction } from "../../utils/fp-tracking";

export async function fpUserSignInAsync(data) {
    try {
        let request = {
            "email": data.username,
            "password": data.password,
            "username": data.username,
            "language": _fp?.config?.client?.language ? _fp.config.client.language : null
        };

        let readerId = fpGetUrlParameterByName("reader_id");
        if (readerId) {
            request.reader_id = readerId;
        }

        if (_fp.config?.client?.site) {
            request.site = _fp.config?.client?.site;
        }

        let url = _fp.config.authority + "/" + _fp.config.client.id + "/login";
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        fpTrackUserAction("fp_authentication", "sign_in_failed", "authentication", null, err, null);
        throw fpRequestErrorHandler(err);
    }
}