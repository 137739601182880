import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpAuthSetCustomOptions } from "../../../../core/utils/fp-set-custom-auth-options";
import { fpSetStorageValue } from "../../../../core/utils/fp-storage-handler";

export function fpAuthFlipPaySocialsHandler(evt, provider) {
    try {
        if (!provider) {
            return;
        }

        if (provider.includes("oogle")) {
            provider = "Google";
        } else if (provider.includes("acebook")) {
            provider = "Facebook";
        } else if (provider.includes("pple")) {
            provider = "Apple";
        }

        const formEl = evt?.currentTarget?.closest("form");
        const formData = formEl ? Object.fromEntries(new FormData(formEl).entries()) : null;
        if (formData?.type === "sign_up") {
            fpAuthSetCustomOptions(null, formEl, false);
        }

        let siteParam = "";
        siteParam = _fp.config.client?.site ? `&site=${_fp.config.client.site}` : "";

        let redirectParam = `?redirect_uri=${encodeURIComponent((document.location.origin + "/flip-pay/login.html"))}&provider=${provider}`;
        redirectParam = _fp.config.authentication?.socials?.redirect_url ? `?redirect_uri=${encodeURIComponent(_fp.config.authentication.socials.redirect_url)}` : redirectParam;

        const markPrefsParam = fpSetSocialMarketingPreferences();
        const url = `${_fp.config.authority}/${_fp.config.client.id}/external/${provider}${redirectParam}${siteParam}${markPrefsParam}`;
        fpAuthFlipPaySocialsRedirect(url, provider);
        return;
    } catch (error) {
        fpErrorHandler("Auth FlipPay Socials", error);
    }
}

function fpAuthFlipPaySocialsRedirect(url, provider) {
    let a = document.createElement("a");
    if (!a.click) { //for IE
        window.location = url;
        return;
    }
    a.setAttribute("href", url);
    a.referrerPolicy = "same-origin";
    a.rel = "sameorigin";
    a.style.display = "none";
    document.body.appendChild(a);

    fpSetStorageValue("fp.redirect_details", JSON.stringify({ "redirect_url": document.location.href, "provider": provider }));
    a.click();
}

function fpSetSocialMarketingPreferences() {
    const markPrefQueries = [
        { query: "#fp-marketing-preferences", propName: "marketing" },
        { query: "#fp-marketing-preferences-2", propName: "marketing_two" }
    ];

    const markPref = {};
    markPrefQueries.forEach(item => {
        const marketingPreferenceEl = document.querySelector(item.query);
        markPref[item.propName] = !!marketingPreferenceEl && marketingPreferenceEl.checked;
    });


    if (typeof markPref === "string") {
        return `&marketing=${markPref}`;
    }

    let markPrefsParam = "";
    if (markPref.marketing) {
        markPrefsParam += "&marketing=true";
    }
    if (markPref.marketing_two) {
        markPrefsParam += "&marketing_two=true";
    }

    return markPrefsParam;
}