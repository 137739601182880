export function fpSetMeteredPaywallTemplateCounter(settings) {
  // const templateHTML = fpGetTemplateByName("metered_paywall_fragment", "limit_reached");
  // const templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");

  settings?.notifications?.forEach(({ query, message }) => {
    if (!query || !message) { return; }

    const element = document.querySelector(query);
    if (element) {
      element.innerHTML = message;
    }
  });


  // const templateHTML = settings?.items_counter?.enabled ? "<h1>Counter HERE</h1>" : "";
  const templateHTML = "";
  return `${templateHTML}${_fp.metadata.content}`;
}
