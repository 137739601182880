import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpMemberCenterPopulateSubscriptionDetails } from "../utils/fp-mc-set-subscription-details";

export function fpMemberCenterPopulateSubscriptions(templateHTML, activeAccessResponse) {
  try {
    const templateDOM = new DOMParser().parseFromString(templateHTML, "text/html");
    const elements = {
      no_sub: templateDOM.querySelector("[data-fp-element='mc_no_subscriptions']"),      
      sub_details: templateDOM.querySelector("[data-fp-element='mc_user_subscriptions'], [data-fp-element='mc_subscription']"),
      // mc_user_subscription is a new element should be used insted of the fallback
    };

    if (_fp.user.entitlements.filter(x => x.entitlement_type === "subscription")?.length) {
      elements.no_sub.remove();
      fpMemberCenterPopulateSubscriptionDetails(elements.sub_details);
    } else if (_fp.user.subscription?.granted_by_corporate_account || _fp.user.subscription?.granted_by_whitelist_rule) {
      elements.no_sub.remove();
      // Delete subscription details section as we don't have them for this type of access
      ["mc_subscription_actions", "mc_subscription_details"].forEach(section => templateDOM.querySelector(`[data-fp-element='${section}']`)?.remove());

      fpMemberCenterPopulateAccessDetails(elements.sub_details);
    } else {
      elements.sub_details.remove();
    }

    let subscriptionTemplateHTML = templateDOM.querySelector("body").innerHTML;
    Object.keys(activeAccessResponse.customer).forEach(key => subscriptionTemplateHTML = subscriptionTemplateHTML.fpPopulateTemplate(key, activeAccessResponse.customer[key]));

    subscriptionTemplateHTML = subscriptionTemplateHTML.fpPopulateTemplate("customer_status", _fp.user?.subscription_status ?? "status-not-defined");

    return subscriptionTemplateHTML;
  } catch (error) {
    fpErrorHandler("Member Center Populate Subscriptions", error);
    return "";
  }
}

// Whitelist rule or corporate account details
function fpMemberCenterPopulateAccessDetails(templateDOM) {
  const mainSectionEl = templateDOM.querySelector("[data-fp-element='mc_whitelist_corporate_details']");
  if (!mainSectionEl) {
    return;
  }

  let mainSectionHTML = mainSectionEl.outerHTML;
  const accessDetails = _fp.user.subscription.granted_by_corporate_account
    ? _fp.user.subscription.granted_by_corporate_account
    : _fp.user.subscription.subscription.granted_by_whitelist_rule;

  Object.keys(accessDetails).forEach(key => mainSectionHTML = mainSectionHTML.fpPopulateTemplate(key, accessDetails[key] ?? ""));
  mainSectionEl.outerHTML = mainSectionHTML;
}
