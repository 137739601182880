import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { userAction } from "../../core/enums/fp-user-action.enum";
import { fpClearFlowWrapperElement } from "../../core/utils/fp-clear-flow-wrapper";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpInitializeCustomerJourney } from "../../journeys/fp-initialize-journey";
import { fpSetJourneyForSelectedFreePriceAsync } from "../../journeys/fp-set-journey-type";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";


export function fpSetPricesTemplateListeners() {
  try {
    const fpJourneyConfig = fpGetJourneyConfig();

    // Define user actions listeners
    const clearEls = document.querySelectorAll("[data-fp-element='clear']");
    clearEls?.forEach(el => el.addEventListener("click", fpClearFlowWrapperElement, false));

    // Define an action on a user price select
    const priceSelectEls = document.querySelectorAll("[data-fp-element='price_select']");
    priceSelectEls?.forEach((psEl) => psEl.addEventListener("click", (e) => fpOnPriceClick(e), false));

    const tabsEls = document.querySelectorAll("[data-fp-element='prices_tabs'] input");
    tabsEls?.forEach((psEl) => {
      if (psEl.value === fpJourneyConfig?.badge) {
        psEl.checked = true;
      }

      psEl.addEventListener("click", (e) => fpOnTabClick(e), false);
    });

    // Sign In Button listener
    fpPricesSetActionListeners();

    return null;
  } catch (error) {
    fpErrorHandler("Set Prices Fragment Template Listeners", error);
  }
}

async function fpOnPriceClick(e) {
  e.preventDefault();
  fpTrackUserAction("fp_user_action", "paywall_price_selected", "price", e.currentTarget.dataset.fpPriceId, null);

  _fp.metadata = _fp.metadata ? _fp.metadata : {};

  const selectedPrice = _fp.metadata.prices.find((price) => price.id === e.currentTarget.dataset.fpPriceId);
  if (!selectedPrice?.placeholder) {
      _fp.metadata.selected_price = selectedPrice;
  } else {
      _fp.metadata.selected_placeholder_price = selectedPrice;

      if ((_fp.metadata?.selected_placeholder_price?.placeholder_prices?.length ?? 0) > 0) {
          _fp.metadata.access_prices = _fp.metadata?.access_status?.prices ?? [];
          _fp.metadata.prices = selectedPrice.placeholder_prices;
          _fp.metadata.prices.forEach(price => price.child_price = true);
          _fp.metadata.selected_price = _fp.metadata.prices[0];
      }
  }

  _fp.metadata.journey = await fpSetJourneyOnPriceSelectAsync(_fp.metadata.selected_price);
  fpTriggerJourney(_fp.metadata.journey);
}

function fpOnTabClick(e) {
  if (_fp?.journey?.badge && _fp?.journey?.badge !== e.currentTarget.value) {
    _fp.journey.badge = e.currentTarget.value;
  }

  if (_fp.config?.journey?.badge && _fp.config?.journey?.badge !== e.currentTarget.value) {
    _fp.config.journey.badge = e.currentTarget.value;
  }

  _fp.metadata = _fp.metadata ? _fp.metadata : {};
  _fp.metadata.user_action = userAction.PricesTabClick;
  _fp.metadata.last_tab_clicked_badge = e.currentTarget.value;

  fpTrackUserAction("fp_user_action", "paywall_badge_change", null, null, null);
  fpInitializeCustomerJourney();
}

async function fpSetJourneyOnPriceSelectAsync(price) {
  const fpJourneyConfig = fpGetJourneyConfig();

  if (!fpJourneyConfig?.simple_checkout && (!_fp.user || !_fp.user.access_token)) {
    fpSetStorageValue("fp.selected_price_id", _fp.metadata.selected_price.id);
    return journeyTypeEnum.SignUp;
  } else if (price.validate_address && _fp.premium_wrapper?.dataset?.badge !== "gift") {
    return journeyTypeEnum.HomeDelivery;
  } else if (price.free) {
    return await fpSetJourneyForSelectedFreePriceAsync(price);
  } else if (price.placeholder && price.placeholder_prices?.length >= 1) {
    _fp.metadata.selected_placeholder_price = JSON.parse(JSON.stringify(price));
    _fp.metadata.prices = price.placeholder_prices;
    _fp.metadata.selected_price = _fp.metadata.prices[0];
    return journeyTypeEnum.OrderSummary;
  } else {
    return journeyTypeEnum.OrderSummary;
  }
}

function fpPricesSetActionListeners() {
  let signInEl = _fp?.premium_wrapper?.querySelector(".fp-sign-in");
  if (signInEl) {
    signInEl.addEventListener("click", () => {
      _fp.metadata.journey = authTypeEnum.SignIn;
      fpShowAuthenticationFragment(authTypeEnum.SignIn);
    }, false);
  }

  let actionEls = _fp?.premium_wrapper?.querySelectorAll("[data-fp-attribute]");
  if (actionEls) {
    actionEls?.forEach(el => {
      el.addEventListener("click", (evt) => {
        evt.preventDefault();
        const type = evt?.currentTarget?.dataset?.fpAttribute;
        if (type === authTypeEnum.SignUp) {
          _fp.metadata.journey = authTypeEnum.SignUp;
          fpShowAuthenticationFragment(authTypeEnum.SignUp);
        }
      }, false);
    });
  }
}