import { fpGetTemplateByName } from "../../../../core/utils/fp-set-template";
import { fpSetCartSelectedItemDetails } from "../../utils/fp-cart-selected-items-details";

export function fpSetCartPaymentTemplate() {
    const template = fpGetTemplateByName("cart_fragment", "payment");
    const templateDOM = new DOMParser().parseFromString(template, "text/html");

    // Account details (if account step not in use)
    const accountEl = templateDOM.querySelector("[data-fp-element='account_details']");
    if (accountEl && _fp.user?.access_token) {
        const signedOutEls = accountEl.querySelectorAll("[data-fp-element='signed_out']");
        signedOutEls?.forEach(el => el.remove());
    }

    // Delivery details if a price marked with validate address
    const deliveryEl = templateDOM.querySelector("[data-fp-element='delivery_details']");
    if (deliveryEl && !_fp.metadata?.selected_price?.validate_address) {
        deliveryEl.remove();
    }

    // Selected price details
    fpSetCartSelectedItemDetails(templateDOM, false);

    // Offer details if selected price is part of placelhoder children prices
    fpPopulateAvailableOffers(templateDOM);

    let templateHTML = templateDOM.querySelector("body").innerHTML;
    if (_fp?.metadata?.selected_price) {
        Object.keys(_fp.metadata.selected_price).forEach(key => {
            templateHTML = templateHTML.fpPopulateTemplate(key, (_fp?.metadata?.selected_price[key] === true ? "active" : _fp?.metadata?.selected_price[key]));
        });
        _fp.metadata?.selected_price.custom_text_fields?.forEach(ctf => templateHTML = templateHTML.fpPopulateTemplate(ctf.name, ctf.value ?? ""));
    }

    return templateHTML;
}

function fpPopulateAvailableOffers(templateDOM) {
    const offersEl = templateDOM.querySelector("[data-fp-element='offers']");
    if (!offersEl) {
        return;
    }

    const { metadata } = _fp || {};
    const { selected_price, prices } = metadata || {};

    // Check if there are no prices or child_price is missing
    if (!selected_price?.child_price || (prices?.length ?? 0) === 0) {
        offersEl.remove();
        return;
    }

    const offerEl = templateDOM.querySelector("[data-fp-element='offer']");
    if (!offerEl) {
        return;
    }

    const offersHTML = (prices || []).reduce((html, price) => {
        let offerHTML = JSON.parse(JSON.stringify(offerEl.outerHTML));
        offerHTML = offerHTML.fpPopulateTemplate("option_id", price.id);

        (price.custom_text_fields || []).forEach(ctf => {
            offerHTML = offerHTML.fpPopulateTemplate(ctf.name, ctf.value);
        });

        return html + offerHTML;
    }, "");

    offersEl.innerHTML = offersHTML;
}

