import { fpLoadScriptsAsync } from "../../../core/utils/fp-load-scripts";
import { fpErrorHandler } from "../../../core/utils/fp-error-handler";
import { fpStripe24InitPaymentElements } from "./fp-init-payment-method";

/**
 * @param {*} data { type: 'setup' or 'payment' }
 */
export async function fpInitializeStripe2024Async(type, data) {
  try {
    if (typeof Stripe === "undefined") {
      await fpLoadScriptsAsync(["https://js.stripe.com/v3/"]);
    }

    data.type = type;

    const providers = _fp.metadata?.payment_providers[type];
    const stripeKey = providers?.payment_providers?.stripe_24?.stripe_credential?.publishable_key ?? _fp.config.payment.key;
    _fp.payment_providers = _fp.payment_providers ? _fp.payment_providers : {};
    _fp.payment_providers.stripe24 = {
      stripe: _fp.payment_providers.stripe24?.stripe ? _fp.payment_providers.stripe24.stripe : Stripe(stripeKey, { locale: _fp?.config?.client?.language ? _fp.config.client.language : "en" }),
    };

    fpStripe24InitPaymentElements(type, data);
  } catch (error) {
    fpErrorHandler("FP:: Initialize Stripe2024", error);
  }
}