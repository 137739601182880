/* eslint-disable no-unused-vars */
import { fpGetCustomerActiveAccessAsync } from "../requests/customer/fp-customer-active-access";
import { fpGetPriceByIdAsync } from "../requests/prices/fp-get-price";

export async function fpSetCustomerActiveAcessDetailsAsync(setPurchasePriceDetails) {
    try {
        _fp.user = _fp.user ? _fp.user : {};

        const customerDetails = await fpGetCustomerActiveAccessAsync();
        if (!customerDetails) {
            return null;
        }

        const { customer, consumables, delivery_holidays } = customerDetails;
        _fp.user.id = customer?.id;
        _fp.user.entitlements = fpPopulateUserEntitlements(customerDetails);
        _fp.user.subscription_status = fpSetCustomerSubscriptionStatus(_fp.user.entitlements, customer?.sku_code_history);

        if (customerDetails?.consumables) {
            _fp.user.consumables = customerDetails?.consumables;
        }

        delete _fp.user.purchase;
        _fp.user.purchase = _fp.user.entitlements?.find(x => x.entitlement_type === "subscription") || null;

        if (delivery_holidays?.length) {
            _fp.user.purchase = { ...(_fp.user.purchase || {}), delivery_holidays };
        }

        if (customerDetails?.delivery_holidays?.length > 0 && !!_fp.user.purchase) {
            _fp.user.purchase.delivery_holidays = customerDetails.delivery_holidays;
        }

        // await fpSetCustomerSubscriptionDetailsAsync(customerDetails, setPurchasePriceDetails);

        return customerDetails;
    } catch (error) {
        console.error("FP:: Active Access", error);
        throw error;
    }
}

function fpPopulateUserEntitlements(customerDetails) {
    const entitlements = [
        { entitlements: customerDetails?.purchases?.filter(x => !x.metered && !x.renewable && !x.subscription), entitlement_type: "once_off" },
        { entitlements: customerDetails?.purchases?.filter(x => (x.subscription && !x.downgraded) || (!x.metered && x.renewable)), entitlement_type: "subscription" },
        { entitlements: customerDetails?.purchases?.filter(x => (x.downgraded)), entitlement_type: "downgraded" },
        { entitlements: (customerDetails?.app_purchases ?? []), entitlement_type: "subscription" },
        { entitlements: (customerDetails?.corporate_accounts ?? []), entitlement_type: "corporate_account" },
        { entitlements: (customerDetails?.whitelist_rule ? [customerDetails?.whitelist_rule] : []), entitlement_type: "whitelist_rule" },
    ];

    return entitlements.reduce((item, { entitlements, entitlement_type }) => {
        if (entitlements) {
            item.push(...entitlements.map(entitlement => ({ ...entitlement, entitlement_type: entitlement_type })));
        }
        return item;
    }, []);
}

async function fpSetCustomerSubscriptionDetailsAsync(customerDetails, setPurchasePriceDetails) {
    if ((customerDetails?.purchases ?? []).length === 0 && (customerDetails?.corporate_accounts ?? []).length === 0 && !customerDetails?.whitelist_rule) {
        _fp.user.subscription = null;
        return;
    }

    delete _fp.user.purchase;

    _fp.user.subscription = {
        granted_by_renewable_purchase: customerDetails?.purchases.find((x) => !x.metered && x.renewable),
        granted_by_purchase: customerDetails?.purchases.find((x) => !x.metered && x.renewable) ? customerDetails.purchases.find((x) => !x.metered) : (customerDetails.purchases ? customerDetails.purchases[0] : null),
        granted_by_app_purchase: customerDetails.app_purchases ? customerDetails.app_purchases[0] : null,
        granted_by_corporate_account: customerDetails?.corporate_accounts ? customerDetails.corporate_accounts[0] : null,
        granted_by_whitelist_rule: customerDetails?.whitelist_rule,
    };

    if (_fp.user.subscription.granted_by_purchase || _fp.user.subscription.granted_by_app_purchase || _fp.user.subscription.granted_by_renewable_purchase) {
        const purchase = _fp.user.subscription.granted_by_renewable_purchase
            ? _fp.user.subscription.granted_by_renewable_purchase
            : _fp.user.subscription.granted_by_purchase
                ? _fp.user.subscription.granted_by_purchase
                : _fp.user.subscription.subscription.granted_by_app_purchase;

        _fp.user.purchase = purchase;

        if (!_fp?.user?.purchase?.price && setPurchasePriceDetails) {
            _fp.user.purchase.price = await fpGetPriceByIdAsync(_fp.user.purchase.price_id);
        }

        if (customerDetails?.delivery_holidays?.length > 0) {
            _fp.user.purchase.delivery_holidays = customerDetails.delivery_holidays;
        }
    }

    if (customerDetails?.consumables) {
        _fp.user.consumables = customerDetails?.consumables;
    }
}

function fpSetCustomerSubscriptionStatus(entitlements, skuCodeHistory) {
    let status = "new-customer";
    if ((entitlements ?? []).find(x => x.entitlement_type === "subscription")) {
        status = "active-subscriber";
    } else if ((skuCodeHistory ?? []).length > 0) {
        status = "lapsed-subscriber";
    }

    return status;
}