import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpSetFragmentTeaser } from "../../core/utils/fp-set-fragment-teaser";
import { fpGetTemplateByName } from "../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../core/utils/fp-set-template-price-details";
import { fpClearNotUsedAnchors } from "../../core/utils/fp-clear-template";
import { fpIsShopfrontPage } from "../../core/utils/fp-is-shopfront-page";
import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";

export function fpSetPricesTemplate(withoutTeaser) {
    try {
        const fragmentSettings = (fpGetJourneyConfig()?.settings ?? _fp.config.fragments.prices_fragment.settings) ?? {};

        let template = fpGetTemplateByName("prices_fragment");

        // Set a different price template for a client side metering flow if required
        const isShopfront = fpIsShopfrontPage();
        const isClientSideMetering = !isShopfront && _fp.config?.metering?.enabled && !!_fp.config?.metering?.settings?.counter && _fp.config.fragments.prices_fragment.templates?.metering_template;
        const isRedirectToShopfront = !isShopfront && fragmentSettings.purchase_shopfront_only && _fp.config.fragments.prices_fragment.templates?.redirect_to_shopfront;

        if (isClientSideMetering) {
            template = fpGetTemplateByName("prices_fragment", "metering_template");
        } else if (isRedirectToShopfront) {
            template = fpGetTemplateByName("prices_fragment", "redirect_to_shopfront");
        } else if (!_fp.metadata.prices || _fp.metadata.prices?.length === 0) {
            template = fpGetTemplateByName("prices_fragment", "no_prices");
        }

        const templateDOM = new DOMParser().parseFromString(template, "text/html");
        if (_fp.metadata?.prices?.length > 0 && !isRedirectToShopfront) {           
            fpSetPricesFragmentTabsElement(templateDOM, fragmentSettings);
            fpSetPricesFragmentPriceDetails(templateDOM);
        }

        let pricesFragment = templateDOM.querySelector("body").innerHTML;
        pricesFragment = pricesFragment
            .fpPopulateTemplate("auth_status", (_fp.user && _fp.user.access_token) ? "fp-user-logged-in" : "fp-user-logged-out")
            .fpPopulateTemplate("subscription_status", (_fp?.metadata?.access_status?.subscription) ? "fp-subscription" : "fp-no-subscription")
            .fpPopulateTemplate("page_type", isShopfront ? "fp-fragment-shopfront" : "fp-fragment-inline")
            .fpPopulateTemplate("badge", _fp?.config?.badge ?? "")
            .fpPopulateTemplate("offer_not_available_description", _fp?.metadata?.access_status?.offer_not_available_description ? _fp?.metadata?.access_status?.offer_not_available_description : "");

        if (!!_fp.metadata.access_status.custom_text_fields && _fp.metadata.access_status.custom_text_fields.length > 0) {
            _fp.metadata.access_status.custom_text_fields.forEach((pc) => {
                pricesFragment = pricesFragment.fpPopulateTemplate(pc.name, pc.value ? pc.value : "");
            });
        }

        pricesFragment = fpClearNotUsedAnchors(pricesFragment);
        const teaser = withoutTeaser ? "" : fpSetFragmentTeaser("prices_fragment");
        return `${teaser}${pricesFragment}`;
    } catch (error) {
        fpErrorHandler("Set Prices Fragment Template", error);
        return "";
    }
}

function fpSetPricesFragmentTabsElement(templateDOM, settings) {
    const tabs = settings?.tabs ??_fp.config.fragments?.prices_fragment?.settings?.tabs;
    const pricesTabsEl = templateDOM.querySelector("[data-fp-element='prices_tabs']");
    if (!tabs?.length) {
        pricesTabsEl?.remove();
        return;
    }

    let tabsTemplateHTML = "";
    tabs.forEach((tab, ind) => {
        let tabTemplateHTML = JSON.parse(JSON.stringify(pricesTabsEl.innerHTML));
        tabTemplateHTML = tabTemplateHTML
            .fpPopulateTemplate("tab_id", `tab-id-${ind}`)
            .fpPopulateTemplate("tab_label", tab.label)
            .fpPopulateTemplate("tab_value", tab.badge)
            .fpPopulateTemplate("tab_checked", ind === 0 ? "checked='checked'" : "");

        tabsTemplateHTML += tabTemplateHTML;
    });

    pricesTabsEl.innerHTML = tabsTemplateHTML;
    return tabsTemplateHTML;
}

function fpSetPricesFragmentPriceDetails(templateDOM) {
    const priceEl = templateDOM.querySelector("[data-fp-element='price']");
    if (!priceEl) {
        console.error("Prices Fragment template is missing mandatory attribute -> [data-fp-element='price']");
        throw new Error("Missing Mandatory DOM Element");
    }

    let pricesHtml = "";
    _fp.metadata.prices.forEach((price, ind) => {
        pricesHtml += fpPopulateTemplatePriceDetails(priceEl.outerHTML, price, ind);
    });

    priceEl.outerHTML = pricesHtml;
}