import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpGetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpTrackUserAction } from "../../core/utils/fp-tracking";
import { fpGetUrlParameterByName } from "../../core/utils/fp-url-handler";
import { fpSetGiftRedeemTemplate } from "./fp-set-gift-redeem-template";
import { fpSetGiftRedeemTemplateListeners } from "./fp-set-gift-redeem-template-listeners";

export function fpShowGiftRedeemFragment(type) {
    try {
        const settings = _fp.config.fragments?.gift_redeem_fragment?.settings;

        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.gift = { code: fpGetUrlParameterByName("gift_code") };
        if (!_fp.metadata.gift?.code) {
            _fp.metadata.gift.code = fpGetStorageValue("fp.gift_code");
        }

        _fp.premium_wrapper.innerHTML = fpSetGiftRedeemTemplate(type);
        fpSetGiftRedeemTemplateListeners(settings);

        fpTrackUserAction("fp_fragment_displayed", null, "gift_redeem", null, null);
    } catch (error) {
        fpTrackUserAction("fp_error", null, "gift_redeem", null, error);
        fpErrorHandler("Gift Redeem Fragment", error);
    }
}