import { fpGetTemplateByName } from "../../../core/utils/fp-set-template";
import { fpPopulateTemplatePriceDetails } from "../../../core/utils/fp-set-template-price-details";

export function fpSetCartSelectedItemDetails(wrapperEl, setFromTemplate) {

    // Check whether selected price is a placeholder and if yes update metadata accordingly
    if (_fp.metadata.selected_price?.placeholder) {
        _fp.metadata.selected_placeholder_price = _fp.metadata.selected_price;
        if ((_fp.metadata?.selected_placeholder_price?.placeholder_prices.length ?? 0) > 0) {
            _fp.metadata.access_prices = _fp.metadata?.access_status?.prices ?? [];
            _fp.metadata.prices = _fp.metadata.selected_price.placeholder_prices;
            _fp.metadata.prices.forEach(price => price.child_price = true);
            _fp.metadata.selected_price = _fp.metadata.prices[0];
        }
    }

    const selectedPriceWrapperEl = wrapperEl.querySelector("[data-fp-element='cart-selected-option']");
    if (!selectedPriceWrapperEl) {
        return;
    }

    let template = selectedPriceWrapperEl;
    if (setFromTemplate) {
        template = new DOMParser().parseFromString(fpGetTemplateByName("cart_fragmnet", "account"), "text/html").querySelector("[data-fp-element='cart-selected-option']");
    }

    selectedPriceWrapperEl.innerHTML = fpPopulateTemplatePriceDetails(template.innerHTML, _fp.metadata.selected_price, "selected");
}
