import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpGetPriceByIdAsync(id) {
    try {
        const url = `${_fp.config.base_url_transaction}/prices/${id}`;
        return await fpSendRequest("GET", url, null);
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}