export function fpGetUTMParameters() {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const utmParameters = {};

    urlParams.forEach((value, key) => {
        if (key.startsWith("utm_")) {
            utmParameters[key] = value;
        }
    });

    return utmParameters;
}