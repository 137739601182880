import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { userAction } from "../../../../core/enums/fp-user-action.enum";
import { fpGetJourneyConfig } from "../../../../core/utils/fp-get-journeys-config";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpGetUrlParameterByName, fpRemoveUrlParamenterByName } from "../../../../core/utils/fp-url-handler";
import { fpInitializeCustomerJourney } from "../../../../journeys/fp-initialize-journey";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";


export function fpSetCartItemsListeners() {
    fpInitTabsHandler();

    // Define action on a user item select
    const priceSelectEls = document.querySelectorAll("[data-fp-element='price_select']");
    priceSelectEls?.forEach((psEl) => psEl.addEventListener("click", (e) => fpOnItemPriceClick(e), false));
}

async function fpOnItemPriceClick(e) {
    e.preventDefault();
    fpTrackUserAction("fp_user_action", "cart_price_selected", "price", e.currentTarget.dataset.fpPriceId, null);

    _fp.metadata = _fp.metadata ? _fp.metadata : {};

    const selectedPrice = _fp.metadata.prices.find((price) => price.id === e.currentTarget.dataset.fpPriceId);
    if (!selectedPrice?.placeholder) {
        _fp.metadata.selected_price = selectedPrice;
    } else {
        _fp.metadata.selected_placeholder_price = selectedPrice;
        if ((_fp.metadata?.selected_placeholder_price?.placeholder_prices.length ?? 0) > 0) {
            _fp.metadata.access_prices = _fp.metadata?.access_status?.prices ?? [];
            _fp.metadata.prices = selectedPrice.placeholder_prices;
            _fp.metadata.prices.forEach(price => price.child_price = true);
            _fp.metadata.selected_price = _fp.metadata.prices[0];
        }
    }

    _fp.metadata.journey = journeyTypeEnum.Cart;
    fpTriggerJourney(journeyTypeEnum.Cart);
}

function fpInitTabsHandler() {
    const tabsConfig = _fp.config?.fragments?.cart_fragment?.settings?.tabs;
    if (!tabsConfig || tabsConfig.length === 0) {
        return;
    }

    const fpJourneyConfig = fpGetJourneyConfig();
    const activeBadge = fpGetUrlParameterByName("badge") ?? fpJourneyConfig?.badge;
    fpRemoveUrlParamenterByName("badge");

    const tabsEls = document.querySelectorAll("[data-fp-element='prices_tabs'] input");
    tabsEls?.forEach((psEl) => {
        if (psEl.value === activeBadge) {
            psEl.checked = true;
        }

        psEl.addEventListener("click", (e) => fpOnCartTabClick(e), false);
    });
}


function fpOnCartTabClick(e) {
    if (_fp?.journey?.badge && _fp?.journey?.badge !== e.currentTarget.value) {
        _fp.journey.badge = e.currentTarget.value;
    } else {
        return;
    }

    if (_fp.config?.journey?.badge && _fp.config?.journey?.badge !== e.currentTarget.value) {
        _fp.config.journey.badge = e.currentTarget.value;
    }

    _fp.metadata = _fp.metadata ? _fp.metadata : {};
    _fp.metadata.user_action = userAction.PricesTabClick;
    _fp.metadata.last_tab_clicked_badge = e.currentTarget.value;

    fpTrackUserAction("fp_user_action", "paywall_badge_change", null, null, null);
    fpInitializeCustomerJourney();
}
