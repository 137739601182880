import { fpAccessRequestAsync } from "../../core/requests/fp-access";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpFindSelectedPriceInAvailablePrices } from "../../core/utils/fp-set-selected-price";
import { fpSetAccessRequestDetailsAsync } from "./fp-access-request";

export async function fpGetServerSideAccessStatusAsync() {
    try {
        const request = await fpSetAccessRequestDetailsAsync();
        if (!request.group_code) {
            return null;
        }

        const response = await fpAccessRequestAsync(request);
        if (!response) {
            return null;
        }

        return fpSetAccessStatus(response);
    } catch (error) {
        fpErrorHandler("Get Server Side Access", error);
        return null;
    }
}

function fpSetAccessStatus(response) {
    try {
        _fp.metadata = _fp.metadata ? _fp.metadata : {};
        _fp.metadata.prices = response.prices ? response.prices : null;

        // Check whether the latest access response contains the selected price
        if (_fp?.metadata?.selected_price) {
            _fp.metadata.selected_price = fpFindSelectedPriceInAvailablePrices(_fp.metadata.prices, _fp.metadata.selected_price.id);
        }

        return response;
    } catch (error) {
        fpErrorHandler("Set Access Status", error);
        return null;
    }
}