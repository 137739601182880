import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { meteredPaywallTypeEnum } from "../../core/enums/fp-metered-paywall-type.enum";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpAuthFlipPaySocialsHandler } from "../authentication/flip-pay/handlers/fp-socials-handler";
import { fpShowAuthenticationFragment } from "../authentication/fp-authentication-fragment";
import { fpSetPricesTemplateListeners } from "../prices/fp-set-prices-fragment-listeners";

export function fpSetMeteredPaywallTemplateListeners(type) {
    if (type === meteredPaywallTypeEnum.LimitReached || type === meteredPaywallTypeEnum.UserNotRegistered) {
        fpSetPricesTemplateListeners();
        return;
    }

    const actionButtonsEls = document.querySelectorAll("[data-fp-element='action_btn']");
    actionButtonsEls?.forEach(el => el.addEventListener("click", (evt) => fpMeteredPaywallButtonAction(evt), false));

    const socialProviders = document.querySelectorAll("[data-fp-provider]");
    if (socialProviders) {
        socialProviders.forEach((sp) => sp.addEventListener("click", (e) => fpAuthFlipPaySocialsHandler(e, e.currentTarget.dataset.fpProvider)));
    }
}

function fpMeteredPaywallButtonAction(evt) {
    evt.preventDefault();

    if ([authTypeEnum.SignIn, authTypeEnum.SignUp].includes(evt.currentTarget.dataset.fpAttribute)) {
        fpShowAuthenticationFragment(evt.currentTarget.dataset.fpAttribute);
    } else {
        fpErrorHandler("Metered Paywall Not Defined Action Button");
    }
}