import { fpGetCustomerAccessStatusAsync } from "../../access/fp-access";
import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { fpTriggerJourneyByAccessStatusAsync } from "../../journeys/fp-initialize-journey";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";

// eslint-disable-next-line no-unused-vars
export function fpSetMarketingMessageFragmentListeners(fragmentConfig) {
    const dismissEl = document.querySelector("[data-fp-element='marketing_content_action_dismiss']");
    dismissEl?.addEventListener("click", () => {
        if (!_fp?.journey?.type) {
            return;
        }

        _fp.journey.type = journeyTypeEnum.MarketingMessage;
        fpTriggerJourney(journeyTypeEnum.MarketingMessage);
    });

    const triggerEl = document.querySelector("[data-fp-element='marketing_content_trigger']");
    triggerEl?.addEventListener("click", async () => {
        const wrapEl = document.querySelector("[data-fp-element='marketing_content']");
        if (!wrapEl) {
            return;
        }

        const actionsWrapEl = document.querySelector("[data-fp-element='marketing_content_actions']");
        if (actionsWrapEl) {
            actionsWrapEl.style.display = "inherit";
        }

        wrapEl.innerHTML = "";
        _fp.premium_wrapper = document.querySelector("[data-fp-element='marketing_content']");
        if (_fp?.journey?.type) {
            _fp.journey.type = journeyTypeEnum.VoluntaryContribution;
        }

        const accessStatus = await fpGetCustomerAccessStatusAsync();
        if (!accessStatus) {
            return;
        }

        fpTriggerJourneyByAccessStatusAsync(accessStatus);
    }, false);
}