import { fpErrorHandler } from "./fp-error-handler";

export function fpIsShopfrontPage() {
    try {
        let isShopfrontPage = document.location.pathname.indexOf(_fp.config.client.shopfront_path) > -1;
        if (!isShopfrontPage && !!_fp.config.redirects) {
            isShopfrontPage = document.location.href.indexOf(_fp.config.redirects.shopfront_main) > -1;
            if (!isShopfrontPage && !!_fp.config.redirects.shopfront_pages && _fp.config.redirects.shopfront_pages.length > 0) {
                isShopfrontPage = _fp.config.redirects.shopfront_pages.indexOf(document.location.href) > -1;
                if (!isShopfrontPage) {
                    isShopfrontPage = !!_fp.config.redirects.shopfront_pages.find(x => document.location.href.includes(x));
                }
            }
        }
        return isShopfrontPage;
    } catch (err) {
        fpErrorHandler("Shopfront check", err);
        return false;
    }
}