import { fpErrorHandler } from "./fp-error-handler";

export function fpSendRequest(method, url, body, allowTimeout, contentType) {
    try {
        return new Promise(function (resolve, reject) {
            let xhr = new XMLHttpRequest();
            xhr.open(method, url, true);
            xhr.onload = function () {
                if (this.status === 200) {
                    let response = xhr.response;
                    if (response) {
                        response = JSON.parse(xhr.response);
                    }
                    resolve(response);
                } else if (this.status === 204) {
                    resolve();
                } else {
                    let responseErr = JSON.parse(JSON.stringify(xhr.response));
                    reject({
                        status: this.status,
                        statusText: xhr.statusText,
                        error: responseErr
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };

            if (!!_fp && !!_fp.user && !!_fp.user.access_token) {
                xhr.setRequestHeader("Authorization", "Bearer" + " " + _fp.user.access_token);
            }

            if (method === "POST" || method === "PUT" || method === "PATCH") {
                let ct = contentType ? contentType : "application/json";
                xhr.setRequestHeader("Content-Type", ct);
            }

            xhr.send(body);
        });
    } catch (err) {
        fpErrorHandler("HTTP Request", err);
        return null;
    }
}