export const authTypeEnum = {
    SignIn: "sign_in",
    SignUp: "sign_up",
    SignOut: "sign_out",
    AuthClose: "auth_close",
    RequestResetPassword: "request_reset_pwd",
    ResetPassword: "reset_pwd",
    MemberCenter: "member_center",
    VerifyEmail: "verify_email",
    SilentSignUp: "silent_sign_up",
};
