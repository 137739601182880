export function fpSetPinCodeListener(wrapperEl) {
    const inputs = wrapperEl?.querySelectorAll("input[type='number']");
    if (!inputs || inputs.length === 0) { return; }

    const firstInput = inputs[0];

    // Handle paste event and distribute digits
    firstInput.addEventListener("input", fpPinCodeSplitNumber);

    for (const input of inputs) {
        input.addEventListener("keyup", (e) => fpPinCodeHandleKeyUp(e, wrapperEl));
        input.addEventListener("focus", (e) => fpPinCodeHandleFocus(e, firstInput));
    }
}

function fpPinCodeSplitNumber(e) {
    let data = e.data || e.target.value;
    if (!data || data.length === 1) { return; }

    fpPinCodeDistributeDigits(e.target, data);
}

function fpPinCodeDistributeDigits(el, data) {
    el.value = data[0]; // Set first digit
    let next = el.nextElementSibling;
    let i = 1;

    while (next && i < data.length) {
        next.value = data[i++];
        next = next.nextElementSibling;
    }
}

function fpPinCodeSetInputValue(wrapperEl) {
    const pinCodeInput = document.querySelector("form[data-fp-element='validation_pin_code'] input[name='pin_code']");
    if (!pinCodeInput) {
        return;
    }

    const inputs = wrapperEl?.querySelectorAll("input[type='number']");
    pinCodeInput.value = [...inputs].map(input => input.value).join("");
    // Trigger input event so the form detects the change
    pinCodeInput.dispatchEvent(new Event("input", { bubbles: true }));
}

function fpPinCodeHandleKeyUp(e, wrapperEl) {
    const { keyCode, target } = e;
    const prev = target.previousElementSibling;
    const next = target.nextElementSibling;

    if ([16, 9, 224, 18, 17].includes(keyCode)) { return; } // Ignore Shift, Tab, CMD, Option, Control

    if ((keyCode === 8 || keyCode === 37) && prev?.tagName === "INPUT") {
        prev.select(); // Move focus to previous field on Backspace/Left arrow
    } else if (keyCode !== 8 && next) {
        next.select(); // Move focus to next field on input
    }

    // Handle pasting a multi-digit value
    if (target.value.length > 1) {
        fpPinCodeSplitNumber(e);
    }

    fpPinCodeSetInputValue(wrapperEl);
}

function fpPinCodeHandleFocus(e, firstInput) {
    if (e.target === firstInput) { return; }

    if (!firstInput.value) {
        firstInput.focus();
    } else {
        let prev = e.target.previousElementSibling;
        while (prev) {
            if (!prev.value) {
                prev.focus();
                return;
            }
            prev = prev.previousElementSibling;
        }
    }
}

