import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpGetEntitlementsAsync(groupCode) {
    try {
        const url = `${_fp.config.base_url_transaction}/entitlements/`;
        const method = groupCode ? "POST" : "GET";
        const body = groupCode ? JSON.stringify({ group_code: groupCode }) : null;

        return await fpSendRequest(method, url, body);
    } catch (err) {
        console.error("FP::", err);
        throw err;
    }
}