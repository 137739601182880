import { paymentErrorEnum } from "../../../../core/enums/fp-payment-error.enum";
import { fpConfirmSetupIntentAsync } from "../../../../core/requests/purchase/fp-payment-intents";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTrackUserAction } from "../../../../core/utils/fp-tracking";
import { fpStripeConfirmActionAsync } from "./fp-stripe-confirm-action";
import { fpStripeConfirmCardActionAsync } from "./fp-stripe-confirm-card-action";
import { fpStripeOnError } from "./fp-stripe-error-handler";

export async function fpUpdateStripe24PaymentMethodAsync(paymentMethodType) {
    try {
        fpSpinnerHandler("show");
        const data = _fp.payment_providers?.stripe24?.setup;
        if (!data) {
            return;
        }

        const confirmSetupResponse = paymentMethodType === "card"
            ? await fpStripeConfirmCardActionAsync(data.setup_client_secret, data.elements, "setup")
            : await fpStripeConfirmActionAsync(data.setup_client_secret, data.elements, paymentMethodType, "setup");

        if (!confirmSetupResponse || confirmSetupResponse.error) {
            fpSpinnerHandler("hide", null);
            throw { error: confirmSetupResponse?.error ?? paymentErrorEnum.Generic };
        }

        const response = await fpConfirmSetupIntentAsync(_fp.metadata?.payment_providers?.setup?.id, confirmSetupResponse.setupIntent.id, "stripe_24", paymentMethodType);
        if (!response || response.error) {
            // TODO: error handler
            fpSpinnerHandler("hide");
            return false;
        }

        if (paymentMethodType === "card") {
            return true;
        }

        window.location.reload();
    } catch (err) {
        fpSpinnerHandler("hide");
        fpTrackUserAction("fp_error", null, "update_payment_error", null, "Generic error");
        fpStripeOnError(err);
    }
}