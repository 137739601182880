import { fpGetJourneyConfig } from "../../core/utils/fp-get-journeys-config";
import { fpSetMarketingMessageFragmentListeners } from "./fp-marketing-message-listeners";
import { fpSetMarketingMessageFragmentTemplate } from "./fp-marketing-message-template";

export function fpShowMarketingMessageFragment() {
    const fragmentConfig = fpGetJourneyConfig();
    if (fragmentConfig?.useCustomImplementation && fragmentConfig.customHandler) {
        // Use custom handler if specified in the config
        const dynamicFunction = new Function(`return ${fragmentConfig.customHandler}`)();
        dynamicFunction();
        return;
    }

    // RULES When to display marketing message
    const displayMessage = fpDisplayMarketingMessage(fragmentConfig);
    if (!displayMessage) {
        fpClearMarketingMessageFragment();
        return;
    }

    // TEMPLATE population
    const templateHTML = fpSetMarketingMessageFragmentTemplate(fragmentConfig);
    fpInjectMarketingMessageFragment(fragmentConfig, templateHTML);

    // LISTENERS Actions
    fpSetMarketingMessageFragmentListeners(fragmentConfig);
}

// Define rules where the fragment should be injected
function fpInjectMarketingMessageFragment(fragmentConfig, templateHTML) {
    const placement = fragmentConfig?.settings?.placement;
    const isCustomPlacement = placement?.type === "custom";

    const targetSelector = isCustomPlacement ? placement?.query : "[data-fp-element='marketing_content_wrapper']";

    const wrapperEl = document.querySelector(targetSelector);
    const marketingEl = document.createElement("div");
    marketingEl.innerHTML = templateHTML;

    if (!wrapperEl) {
        if (!isCustomPlacement) {
            document.body.appendChild(marketingEl);
        }
        return;
    }

    placement?.injection_type === "append"
        ? wrapperEl.appendChild(marketingEl)
        : (wrapperEl.innerHTML = templateHTML);
}

function fpClearMarketingMessageFragment() {
    const wrapperEl = document.querySelector("[data-fp-element='marketing_content_wrapper']");
    wrapperEl?.remove();
}

function fpDisplayMarketingMessage(fragmentConfig) {
    const settings = fragmentConfig?.settings;
    const isSessionBased = !settings?.persistence || settings.persistence === "session";
    if (isSessionBased) {
        const sessionKey = "fp.marketing_message";
        if (sessionStorage.getItem(sessionKey)) { return false; }

        sessionStorage.setItem(sessionKey, "displayed");
    }

    return true;
}