import { fpSendRequest } from "../../utils/fp-http-request";
import { fpGetUrlParameterByName } from "../../utils/fp-url-handler";
import { fpRequestErrorHandler } from "../../utils/fp-request-error-handler";
import { fpGenerateRecaptchaTokenAsync } from "../../../providers/recaptcha/fp-generate-recaptcha-token";
import { fpTrackUserAction } from "../../utils/fp-tracking";

export async function fpUserSignUpAsync(data) {
    try {
        const request = await fpSetSignUpRequestAsync(data);
        const url = _fp.config.authority + "/" + _fp.config.client.id + "/register";
        return await fpSendRequest("POST", url, JSON.stringify(request));
    } catch (err) {
        if (!!err && err.status === 402 && !!err.error && !!err.error.includes("email_verification_required")) {
            fpHandleSignupErrorResponse(err);
            fpTrackUserAction("fp_authentication", "email_verification_required", "authentication", null, null, null);
            return "email_verification_required";
        }

        fpTrackUserAction("fp_authentication", "sign_up_failed", "authentication", null, err, null);
        throw fpRequestErrorHandler(err);
    }
}

async function fpSetSignUpRequestAsync(data) {
    const { hostname, pathname, search, searchParams } = new URL(window.location.href);
    const currentUrl = `${hostname}${pathname}${searchParams.size > 0 ? search : ""}`;

    let redirectUri = `${currentUrl}${searchParams.size > 0 ? "&" : "?"}`;
    if (_fp.metadata?.selected_price) {
        redirectUri += `price_id=${_fp.metadata.selected_price.id}&verify_email=>>Token<<`;
    } else {
        redirectUri += "verify_email=>>Token<<";
    }

    let request = {
        redirect_uri: redirectUri,
        source: "web-direct",
        language: _fp?.config?.client?.language ? _fp.config.client.language : null,
        site: _fp.config?.client?.site ?? null,
    };

    // Default newsletter checkbox (value of the element needs to be set to true)
    if (data?.marketing === "true" || data?.marketing === "on") {
        request.marketing = true;
    }

    const dataKeys = Object.keys(data);
    dataKeys.forEach(key => request[key] = data[key]);

    if (!request.name && (request.first_name && request.last_name)) {
        request.name = `${request.first_name} ${request.last_name}`;
    }

    const readerId = fpGetUrlParameterByName("reader_id");
    if (readerId) {
        request.reader_id = readerId;
    }

    const auhtSettings = _fp.config?.fragments?.authentication_fragment?.settings;
    if (auhtSettings?.newsletter?.enabled && data?.custom_options) {
        request.newsletters = {};
        data?.custom_options.forEach(item => request.newsletters[item.option] = !!item?.value);
        Object.keys(request).filter(key => key.includes("custom_option_"))?.forEach(key => delete request[key]);
    }

    const mandatoryFields = _fp.config.payment?.required_customer_properties;
    if (mandatoryFields && mandatoryFields?.length > 0) {
        request.properties = {};
        mandatoryFields.forEach(prop => {
            if (data[prop]) {
                request.properties[prop] = data[prop];
                delete request[prop];
            }
        });


        if (Object.keys(request.properties)?.length === 0) { delete request.properties; }
    }

    if (_fp.config?.payment?.recaptcha?.enabled) {
        request.recaptcha_token = await fpGenerateRecaptchaTokenAsync();
    }

    return request;
}

function fpHandleSignupErrorResponse(err) {
    let errObj;

    try {
        errObj = JSON.parse(err.error);
    } catch {
        return; // Exit early if parsing fails
    }

    if (!errObj?.param) { return; } // Ensure `param` exists before updating

    _fp.metadata = _fp.metadata || {};
    _fp.metadata.auth_token = errObj.param;
}