import { authProviderEnum } from "../../core/enums/fp-auth-provider.enum";
import { authTypeEnum } from "../../core/enums/fp-auth-type.enum";
import { fpSetCustomerProperties } from "../../core/requests/customer/fp-customer-properties";
import { fpSetNewsletterRegistrations } from "../../core/requests/newsletter/fp-newsletter-registrations";
import { fpDispatchCustomEvent } from "../../core/utils/fp-custom-event";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpIsMemberCenterPage } from "../../core/utils/fp-is-member-center-page";
import { fpSetCustomerActiveAcessDetailsAsync } from "../../core/utils/fp-set-customer-active-access-details";
import { fpDeleteStorageValue, fpGetStorageValue, fpSetStorageValue } from "../../core/utils/fp-storage-handler";
import { fpShowNavigationFragment } from "../../fragments/navigation/fp-navigation-fragment";
import { fpAuthFlipPaySetUserDetails } from "./flip-pay/fp-set-user-details";
import { fpAuthVendorSetUserDetails } from "./vendor/fp-set-user-details";

export async function fpInitializeAuthenticationAsync(data) {
  try {
    const auhtSettings = _fp.config?.authentication;
    if (!auhtSettings) {
      console.error("FP:: Auth Settings Not Provided");
      return;
    }

    _fp.user = _fp.user ? _fp.user : {};
    if (auhtSettings.provider === authProviderEnum.FlipPay) {
      await fpAuthFlipPaySetUserDetails(data);
    } else if (auhtSettings.provider === authProviderEnum.Vendor) {
      fpAuthVendorSetUserDetails();
    } else if (auhtSettings.provider === authProviderEnum.Auth0) {
      console.log("Auth0");
    }

    // If user id exists the data have been already populated
    let customerDetails = null;
    const populateUserDetails = auhtSettings?.set_customer_details_on_sign_in && _fp?.user?.access_token && !_fp.user?.id;
    const isMemberCenterPage = fpIsMemberCenterPage();

    if (populateUserDetails && !isMemberCenterPage) {
      const storageUser = JSON.parse(JSON.stringify(_fp.user));
      customerDetails = await fpSetCustomerActiveAcessDetailsAsync(false);

      _fp.user.id = storageUser.id = customerDetails.customer.id;
      ["email", "name"].forEach((prop) => (customerDetails?.customer && customerDetails.customer[prop] ? (storageUser[prop] = customerDetails.customer[prop]) : null));

      // Purchase property only populated when an active pruchase exists
      _fp.user.subscriber = storageUser.subscriber = !!_fp?.user?.purchase;
      fpSetStorageValue("fp.user", storageUser);
    }

    if (data?.auth_type) {
      _fp.metadata = _fp.metadata ? _fp.metadata : {};
      _fp.metadata.authentication_type = data.auth_type;
    }

    fpDispatchCommonAuthCustomEvent(_fp.metadata?.authentication_type);
    fpAuthenticationCustomOptionsHandler(customerDetails);

    if (_fp.auth_flow || _fp.metadata?.authentication_type === authTypeEnum.SignUp) {
      await fpSetCustomerCustomProperties();
    }

    if (_fp.config?.navigation?.enabled) {
      fpShowNavigationFragment();
    }
  } catch (error) {
    fpErrorHandler("Authentication", error);
  }
}

export async function fpAuthenticationCustomOptionsHandler(customerDetails) {
  try {
    const customOptionsCookieProp = "fp.auth.custom_options";
    const customOptions = fpGetStorageValue(customOptionsCookieProp);
    fpDeleteStorageValue(customOptionsCookieProp);
    if (!customOptions) {
      return;
    }

    const eventData = {
      customer: {
        id: customerDetails?.customer?.id,
        email: customerDetails?.customer?.email,
        country_code: customerDetails?.customer?.country_code,
      },
      custom_options: customOptions,
    };

    const auhtSettings = _fp.config?.fragments?.authentication_fragment?.settings;
    if (auhtSettings?.newsletter?.enabled) {
      const newsletterRegistrations = {};
      customOptions.forEach((item) => (newsletterRegistrations[item.option] = !!item?.value));
      await fpSetNewsletterRegistrations(_fp.config.client.root_group_code, newsletterRegistrations);
    }

    fpDispatchCustomEvent(document, "fp_auth_custom_options", eventData);
  } catch (error) {
    fpErrorHandler("Authentication Custom Options", error, false);
  }
}

export function fpDispatchCommonAuthCustomEvent(authType) {
  try {
    let type = authTypeEnum.SignOut;
    if (_fp?.user?.access_token) {
      type = _fp?.user?.registered ? authTypeEnum.SignUp : authTypeEnum.SignIn;
    }

    if (authType) {
      // console.log("auth type", authType);
    }

    const eventDetails = {
      type: type,
      user_id: _fp?.user?.user_id ?? null,
      access_token: _fp?.user?.access_token ?? null,
    };

    const storagedAuthType = fpGetStorageValue("fp.auth_type", true);
    if (!storagedAuthType || storagedAuthType.type !== eventDetails.type) {
      fpSetStorageValue("fp.auth_type", eventDetails, null, true);
    }

    fpDispatchCustomEvent(document, "fp.authentication", eventDetails);
    fpDispatchCustomEvent(document, "fpAuthStatus", eventDetails);
  } catch (err) {
    console.error("FP:: Auth common custom event not dispatched");
  }
}

async function fpSetCustomerCustomProperties() {
  const mandatoryFiels = _fp.config.payment?.required_customer_properties;
  if (!mandatoryFiels || mandatoryFiels?.length === 0) {
    return Promise.resolve(null);
  }

  const request = {};
  mandatoryFiels.forEach((prop) => (_fp.metadata.auth_details[prop] ? (request[prop] = _fp.metadata.auth_details[prop]) : null));
  if (Object.keys(request)?.length === 0) {
    return;
  }

  await fpSetCustomerProperties(request);
}
