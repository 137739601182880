import { fpGetTemplateByName } from "./fp-set-template";

export function fpSpinnerHandler(type, parentElement) {
    const spinnerWrapperId = "fp-spinner-overlay";
    if (type === "hide") {
        let spinnerElement = document.getElementById(spinnerWrapperId);
        if (spinnerElement) {
            let spinnerParentElement = spinnerElement.parentElement;
            spinnerParentElement.removeChild(spinnerElement);
        }

        let spinnerEl = document.getElementById("fp-spinner");
        if (spinnerEl) { spinnerEl.remove(); }
        return;
    }

    // Spinner already active
    let spinnerElement = document.getElementById(spinnerWrapperId);
    if (spinnerElement) {
        return;
    }

    parentElement = parentElement ? parentElement : document.getElementsByClassName("flip-pay-wrapper")[0];
    parentElement = parentElement ? parentElement : document.querySelector("body");
    if (parentElement) {
        parentElement.style.position = "relative";
        spinnerElement = document.getElementById(spinnerWrapperId);
        if (!spinnerElement) {

            let spinnerEl = document.getElementById("fp-spinner");
            if (!spinnerEl) {
                const tempElement = document.createElement("div");
                tempElement.id = spinnerWrapperId;
                tempElement.innerHTML = fpGetTemplateByName("spinner_fragment");
                parentElement.appendChild(tempElement);
            }
        }
    }
}

