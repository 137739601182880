import { authTypeEnum } from "../../../../core/enums/fp-auth-type.enum";
import { journeyTypeEnum } from "../../../../core/enums/fp-journey-type.enum";
import { fpErrorHandler } from "../../../../core/utils/fp-error-handler";
import { fpSetAuthenticationNavigationListeners } from "../../../../core/utils/fp-set-auth-listeners";
import { fpSpinnerHandler } from "../../../../core/utils/fp-spinner";
import { fpTriggerJourney } from "../../../../journeys/fp-trigger-journey";
import { fpAuthenticationActionAsync } from "../../../../providers/authentication/fp-auth-actions";
import { fpFormHandleErrorMessage } from "../../utils/fp-cart-form-error-handler";

export function fpSetCartAccountListeners() {
    const formEl = document.querySelector("[data-fp-element='cart-account-form']");
    if (!formEl) {
        console.error("FP::", "Cart account form element is missing");
    }

    formEl.removeEventListener("submit", fpOnCartAccountFormSubmitAsync, false);
    formEl.removeEventListener("change", fpOnCartAccountFormChange, false);

    formEl.addEventListener("submit", fpOnCartAccountFormSubmitAsync, false);
    formEl.addEventListener("change", fpOnCartAccountFormChange, false);

    // Authentication listeners    
    fpSetAuthenticationNavigationListeners(authTypeEnum.SignIn);
}

async function fpOnCartAccountFormSubmitAsync(evt) {
    try {
        evt.preventDefault();
        fpSpinnerHandler("show");
        const response = await fpAuthenticationActionAsync(authTypeEnum.SilentSignUp, { form_query: "[data-fp-element='cart-account-form']" });
        if (!response.success) {
            fpSpinnerHandler("hide");
            fpFormHandleErrorMessage(response);
            return;
        }

        // TEMP TO REMOVE 
        _fp.user = { access_token: "dummy_token" };

        _fp.metadata.journey = journeyTypeEnum.Cart;
        fpTriggerJourney(journeyTypeEnum.Cart);
    } catch (err) {
        fpErrorHandler("Cart Account Sign Up", err);
        fpSpinnerHandler("hide", null);
    }
}


function fpOnCartAccountFormChange(evt) {
    evt.preventDefault();
    // const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());
    // console.log("form data 1", formData);

    // Clear error message
    fpFormHandleErrorMessage(null);
}
