import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpPurchaseDowngradeAsync(purchaseId, priceId) {
  try {
    const url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/user_downgrade`;
    await fpSendRequest("POST", url, JSON.stringify({ price_id: priceId }));
    return true;
  } catch (err) {
    console.error("FP::", err);
    throw err;
  }
}
