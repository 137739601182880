import { journeyTypeEnum } from "../../core/enums/fp-journey-type.enum";
import { paymentActionEnum } from "../../core/enums/fp-payment-action.enum";
import { paymentFragmentEnum } from "../../core/enums/fp-payment-fragment.enum";
import { fpGetCustomerMeAsync } from "../../core/requests/customer/fp-customer-me";
import { fpSetCustomerProperties } from "../../core/requests/customer/fp-customer-properties";
import { fpErrorHandler } from "../../core/utils/fp-error-handler";
import { fpInitMultiselect } from "../../core/utils/fp-multiselect";
import { fpSpinnerHandler } from "../../core/utils/fp-spinner";
import { fpTriggerJourney } from "../../journeys/fp-trigger-journey";
import { fpPaymentActionHandlerAsync } from "../../providers/payment/fp-payment-action-handler";


export async function fpSetCustomerMandatoryFieldsTemplateListeners() {
    try {
        const formEl = document.querySelector("[data-fp-element='form_customer_mandatory_properties']");
        formEl?.addEventListener("submit", (evt) => fpOnCustomerMandatoryFieldsFormSubmit(evt), false);

        const isCustomerPopulated = _fp.metadata.customer.properties && Object.keys(_fp.metadata.customer.properties)?.length > 0;
        if (!isCustomerPopulated) {
            await fpGetCustomerMeAsync();
        }

        fpPopulateCustomerMandatoryPropertiesForm(formEl);
        const multiSelectEls = document.querySelectorAll("[data-fp-element='multiselect']");
        multiSelectEls?.forEach(el => fpInitMultiselect(el));

        const actionEls = document.querySelectorAll("[data-fp-attribute]");
        actionEls?.forEach(el => el.addEventListener("click", fpMandatoryFieldsActionHandler, false));
    } catch (error) {
        console.error("FP::", error);
        throw error;
    }
}

async function fpOnCustomerMandatoryFieldsFormSubmit(evt) {
    try {
        evt.preventDefault();
        const formData = Object.fromEntries(new FormData(evt.currentTarget).entries());

        fpSpinnerHandler("show");
        await fpSetCustomerProperties(formData);
        const response = fpPaymentActionHandlerAsync(paymentFragmentEnum.PricesFragment, paymentActionEnum.ProcessPayment);
        if (response?.success) {
            fpTriggerJourney(journeyTypeEnum.PremiumContent);
        }
    } catch (err) {
        fpSpinnerHandler("hide");
        fpErrorHandler("Customer Mandatory Properties Form", err);
    }
}

function fpPopulateCustomerMandatoryPropertiesForm(formEl) {
    Object.keys(_fp.metadata.customer.properties).forEach((prop) => {
        const inputEl = formEl.querySelector(`input[name=${prop}]`);
        if (inputEl && _fp.metadata.customer.properties[prop]) {
            inputEl.value = _fp.metadata.customer.properties[prop];
            return;
        }

        const selectEl = formEl.querySelector(`select[name=${prop}]`);
        if (selectEl && _fp.metadata.customer.properties[prop]) {
            selectEl.value = _fp.metadata.customer.properties[prop];
        }
    });
}

function fpMandatoryFieldsActionHandler(e) {
    e.preventDefault();
    const actionType = e.currentTarget.dataset.fpAttribute;
    if (actionType === "notification_close") {
        fpTriggerJourney(journeyTypeEnum.Prices);
    }
}