import { fpSendRequest } from "../../utils/fp-http-request";

export async function fpExecutePurchaseAsync(purchaseId, data) {
    let url = `${_fp.config.base_url_transaction}/purchases/${purchaseId}/execute`;
    if (!_fp.user?.access_token && _fp.metadata.selected_price.allow_anonymous) {
        url = `${_fp.config.base_url_transaction}/purchases/${_fp.config.client.id}/${purchaseId}/execute`;    
    }

    const request = {
        payment_intent_id: data.payment_intent_id ? data.payment_intent_id : null,
        setup_intent_id: data.setup_intent_id ? data.setup_intent_id : null
    };

    if (data.paymentIntentId) {        
        request.payment_intent_id = data.paymentIntentId;
    }

    if (data.setupIntentId) {
        request.setup_intent_id = data.setupIntentId;
    }

    return await fpSendRequest("POST", url, JSON.stringify(request));
}