/* eslint-disable sonarjs/cognitive-complexity */
import { fpSetRequestCode } from "../../../access/server-side/fp-access-request";
import { fpGenerateRecaptchaTokenAsync } from "../../../providers/recaptcha/fp-generate-recaptcha-token";
import { fpGetJourneyConfig } from "../../utils/fp-get-journeys-config";
import { fpGetUTMParameters } from "../../utils/fp-get-utm-parameters";
import { fpSendRequest } from "../../utils/fp-http-request";
import { fpSpinnerHandler } from "../../utils/fp-spinner";
import { fpTrackUserAction } from "../../utils/fp-tracking";

export async function fpCreatePurchaseAsync(data) {
    try {
        fpSpinnerHandler("show", null);
        let url = `${_fp.config.base_url_transaction}/purchases`;
        if (!_fp.user?.access_token && _fp.metadata?.selected_price?.allow_anonymous) {
            url = `${_fp.config.base_url_transaction}/purchases/${_fp.config.client.id}`;
        }

        const request = fpSetCreatePurchaseRequest(data);
        fpTrackUserAction("fp_purchase_request", null, "purchase", null, request);

        const billingEmailNotSet = (!request.billing_email || request.billing_email?.match(/^ *$/) !== null);
        if (!_fp.user?.access_token && billingEmailNotSet && !["paypal"].includes(data.payment_method_type)) {
            throw new Error({ error: { message: "Billing email not set" } });
        }

        if (_fp.config?.payment?.recaptcha?.enabled) {
            request.recaptcha_token = await fpGenerateRecaptchaTokenAsync();
        }

        const response = await fpSendRequest("POST", url, JSON.stringify(request));
        if (!!response && !!response.executed) {
            response.isNewPurchase = true;
            // if (!_fp.show_coupon_code_first && !_fp.upgrade_flow) {
            //     fp_dom_helpers.showSnackbarMessage(null, "Purchase successful..", "success");
            // }
        }

        delete _fp?.metadata?.coupon;

        fpSpinnerHandler("hide", null);
        return response;
    } catch (err) {
        fpSpinnerHandler("hide", null);
        throw err;
    }
}

function fpSetCreatePurchaseRequest(data) {
    const fpJourneyConfig = fpGetJourneyConfig();

    let request = {
        site: _fp.config.client.site,
        item_group_code: fpJourneyConfig?.group_code,
        code: fpSetRequestCode(),
        price_id: _fp.metadata?.selected_price?.id,
        browser_session_id: _fp.metadata?.browser_session_id,
        description: document.title,
        referrer: document.referrer,
        upgrade: _fp.upgrade_flow ?? false,
        link: window.location.toString(),
        href: window.location.toString(),
        language: _fp?.config?.client?.language ?? null,
        card: data?.card ?? null,

        purchase_intent_id: data?.purchase_intent_id ?? null,
        payment_provider: _fp?.config?.payment?.provider ?? "stripe",
        payment_method_type: data?.payment_method_type ?? null,
        payment_intent_id: data?.payment_intent_id,

        amount: _fp.metadata?.selected_price?.variable_amount ? _fp.metadata.selected_price.amount : null,
        original_purchase_id: _fp.metadata?.access_status?.granted_by_purchase ? _fp.metadata.access_status.granted_by_purchase.id : null,

        tags: _fp.config.tags,
        coupon_code: _fp.metadata?.coupon?.code,

        name: _fp.user?.user_name,
        organisation: data?.organisation ? data.organisation : _fp.user?.billing_details?.organisation,

        gift: _fp.premium_wrapper?.dataset?.gift === "voucher",
        access_badge: _fp.config?.badge,
        ...(data?.card_id && { card_id: data?.card_id }),
    };

    if (_fp?.metadata?.selected_placeholder_price) {
        request.placeholder_price_id = _fp?.metadata?.selected_placeholder_price.id;
    }

    if (data?.paymentIntentId) {
        request.payment_intent_id = data.paymentIntentId;
    }

    // AuthorizeNet card token
    if (data?.payment_token) {
        request.payment_token = data.payment_token;
    }

    const billingDetails = _fp.user?.billing_details && _fp.user?.billing_details.email ? _fp.user?.billing_details : data?.billing_details;
    if (billingDetails) {
        request.billing_email = billingDetails.email ?? null;
        request.billing_name = billingDetails.name ?? null;
        request.password = billingDetails.password ?? null;
    }

    if (!request.code && _fp.metadata?.selected_price?.default_for_items) {
        request.code = new Date().toISOString();
    }

    // Set UTM Parameters
    const utmParameters = fpGetUTMParameters();
    if (Object.keys(utmParameters).length > 0) {
        Object.keys(utmParameters).forEach((param) => request[param] = encodeURIComponent(utmParameters[param]));
    }

    // Home delivery details
    if (_fp.user?.address) {
        fpSetPurchaseAddressDetails(request);
    }

    // Send redirect uri when a customer not signed in 
    if (!_fp.user || !_fp.user.access_token) {
        let paramChar = window.location.href.includes("?") ? "&" : "?";
        request.redirect_uri = `${window.location.href}${paramChar}verify_email=>>Token<<`;
    }

    if (_fp.metadata?.subscription_action && _fp.metadata?.subscription_action?.type) {
        request.upgrade = _fp.metadata.subscription_action.type === "upgrade";
        request.downgrade = _fp.metadata.subscription_action.type === "downgrade";
        request.original_purchase_id = _fp.metadata.subscription_action.original_purchase_id;
    }

    // Check payment form element to collect all fields
    const paymentForm = document.querySelector("[data-fp-element='payment-form']");
    const mandatoryFields = document.querySelector("[data-fp-element='mandatory-properties']");
    if (paymentForm instanceof HTMLFormElement && !!mandatoryFields) {
        const formData = new FormData(paymentForm);

        // Convert form data to an object, filtering out empty values and "price_id"        
        const formObject = Object.fromEntries([...formData].filter(([key, value]) => key.trim() !== "price_id" && value.trim() !== ""));

        // If formObject contains more than one key, merge into request.properties
        if (Object.keys(formObject).length > 1) {
            request.properties = { ...request.properties, ...formObject };
        }
    }

    Object.keys(request).forEach(key => !request[key] ? delete request[key] : null);
    return request;
}

function fpSetPurchaseAddressDetails(request) {
    request.name = _fp.user.address.user_name ? _fp.user.address.user_name : request.name;
    request.address_country_code = _fp.user.address.country_code ? _fp.user.address.country_code : null;

    let addr = _fp.user.address;
    let addrFields = ["phone_number", "delivery_notes", "address_id", "full", "address_1", "address_2", "address_3", "address_4", "address_5", "post_code", "address_latitude", "address_longitude"];
    for (let af = 0; af < addrFields.length; af++) {
        if (addr[addrFields[af]]) {
            let addrKey = addrFields[af] === "full" ? "address" : addrFields[af];
            request[addrKey] = addr[addrFields[af]];
        }
    }
}